<template>
  <div class="bg" v-show="visible" @click="hide">
    <div class="dialog" @click.stop>
      <div class="title">绑定微信</div>
      <img class="close-button" src="../../../assets/homepage/dialog-close.svg" @click="hide" />
      <div class="qr-box">
        <webview v-if="wechatScanUrl" ref="webview" class="wechat-scan" :src="wechatScanUrl"></webview>
        <div class="top-cover"></div>
        <div class="bottom-cover"></div>
        <div class="loading-tip" v-show="isLoadingWechat">
          <img src="../../../assets/icon/icon_loading.svg" />
          <div>加载中...</div>
        </div>
      </div>
      <div class="tip">微信扫码绑定</div>
    </div>
  </div>
</template>
<script>
import { getApiBaseUrl, getAppChannel } from '../../../common/const'
import { getUserInfo, setUserInfo } from '../../../utils/auth'
import { bindWechat } from '../../../api/user'

export default {
  name: 'BindWxDialog',
  data () {
    return {
      visible: false,
      wechatScanUrl: '',
      isLoadingWechat: false,
      wechatData: null
    }
  },
  methods: {
    show () {
      this.visible = true
      this.wechatScanUrl = `${getApiBaseUrl()}/anon/audio/user/login/wechatScan?ch=${getAppChannel()}`
      this.$nextTick(() => {
        this.initWechatScan()
      })
    },
    hide () {
      this.visible = false
    },
    initWechatScan() {
      this.$refs.webview.addEventListener('did-start-loading', () => {
        this.isLoadingWechat = true
      })
      this.$refs.webview.addEventListener('dom-ready', () => {
        this.isLoadingWechat = false
        this.$refs.webview.insertCSS(`
          body {
            color: white;
          }
        `)
        if (this.$refs.webview.getURL().indexOf('wechatResult') !== -1) {
          this.$refs.webview.executeJavaScript('document.body.innerText').then(res => {
            this.wechatData = res
            this.hide()
            this.requestBind({data: this.wechatData})
          })
        }
      })
    },
    requestBind(data) {
      bindWechat(data).then(res => {
        if (res.data.code === 0) {
          const userInfo = getUserInfo()
          userInfo.user.isBind = 1
          userInfo.user.wechatNick = res.data.data.nickname
          setUserInfo(userInfo)
          this.$emit('updateUserInfo')
          this.$message('绑定成功', 'success')
        } else {
          this.$message(res.data.desc, 'error')
        }
      })
    }
  }
}
</script>
<style scoped lang="scss">
.bg {
  position: fixed;
  font-family: PingFangSC-Regular, PingFang SC;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,0.1);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  .dialog {
    display: flex;
    flex-direction: column;
    width: 320px;
    height: 392px;
    background: rgba(255,255,255,1);
    border: 1px solid rgba(224,224,224,1);
    border-radius: 8px;
    box-shadow:  0 0 10px 0 rgba(0,0,0,0.16);
    position: relative;
    .title {
      margin-top: 18px;
      height: 80px;
      font-size: 16px;
      font-weight: 500;
      color: rgba(51,51,51,1);
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .close-button {
      position: absolute;
      top: 14px;
      right: 14px;
      width: 28px;
      height: 28px;
      cursor: pointer;
    }
    .qr-box {
      width: 200px;
      height: 200px;
      margin: 0 auto;
      position: relative;
      overflow: hidden;
      .wechat-scan {
        position: absolute;
        top: -25px;
        left: -58px;
        width: 320px;
        height: 420px;
      }
      .top-cover {
        height: 20px;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        background: white;
      }
      .bottom-cover {
        height: 20px;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background: white;
      }
      .loading-tip {
        position: absolute;
        top: 55px;
        left: 55px;
        width: 90px;
        height: 90px;
        border-radius: 2px;
        border: 1px solid #D2E1FF;
        background: #EDF2FC;
        display: flex;
        flex-direction: column;
        align-items: center;
        & img {
          width: 22px;
          height: 22px;
          margin-top: 22px;
          animation: rotate 1s linear infinite;
          -webkit-animation: rotate 1s linear infinite;
        }
        & div {
          font-size: 10px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #409EFF;
          margin-top: 15px;
        }
      }
    }
    .tip {
      margin: 32px auto 0 auto;
      font-size: 14px;
      font-weight: normal;
      color: rgba(102,102,102,1);
    }
  }
}
</style>
