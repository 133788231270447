<template>
  <transition name="fade">
    <router-view></router-view>
  </transition>
</template>
<script>
import { createEmptyDoc } from '@/api/doc'
import { getProjectId } from '@/utils/auth'
import { parseFileNameForDoc } from '@/utils/util'
import { getH5DocUrl } from '@/common/const'
import ahamoment from "ahamoment.js"
import { getUid } from '../utils/auth'

export default {
  name: 'MoyinIndex',
  data () {
    return {
      isOnline: true,
      pendingTaskList: []
    }
  },
  mounted () {
    this.isOnline = navigator.onLine
    window.addEventListener('online', this.onOnline)
    window.addEventListener('offline', this.onOffline)
    this.restorePendingDocTaskList()
    this.$bus.$on('upload-video-selected', this.onUploadVideoSelected)
    var uid = getUid()
    uid && ahamoment.identify(uid)
    ahamoment.identify()
  },
  destroyed () {
    this.$bus.$off('upload-video-selected', this.onUploadVideoSelected)
  },
  methods: {
    onOnline: function () {
      this.isOnline = true
      this.restorePendingDocTaskList()
    },
    onOffline: function () {
      this.isOnline = false
    },
    onRecordVideoRecorded: function (event, filePath, options) {
      var fileName = parseFileNameForDoc(filePath)
      this.requestAddDoc(fileName, docInfo => {
        this.$docService.upload(docInfo.docId, filePath, options, [])
        this.$bus.$emit('doc-added', docInfo)
        this.gotoDocDetail(docInfo)
      }, () => {
        this.addFailedPendingTask({
          type: 'file',
          path: filePath,
          title: fileName,
          options: options
        })
      })
    },
    onRecordVideoUrlRecorded: function (event, url, options) {
      this.requestAddDoc(options.name, docInfo => {
        this.$docService.uploadUrl(docInfo.docId, url, options)
        this.$bus.$emit('doc-added', docInfo)
        this.gotoDocDetail(docInfo)
      }, () => {
        this.addFailedPendingTask({
          type: 'url',
          url: url,
          title: options.name,
          options: options
        })
      })
    },
    onUploadVideoSelected: function (file) {
      var fileName = parseFileNameForDoc(file.name)
      const videoDom = document.createElement('video')
      const _this = this
      videoDom.onloadedmetadata = () => {
        const options = {
          duration: parseInt(videoDom.duration * 1000),
          width: videoDom.videoWidth,
          height: videoDom.videoHeight
        }
        _this.requestAddDoc(fileName, docInfo => {
          _this.$docService.upload(docInfo.docId, file, options, [])
          _this.$bus.$emit('doc-added', docInfo)
          _this.gotoDocDetail(docInfo)
        }, () => {
          _this.addFailedPendingTask({
            type: 'file',
            file: file,
            title: fileName,
            options: options
          })
        })
      }
      videoDom.onerror = (err) => {
        console.log(err)
        _this.requestAddDoc(fileName, docInfo => {
          _this.$docService.upload(docInfo.docId, file, {}, [])
          _this.$bus.$emit('doc-added', docInfo)
          _this.gotoDocDetail(docInfo)
        }, () => {
          _this.addFailedPendingTask({
            type: 'file',
            file: file,
            title: fileName,
            options: {}
          })
        })
      }
      videoDom.src = URL.createObjectURL(file)
    },
    gotoDocDetail: function (docInfo) {
      if (this.$route.path !== '/moyin/detail') {
        this.$router.push({
          path: '/moyin/detail',
          query: {
            docId: docInfo.docId
          }
        })
      } else {
        this.$bus.$emit('doc-switch', docInfo.docId)
      }
      this.$clipboard(getH5DocUrl(docInfo.docId))
      this.$message('链接已复制', 'success')
    },
    gotoDefaultGroup: function () {
      this.$router.replace({
        path: '/moyin/homepage/group',
        query: {
          id: 'default',
          name: '默认分组'
        }
      })
    },
    requestAddDoc: function (title, callback, failCallback) {
      createEmptyDoc({
        projectId: getProjectId(),
        title: title
      }).then(res => {
        if (res.data.code === 0) {
          var docId = res.data.data.docId
          var docInfo = {
            id: docId,
            docId: docId,
            name: res.data.data.title,
            docTitle: res.data.data.title,
            createTime: res.data.data.createTime,
            updateTime: res.data.data.updateTime,
            viewTime: res.data.data.updateTime,
            nodekey: docId,
            type: 'file',
            upperName: '/'
          }
          callback(docInfo)
        } else {
          this.$message(res.data.desc, 'error')
          failCallback()
        }
      }).catch(err => {
        console.log(err)
        failCallback()
      })
    },
    restorePendingDocTaskList: function () {
      if (!this.isOnline) {
        return
      }
      var taskList = this.pendingTaskList
      this.pendingTaskList = []
      taskList.forEach(task => {
        this.requestPendingTask(task)
      })
    },
    requestPendingTask: function (task) {
      if (task.type === 'file') {
        const fs = require('fs')
        if (fs.existsSync(task.path)) {
          this.requestAddDoc(task.title, docInfo => {
            this.$docService.upload(docInfo.docId, task.path, task.options, [])
            this.$bus.$emit('doc-added', docInfo)
          }, () => {
            this.addFailedPendingTask(task)
          })
        }
      } else if (task.type === 'url') {
        this.requestAddDoc(task.title, docInfo => {
          this.$docService.uploadUrl(docInfo.docId, task.url, task.options)
          this.$bus.$emit('doc-added', docInfo)
        }, () => {
          this.addFailedPendingTask(task)
        })
      }
    },
    addFailedPendingTask: function (task) {
      this.pendingTaskList.push(task)
    }
  }
}
</script>
<style scoped lang="scss">
</style>
