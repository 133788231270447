import { Extension, Plugin } from 'tiptap'
import { Decoration, DecorationSet } from 'prosemirror-view'
import { TextSelection } from 'prosemirror-state'

export default class HighlightTime extends Extension {
  constructor(options = {}) {
    super(options)

    this.result = null
    this._updating = false
    this.dom = null
    this.findClass = 'word-normal'
    this.dataTime = ''
  }

  get name() {
    return 'highlightTime'
  }

  get defaultOptions() {
    return {
      autoSelectNext: true,
      searching: false,
      caseSensitive: false,
      disableRegex: true,
      alwaysSearch: false
    }
  }

  commands() {
    return {
      highlightTime: attrs => this.find(attrs),
      clearTime: () => this.clear()
    }
  }

  get decorations() {
    const { from, to } = this.result
    return [Decoration.node(from, to, { class: this.findClass, 'data-time': this.dataTime, id: this.dom.id })]
  }

  _search() {
    if (!this.dom) {
      this.result = { from: 0, to: 0 }
      return
    }
    const { from, to } = this.getMarkPos(this.dom)
    this.result = { from, to }
  }

  find({ dom, className, dataTime }) {
    if (className) {
      this.findClass = className
    }
    if (dataTime) {
      this.dataTime = dataTime
    }
    return (state, dispatch) => {
      if (dom) {
        this.dom = dom
      }
      this.updateView(state, dispatch)
    }
  }

  clear() {
    return (state, dispatch) => {
      this.findClass = ''
      this.dataTime = ''
      this.dom = null
      this.updateView(state, dispatch)
    }
  }

  updateView({ tr }, dispatch) {
    this._updating = true
    const { from } = this.editor.state.selection
    const resolvedFrom = this.minMax(from, 0, tr.doc.content.size)
    const resolvedEnd = this.minMax(from, 0, tr.doc.content.size)
    const selection = TextSelection.create(tr.doc, resolvedFrom, resolvedEnd)
    const transaction = tr.setSelection(selection)
    dispatch(transaction)
    this._updating = false
  }

  createDeco(doc) {
    this._search()
    const deco = this.decorations ? DecorationSet.create(doc, this.decorations) : []
    return deco
  }

  getMarkPos(dom) {
    const pos = this.editor.view.posAtDOM(dom)
    return { from: pos, to: pos + dom.innerText.length }
  }

  minMax(value = 0, min = 0, max = 0) {
    return Math.min(Math.max(parseInt(value, 10), min), max)
  }

  get plugins() {
    return [
      new Plugin({
        state: {
          init() {
            return DecorationSet.empty
          },
          apply: (tr, old) => {
            if (this._updating) {
              return this.createDeco(tr.doc)
            }

            if (tr.docChanged) {
              return old.map(tr.mapping, tr.doc)
            }

            return old
          }
        },
        props: {
          decorations(state) {
            return this.getState(state)
          }
        }
      })
    ]
  }
}
