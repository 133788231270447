import { Extension } from 'tiptap'
import { history, undo, redo, undoDepth, redoDepth } from 'prosemirror-history'
// import { UndoRedoHistory } from '../../../store/plugins/history'

export default class History extends Extension {
  get name() {
    return 'history'
  }

  get defaultOptions() {
    return {
      depth: 100,
      newGroupDelay: 500
    }
  }

  keys() {
    const keymap = {
      // 'Mod-z': this.magicUndo,
      // 'Mod-y': this.magicRedo
    }

    return keymap
  }

  get plugins() {
    return [
      history({
        depth: this.options.depth,
        newGroupDelay: this.options.newGroupDelay
      })
    ]
  }

  commands() {
    return {
      undo: () => this.magicUndo,
      redo: () => this.magicRedo,
      undoDepth: () => this.magicUndoDepth,
      redoDepth: () => this.magicRedoDepth
    }
  }

  magicUndo(state, dispatch) {
    // const history = UndoRedoHistory.getInstance()
    // history.undo()
    undo(state, dispatch)
  }

  magicRedo(state, dispatch) {
    // const history = UndoRedoHistory.getInstance()
    // history.redo()
    redo(state, dispatch)
  }

  magicUndoDepth(state) {
    return undoDepth(state)
  }

  magicRedoDepth(state) {
    return redoDepth(state)
  }
}
