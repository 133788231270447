<template>
  <div class="switch" @click="clickSwitchButton" :class="{ 'switch-checked' : value }"></div>
</template>
<script>
export default {
  name: 'SwitcherControl',
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    clickSwitchButton: function () {
      this.$emit('input', !this.value)
      this.$emit('change', !this.value)
    }
  }
}
</script>
<style scoped lang="scss">
.switch {
  width: 32px;
  height: 20px;
  background-color: rgba(222,224,227,1);
  border-radius: 10px;
  position: relative;
  transition: all .3s;
  cursor: pointer;
}
.switch::after {
  content: "";
  width: 16px;
  height: 16px;
  background-color: rgba(255,255,255,1);
  border-radius: 8px;
  position: absolute;
  top: 2px;
  left: 2px;
  transition: all .3s;
}
.switch-checked {
  background-color: rgba(35,108,251,1);
}
.switch-checked::after {
  left: 100%;
  margin-left: -18px;
}
</style>