<template>
  <div class="content-out">
    <div class="content">
      <div class="left">
        <img class="logo" src="../../assets/login/logo.svg" />
        <div class="login-card" style="margin-top:72px;" v-if="status === 'phone'">
          <div class="phone-box">
            <div class="group phone-group">
              <input ref="phone" name="phone" type="text" @focus="onFocused($event, 'phone')" @blur="onBlured($event, 'phone')" @input="onInput($event, false)" v-model="phone" @keyup.enter="onEnterPhone" /><label @click="clickPhoneText">手机号</label>
            </div>
            <div v-if="phoneEmptyError" class="error-tip">请输入手机号</div>
            <div v-else class="space"></div>
            <div class="code-line">
              <div class="group code-group">
                <input ref="code" type="text" name="code" @focus="onFocused($event, 'code')" @blur="onBlured($event, 'code')" @input="onInput($event, false)" v-model="code" @keyup.enter="onEnterCode" /><label @click="clickCodeText">验证码</label>
              </div>
              <button ref="codeButton" class="code-button" @click="clickCode(false)" disabled>{{ codeButtonText }}</button>
            </div>
            <div v-if="codeEmptyError" class="error-tip">请输入验证码</div>
            <div v-else class="space"></div>
            <button ref="loginButton" class="login-button" @click="clickLogin()" disabled>手机号一键登录</button>
            <div class="license">登录即代表您已阅读并同意<span @click="clickLicense">《用户协议》</span>和<span @click="clickPrivacy">《隐私政策》</span></div>
          </div>
          <div class="or-line">
            <div class="line"></div>
            <div class="text">或</div>
            <div class="line"></div>
          </div>
          <div class="switch-wechat-button" @click="clickWechatLogin">
            <img src="../../assets/login/wechat.svg" />
            <div>微信登录</div>
          </div>
        </div>
        <div class="login-card" v-else-if="status === 'wechat'">
          <div class="title-center">微信登录</div>
          
          <div class="wx-login">
            <img v-if="wx.status === 'open' || wx.status === 'waitting'" :src="wx.imgUrl" alt="">
            <template v-else-if="wx.status === 'success'">
              <img style="width: 100px; height: 100px" src="../../assets/icon/success.svg" alt="">
              <p>扫码成功</p>
              <p>请在微信上确认登录</p>
            </template>
            <template v-else-if="wx.status === 'cancel'">
              <p>扫码取消</p>
              <p>刷新二维码</p>
            </template>
            <template v-else>
              <p>二维码过期</p>
              <p>请刷新二维码</p>
            </template>
          </div>
          <!-- <div class="qr-box">
            <div ref="webview" class="wechat-iframe" :src="wechatScanUrl" >
              <wxlogin
                v-if="wx.appid && wx.redirect_uri"
                :appid="wx.appid"
                scope="snsapi_login"
                :redirect_uri="redirect_uri"
                :href="wx.href"
                :state="wx.state"
              ></wxlogin>
            </div>
            <div class="top-cover"></div>
            <div class="bottom-cover"></div>
            <div class="loading-tip" v-show="isLoadingWechat">
              <img src="../../assets/icon/icon_loading.svg" />
              <div>加载中...</div>
            </div>
          </div> -->
          <div class="scan-tip">请使用微信扫一扫登录“魔音助手”</div>
          <div class="license">登录即代表您已阅读并同意<span @click="clickLicense">《用户协议》</span>和<span @click="clickPrivacy">《隐私政策》</span></div>
          <div class="or-line">
            <div class="line"></div>
            <div class="text">或</div>
            <div class="line"></div>
          </div>
          <div class="switch-phone-button" @click="clickPhoneLogin">
            <img src="../../assets/login/phone.svg" />
            <div>手机号登录</div>
          </div>
        </div>
        <div class="login-card" v-else-if="status === 'bind'">
          <div class="title-center">绑定手机号</div>
          <div class="bind-tip">根据相关政策要求，登录用户需绑定手机号，才可以使用全部功能</div>
          <div class="group bind-group">
            <input ref="phone" name="phone" type="text" @focus="onFocused($event, 'phone')" @blur="onBlured($event, 'phone')" @input="onInput($event, true)" v-model="bindPhone" @keyup.enter="onEnterPhone" /><label @click="clickPhoneText">手机号</label>
          </div>
          <div v-if="phoneEmptyError" class="error-tip">请输入手机号</div>
          <div v-else class="space"></div>
          <div class="code-line">
            <div class="group code-group">
              <input ref="code" type="text" name="code" @focus="onFocused($event, 'code')" @blur="onBlured($event, 'code')" @input="onInput($event, true)" v-model="bindCode" @keyup.enter="onEnterCode" /><label @click="clickCodeText">验证码</label>
            </div>
            <button ref="codeButton" class="code-button" @click="clickCode(true)" disabled>{{ codeButtonText }}</button>
          </div>
          <div v-if="codeEmptyError" class="error-tip">请输入验证码</div>
          <div v-else class="space"></div>
          <button ref="loginButton" class="login-button bind-button" @click="clickWechatBind()" disabled>完成绑定(1/2)</button>
        </div>
         <div class="login-card"  v-else-if="status === 'bindinfo'">
            <div class="title-center" style="margin-bottom: 30px">欢迎来到魔音助手</div>
            <div class="group bind-group">
              <input  type="text" @focus="onFocused($event, 'realName')" @input="inputInfo" @blur="onBlured($event, 'realName')"  v-model="userInfo.realName"  /><label >姓名</label>
            </div>
            <div v-if="infoEmptyError.realName" class="error-tip">请输入姓名</div>
            <div v-else class="space"></div>
            <div class="group bind-group">
              <input  type="text" @focus="onFocused($event, 'companyName')" @input="inputInfo" @blur="onBlured($event, 'companyName')" v-model="userInfo.companyName"  /><label >公司</label>
            </div>
            <div v-if="infoEmptyError.companyName" class="error-tip">请输入公司</div>
            <div v-else class="space"></div>
            <div class="group bind-group">
              <input  type="text" @focus="onFocused($event, 'position')" @input="inputInfo" @blur="onBlured($event, 'position')" v-model="userInfo.position"  /><label >职位</label>
            </div>
            <div v-if="infoEmptyError.position" class="error-tip">请输入职位</div>
            <div v-else class="space"></div>
            <div class="group bind-group">
              <input  type="text" @focus="onFocused($event, 'email')" @input="inputInfo" @blur="onBlured($event, 'email')" v-model="userInfo.email" /><label >邮箱</label>
            </div>
            <div v-if="infoEmptyError.email" class="error-tip">请输入邮箱</div>
            <div v-else class="space"></div>
            <button :disabled='infodisabled'  class="login-button bind-button" @click="clickInfoBind()">{{bindPhone ? '完成注册, 进入魔音(2/2)' : '完成注册, 进入魔音'}}</button>
          </div>

      </div>
      <div class="right">
        <img class="splash" src="../../assets/login/splash.png" />
      </div>
    </div>
  </div>
</template>
<script>
import { setProjectId, setUserInfo, removeUserInfo, setUniqueId } from '@/utils/auth'
import { getProjectList } from '@/api/project'
import { loginByWechat, sendSmsCode, userLogin, bindUserInfo, getWeChatUrl, checkUrl } from '@/api/account'
import { getApiBaseUrl, getAppChannel } from '@/common/const'
import { getProjectId, getUserInfo } from '../../utils/auth'
import { getProjectDetail } from '../../api/project'
export default {
  name: 'LoginIndex',
  // components: {wxlogin},
  data () {
    return {
      phone: '',
      code: '',
      phoneEmptyError: false,
      codeEmptyError: false,
      codeButtonText: '获取验证码',
      smsSendTime: 0,
      codeInterval: null,
      status: 'phone',
      wechatScanUrl: '',
      wechatData: '',
      bindPhone: '',
      bindCode: '',
      isLoadingWechat: false,
      domList: [],
      userInfo: {
        companyName: '', // 企业
        position: '', // 职位
        realName: '', // 姓名
        email: ''
      },
      infoEmptyError: {
        companyName: false, // 企业
        position: false, // 职位
        realName: false, // 姓名
        email: false
      },
      projectId: '',
      infodisabled: true,
      wx: {
        appid: '',
        redirect_uri: '',
        href: '',
        state: 'zero',
        imgUrl: '',
        status: ''
      }
    }
  },
  mounted () { 
    var userInfo = getUserInfo()
    var projectId = getProjectId()
    if (userInfo != null && projectId != null) {
      getProjectDetail({
        projectId: projectId
      }).then(response => {
        if (response.data.code === 0) {
          this.$router.replace('/moyin/homepage')
        } else if (response.data.code !== 1001 && response.data.code !== 1002) {
          console.log('Error Desc:' + response.data.desc)
          this.$router.replace('/moyin/homepage')
        }
      }).catch(err => {
        console.log(err)
        this.$router.replace('/moyin/homepage')
      })
    }
  },
  methods: {
    resetDefaultState: function () {
      if (this.codeInterval != null) {
        clearInterval(this.codeInterval)
        this.codeInterval = null
      }
      this.phone = ''
      this.code = ''
      this.phoneEmptyError = false
      this.codeEmptyError = false
      this.codeButtonText = '获取验证码'
      this.smsSendTime = 0
      this.status = 'phone'
      this.wechatScanUrl = ''
      this.wechatData = ''
      this.bindPhone = ''
      this.bindCode = ''
      this.isLoadingWechat = false
      this.$nextTick(() => {
        this.$refs.phone.nextSibling.style.top = '16px'
        this.$refs.phone.nextSibling.style.fontSize = '14px'
        this.$refs.code.nextSibling.style.top = '16px'
        this.$refs.code.nextSibling.style.fontSize = '14px'
      })
    },
    onFocused: function (event, type) {
      var labelDom = event.target.nextSibling
      labelDom.style.top = '8px'
      labelDom.style.fontSize = '12px'
      if (type === 'phone') {
        this.phoneEmptyError = false
      } else if (type === 'code') {
        this.codeEmptyError = false
      } else {
        if (type !== 'phone' || type !== 'code') {
          this.infoEmptyError[type] = false
        }
      }
    },
    onBlured: function (event, type) {
      var targetDom = event.target
      var labelDom = targetDom.nextSibling
      if (targetDom.value === '') {
        labelDom.style.top = '16px'
        labelDom.style.fontSize = '14px'
        if (type === 'phone') {
          this.phoneEmptyError = true
        } else if (type === 'code') {
          this.codeEmptyError = true
        }
      }
    },
    onInput: function (event, isBind) {
      var targetDom = event.target
      targetDom.value = targetDom.value.replace(/[^\d]/g, '')
      var codeButton = this.$refs.codeButton
      var loginButton = this.$refs.loginButton
      var time = new Date().getTime()
      if (isBind) {
        if (this.bindPhone !== '' && time - this.smsSendTime >= 60000) {
          codeButton.disabled = false
        } else {
          codeButton.disabled = true
        }
        if (this.bindPhone !== '' && this.bindCode !== '') {
          loginButton.disabled = false
        } else {
          loginButton.disabled = true
        }
      } else {
        if (this.phone !== '' && time - this.smsSendTime >= 60000) {
          codeButton.disabled = false
        } else {
          codeButton.disabled = true
        }
        if (this.phone !== '' && this.code !== '') {
          loginButton.disabled = false
        } else {
          loginButton.disabled = true
        }
      }
    },
    updateCodeButtonText: function () {
      this.smsSendTime = new Date().getTime()
      this.codeButtonText = '60s后重发'
      var _this = this
      this.codeInterval = setInterval(function () {
        var countDown = parseInt((_this.smsSendTime + 60000 - new Date().getTime()) / 1000)
        if (countDown <= 0) {
          clearInterval(_this.codeInterval)
          _this.codeInterval = null
          _this.codeButtonText = '获取验证码'
          _this.$refs.codeButton.disabled = false
        } else {
          _this.codeButtonText = countDown + 's后重发'
        }
      }, 500)
    },
    clickCode: function (isBind) {
      if (!isBind) {
        removeUserInfo()
      }
      var _this = this
      this.$refs.codeButton.disabled = true
      sendSmsCode({
        mobile: isBind ? this.bindPhone : this.phone
      }).then(response => {
        if (response.data.code === 0) {
          _this.updateCodeButtonText()
        } else {
          _this.$message(response.data.desc, 'error')
        }
      })
    },
    clickLogin: function () {
      var _this = this
      this.$refs.loginButton.disabled = true
      userLogin({
        mobile: this.phone,
        code: this.code
      }).then(response => {
        if (response.data.code === 0) {
          setUserInfo(response.data.data)
          _this.loadProjectId()
        } else {
          _this.$refs.loginButton.disabled = false
          _this.$message(response.data.desc, 'error')
        }
      }).catch(err => {
        console.log(err)
        _this.$refs.loginButton.disabled = false
      })
    },
    loadProjectId: function () {
      var _this = this
      getProjectList({})
        .then(response => {
          if (response.data.code === 0) {
            if (response.data.data.length > 0) {
              var projectId = response.data.data[0].projectId
              _this.projectId = projectId
              const userInfo = getUserInfo()
              if (userInfo.user.email !== '') {
                setProjectId(projectId)
                setUniqueId(userInfo.user)
                this.$router.replace('/moyin/homepage')
              } else {
                this.status = 'bindinfo'
              }
            } else {
              _this.$message('登录失败：服务器错误', 'error')
            }
          } else {
            _this.$message(response.data.desc, 'error')
          }
        })
    },
    onEnterPhone: function () {
      this.$refs.code.focus()
    },
    onEnterCode: function (event) {
      event.target.blur()
      this.$refs.loginButton.click()
    },
    clickPhoneText: function () {
      this.$refs.phone.focus()
    },
    clickCodeText: function () {
      this.$refs.code.focus()
    },
    clickWechatLogin: function () {
      removeUserInfo()
      this.status = 'wechat'
      this.refreshQrcode()
      // this.wechatScanUrl = getApiBaseUrl() + '/anon/audio/user/login/wechatScan?ch=' + getAppChannel()
      // this.$nextTick(() => {
      //   this.$refs.webview.addEventListener('did-start-loading', () => {
      //     this.isLoadingWechat = true
      //   })
      //   this.$refs.webview.addEventListener('did-stop-loading', () => {
      //     this.isLoadingWechat = false
      //   })
      //   this.$refs.webview.addEventListener('dom-ready', () => {
      //     this.isLoadingWechat = false
      //     this.$refs.webview.insertCSS(`
      //       body {
      //         color: white;
      //       }
      //     `)
      //     if (this.$refs.webview.getURL().indexOf('wechatResult') !== -1) {
      //       this.$refs.webview.executeJavaScript('document.body.innerText').then(res => {
      //         this.wechatData = res
      //         this.tryLoginByWechat(this.wechatData)
      //       })
      //     }
      //   })
      // })
    },
    refreshQrcode() {
      this.wx.status = 'open'
      getWeChatUrl().then(res => {
        if (res.data.code === 0) {
          this.wx.imgUrl = res.data.data.qrCodeImgUrl
          this.wx.checkUrl = res.data.data.qrcodeStatusCheckUrl
          this.checkWxLogin(res.data.data.qrcodeStatusCheckUrl)
        }
      })
    },
    checkWxLogin(url) {
      this.checkStatus(url)
    },
    checkStatus: function(url) {
      const that = this
      const dom = document.createElement("script")
      this.domList.push(dom)
      dom.src = "".concat(url, "&t=").concat(Date.now())
      dom.onload = function () {
        dom.onload = null
        if (that.wx.status === 'close') return
        try {
          document.head.removeChild(dom)
          if (that.domList.indexOf(dom) > -1) {
            that.domList.splice(that.domList.indexOf(dom), 1)
          }
          const i  = window.wx_errcode
          switch (i) {
            case 405: // 登录成功
              that.tryLoginByWechat('', '', '', window.wx_code)
              break
            case 404: // 扫码成功
              that.wx.status = 'success'
              setTimeout(() => {
                that.checkStatus(url)
              }, 1000);
              break
            case 403: // 取消扫码
              that.wx.status = 'cancel'
              break
            case 402:
            case 500: // 二维码过期
              that.wx.status = ''
              that.refreshQrcode()
              break
            case 408: // 等待中
              that.wx.status = 'waitting'
              setTimeout(() => {
                that.checkStatus(url)
              }, 2000);
              break
            case 666: // 超时
              that.closeCheck()
              that.wx.status = 'timeout'
              break
          }
        } catch (error) {
          return
        }
      }
      document.head.appendChild(dom)
    },
    closeCheck: function() {
      this.wx.status = 'close'
      var highestTimeoutId = setTimeout(";")
      for (var i = 0 ; i < highestTimeoutId ; i++) {
          clearTimeout(i)
      }
      for (let i = 0; i < this.domList.length; i++) {
        const element = this.domList[i]
        document.head.removeChild(element)
      }
      this.domList = []
    },
    clickPhoneLogin: function () {
      this.closeCheck()
      this.status = 'phone'
      this.wechatScanUrl = ''
      this.phoneEmptyError = false
      this.codeEmptyError = false
      this.$nextTick(() => {
        var phoneDom = this.$refs.phone
        var phoneLabel = phoneDom.nextSibling
        var codeDom = this.$refs.code
        var codeLabel = codeDom.nextSibling
        if (phoneDom.value === '') {
          phoneLabel.style.top = '16px'
          phoneLabel.style.fontSize = '14px'
        } else {
          phoneLabel.style.top = '8px'
          phoneLabel.style.fontSize = '12px'
        }
        if (codeDom.value === '') {
          codeLabel.style.top = '16px'
          codeLabel.style.fontSize = '14px'
        } else {
          codeLabel.style.top = '8px'
          codeLabel.style.fontSize = '12px'
        }
      })
    },
    tryLoginByWechat: function (data, mobile, code, wechatCode) {
      var param = {
        data: data
      }
      if (mobile != null) {
        param.mobile = mobile
      }
      if (code != null) {
        param.code = code
      }
      if (wechatCode) {
        param = {
          wechatCode: wechatCode
        }
      }
      loginByWechat(param).then(res => {
        this.wx.status = 'close'
        if (res.data.code === 0) {
          setUserInfo(res.data.data)
          this.loadProjectId()
        } else if (res.data.code === 1012) {
          this.status = 'bind'
        } else {
          this.$message(res.data.desc, 'error')
        }
      })
    },
    clickWechatBind: function () {
      this.tryLoginByWechat(this.wechatData, this.bindPhone, this.bindCode)
    },
    clickLicense: function () {
      window.open('https://www.moyincloud.com/license.html')
    },
    clickPrivacy: function () {
      window.open('https://www.moyincloud.com/privacy.html')
    },
    inputInfo: function () {
      let flag = false
      for (const key in this.userInfo) {
        if (!this.userInfo[key]) {
          flag = true
        } else {
          this.infoEmptyError[key] = false
        }
      }
      this.infodisabled = flag
    },
    clickInfoBind: function() {
      let flag = false
      for (const key in this.userInfo) {
        if (!this.userInfo[key]) {
          flag = true
          this.infoEmptyError[key] = true
        }
        if (key === 'email') {
          const exp = new RegExp(/^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/)
          if (!exp.test(this.userInfo[key])) {
            flag = true
            this.infoEmptyError[key] = true
          }
        }
      }
      if (flag) {
        return false
      } else {
        bindUserInfo({...this.userInfo}).then(res => {
          if (res.data.code === 0) {
            setProjectId(this.projectId)
            setUniqueId(this.userInfo)
            this.$router.replace('/moyin/homepage')
          }
        })
      }
    }
  }
}
</script>
<style scoped lang="scss">
.content-out {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(../../assets/login/bg_img.png);
  background-size: 100% 100%;
  .content {
    display: flex;
    flex-direction: row;
    font-family: PingFangSC-Regular, PingFang SC;
    -webkit-app-region: drag;
    border-radius: 12px;
    overflow: hidden;
    box-shadow:  0 0 10px 0 rgba(0,0,0,0.16);
    .left {
      position: relative;
      width: 364px;
      height: 540px;
      background: rgba(255,255,255,1);
      display: flex;
      flex-direction: column;
      .logo {
        width: 104px;
        height: 24px;
        margin: 44px 0px 0px 54px;
      }
      .login-card {
        margin: 42px 0px 0px 54px;
        display: flex;
        flex-direction: column;
        width: 300px;
        .phone-box {
          margin-top: 20px;
          height: 250px;
        }
        .group {
          position: relative;
          height: 50px;
          -webkit-app-region: no-drag;
        }
        .phone-group {
          -webkit-app-region: no-drag;
        }
        .group input {
          position: absolute;
          top: 0px;
          left: 0px;
          padding: 22px 8px 4px 16px;
          width: calc(100% - 26px);
          display: block;
          background: transparent;
          border: 1px solid rgba(221,223,230,1);
          border-radius: 4px;
          font-size: 14px;
          height: 18px;
          line-height: 18px;
          color: rgba(51,51,51,1);
        }
        .group input:focus {
          outline: none;
          border: 1px solid rgba(0,112,255,1);
        }
        .group label {
          line-height: 14px;
          font-size: 14px;
          font-weight: normal;
          color: rgba(153,153,153,1);
          position: absolute;
          left: 16px;
          top: 16px;
          -webkit-transition: all 0.2s ease;
          transition: all 0.2s ease;
        }
        .error-tip {
          margin-bottom: 8px;
          font-size: 12px;
          font-weight: normal;
          color: rgba(245,0,0,0.7);
        }
        .space {
          height: 12px;
        }
        .code-line {
          display: flex;
          flex-direction: row;
          .code-group {
            flex-grow: 1;
            -webkit-app-region: no-drag;
          }
          .code-button {
            margin-left: 8px;
            width: 102px;
            height: 46px;
            background: white;
            border: 1px solid rgba(221,223,230,1);
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            font-weight: normal;
            color: rgba(36,106,250,1);
            cursor: pointer;
            -webkit-app-region: no-drag;
          }
          .code-button:disabled {
            border: 1px solid rgba(221,223,230,1);
            color: rgba(204,204,204,1);
            cursor: not-allowed;
          }
        }
        .login-button {
          margin-top: 16px;
          width: 100%;
          padding: 0;
          height: 46px;
          background: linear-gradient(114.93deg, rgba(70,129,250,1) 0%,rgba(36,106,250,1) 100%);
          border-radius: 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 16px;
          font-weight: normal;
          color: rgba(255,255,255,1);
          cursor: pointer;
          -webkit-app-region: no-drag;
          &:disabled {
            background: rgba(178,205,255,1);
          }
        }
        .license {
          margin-top: 12px;
          margin-bottom: 12px;
          text-align: center;
          font-size: 12px;
          font-weight: normal;
          color: rgba(102,102,102,1);
          -webkit-app-region: no-drag;
          & span {
            color: rgba(0,112,255,1);
            cursor: pointer;
          }
        }
        .or-line {
          display: flex;
          flex-direction: row;
          align-items: center;
          .line {
            flex-grow: 1;
            height: 1px;
            background: rgba(244,244,244,1);
          }
          .text {
            margin: 0 23px;
            font-size: 14px;
            font-weight: normal;
            color: rgba(102,102,102,1);
          }
        }
        .switch-wechat-button {
          margin-top: 12px;
          height: 46px;
          border: 1px solid rgba(221,223,230,1);
          border-radius: 4px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          -webkit-app-region: no-drag;
          & img {
            width: 20px;
            height: 20px;
          }
          & div {
            margin-left: 14px;
            font-size: 16px;
            font-weight: normal;
            color: rgba(24,184,141,1);
          }
          &:hover {
            border: 1px solid rgba(48,191,42,1);
          }
          &:active {
            background: rgba(253,255,252,1);
            border: 1px solid rgba(48,191,42,1);
          }
        }
        .title-center {
          margin: 0 auto;
          font-size: 18px;
          font-weight: normal;
          color: rgba(51,51,51,1);
        }
        .qr-box {
          position: relative;
          width: 232px;
          height: 180px;
          margin: 0 auto;
          overflow: hidden;
          .wechat-iframe {
            position: absolute;
            width: 320px;
            height: 420px;
            left: -42px;
            top: -35px;
          }
          img{
              width: 100%;
              height: 100%;
          }
          .top-cover {
            height: 20px;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            background: white;
          }
          .bottom-cover {
            height: 10px;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            background: white;
          }
          .loading-tip {
            position: absolute;
            top: 45px;
            left: 71px;
            width: 90px;
            height: 90px;
            border-radius: 2px;
            border: 1px solid #D2E1FF;
            background: #EDF2FC;
            display: flex;
            flex-direction: column;
            align-items: center;
            & img {
              width: 22px;
              height: 22px;
              margin-top: 22px;
              animation: rotate 1s linear infinite;
              -webkit-animation: rotate 1s linear infinite;
            }
            & div {
              font-size: 10px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #409EFF;
              margin-top: 15px;
            }
          }
        }
        .scan-tip {
          margin: 0 auto;
          font-size: 16px;
          font-weight: normal;
          color: rgba(51,51,51,1);
        }
        .switch-phone-button {
          margin-top: 12px;
          height: 46px;
          border: 1px solid rgba(221,223,230,1);
          border-radius: 4px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          -webkit-app-region: no-drag;
          & img {
            width: 20px;
            height: 20px;
          }
          & div {
            margin-left: 10px;
            font-size: 16px;
            font-weight: normal;
            color: rgba(0,112,255,1);
          }
          &:hover {
            border: 1px solid rgba(0,112,255,1);
          }
          &:active {
            background: rgba(252,253,255,1);
            border: 1px solid rgba(0,112,255,1);
          }
        }
        .bind-tip {
          margin-top: 32px;
          margin-bottom: 24px;
          font-size: 14px;
          font-weight: normal;
          color: rgba(51,51,51,1);
          line-height: 20px;
        }
        .bind-button {
          margin-top: 28px;
          -webkit-app-region: no-drag;
        }
      }
    }
    .right {
      background: white;
      width: 516px;
      height: 540px;
      position: relative;
      .splash {
        width: 516px;
        height: 540px;
        position: relative;
        top: -1px;
        right: -1px;
      }
    }
  }
  .wx-login{
    width: 100%;
    min-height: 200px;
    text-align: center;
    img {
      display: block;
      width: 200px;
      margin: 0 auto;
    }
  }
}
</style>
