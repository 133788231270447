import Vue from 'vue'
import { getSplitWordCallbackWithDoaV1, getSplitWordProgressV1, updateAudioDocFileMeta } from '../../api/audio'
import { getQiniuVideoToken } from '../../api/qiniu'
import { getProjectId, isLogined } from '../../utils/auth'
import { loadDocIdStatusMap, saveDocIdStatusMap } from '../../utils/config'
import { kDocStatusPreparing, kDocStatusRequestFailed, kDocStatusTaskPaused, kDocStatusTranscript, kDocStatusTranscriptFailed, kDocStatusTranscriptFinish, kDocStatusUploadFailed, kDocStatusUploading, kDocStatusTranscriptTaskCreated } from '../../utils/statusConst'
import { formatDate } from '../../utils/util'
import * as qiniu from 'qiniu-js'

export const kUploadCodeError = -1
export const kUploadCodeSuccess = 0
export const kUploadCodeProgress = 1

export class DocService {
  constructor() {
    this.init()
  }

  init () {
    var statusIdMap = loadDocIdStatusMap()
    this.statusMap = statusIdMap
    this.deletedMap = {}
    for (var item in statusIdMap) {
      if (statusIdMap[item].status !== kDocStatusUploadFailed && statusIdMap[item].status !== kDocStatusRequestFailed) {
        if (statusIdMap[item].status === kDocStatusUploading) {
          statusIdMap[item].status = kDocStatusUploadFailed
          statusIdMap[item].statusText = this.getDocStatusText(kDocStatusUploadFailed)
        } else if (statusIdMap[item].status === kDocStatusTranscript) {
          this.startGetTranscriptProgress(statusIdMap[item].docId)
        } else {
          statusIdMap[item].status = kDocStatusTaskPaused
          statusIdMap[item].statusText = this.getDocStatusText(kDocStatusTaskPaused)
        }
        statusIdMap[item].progressText = ''
      }
    }
  }

  getStatus (docId) {
    return this.statusMap[docId]
  }

  getDocStatusText (status) {
    switch (status) {
      case kDocStatusPreparing:
        return '准备中...'
      case kDocStatusUploading:
        return '上传中...'
      case kDocStatusUploadFailed:
        return '上传失败'
      case kDocStatusTranscriptTaskCreated:
        return '转录中...'
      case kDocStatusTranscript:
        return '转录中...'
      case kDocStatusTranscriptFinish:
        return '转录完成'
      case kDocStatusTranscriptFailed:
        return '转录失败'
      case kDocStatusRequestFailed:
        return '网络失败'
      case kDocStatusTaskPaused:
        return '任务中断'
      default:
        return ''
    }
  }

  upload (docId, file, options) {
    this.updateDocStatus(docId, kDocStatusPreparing, {
      file: file,
      duration: options.duration,
      width: options.width,
      height: options.height
    })
    this.startUploadVideo(file, (code, snapshot) => {
      if (code === kUploadCodeSuccess) {
        if (docId in this.deletedMap) {
          return
        }
        this.dealNormalVideo(docId, snapshot)
      } else if (code === kUploadCodeProgress) {
        let progressText = snapshot.percent + '%'
        this.updateDocStatus(docId, kDocStatusUploading, {
          progressText: progressText
        })
      } else if (code === kUploadCodeError) {
        this.updateDocStatus(docId, kDocStatusUploadFailed)
      }
    })
  }

  retry (docId) {
    var status = this.statusMap[docId]
    if (status != null) {
      if (status.file.size != null && status.file.name != null) {
        this.upload(docId, status.file, status)
      } else {
        const _this = this
        const fileInput = document.createElement('input')
        fileInput.setAttribute('type', 'file')
        fileInput.setAttribute('accept', '.mp4,.webm,.mkv,.mov,.ogv,.m4v')
        fileInput.onchange = function (event) {
          const file = event.target.files[0]
          const videoDom = document.createElement('video')
          videoDom.onloadedmetadata = () => {
            const options = {
              duration: parseInt(videoDom.duration * 1000),
              width: videoDom.videoWidth,
              height: videoDom.videoHeight
            }
            _this.upload(docId, file, options)
          }
          videoDom.onerror = (err) => {
            _this.upload(docId, file, status)
          }
          videoDom.src = URL.createObjectURL(file)
        }
        fileInput.click()
      }
    }
  }

  delete (docId) {
    this.deletedMap[docId] = true
    this.clearDocStatus(docId)
  }

  startUploadVideo (file, callback) {
    const key = 'feed/video/' + formatDate(new Date(), 'yyyyMMdd') + '/' + new Date().getTime()
    getQiniuVideoToken().then(async function (res) {
      if (res.data.code === 0) {
        var qiniuToken = res.data.data.token
        var domain = res.data.data.domain
        var config = {
          zone: qiniu.region.z1,
          useCdnDomain: true
        }
        var putExtra = {}
        const observable = qiniu.upload(file, key, qiniuToken, putExtra, config)
        const observer = {
          next: function (response) {
            callback(kUploadCodeProgress, {
              percent: parseInt(response.total.percent)
            })
          },
          error: function (error) {
            console.log(error)
            callback(kUploadCodeError)
          },
          complete: function (res) {
            const mediaUrl = domain + '/' + res.key
            callback(kUploadCodeSuccess, {
              videoUrl: mediaUrl
            })
          }
        }
        observable.subscribe(observer)
      } else {
        callback(kUploadCodeError)
      }
    }).catch(err => {
      console.log(err)
      callback(kUploadCodeError)
    })
  }

  dealNormalVideo (docId, snapshot) {
    const options = this.getDocOptions(docId)
    var params = {
      audioDocId: docId,
      fileUrl: snapshot.videoUrl,
      fileType: 1,
      duration: options.duration,
      width: options.width,
      height: options.height
    }
    updateAudioDocFileMeta(params).then(res => {
      if (res.data.code === 0) {
        this.updateDocStatus(docId, kDocStatusTranscriptTaskCreated)
        if (docId in this.deletedMap) {
          return
        }
        getSplitWordCallbackWithDoaV1({
          fileUrl: snapshot.videoUrl,
          fileType: 1,
          projectId: getProjectId(),
          docId: docId,
          duration: options.duration
        }).then(res2 => {
          if (res2.data.code === 0) {
            this.startGetTranscriptProgress(docId)
          } else {
            this.updateDocStatus(docId, kDocStatusRequestFailed)
          }
        }).catch(err2 => {
          console.log(err2)
          this.updateDocStatus(docId, kDocStatusRequestFailed)
        })
      }
    }).catch(err => {
      console.log(err)
      this.updateDocStatus(docId, kDocStatusRequestFailed)
    })
  }

  startGetTranscriptProgress (docId) {
    this.updateDocStatus(docId, kDocStatusTranscript)
    this.scheduleGetTranscriptProgress(docId)
  }

  scheduleGetTranscriptProgress (docId) {
    var _this = this
    setTimeout(() => {
      if (docId in _this.deletedMap) {
        return
      }
      _this.doGetTranscriptProgress(docId)
    }, 2000)
  }

  doGetTranscriptProgress (docId) {
    if (!isLogined()) {
      this.scheduleGetTranscriptProgress(docId)
      return
    }
    var _this = this
    getSplitWordProgressV1({
      docId: docId
    }).then(res => {
      if (res.data.code === 0) {
        if (res.data.data.taskStatus === 0) {
          _this.updateDocStatus(docId, kDocStatusTranscriptFinish)
          _this.clearDocStatus(docId)
        } else if (res.data.data.taskStatus === 1 || res.data.data.taskStatus === 2) {
          _this.updateDocStatus(docId, kDocStatusTranscriptFailed)
          _this.clearDocStatus(docId)
        } else {
          _this.scheduleGetTranscriptProgress(docId)
        }
      } else if (res.data.code === 5010 || res.data.code === 5011) {
        _this.clearDocStatus(docId)
      } else {
        console.log(res.data.desc)
        _this.scheduleGetTranscriptProgress(docId)
      }
    }).catch(err => {
      console.log(err)
      _this.scheduleGetTranscriptProgress(docId)
    })
  }

  getDocOptions (docId) {
    if (docId in this.statusMap) {
      return {
        duration: this.statusMap[docId].duration,
        width: this.statusMap[docId].width,
        height: this.statusMap[docId].height
      }
    } else {
      return {
        duration: 0,
        width: 0,
        height: 0
      }
    }
  }

  updateDocStatus (docId, status, options) {
    if (docId in this.deletedMap) {
      return
    }
    if (docId in this.statusMap) {
      this.statusMap[docId].docId = docId
      this.statusMap[docId].status = status
      this.statusMap[docId].statusText = this.getDocStatusText(status)
      this.statusMap[docId].progressText = options && options.progressText != null ? options.progressText : ''
      if (options != null) {
        if (options.videoUrl != null) {
          this.statusMap[docId].videoUrl = options.videoUrl
        }
        if (options.file != null) {
          this.statusMap[docId].file = options.file
        }
        if (options.duration != null) {
          this.statusMap[docId].duration = options.duration
        }
        if (options.width != null) {
          this.statusMap[docId].width = options.width
        }
        if (options.height != null) {
          this.statusMap[docId].height = options.height
        }
      }
    } else {
      var docStatus = {
        docId: docId,
        status: status,
        statusText: this.getDocStatusText(status),
        progressText: options && options.progressText ? options.progressText : '',
        videoUrl: options && options.videoUrl ? options.videoUrl : null,
        file: options && options.file ? options.file : null,
        duration: options && options.duration ? options.duration : 0,
        width: options && options.width ? options.width : 0,
        height: options && options.height ? options.height : 0
      }
      this.statusMap[docId] = docStatus
    }
    saveDocIdStatusMap(this.statusMap)
    if (Vue.prototype.$bus != null) {
      Vue.prototype.$bus.$emit('doc-status', docId, this.statusMap[docId])
    }
  }

  clearDocStatus (docId) {
    delete this.statusMap[docId]
    saveDocIdStatusMap(this.statusMap)
  }
}
