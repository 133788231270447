<template>
  <div class="avatar-button" v-clickoutside="hideAvatarMenu">
    <img :src="userAvatar || require('../../../assets/homepage/default-avatar.svg')" @click="showAvatarMenu"/>
    <div class="profile-menu" v-show="avatarMenuVisible">
      <div @click.stop="clickEditProfile" class="menu-item">
        <img class="icon" src="../../../assets/homepage/icon-profile.svg" />
        <span class="text">编辑资料</span>
      </div>
      <div @click.stop="clickGoWebsite" class="menu-item home">
        <img class="icon" src="../../../assets/homepage/icon-website.svg" />
        <span class="text">官网</span>
      </div>
      <div @click.stop="clickQuitAccount" class="menu-item">
        <img class="icon" src="../../../assets/homepage/icon-exit.svg" />
        <span class="text">退出登录</span>
      </div>
    </div>
    <!-- 编辑个人资料 -->
    <profile-dialog ref="profileDialog" @editAvatar="editAvatar" @bindWx="bindWx" @updateUserInfo="buildAvatarText" />
    <!-- 编辑头像 -->
    <avatar-dialog ref="avatarDialog" @updateUserInfo="buildAvatarText" />
    <!-- 绑定微信 -->
    <bind-wx-dialog ref="bindWxDialog" @updateUserInfo="buildAvatarText" />
  </div>
</template>
<script>
import ProfileDialog from '../account/ProfileDialog.vue'
import AvatarDialog from '../account/AvatarDialog.vue'
import BindWxDialog from '../account/BindWxDialog.vue'
import { getUid, getUserInfo, setUserInfo } from '@/utils/auth'
import { isWinOS } from '@/utils/os'
import { getUserDetail } from '@/api/user'

export default {
  name: 'AvatarButton',
  components: {
    ProfileDialog,
    AvatarDialog,
    BindWxDialog
  },
  data () {
    return {
      isWin: false,
      avatarMenuVisible: false,
      userAvatar: ''
    }
  },
  mounted () {
    this.buildAvatarText()
    this.isWin = isWinOS()
  },
  methods: {
    buildAvatarText: function() {
      var user = getUserInfo()
      if (user != null) {
        this.userAvatar = user.user.imageUrl
        this.$refs.profileDialog.updateUserInfo()
        this.$refs.avatarDialog.updateUserInfo()
      }
    },
    showAvatarMenu: function () {
      this.avatarMenuVisible = !this.avatarMenuVisible
    },
    hideAvatarMenu: function () {
      this.avatarMenuVisible = false
    },
    clickEditProfile: function () {
      this.avatarMenuVisible = false
      getUserDetail({
        userId: getUid()
      }).then(res => {
        if (res.data.code === 0) {
          var user = getUserInfo()
          user.user = res.data.data
          setUserInfo(user)
          this.buildAvatarText()
          this.$refs.profileDialog.show()
        } else {
          this.$refs.profileDialog.show()
        }
      }).catch(err => {
        console.log(err)
        this.$refs.profileDialog.show()
      })
    },
    editAvatar() {
      this.$refs.avatarDialog.show()
    },
    bindWx() {
      this.$refs.bindWxDialog.show()
    },
    clickGoWebsite: function () {
      this.avatarMenuVisible = false
      window.open('https://www.moyincloud.com/')
    },
    clickQuitAccount: function () {
      this.avatarMenuVisible = false
      this.$emit('quit')
    }
  }
}
</script>
<style scoped lang="scss">
.avatar-button {
  margin-right: 20px;
  width: 32px;
  height: 32px;
  position: relative;
  -webkit-app-region: no-drag;
  & img {
    width: 32px;
    height: 32px;
    border-radius: 16px;
    cursor: pointer;
  }
  .profile-menu {
    position: absolute;
    z-index: 1000;
    top: 42px;
    right: 0px;

    width: 184px;
    padding: 12px 0;
    background: rgba(255,255,255,1);
    border: 1px solid rgba(224,224,224,1);
    border-radius: 4px;
    box-shadow:  0 0 6px 0 rgba(187,187,187,0.5);
    .menu-item {
      display: flex;
      align-items: center;
      cursor: pointer;
      height: 32px;
      &:hover {
        background: rgba(243,245,247,1);
      }
      .icon {
        width: 16px;
        height: 16px;
        margin-left: 16px;
      }
      .text {
        color: rgba(51,51,51,1);;
        margin-left: 12px;
        font-size: 12px;
        font-weight: normal;
      }
    }
  }
}
</style>
