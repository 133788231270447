import Vue from 'vue'
import Router from 'vue-router'
import Login from '../views/login/Login.vue'
import Moyin from '../views/Moyin.vue'
import Layout from '../layout/index.vue'
import RecentDocList from '../views/homepage/RecentDocList.vue'
import GroupDocList from '../views/homepage/GroupDocList.vue'
import SearchDocResult from '../views/homepage/SearchDocResult.vue'
import DetailDoc from '../views/detail/DetailDoc.vue'
import OnboardIndex from '../views/Index.vue'

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/login',
      name: 'login',
      component: Login
    },
    {
      path: '/moyin',
      component: Moyin,
      redirect: '/moyin/homepage',
      children: [
        {
          path: 'homepage',
          component: Layout,
          redirect: '/moyin/homepage/recent',
          children: [
            {
              path: 'recent',
              component: RecentDocList,
              name: 'HomepageRecentDoc'
            },
            {
              path: 'group',
              component: GroupDocList,
              name: 'HomepageGroupDoc'
            },
            {
              path: 'search',
              component: SearchDocResult,
              name: 'HomepageSearchDoc'
            }
          ]
        },
        {
          path: 'detail',
          name: 'detailDoc',
          component: DetailDoc
        }
      ]
    },
    {
      path: '*',
      name: 'onboard',
      component: OnboardIndex
    }
  ]
})
