const kDocStatusPreparing = 0
const kDocStatusTranscript = 1
const kDocStatusTranscriptFailed = 2
const kDocStatusTranscriptFinish = 3
const kDocStatusUploading = 6
const kDocStatusUploadFailed = 11
const kDocStatusRequestFailed = 12
const kDocStatusTaskPaused = 13
const kDocStatusTranscriptTaskCreated = 14

const kInitCodeSuccess = 0
const kInitCodeFailAudio = 1
const kInitCodeFailVideo = 2

export {
  kDocStatusPreparing,
  kDocStatusTranscript,
  kDocStatusTranscriptFailed,
  kDocStatusTranscriptFinish,
  kDocStatusUploading,
  kDocStatusUploadFailed,
  kDocStatusRequestFailed,
  kDocStatusTaskPaused,
  kInitCodeSuccess,
  kInitCodeFailAudio,
  kInitCodeFailVideo,
  kDocStatusTranscriptTaskCreated
}
