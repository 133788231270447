<template>
  <div class="download-button">
    <img class="icon" src="../../../assets/detail/icon-down.svg" />
    <div class="text">下载</div>
  </div>
</template>
<script>
import { downloadDocSimple } from '@/api/doc'

export default {
  name: 'DownloadButton',
  props: {
    docId: {
      required: true
    },
    docTitle: {
      required: true
    },
    currentVersion: {
      required: true
    }
  },
  data () {
    return {
    }
  },
  methods: {
    doDownloadAction: function () {
      if (this.floatStatusVisible) {
        this.floatStatusVisible = false
        return
      }
      downloadDocSimple({
        audioDocId: this.docId,
        version: this.currentVersion
      }).then(res => {
        if (res.data.code === 0) {
          // var link = document.createElement('a')
          // link.target = '_blank'
          // link.download = this.docTitle + '_' + this.currentVersion + '.mp4'
          // link.href = res.data.data
          // link.click()
          this.videoDownload(res.data.data, this.docTitle + '_' + this.currentVersion + '.mp4')
        } else if (res.data.code === 5009) {
          this.$message('剪辑处理中，请稍后再下载…', 'error')
        } else {
          this.$message(res.data.desc, 'error')
        }
      }).catch(err => {
        console.log(err)
      })
    },

    videoDownload: function (filePath, fileName) {
        const link = document.createElement('a');
        link.target = '_blank'
        link.href = filePath + '&response-content-disposition=' + encodeURIComponent('attachment; filename="' + fileName + '"');
        link.download = fileName;
        link.click();
    }
  }
}
</script>
<style scoped lang="scss">
.download-button {
  margin-left: 14px;
  margin-right: 14px;
  width: 80px;
  height: 38px;
  background: rgba(255,255,255,1);
  border: 1px solid rgba(235,235,235,1);
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  -webkit-app-region: no-drag;
  position: relative;
  .icon {
    width: 18px;
    height: 18px;
  }
  .text {
    margin-left: 8px;
    font-size: 14px;
    font-weight: normal;
    color: rgba(51,51,51,1);
  }
  &:hover {
    background: rgba(245,247,251,1);
  }
  &:active {
    background: rgba(239,241,245,1);
  }
  .float-status {
    position: absolute;
    z-index: 1000;
    top: 42px;
    right: 0px;
    width: 170px;
    height: 60px;
    background: rgba(255,255,255,1);
    border: 1px solid rgba(224,224,224,1);
    border-radius: 6px;
    box-shadow:  0 0 6px 0 rgba(187,187,187,0.5);
    .status-content {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .status-line {
        margin-left: 12px;
        margin-right: 12px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        .title {
          font-size: 14px;
          font-weight: normal;
          color: rgba(51,51,51,1);
        }
        .icon {
          width: 16px;
          height: 16px;
          cursor: pointer;
        }
      }
      .progress {
        margin: 9px 12px 1px 12px;
        height: 4px;
        background: rgba(245,247,251,1);
        border-radius: 4px;
        .progress-current {
          width: 50%;
          height: 100%;
          background: rgba(98,165,251,1);
          border-radius: 4px;
        }
      }
    }
  }
}
</style>
