<template>
  <div class="side-content">
    <div class="create-button" v-clickoutside="hideCreateButtons">
      <div class="create-button-inner" @click="showCreateButtons">
        <img class="icon" src="../../assets/homepage/create.svg" />
        <div class="text">创建</div>
      </div>
      <div class="float-buttons" v-show="createButtonsVisible">
        <div class="button-line">
          <div class="button" @click="clickRecordButton">
            <img class="icon" src="../../assets/homepage/record.svg" />
            <div class="text">录屏</div>
          </div>
          <div class="button" @click="clickUploadButton">
            <img class="icon" src="../../assets/homepage/upload.svg" />
            <div class="text">上传视频</div>
          </div>
          <div class="vline"></div>
          <div class="button" @click="clickNewGroupButton">
            <img class="icon" src="../../assets/homepage/newfolder.svg" />
            <div class="text">新分组</div>
          </div>
          <input ref="fileUpload" type="file" accept=".mp4,.webm,.mkv,.mov,.ogv,.m4v" hidden @change="onFileUploadChanged" />
        </div>
        <!-- <div class="tip-line" :class="tipVisible ? 'tip-line-active' : ''" @click="showTipContent" v-clickoutside="hideTipContent">
          <img class="icon" v-if="tipVisible" src="../../assets/homepage/icon-tip-active.svg" />
          <img class="icon" v-else src="../../assets/homepage/icon-tip.svg" />
          <div class="text">功能说明</div>
          <div class="tip-content" v-show="tipVisible">
            <div class="box">
              <div class="tip-content-line"><span>录屏：</span>录制屏幕或窗口内容，开启摄像头边录边讲。</div>
              <div class="tip-content-line"><span>演示：</span>导入PPT、图片或视频素材，录制一个演示视频。</div>
            </div>
          </div>
        </div> -->
      </div>
    </div>
    <div class="line"></div>
    <div class="menu">
      <div class="menu-item" :class="path === '/moyin/homepage/recent' ? 'menu-item-active' : ''" @click="clickHomepage">
        <img class="icon" src="../../assets/homepage/home.svg" />
        <div class="text">最近</div>
      </div>
      <div class="menu-item" @click="groupListVisible = !groupListVisible">
        <img class="icon" src="../../assets/homepage/mine.svg" />
        <div class="text">我的</div>
        <img v-if="groupListVisible" class="arrow" src="../../assets/homepage/arrow-up.svg" />
        <img v-else class="arrow" src="../../assets/homepage/arrow-down.svg" />
      </div>
      <div class="sub-menu" v-show="groupListVisible">
        <div class="sub-menu-item" :class="(path === '/moyin/homepage/group' && id === 'default') ? 'sub-menu-item-active' : ''" @click="clickDefaultGroup">
          <img class="icon" src="../../assets/homepage/folder.svg" />
          <div class="text">默认分组</div>
        </div>
        <div v-for="(item, index) in groupList" :key="index" class="sub-menu-item" :class="(path === '/moyin/homepage/group' && id === item.fsId) ? 'sub-menu-item-active' : ''" @click="clickGroupItem(item)">
          <img class="icon" src="../../assets/homepage/folder.svg" />
          <div class="text">{{ item.name }}</div>
        </div>
      </div>
      <div class="menu-item" @click="clickMyFavorite" style="visibility: hidden;">
        <img class="icon" src="../../assets/homepage/collection.svg" />
        <div class="text">我的收藏</div>
      </div>
    </div>
    <div class="progress-track">
      <div class="progress-thumb" :style="{ width: capacityPercent + '%' }"></div>
    </div>
    <div class="storage-info">
      <div class="info">{{ capacityInfo }}</div>
      <!-- <div class="upgrade-button" @click="clickUpgradeButton">升级</div> -->
    </div>
  </div>
</template>
<script>
import throttle from 'throttle-debounce/throttle'
import { addDir, listFsDocGroup } from '../../api/fs'
import { getMyCapacity } from '@/api/user'
import { formatSize } from '@/utils/util'
import { loadSideBarSetting, saveSideBarSetting } from '@/utils/config'
import { detectOS } from '@/utils/os'
import { isTestEnv } from '../../common/const'
import openUriWithInputTimeoutHack from '@/utils/testInstall'
export default {
  name: 'SideBar',
  data () {
    return {
      path: this.$route.path,
      id: this.$route.query.id,
      createButtonsVisible: false,
      tipVisible: false,
      groupListVisible: true,
      groupList: [],
      capacity: null,
      downloadUrl: ''
    }
  },
  watch: {
    $route (to) {
      this.path = to.path
      this.id = to.query.id
    }
  },
  computed: {
    capacityInfo: function () {
      if (this.capacity != null) {
        return formatSize(this.capacity.used) + ' /' + formatSize(this.capacity.capacity)
      } else {
        return ''
      }
    },
    capacityPercent: function () {
      if (this.capacity != null) {
        var percent = this.capacity.used * 100 / this.capacity.capacity
        if (percent > 100) {
          percent = 100
        }
        return percent
      } else {
        return 0
      }
    }
  },
  mounted () {
    this.loadSetting()
    this.loadGroupList()
    this.loadDownloadUrl()
    this.$bus.$on('group-name-changed', this.onGroupNameChanged)
    this.$bus.$on('group-deleted', this.onGroupDeleted)
  },
  destroyed () {
    this.$bus.$off('group-name-changed', this.onGroupNameChanged)
    this.$bus.$off('group-deleted', this.onGroupDeleted)
  },
  methods: {
    onGroupNameChanged: function (id, name) {
      this.groupList.forEach(item => {
        if (item.fsId === id) {
          item.name = name
        }
      })
    },
    onGroupDeleted: function (id) {
      for (var i = 0; i < this.groupList.length; i++) {
        if (this.groupList[i].fsId === id) {
          this.groupList.splice(i, 1)
          break
        }
      }
    },
    showCreateButtons: function () {
      this.createButtonsVisible = !this.createButtonsVisible
    },
    hideCreateButtons: function () {
      this.createButtonsVisible = false
    },
    clickRecordButton: function () {
      this.createButtonsVisible = false
      // const arr = ['moyin://record', 'moyin-test://']
      openUriWithInputTimeoutHack(isTestEnv() ? 'moyin://record' : 'moyin://record', ()=>{
        if (isTestEnv()) {
          window.open('https://www.moyincloud.com/launch-test.html')
        } else {
          window.open('https://www.moyincloud.com/launch.html')
        }
      }, ()=>{
        console.log('open record sunccess')
      })
      
    },
    clickUploadButton: function () {
      this.createButtonsVisible = false
      this.$refs.fileUpload.click()
    },
    onFileUploadChanged: function (event) {
      const file = event.target.files[0]
      this.$bus.$emit('upload-video-selected', file)
    },
    clickNewGroupButton: function () {
      this.createButtonsVisible = false
      this.$input('新建分组', '', {
        confirmCallback: title => {
          if (title !== '') {
            addDir({
              upperName: '/',
              name: title
            }).then(res => {
              if (res.data.code === 0) {
                res.data.data.fsId = res.data.data.id
                this.groupList.push(res.data.data)
              } else {
                this.$message(res.data.desc, 'error')
              }
            })
            return true
          } else {
            this.$message('分组名称不能为空', 'error')
            return false
          }
        }
      })
    },
    showTipContent: function () {
      this.tipVisible = !this.tipVisible
    },
    hideTipContent: function () {
      this.tipVisible = false
    },
    clickHomepage: function () {
      if (this.$route.path === '/moyin/homepage/recent') {
        this.loadGroupList()
      }
      this.$router.replace({
        path: '/moyin/homepage/recent',
        query: {
          ts: new Date().getTime()
        }
      })
    },
    clickDefaultGroup: function () {
      this.$router.replace({
        path: '/moyin/homepage/group',
        query: {
          id: 'default',
          name: '默认分组',
          ts: new Date().getTime()
        }
      })
    },
    clickGroupItem: function (group) {
      this.$router.replace({
        path: '/moyin/homepage/group',
        query: {
          id: group.fsId,
          name: group.name,
          ts: new Date().getTime()
        }
      })
    },
    clickMyFavorite: function () {
    },
    clickUpgradeButton: function () {
    },
    loadSetting: function () {
      var setting = loadSideBarSetting()
      if (setting.permissionVisible != null && setting.permissionVisible) {
        this.$refs.permissionDialogEx.show()
      }
    },
    saveSetting: function (show) {
      saveSideBarSetting({
        permissionVisible: show
      })
    },
    loadGroupList: throttle(1000, function () {
      this.groupList = []
      listFsDocGroup({}).then(res => {
        if (res.data.code === 0) {
          this.groupList = res.data.data
        } else {
          this.$message(res.data.desc, 'error')
        }
        this.loadMyCapacity()
      }).catch(err => {
        console.log(err)
        this.loadMyCapacity()
      })
    }),
    loadMyCapacity: function () {
      getMyCapacity({}).then(res => {
        if (res.data.code === 0) {
          this.capacity = res.data.data
        } else {
          this.$message(res.data.desc, 'error')
        }
      })
    },
    loadDownloadUrl: function () {
      const _this = this
      const axios = require('axios')
      axios.get('https://www.moyincloud.com/latest.json').then(res => {
        const info = res.data
        const os = detectOS()
        if (os === 'windows' && info.windows != null) {
          _this.downloadUrl = info.windows.url
        } else if (os === 'mac' && info.macIntel != null) {
          _this.downloadUrl = info.macIntel.url
        }
      })
    }
  }
}
</script>
<style scoped lang="scss">
.side-content {
  width: 216px;
  min-width: 216px;
  height: calc(100vh - 71px);
  display: flex;
  flex-direction: column;
  font-family: PingFangSC-Regular, PingFang SC;
  .create-button {
    margin: 12px 20px 20px 20px;
    height: 40px;
    background: rgba(0,112,255,1);
    border-radius: 4px;
    position: relative;
    .create-button-inner {
      cursor: pointer;
      height: 40px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      .icon {
        width: 20px;
        height: 20px;
      }
      .text {
        margin-left: 6px;
        font-size: 18px;
        font-weight: 500;
        color: rgba(255,255,255,1);
      }
    }
    .float-buttons {
      position: absolute;
      top: 50px;
      left: 0px;
      width: 494px;
      height: 200px;
      background: rgba(255,255,255,1);
      border: 1px solid rgba(216,216,216,1);
      border-radius: 8px;
      box-shadow:  0 0 10px 0 rgba(0,0,0,0.16);
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      z-index: 200;
      .button-line {
        display: flex;
        flex-direction: row;
        align-items: center;
        .button {
          margin: 0 6px;
          width: 118px;
          height: 120px;
          background: rgba(255,255,255,1);
          border: 1px solid rgba(233,233,233,1);
          border-radius: 4px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          position: relative;
          cursor: pointer;
          .icon {
            width: 30px;
            height: 30px;
          }
          .text {
            margin-top: 10px;
            font-size: 12px;
            font-weight: 500;
            color: rgba(51,51,51,1);
          }
          &:hover {
            background: rgba(243,245,247,1);
            border: 1px solid rgba(52,141,255,1);
          }
          .tip {
            position: absolute;
            top: 0px;
            right: 0px;
            border-top-right-radius: 4px;
            width: 36px;
            height: 16px;
            background: rgba(62,230,176,1);
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 8px;
            font-weight: normal;
            color: rgba(51,51,51,1);
          }
        }
        .vline {
          margin: 0 12px;
          width: 1px;
          height: 34px;
          background: rgba(233,233,233,1);
        }
      }
      .tip-line {
        margin-top: 23px;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        position: relative;
        .icon {
          width: 14px;
          height: 14px;
        }
        .text {
          margin-left: 4px;
          font-size: 12px;
          font-weight: normal;
          color: rgba(102,102,102,1);
        }
      }
      .tip-line-active {
        .text {
          color: rgba(34,137,233,1);
        }
      }
      .tip-content {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 20px;
        background: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 10px;
        .box {
          margin-top: 36px;
          width: 370px;
          height: 110px;
          background-image: url(../../assets/homepage/tip-content-bg.svg);
          background-size: 370px 120px;
          background-repeat: no-repeat;
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding-bottom: 10px;
          .tip-content-line {
            margin: 5px 25px;
            font-size: 12px;
            font-weight: normal;
            color: rgba(51,51,51,1);
            & span {
              font-weight: bold;
            }
          }
        }
        .arrow {
          width: 0px;
          height: 0px;
          border-width: 10px;
          border-style: solid;
          border-color: white transparent transparent transparent;
          box-shadow:  0 0 10px 0 rgba(0,0,0,0.18);
        }
      }
    }
  }
  .line {
    margin: 0 20px;
    height: 1px;
    background: rgba(240,240,240,1);
  }
  .menu {
    padding-top: 2px;
    height: calc(100vh - 216px);
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    .menu-item {
      margin: 0 20px;
      height: 40px;
      min-height: 40px;
      display: flex;
      flex-direction: row;
      align-items: center;
      cursor: pointer;
      .icon {
        width: 20px;
        height: 20px;
        margin-left: 24px;
      }
      .text {
        margin-left: 16px;
        flex-grow: 1;
        font-size: 14px;
        font-weight: 500;
        color: rgba(51,51,51,1);
      }
      .arrow {
        width: 13px;
        height: 6px;
        margin-right: 12px;
      }
      &:hover {
        background: rgba(245,247,251,1);
        border-radius: 4px;
      }
    }
    .menu-item-active {
      background: rgba(239,241,245,1);
      border-radius: 4px;
    }
    .sub-menu {
      margin: 0 20px;
      display: flex;
      flex-direction: column;
      .sub-menu-item {
        height: 40px;
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;
        .icon {
          margin-left: 42px;
          width: 20px;
          height: 20px;
        }
        .text {
          margin-left: 8px;
          font-size: 14px;
          font-weight: normal;
          color: rgba(102,102,102,1);
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        &:hover {
          background: rgba(245,247,251,1);
          border-radius: 4px;
          .text {
            font-weight: 500;
            color: rgba(34,34,34,1);
          }
        }
      }
      .sub-menu-item-active {
        background: rgba(239,241,245,1);
        border-radius: 4px;
        .text {
          font-weight: 500;
          color: rgba(34,34,34,1);
        }
      }
    }
  }
  .progress-track {
    margin: 12px 20px 0 20px;
    height: 6px;
    background: rgba(243,245,247,1);
    border-radius: 3px;
    .progress-thumb {
      height: 6px;
      background: rgba(0,112,255,1);
      border-radius: 3px;
    }
  }
  .storage-info {
    height: 36px;
    margin: 0 20px 16px 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    .info {
      margin-right: 12px;
      font-size: 12px;
      font-weight: normal;
      color: rgba(51,51,51,1);
      flex-grow: 1;
    }
    .upgrade-button {
      font-size: 12px;
      font-weight: normal;
      color: rgba(0,93,255,1);
      cursor: pointer;
    }
  }
}
</style>
