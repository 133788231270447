import request from './../utils/request'

// https://api.moyincloud.com/audio/doc/detailEdit
export function getAudioDocDetail (data) {
  if (data == null) data = {}
  data.t = new Date().getTime()
  return request({
    // url: 'audio/doc/detail',
    url: 'audio/doc/detailEdit',
    method: 'get',
    params: data
  })
}
export function getDocDetailPlayList (data) {
  if (data == null) data = {}
  data.t = new Date().getTime()
  return request({
    url: 'audio/doc/detail/withPlayList',
    method: 'get',
    params: data
  })
}
export function updateAudioDocVersion (data) {
  if (data == null) data = {}
  data.t = new Date().getTime()
  return request({
    url: 'audio/doc/update/currentVersion',
    method: 'post',
    params: data
  })
}
export function deleteAudioDoc (data) {
  if (data == null) data = {}
  data.t = new Date().getTime()
  return request({
    url: 'audio/doc/delete',
    method: 'get',
    params: data
  })
}
export function batchDeleteAudioDoc (data) {
  if (data == null) data = {}
  data.t = new Date().getTime()
  return request({
    url: 'audio/doc/delete/batch',
    method: 'post',
    params: data
  })
}
export function modifyAudioDoc (data) {
  if (data == null) data = {}
  data.t = new Date().getTime()
  return request({
    url: 'audio/doc/modify',
    method: 'post',
    data: JSON.stringify(data),
    params: {}
  })
}
export function requestShareDocFragment (data) {
  if (data == null) data = {}
  return request({
    url: 'audio/doc/fragment/share/generate',
    method: 'post',
    data: JSON.stringify(data),
    params: {}
  })
}
export function searchDocSimple (data) {
  if (data == null) data = {}
  data.t = new Date().getTime()
  return request({
    url: 'search/doc/simple',
    method: 'post',
    params: data
  })
}
export function shareDocSimple (data) {
  if (data == null) data = {}
  data.t = new Date().getTime()
  return request({
    url: 'audio/doc/simple/share',
    method: 'post',
    params: data
  })
}
export function createEmptyDoc (data) {
  if (data == null) data = {}
  data.t = new Date().getTime()
  return request({
    url: 'audio/doc/create/empty',
    method: 'post',
    params: data
  })
}

export function addCreativeVideo (data) {
  if (data == null) data = {}
  data.t = new Date().getTime()
  return request({
    url: 'interactv/add',
    method: 'post',
    params: data
  })
}

export function getPptOssToken (data) {
  if (data == null) data = {}
  data.t = new Date().getTime()
  return request({
    url: 'open/aliyun/sts/get',
    method: 'post',
    params: data
  })
}

export function convertPpt (data) {
  if (data == null) data = {}
  data.t = new Date().getTime()
  return request({
    url: 'open/aliyun/imm/pptConvert',
    method: 'post',
    params: data
  })
}

export function convertPptAsyn (data) {
  if (data == null) data = {}
  data.t = new Date().getTime()
  return request({
    url: 'open/aliyun/imm/pptConvert/asyn',
    method: 'post',
    params: data
  })
}

export function getAliOssToken (data) {
  if (data == null) data = {}
  data.t = new Date().getTime()
  return request({
    url: 'open/aliyun/sts/get',
    method: 'post',
    params: data
  })
}

export function downloadDocSimple (data) {
  if (data == null) data = {}
  data.t = new Date().getTime()
  return request({
    url: 'audio/doc/simple/download',
    method: 'post',
    params: data
  })
}

export function getDocViewRecord (data) {
  if (data == null) data = {}
  data.t = new Date().getTime()
  return request({
    url: 'audio/viewRecord/pvuv',
    method: 'get',
    params: data
  })
}

export function listDocViewRecord (data) {
  if (data == null) data = {}
  data.t = new Date().getTime()
  return request({
    url: 'audio/viewRecord/listByDocId',
    method: 'get',
    params: data
  })
}

export function generateDocShare (data) {
  if (data == null) data = {}
  data.t = new Date().getTime()
  return request({
    url: 'audio/doc/share/generate',
    method: 'post',
    params: data
  })
}

export function updateDocShare (data) {
  if (data == null) data = {}
  data.t = new Date().getTime()
  return request({
    url: 'audio/doc/share/update',
    method: 'post',
    params: data
  })
}

export function getAudioLink (data) {
  if (data == null) data = {}
  data.t = new Date().getTime()
  return request({
    url: 'anon/audio/link/get',
    method: 'get',
    params: data
  })
}

export function saveAudioLink (data) {
  if (data == null) data = {}
  data.t = new Date().getTime()
  return request({
    url: 'audio/link/save',
    method: 'post',
    data: JSON.stringify(data),
    params: {}
  })
}

export function deleteAudioLink (data) {
  if (data == null) data = {}
  data.t = new Date().getTime()
  return request({
    url: 'audio/link/delete',
    method: 'post',
    params: data
  })
}

export function docViewData (data = {}) {
  return request({
    url: 'doc/watch/overview',
    method: 'get',
    params: data
  })
}

export function getWatcherList (data = {}) {
  return request({
    url: 'doc/watch/list',
    method: 'get',
    params: data
  })
}

export function getUserData (data = {}) {
  return request({
    url: 'doc/watch/overview/byUser',
    method: 'get',
    params: data
  })
}

export function getUserViewRecord (data = {}) {
  return request({
    url: 'doc/watch/list/byUser',
    method: 'get',
    params: data
  })
}

export function getUserClickRecord (data = {}) {
  return request({
    url: 'doc/watch/linkClick/list/byUser',
    method: 'get',
    params: data
  })
}

export function getVideoHeatmap (data = {}) {
  return request({
    url: 'audio/doc/heatWaveform',
    method: 'get',
    params: data
  })
}

export function getUserHeatmap (data = {}) {
  return request({
    url: 'audio/doc/user/heatWaveform',
    method: 'get',
    params: data
  })
}

export function updateClick (data = {}) {
  return request({
    url: 'anon/audio/link/click',
    method: 'post',
    params: data
  })
}