import { Paragraph } from 'tiptap'

export default class TipTapP extends Paragraph {
  get schema() {
    return {
      attrs: {
        class: {
          default: ''
        },
        'data-speaker-id': {
          default: ''
        },
        'data-speaker-name': {
          default: ''
        },
        'data-speaker-desc': {
          default: ''
        }
      },
      content: 'inline*',
      group: 'block',
      draggable: false,
      parseDOM: [
        {
          tag: 'p',
          getAttrs: dom => ({
            class: dom.getAttribute('class'),
            'data-speaker-id': dom.getAttribute('data-speaker-id'),
            'data-speaker-name': dom.getAttribute('data-speaker-name'),
            'data-speaker-desc': dom.getAttribute('data-speaker-desc')
          })
        }
      ],
      toDOM: node => {
        return [
          'p',
          {
            ...node.attrs
          },
          0
        ]
      }
    }
  }
}
