<template>
  <div class="link-panel-bg" v-show="linkPanelVisible">
    <div class="link-panel">
      <img class="close-button" src="../../assets/homepage/dialog-close.svg" @click="hide" />
      <div class="title">{{ hasLink ? '编辑链接' : '添加链接' }}</div>
      <div class="line"></div>
      <div class="panel-content">
        <div class="section-title">链接地址</div>
        <input v-model="linkUrl" class="link-input" placeholder="请输入链接地址" @input="onConfigChanged" @blur="onLinkBlured" />
        <div class="option-row">
          <div class="text">文件生成下载链接</div>
          <button :disabled="uploading" class="upload-button" @click="clickUploadButton">{{ uploading ? '上传中(' + uploadPercent + '%)' : '上传' }}</button>
          <input ref="fileUpload" type="file" accept=".pdf,.key,.ppt,.pptx,.doc,.docx,.xls,.xlsx,.jpg,.jpeg,.png,.gif" hidden @change="onUploadDocSelected" />
        </div>
        <div class="attach-tip">支持 .pdf .key .ppt .doc .xls .xlsx 格式</div>
        <div class="section-title">设置文字</div>
        <input v-model="linkText" class="link-input" placeholder="请输入按钮文字" @input="onConfigChanged" />
        <div class="option-row">
          <div class="text">颜色</div>
          <color-selector v-model="textColor" @change="onConfigChanged" />
        </div>
        <div class="section-title">按钮样式</div>
        <div class="option-row">
          <div class="text">圆角</div>
          <input ref="range" type="range" class="range" step="1" min="0" max="25" :value="cornerSize" @input="onSizeChanged"/>
        </div>
        <div class="option-row">
          <div class="text">颜色</div>
          <color-selector v-model="buttonColor" @change="onConfigChanged" />
        </div>
        <div class="button-row" v-if="hasLink">
          <button class="no-button" @click="clickDeleteLink">删除链接</button>
          <button :disabled="linkUrl === '' || linkText === ''" class="yes-button" @click="clickModifyLink">保存修改</button>
        </div>
        <div class="button-row" v-else>
          <button :disabled="linkUrl === '' || linkText === ''" class="yes-button" @click="clickModifyLink">创建链接</button>
        </div>
        <div class="line"></div>
      </div>
    </div>
  </div>
</template>
<script>
import * as qiniu from 'qiniu-js'
import { deleteAudioLink, getAudioLink, saveAudioLink } from '@/api/doc'
import { getQiniuAttachmentToken } from '@/api/qiniu'
import ColorSelector from '../../components/color-selector/index.vue'
import { formatDate, parseFileName, testUrl } from '@/utils/util'
import { isTestEnv } from '@/common/const'
import { getUid } from '@/utils/auth'

export default {
  name: 'LinkPanel',
  components: {
    ColorSelector
  },
  props: {
    docId: {
      required: true
    }
  },
  watch: {
    docId (val) {
      if (val != null) {
        this.loadAudioDocLink(val)
      }
    }
  },
  data () {
    return {
      linkPanelVisible: false,
      hasLink: false,
      linkInfo: null,
      uploading: false,
      uploadPercent: 0,
      linkUrl: '',
      linkBlob: null,
      linkText: '',
      textColor: '#FFFFFFFF',
      cornerSize: 4,
      buttonColor: '#3E7FFFFF'
    }
  },
  mounted () {
    this.updateRangeStyle(this.$refs.range)
  },
  methods: {
    onUploadDocSelected: function (event) {
      const file = event.target.files[0]
      const _this = this
      const key = (isTestEnv() ? 'dev/' : 'prd/') + 'u' + getUid() + '/attachment/' + formatDate(new Date(), 'yyyyMMdd') + '/' + new Date().getTime() + '/' + file.name
      const docName = parseFileName(file.name)
      getQiniuAttachmentToken().then(res => {
        if (res.data.code === 0) {
          _this.uploading = true
          _this.uploadPercent = 0
          var qiniuToken = res.data.data.token
          var domain = res.data.data.domain
          var config = {
            zone: qiniu.region.z1,
            useCdnDomain: true
          }
          var putExtra = {}
          const observable = qiniu.upload(file, key, qiniuToken, putExtra, config)
          const observer = {
            next: function (response) {
              _this.uploadPercent = parseInt(response.total.percent)
            },
            error: function (error) {
              console.log(error)
              _this.$message('上传失败', 'error')
              _this.uploading = false
            },
            complete: function (res) {
              const mediaUrl = domain + '/' + res.key
              _this.linkUrl = mediaUrl
              _this.linkBlob = URL.createObjectURL(file)
              _this.linkText = docName
              _this.onConfigChanged()
              _this.uploading = false
            }
          }
          observable.subscribe(observer)
        } else {
          _this.$message(res.data.desc, 'error')
        }
      })
    },
    resetLinkInfo: function () {
      if (this.linkInfo != null) {
        this.hasLink = true
        this.linkUrl = this.linkInfo.linkUrl
        this.linkBlob = null
        this.linkText = this.linkInfo.content
        let style = JSON.parse(this.linkInfo.style)
        this.textColor = style.textColor ? style.textColor : '#FFFFFFFF'
        this.cornerSize = style.cornerSize ? style.cornerSize : 4
        this.buttonColor = style.buttonColor ? style.buttonColor : '#3E7FFFFF'
      } else {
        this.hasLink = false
        this.linkInfo = null
        this.linkUrl = ''
        this.linkBlob = null
        this.linkText = ''
        this.textColor = '#FFFFFFFF'
        this.cornerSize = 4
        this.buttonColor = '#3E7FFFFF'
      }
      this.$nextTick(() => {
        this.updateRangeStyle(this.$refs.range)
      })
      this.onConfigChanged()
    },
    show: function () {
      this.linkPanelVisible = true
      this.$emit('show')
      this.onConfigChanged()
    },
    hide: function () {
      this.linkPanelVisible = false
      this.$emit('hide')
      this.resetLinkInfo()
    },
    onSizeChanged: function (event) {
      this.updateRangeStyle(event.target)
      this.cornerSize = parseInt(event.target.value)
      this.onConfigChanged()
    },
    onConfigChanged: function () {
      this.$bus.$emit('link-config-changed', {
        hasLink: this.hasLink,
        linkUrl: this.linkBlob != null ? this.linkBlob : this.linkUrl,
        linkText: this.linkText,
        textColor: this.textColor,
        cornerSize: this.cornerSize,
        buttonColor: this.buttonColor
      })
    },
    onLinkBlured: function () {
      if (this.linkUrl != '') {
        if (!this.linkUrl.startsWith('https://') && !this.linkUrl.startsWith('http://')) {
          var url = 'https://' + this.linkUrl
          if (testUrl(url)) {
            this.linkUrl = url
          }
        }
      }
    },
    updateRangeStyle: function (target) {
      const min = target.min
      const max = target.max
      const val = target.value
      target.style.backgroundSize = (val - min) * 100 / (max - min) + '% 100%'
    },
    loadAudioDocLink: function (docId) {
      getAudioLink({
        docId: docId
      }).then(res => {
        if (res.data.code === 0) {
          if (res.data.data == null) {
            this.hasLink = false
          } else {
            this.hasLink = true
            this.linkInfo = res.data.data
            this.linkUrl = res.data.data.linkUrl
            this.linkText = res.data.data.content
            let style = JSON.parse(res.data.data.style)
            this.textColor = style.textColor ? style.textColor : '#FFFFFFFF'
            this.cornerSize = style.cornerSize ? style.cornerSize : 4
            this.buttonColor = style.buttonColor ? style.buttonColor : '#3E7FFFFF'
            this.$nextTick(() => {
              this.updateRangeStyle(this.$refs.range)
            })
          }
          this.onConfigChanged()
        } else {
          this.$message(res.data.desc, 'error')
        }
      })
    },
    clickUploadButton: function () {
      this.$refs.fileUpload.click()
    },
    clickDeleteLink: function () {
      deleteAudioLink({
        docId: this.docId
      }).then(res => {
        if (res.data.code === 0) {
          this.$message('链接删除成功', 'success')
          this.hide()
          this.hasLink = false
          this.linkInfo = null
          this.resetLinkInfo()
        } else {
          this.$message(res.data.desc, 'error')
        }
      })
    },
    clickModifyLink: function () {
      if (!testUrl(this.linkUrl)) {
        this.$message('请输入有效网址', 'error')
        return
      }
      let style = {
        textColor: this.textColor,
        cornerSize: this.cornerSize,
        buttonColor: this.buttonColor
      }
      saveAudioLink({
        docId: this.docId,
        content: this.linkText,
        linkUrl: this.linkUrl,
        style: JSON.stringify(style)
      }).then(res => {
        if (res.data.code === 0) {
          this.$message(this.hasLink ? '链接保存成功' : '链接创建成功', 'success')
          this.linkInfo = res.data.data
          this.hide()
        } else {
          this.$message(res.data.desc, 'error')
        }
      })
    }
  }
}
</script>
<style scoped lang="scss">
.link-panel-bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: all 0.3s;
  background: white;
  .link-panel {
    position: absolute;
    top: 14px;
    right: 14px;
    left: 14px;
    bottom: 14px;
    background: rgba(255,255,255,1);
    border: 1px solid rgba(224,224,224,1);
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    .close-button {
      position: absolute;
      top: 13px;
      right: 11px;
      width: 28px;
      height: 28px;
      cursor: pointer;
    }
    .title {
      padding-left: 20px;
      height: 60px;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    .line {
      height: 1px;
      background: rgba(245,245,245,1);
    }
    .panel-content {
      height: 0;
      flex-grow: 1;
      overflow-y: scroll;
      display: flex;
      flex-direction: column;
      .link-input {
        margin: 14px 20px 0 20px;
        padding: 0 12px;
        height: 44px;
        line-height: 44px;
        border: 1px solid rgba(224,224,224,1);
        border-radius: 4px;
        outline: 0;
        font-size: 14px;
        color: rgba(51,51,51,1);
        flex-shrink: 0;
        &::placeholder {
          color: rgba(153,153,153,1);
        }
        &:focus {
          border: 1px solid rgba(0,112,255,1);
          border-radius: 4px;
          box-shadow:  0 0 3px 0 rgba(0,112,255,0.7);
        }
      }
      .section-title {
        margin: 26px 0 0 20px;
        font-size: 14px;
        font-weight: normal;
        color: rgba(102,102,102,1);
      }
      .attach-tip {
        margin: 8px 0 0 29px;
        font-size: 12px;
        font-weight: normal;
        color: rgba(153,153,153,1);
      }
      .option-row {
        height: 44px;
        min-height: 44px;
        margin: 14px 20px 0px 20px;
        padding: 0 12px;
        border: 1px solid rgba(224,224,224,1);
        border-radius: 4px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        .text {
          font-size: 14px;
          font-weight: normal;
          color: rgba(153,153,153,1);
        }
        .upload-button {
          margin: 0;
          padding: 0;
          background: transparent;
          font-size: 14px;
          font-weight: 500;
          color: rgba(35,108,251,1);
          cursor: pointer;
          &:disabled {
            color: rgba(51,51,51,1);
          }
        }
        .row-input {
          margin-left: 20px;
          outline: 0;
          border: 0;
          flex-grow: 1;
          line-height: 40px;
          font-size: 14px;
          color: rgba(153,153,153,1);
          text-align: right;
          color: rgba(51,51,51,1);
          &::placeholder {
            color: rgba(153,153,153,1);
          }
        }
        .range {
          outline: 0;
          width: 164px;
          margin: 0;
          -webkit-appearance: none;
          height: 3px;
          background: #EDEDED;
          border-radius: 3px;
          background-image: linear-gradient(#0070FF, #0070FF);
          background-size: 0% 100%;
          background-repeat: no-repeat;
        }
        .range::-webkit-slider-thumb {
          -webkit-appearance: none;
          border: none;
          border-radius: 7px;
          height: 14px;
          width: 14px;
          background: rgba(255,255,255,1);
          border: 1px solid rgba(224,224,224,1);
        }
      }
      .button-row {
        margin-top: 72px;
        margin-bottom: 20px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        .yes-button {
          margin: 0 8px;
          width: 168px;
          height: 42px;
          background: rgba(35,108,251,1);
          border-radius: 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 14px;
          font-weight: 500;
          color: rgba(255,255,255,1);
          cursor: pointer;
          &:disabled {
            background: rgba(35,108,251,0.4);
          }
        }
        .no-button {
          width: 166px;
          height: 40px;
          background: rgba(245,247,251,1);
          border: 1px solid rgba(216,216,216,1);
          border-radius: 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 14px;
          font-weight: 500;
          color: rgba(51,51,51,1);
          cursor: pointer;
        }
      }
    }
  }
}
</style>
