<template>
  <div class="player" ref="player">
    <div class="player-inner" :style="{ width: videoDomWidth === 0 ? '100%' : ''}">
      <div class="video-content" :style="{ width: videoDomWidth === 0 ? '100%' : '', height: videoDomHeight === 0 ? '100%' : ''}">
        <div class="video-area" ref="videoArea" @mousemove="onVideoMouseMove" @mouseleave="onVideoMouseLeave"
          :style="{ width: videoDomWidth > 0 ? videoDomWidth + 'px' : '',  height: videoDomHeight > 0 ? videoDomHeight + 'px' : 'calc(100vh - 211px)'  }">
          <!-- <video
            ref="video"
            crossorigin="anonymous"
            x5-playsinline=""
            playsinline=""
            webkit-playsinline=""
            x-webkit-airplay="allow"
            @timeupdate="onTimeUpdate"
            @play="onPlay"
            @playing="onPlaying"
            @pause="onPause"
            @ended="onEnded"
            @error="onError"
            @abort="onAbort"
            @waiting="onWaiting"
            @loadedmetadata="onLoadedMetaData"
            preload="auto"
            /> -->
          <canvas
            ref="videoCanvas"
            :width="videoWidth"
            :height="videoHeight"
            :style="{ width: canvasWidth, height: canvasHeight }"
            />
          <div class="slide-content" v-if="onlySlide && slideList[selectedSlideIndex]">
            <img :src="slideList[selectedSlideIndex].url" />
          </div>
          <div class="play-control" @click="clickPlayOrPauseButton" v-show="controlVisible && !linkEditing" :style="{ 'background-color': (hasLink && playEnded) ? 'rgba(0,0,0,0.21)' : '' }">
            <div v-if="hasLink && (playEnded || (currentTime > 0 && !playing && !loading))">
              <button @click.stop="clickLinkButton" class="link-button" :style="{ color: linkText == '' ? 'rgba(255,255,255,0.5)' : linkTextColor, 'border-radius': linkCornerSize + 'px', 'background-color': linkButtonColor }">{{ linkText == '' ? '查看文档' : linkText }}</button>
              <div class="replay-button">
                <img v-if="playEnded" src="../../assets/player/icon-replay.svg" />
                <img v-else src="../../assets/player/icon-play.svg" />
                <div>{{ playEnded ? '重新播放' : '继续播放' }}</div>
              </div>
            </div>
            <img v-else-if="!playing && !loading" class="full-pause" src="../../assets/detail/icon-play-big.svg" alt="" />
          </div>
          <div class="link-control" v-if="linkEditing">
            <button @click="clickLinkButton" class="link-button" :style="{ color: linkText == '' ? 'rgba(255,255,255,0.5)' : linkTextColor, 'border-radius': linkCornerSize + 'px', 'background-color': linkButtonColor }">{{ linkText == '' ? '查看文档' : linkText }}</button>
          </div>
          <div class="full-control-bar" v-show="fullControlBarVisible && fullscreen" @click.stop>
            <div class="space-line"></div>
            <div class="progress">
              <div class="bg" ref="progressBg"></div>
              <input ref="progress" class="range" type="range"
                  step="any" min="0" max="0" value="0"
                  @mousedown="onFullProgressMouseDown" @input="onFullProgressInput" @mouseup="onFullProgressMouseUp"/>
            </div>
            <div class="time"><span class="current">{{ currentTimeText }}</span> {{ durationText !== '' ? '/ ' + durationText : '' }}</div>
            <div class="button-line">
              <button class="play-button" @click="clickPlayOrPauseButton">
                <img v-if="playing" src="../../assets/player/pause.svg" />
                <img v-else src="../../assets/player/play.svg" />
              </button>
              <button class="action-button" :class="enableBackward ? '' : 'action-button-disable'" @click="clickBackwardButton">
                <img v-if="enableBackward" src="../../assets/player/backward-on.svg" />
                <img v-else src="../../assets/player/backward-off.svg" />
              </button>
              <button class="action-button" :class="enableForward ? '' : 'action-button-disable'" @click="clickForwardButton">
                <img v-if="enableForward" src="../../assets/player/forward-on.svg" />
                <img v-else src="../../assets/player/forward-off.svg" />
              </button>
              <speed-button class="speed-button" :speed="speed" @selected="onFullSpeedSelected" />
              <div class="space"></div>
              <volume-button class="action-button" :volume="volume" @changed="onFullVolumeChanged" />
              <button class="action-button" @click="clickFullscreenButton">
                <img src="../../assets/player/exit-fullscreen.svg" />
              </button>
            </div>
          </div>
          <div class="spinner-container" v-show="loading">
            <img src="../../assets/detail/video-loading.png" alt="" />
          </div>
        </div>
        <div class="slide-list" v-if="slideList.length > 0 && videoDomWidth > 0" :style="{ width: videoDomWidth > 0 ? (videoDomWidth - 20) + 'px' : '' }">
          <div class="slide-list-inner">
            <div class="slide-item" v-for="(item, index) in slideList" :key="index" @click="clickSlideItem(item, index)">
              <div class="image-wrapper" :class="index === selectedSlideIndex ? 'image-wrapper-active' : ''">
                <img :src="item.url" />
              </div>
              <div class="text">{{ index + 1 }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="control-bar" :style="{ width: controlDomWidth > 0 ? (controlDomWidth - 24) + 'px' : '100%' }">
        <button class="action-button" @click="clickPlayOrPauseButton">
          <img v-if="playing" src="../../assets/detail/icon-pause.svg" />
          <img v-else src="../../assets/detail/icon-play.svg" />
        </button>
        <button class="action-button" @click="clickBackwardButton" :disabled="!enableBackward">
          <img src="../../assets/detail/icon-backward-active.svg" />
        </button>
        <button class="action-button" @click="clickForwardButton" :disabled="!enableForward">
          <img src="../../assets/detail/icon-forward-active.svg" />
        </button>
        <div class="speed-button" v-clickoutside="hideSpeedMenu">
          <img class="speed-icon" v-if="speed === 2" @click="clickSpeedButton" src="../../assets/detail/icon-speed-2.0.svg" />
          <img class="speed-icon" v-else-if="speed === 1.7" @click="clickSpeedButton" src="../../assets/detail/icon-speed-1.7.svg" />
          <img class="speed-icon" v-else-if="speed === 1.5" @click="clickSpeedButton" src="../../assets/detail/icon-speed-1.5.svg" />
          <img class="speed-icon" v-else-if="speed === 1.2" @click="clickSpeedButton" src="../../assets/detail/icon-speed-1.2.svg" />
          <img class="speed-icon" v-else @click="clickSpeedButton" src="../../assets/detail/icon-speed-1.0.svg" />
          <div class="float-speed-menu" v-show="speedMenuVisible">
            <div class="menu-item" :class="speed === 2 ? 'menu-item-active' : ''" @click="clickSelectSpeed(2)">2x</div>
            <div class="menu-item" :class="speed === 1.7 ? 'menu-item-active' : ''" @click="clickSelectSpeed(1.7)">1.7x</div>
            <div class="menu-item" :class="speed === 1.5 ? 'menu-item-active' : ''" @click="clickSelectSpeed(1.5)">1.5x</div>
            <div class="menu-item" :class="speed === 1.2 ? 'menu-item-active' : ''" @click="clickSelectSpeed(1.2)">1.2x</div>
            <div class="menu-item" :class="speed === 1 ? 'menu-item-active' : ''" @click="clickSelectSpeed(1)">1x</div>
          </div>
        </div>
        <div ref="progressBar" class="progress-bar" @mousedown="onProgressBarDown">
          <div class="progress-backend-text">{{ currentTimeText }} {{ durationText !== '' ? '/ ' + durationText : '' }}</div>
          <div class="progress-thumb" :style="{ width: progressPercent + '%' }">
            <div class="progress-front-text">{{ currentTimeText }} {{ durationText !== '' ? '/ ' + durationText : '' }}</div>
          </div>
          <canvas v-if="showhot" id="canvas1" class="progressBarCanvas"></canvas>
          <div :style="{ width: progressPercent + '%' }"  class="progressBarCanvas progressBarCanvas2">
            <canvas v-if="showhot" id='canvas2'  ></canvas>
          </div>
        </div>
        <button v-show="slideList.length > 0" class="action-button" @click="clickPPTButton" :disabled="slideList.length === 0">
          <img v-if="!onlySlide" src="../../assets/detail/icon-ppt-inactive.svg" />
          <img v-else src="../../assets/detail/icon-ppt-active.svg" />
        </button>
        <div class="volume-button" v-clickoutside="hideVolumeMenu">
          <img v-if="volume === 0" class="volume-icon" @click="clickVolumeButton" src="../../assets/detail/icon-sound-off.svg" />
          <img v-else class="volume-icon" @click="clickVolumeButton" src="../../assets/detail/icon-sound-on.svg" />
          <div ref="volumeMenu" class="float-volume-menu" v-show="volumeMenuVisible" @mousedown="onVolumeMenuDown">
            <div class="volume-text">{{ volume }}</div>
          </div>
        </div>
        <button class="action-button" @click="clickFullscreenButton">
          <img src="../../assets/detail/icon-enter-fullscreen.svg" />
        </button>
        <div class="control-bar-cover" v-if="linkEditing" @click.stop></div>
      </div>
    </div>
  </div>
</template>
<script>
import { formatVideoTime } from '@/utils/time'
import { loadFullPlayerSetting, saveFullPlayerSetting } from '@/utils/config'
import SpeedButton from './SpeedButton.vue'
import VolumeButton from './VolumeButton.vue'
import draw from '../../utils/heatmap'
const kVideoDomMaxCount = 10

export default {
  name: 'FullPlayer',
  components: {
    SpeedButton,
    VolumeButton
  },
  props: {
    playerWidth: {
      type: Number,
      required: true,
      default: 0
    },
    playerHeight: {
      type: Number,
      required: true,
      default: 0
    },
    duration: {
      type: Number,
      required: true,
      default: 0
    },
    slideList: {
      type: Array,
      required: true,
      default: () => {
        return []
      }
    },
    linkEditing: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  computed: {
    enableBackward: function () {
      if (this.slideList.length > 0) {
        return this.selectedSlideIndex > 0
      } else {
        return this.currentTime > 0
      }
    },
    enableForward: function () {
      if (this.slideList.length > 0) {
        return this.selectedSlideIndex < this.slideList.length - 1
      } else {
        return this.currentTime < this.duration
      }
    },
    progressPercent: function () {
      if (this.duration === 0) {
        return 0
      } else {
        return this.currentTime * 100 / this.duration
      }
    }
  },
  watch: {
    playerWidth: function () {
      this.calcVideoDomSize()
    },
    playerHeight: function () {
      this.calcVideoDomSize()
    },
    duration (value) {
      this.durationText = formatVideoTime(value)
      this.$refs.progress.max = value
    }
  },
  data () {
    return {
      playing: false,
      wasPlaying: false,
      pauseForSeek: false,
      pauseForSeekMediaIndex: -1,
      loading: false,
      loadingTipTimer: null,
      currentTime: 0,
      controlVisible: true,
      recentMouseMovement: false,
      mouseMoveTimer: null,
      hideCursorTimer: null,
      playEnded: false,

      videoWidth: 0,
      videoHeight: 0,
      videoDomWidth: 0,
      videoDomHeight: 0,
      controlDomWidth: 0,
      canvasWidth: '',
      canvasHeight: '',
      fullControlBarVisible: false,
      canvasContext: null,
      audioContext: null,
      audioDestination: null,

      drawInterval: null,
      mediaList: [],
      curMediaIndex: 0,
      videoDomList: [],

      speed: 1,
      speedMenuVisible: false,

      currentTimeText: '0:00',
      durationText: '',
      progressDragging: false,
      dragStartTime: 0,
      dragStartX: -1,

      selectedSlideIndex: 0,
      onlySlide: false,

      volume: 100,
      volumeMenuVisible: false,
      volumeDragging: false,
      dragStartVolume: 100,
      dragStartY: -1,
      fullscreen: false,

      hasLink: false,
      linkUrl: '',
      linkText: '',
      linkTextColor: '#FFFFFFFF',
      linkCornerSize: 4,
      linkButtonColor: '#3E7FFFFF',

      showhot: false,
      hotList: []
    }
  },
  created () {
    document.addEventListener('mouseup', this.onDocumentMouseUp, true)
    document.addEventListener('fullscreenchange', this.onFullscreenChange, true)
  },
  beforeDestroy () {
    this.stopDrawInterval()
    this.videoDomList.forEach(videoDom => {
      videoDom.dom.src = ''
    })
    this.playing = false
  },
  destroyed () {
    this.$bus.$off('link-config-changed', this.onLinkConfigChanged)
    document.removeEventListener('mouseup', this.onDocumentMouseUp)
    document.removeEventListener('fullscreenchange', this.onFullscreenChange)
    this.clearLoadingTimer()
    this.clearMouseMoveTimer()
    this.stopHideCursorTimer()
  },
  mounted () {
    var setting = loadFullPlayerSetting()
    if (setting.speed != null) {
      this.speed = setting.speed
    }
    if (setting.volume != null) {
      this.volume = setting.volume
      this.$refs.volumeMenu.style.backgroundSize = '100% ' + (100 - this.volume) + '%'
    }
    this.canvasContext = this.$refs.videoCanvas.getContext('2d')
    window.player = this
    this.$bus.$on('link-config-changed', this.onLinkConfigChanged)
  },
  methods: {
    onLinkConfigChanged: function (linkConfig) {
      this.hasLink = linkConfig.hasLink
      this.linkUrl = linkConfig.linkUrl
      this.linkText = linkConfig.linkText
      this.linkTextColor = linkConfig.textColor
      this.linkCornerSize = linkConfig.cornerSize
      this.linkButtonColor = linkConfig.buttonColor
    },
    init: function () {
      this.playing = false
      this.wasPlaying = false
      this.pauseForSeek = false
      this.pauseForSeekMediaIndex = -1
      this.loading = false
      this.clearLoadingTimer()
      this.currentTime = 0
      this.controlVisible = true
      this.recentMouseMovement = false
      this.clearMouseMoveTimer()
      this.stopHideCursorTimer()
      this.videoWidth = 0
      this.videoHeight = 0
      this.videoDomWidth = 0
      this.videoDomHeight = 0
      this.controlDomWidth = 0
      this.canvasWidth = ''
      this.canvasHeight = ''
      this.stopDrawInterval()
      this.mediaList = []
      this.curMediaItem = 0
      this.videoDomList.forEach(videoDom => {
        videoDom.dom.src = ''
      })

      this.currentTimeText = '0:00'
      this.durationText = ''
      this.selectedSlideIndex = 0
      this.onlySlide = false
    },
    startDrawInterval: function () {
      if (this.drawInterval == null) {
        const _this = this
        this.drawInterval = setInterval(() => {
          _this.drawVideoImage()
          _this.checkSwitchMedia()
        }, 1000 / 30)
      }
    },
    stopDrawInterval: function () {
      if (this.drawInterval != null) {
        clearInterval(this.drawInterval)
        this.drawInterval = null
      }
    },
    drawVideoImage: function () {
      try {
        this.canvasContext.drawImage(this.getCurrentVideoDom().dom, 0, 0)
      } catch (err) {
        console.log(err)
      }
    },
    isPlaying: function () {
      return this.playing
    },
    getCurrentTime: function () {
      return this.currentTime
    },
    getDuration: function () {
      return this.duration
    },
    pauseVideoDom: function (videoDom) {
      // console.log('pauseVideoDom:' + videoIndex + ';' + videoDom.canPaused + ';' + videoDom.willPause)
      if (videoDom.canPaused) {
        videoDom.dom.pause()
        // console.log('videoDom paused:' + videoIndex)
      } else {
        videoDom.willPause = true
      }
    },
    pause: function () {
      const videoDom = this.getCurrentVideoDom()
      // console.log('pause video dom current:' + this.curMediaIndex)
      this.pauseVideoDom(videoDom, this.curMediaIndex % kVideoDomMaxCount)
    },
    initAudioContext: function () {
      if (this.audioContext == null) {
        this.audioContext = new AudioContext()
        this.videoDomList.forEach((videoDom) => {
          const gainNode = this.audioContext.createGain()
          const sourceNode = this.audioContext.createMediaElementSource(videoDom.dom)
          sourceNode.connect(gainNode)
          gainNode.connect(this.audioContext.destination)
          gainNode.gain.value = 0
          videoDom.gainNode = gainNode
        })
      }
    },
    play: function () {
      this.initAudioContext()
      const videoDom = this.getCurrentVideoDom()
      // console.log('play video dom current:' + this.curMediaIndex + ';' + videoDom.canPaused + ';' + videoDom.willPause)
      videoDom.canPaused = false
      videoDom.willPause = false
      videoDom.dom.mediaIndex = this.curMediaIndex
      // console.log('play media:' + videoDom.dom.src)
      videoDom.dom.play().then(() => {
        if (videoDom.willPause) {
          videoDom.dom.pause()
          videoDom.willPause = false
        }
        videoDom.canPaused = true
      }).catch(err => {
        console.log(err)
      })
      this.cacheNextMedia()
    },
    mediaUrlEquals: function (url1, url2) {
      if (url1 != null && url1 !== '' & url2 != null && url2 !== '') {
        return url1.split('?')[0] === url2.split('?')[0]
      } else {
        return url1 === url2
      }
    },
    cacheNextMedia: function () {
      var cacheCount = Math.min(kVideoDomMaxCount - 1, this.mediaList.length - this.curMediaIndex - 1)
      for (let i = 0; i < cacheCount; i++) {
        const mediaIndex = (this.curMediaIndex + i + 1) % this.mediaList.length
        const mediaItem = this.mediaList[mediaIndex]
        var videoDom = this.getVideoDom(mediaIndex).dom
        if (!this.mediaUrlEquals(videoDom.src, mediaItem.url)) {
          videoDom.src = mediaItem.url
        }
        videoDom.mediaIndex = mediaIndex
        videoDom.currentTime = mediaItem.mediaStart / 1000
      }
      // this.pauseOtherVideoDoms()
    },
    onTimeUpdate: function (event) {
      if (event.target.mediaIndex !== this.curMediaIndex) {
        return
      }
      const duration = this.getDuration()
      const currentTime = this.getCurrentTime() > duration ? duration : this.getCurrentTime()
      this.currentTimeText = formatVideoTime(currentTime)
      for (var i = this.slideList.length - 1; i >= 0; i--) {
        if (currentTime >= this.slideList[i].beginTime) {
          this.selectedSlideIndex = i
          break
        }
      }
    },
    onPlay: function (event) {
      if (event.target.mediaIndex !== this.curMediaIndex) {
        return
      }
      this.onPlayStateChanged()
    },
    onPlaying: function (event) {
      this.startDrawInterval()
      if (event.target.mediaIndex !== this.curMediaIndex) {
        return
      }
      this.playing = true
      this.loading = false
      this.playEnded = false
      this.updateVideoSpeed(this.speed)
      this.updateVideoVolume(this.volume / 100)
      var videoDom = this.getCurrentVideoDom()
      const curMedia = this.mediaList[this.curMediaIndex]
      if (curMedia.end - curMedia.start < 100) {
        const half = (curMedia.end - curMedia.start) / 2
        videoDom.gainNode.gain.setValueAtTime(0, this.audioContext.currentTime)
        videoDom.gainNode.gain.linearRampToValueAtTime(1, this.audioContext.currentTime + half / 1000)
        videoDom.gainNode.gain.setValueAtTime(1, this.audioContext.currentTime + half / 1000)
        videoDom.gainNode.gain.linearRampToValueAtTime(0, this.audioContext.currentTime + half / 500)
      } else {
        videoDom.gainNode.gain.setValueAtTime(0, this.audioContext.currentTime)
        videoDom.gainNode.gain.linearRampToValueAtTime(1, this.audioContext.currentTime + 0.03)
      }
      this.checkSwitchMedia()
      this.clearLoadingTimer()
      this.$emit('playing')
    },
    onPause: function (event) {
      if (event.target.mediaIndex !== this.curMediaIndex) {
        return
      }
      if (this.pauseForSeek && this.curMediaIndex === this.pauseForSeekMediaIndex) {
        this.pauseForSeek = false
        this.pauseForSeekMediaIndex = -1
        return
      }
      this.pauseForSeek = false
      this.pauseForSeekMediaIndex = -1
      this.stopDrawInterval()
      this.playing = false
      this.loading = false
      this.clearLoadingTimer()
      this.onPlayStateChanged()
      this.$emit('pause')
    },
    onEnded: function (event) {
      if (event.target.mediaIndex !== this.curMediaIndex) {
        return
      }
      const curMediaItem = this.mediaList[this.curMediaIndex]
      let currentTime = curMediaItem.end
      if (this.curMediaIndex >= this.mediaList.length - 1) {
        this.curMediaIndex = 0
        this.onPlayListEnded()
      } else {
        this.curMediaIndex++
        this.play()
      }
      this.currentTime = currentTime
      this.$refs.progress.value = currentTime
      this.updateRangeStyle(this.$refs.progress, this.$refs.progressBg)
      this.$emit('changeCurrentTime', currentTime)
    },
    onError: function (event) {
      if (event.target.mediaIndex !== this.curMediaIndex) {
        return
      }
      this.stopDrawInterval()
      this.pauseForSeek = false
      this.pauseForSeekMediaIndex = -1
      this.playing = false
      this.loading = false
      this.$emit('error')
    },
    onAbort: function (event) {
      if (event.target.mediaIndex !== this.curMediaIndex) {
        return
      }
      this.stopDrawInterval()
      this.playing = false
      this.loading = false
      this.$emit('abort')
    },
    onWaiting: function (event) {
      if (event.target.mediaIndex !== this.curMediaIndex) {
        return
      }
      const mediaIndex = event.target.mediaIndex
      this.stopDrawInterval()
      this.$emit('waiting')
      this.loadingTipTimer = setTimeout(() => {
        if (mediaIndex === this.curMediaIndex) {
          this.loading = true
          // console.log('waiting index:' + this.curMediaIndex)
        }
      }, 3000)
    },
    clearLoadingTimer: function () {
      if (this.loadingTipTimer != null) {
        clearTimeout(this.loadingTipTimer)
        this.loadingTipTimer = null
      }
    },
    onLoadedData: function (event) {
      if (event.target.mediaIndex !== this.curMediaIndex) {
        return
      }
      this.drawVideoImage()
    },
    onLoadedMetaData: function (event) {
      if (event.target.mediaIndex !== this.curMediaIndex) {
        return
      }
      const videoDom = this.getCurrentVideoDom().dom
      const duration = this.getDuration()
      this.durationText = formatVideoTime(duration)
      if (videoDom != null) {
        this.videoWidth = videoDom.videoWidth
        this.videoHeight = videoDom.videoHeight
        this.calcVideoDomSize()
        this.drawVideoImage()
        this.canvasResize()
      }
    },
    onVideoMouseMove: function () {
      this.recentMouseMovement = true
      this.computeOpacity()
      this.clearMouseMoveTimer()
      var $t = this
      this.mouseMoveTimer = setTimeout(function () {
        $t.onVideoMouseStill()
      }, 3000)
    },
    clearMouseMoveTimer: function () {
      if (this.mouseMoveTimer != null) {
        clearTimeout(this.mouseMoveTimer)
        this.mouseMoveTimer = null
      }
    },
    onVideoMouseLeave: function () {
      this.onVideoMouseStill()
    },
    onVideoMouseStill: function () {
      this.recentMouseMovement = false
      this.computeOpacity()
    },
    onPlayStateChanged: function () {
      this.computeOpacity()
    },
    computeOpacity: function () {
      if (this.getCurrentVideoDom().dom == null) {
        return
      }
      const videoIsPaused = this.getCurrentVideoDom().dom.paused
      if (videoIsPaused || this.recentMouseMovement) {
        this.controlVisible = true
        this.fullControlBarVisible = true
        this.$refs.videoArea.style.cursor = ''
        this.stopHideCursorTimer()
      } else {
        this.startHideCursorTimer()
      }
    },
    startHideCursorTimer: function () {
      this.stopHideCursorTimer()
      const _this = this
      this.hideCursorTimer = setTimeout(() => {
        if (_this.getCurrentVideoDom().dom != null && _this.isPlaying()) {
          _this.controlVisible = false
          _this.fullControlBarVisible = false
          _this.$refs.videoArea.style.cursor = 'none'
        }
      }, 1000)
    },
    stopHideCursorTimer: function () {
      if (this.hideCursorTimer != null) {
        clearTimeout(this.hideCursorTimer)
        this.hideCursorTimer = null
      }
    },
    onFullProgressMouseDown: function () {
      this.wasPlaying = !this.getCurrentVideoDom().dom.paused
      this.pause()
    },
    onFullProgressInput: function () {
      const progress = this.$refs.progress.value
      this.seekTo(parseInt(progress))
    },
    onFullProgressMouseUp: function () {
      if (this.wasPlaying) {
        this.play()
      }
    },
    onFullSpeedSelected: function (speed) {
      this.clickSelectSpeed(speed)
    },
    onFullVolumeChanged: function (volume) {
      this.volume = volume
      this.updateVideoVolume(this.volume / 100)
      this.$refs.volumeMenu.style.backgroundSize = '100% ' + (100 - this.volume) + '%'
    },
    updateRangeStyle: function (rangeEle, bgEle) {
      const min = rangeEle.min
      const max = rangeEle.max
      const val = rangeEle.value
      if (max - min === 0) {
        bgEle.style.backgroundSize = '0% 100%'
      } else {
        bgEle.style.backgroundSize = (val - min) * 100 / (max - min) + '% 100%'
      }
    },
    clickSlideItem: function (item, index) {
      const destTime = item.beginTime + 200
      this.selectedSlideIndex = index
      this.currentTime = destTime
      this.currentTimeText = formatVideoTime(this.currentTime)
      this.seekTo(destTime)
    },
    clickPlayOrPauseButton: function () {
      if (this.isMediaPlaying(this.getCurrentVideoDom().dom) || this.playing) {
        this.pause()
      } else {
        this.play()
      }
    },
    clickLinkButton: function () {
      // const duration = this.getDuration()
      // const currentTime = this.getCurrentTime() > duration ? duration : this.getCurrentTime()
      // this.$emit('linkClick', parseInt(currentTime / 1000))
      if (this.linkUrl !== '') {
        window.open(this.linkUrl)
      }
    },
    clickBackwardButton: function () {
      if (this.slideList.length > 0) {
        this.clickSlideItem(this.slideList[this.selectedSlideIndex - 1], this.selectedSlideIndex - 1)
      } else {
        const destTime = this.currentTime > 15000 ? this.currentTime - 15000 : 0
        this.currentTime = destTime
        this.currentTimeText = formatVideoTime(destTime)
        this.seekTo(destTime)
      }
    },
    clickForwardButton: function () {
      if (this.slideList.length > 0) {
        this.clickSlideItem(this.slideList[this.selectedSlideIndex + 1], this.selectedSlideIndex + 1)
      } else {
        const destTime = this.currentTime + 15000 < this.duration ? this.currentTime + 15000 : this.duration
        this.currentTime = destTime
        this.currentTimeText = formatVideoTime(destTime)
        this.seekTo(destTime)
      }
    },
    hideSpeedMenu: function () {
      this.speedMenuVisible = false
    },
    clickSpeedButton: function () {
      this.speedMenuVisible = !this.speedMenuVisible
    },
    clickSelectSpeed: function (speed) {
      this.updateVideoSpeed(speed)
      this.speed = speed
      this.hideSpeedMenu()
      this.saveSetting()
    },
    onProgressBarDown: function (event) {
      if (this.duration === 0) {
        return
      }
      this.wasPlaying = !this.getCurrentVideoDom().dom.paused
      if (this.wasPlaying) {
        this.pause()
        this.pauseForSeek = true
        this.pauseForSeekMediaIndex = this.curMediaIndex
      }

      const progressRect = this.$refs.progressBar.getBoundingClientRect()
      const destTime = parseInt((event.clientX - progressRect.x) * this.duration / this.$refs.progressBar.offsetWidth)
      this.currentTime = destTime
      this.currentTimeText = formatVideoTime(destTime)
      this.seekTo(destTime)

      this.progressDragging = true
      this.dragStartTime = this.currentTime
      this.dragStartX = event.screenX
      document.addEventListener('mousemove', this.onDocumentMouseMove, true)
    },
    clickPPTButton: function () {
      this.onlySlide = !this.onlySlide
    },
    hideVolumeMenu: function () {
      this.volumeMenuVisible = false
    },
    clickVolumeButton: function () {
      this.volumeMenuVisible = !this.volumeMenuVisible
    },
    onVolumeMenuDown: function (event) {
      const menuRect = this.$refs.volumeMenu.getBoundingClientRect()
      const volumeChange = parseInt((event.clientY - menuRect.y) * 100 / 88)
      this.volume = 100 - volumeChange
      this.updateVideoVolume(this.volume / 100)
      this.$refs.volumeMenu.style.backgroundSize = '100% ' + (100 - this.volume) + '%'

      this.volumeDragging = true
      this.dragStartVolume = this.volume
      this.dragStartY = event.screenY
      document.addEventListener('mousemove', this.onDocumentMouseMove, true)
    },
    onFullscreenChange: function () {
      if (document.fullscreenElement) {
        this.fullscreen = true
      } else {
        this.fullscreen = false
      }
    },
    clickFullscreenButton: function () {
      if (this.fullscreen) {
        document.exitFullscreen()
      } else {
        this.$refs.videoArea.requestFullscreen()
      }
    },
    onDocumentMouseMove: function (event) {
      if (this.volumeDragging) {
        var y = event.screenY
        var volumeChange = parseInt((y - this.dragStartY) * 100 / 88)
        var destVolume = this.dragStartVolume - volumeChange
        if (destVolume < 0) {
          destVolume = 0
        } else if (destVolume > 100) {
          destVolume = 100
        }
        this.volume = destVolume
        this.updateVideoVolume(this.volume / 100)
        this.$refs.volumeMenu.style.backgroundSize = '100% ' + (100 - this.volume) + '%'
      }
      if (this.progressDragging) {
        var x = event.screenX
        var timeChange = parseInt((x - this.dragStartX) * this.duration / this.$refs.progressBar.offsetWidth)
        var destTime = this.dragStartTime + timeChange
        if (destTime < 0) {
          destTime = 0
        } else if (destTime > this.duration) {
          destTime = this.duration
        }
        this.currentTime = destTime
        this.currentTimeText = formatVideoTime(destTime)
        this.seekTo(destTime)
      }
    },
    onDocumentMouseUp: function () {
      document.removeEventListener('mousemove', this.onDocumentMouseMove)
      if (this.volumeDragging) {
        this.volumeDragging = false
        this.dragStartVolume = this.volume
        this.dragStartY = -1
        this.saveSetting()
      }

      if (this.progressDragging) {
        this.progressDragging = false
        this.dragStartTime = this.currentTime
        this.dragStartX = -1
        if (this.wasPlaying) {
          this.play()
          this.wasPlaying = false
        }
      }
    },
    isMediaPlaying: function (el) {
      return el.currentTime > 0 && !el.paused && !el.ended && el.readyState > 2
    },
    calcVideoDomSize: function () {
      if (this.playerWidth > 0 && this.videoWidth > 0 && this.videoHeight > 0 && this.$refs.player != null) {
        var playerHeight = this.$refs.player.offsetHeight - 60 - 20 - 20
        if (this.slideList.length > 0) {
          playerHeight = playerHeight - 120
        }
        if (this.playerWidth * this.videoHeight > this.videoWidth * playerHeight) {
          this.videoDomHeight = playerHeight
          this.videoDomWidth = this.videoWidth * playerHeight / this.videoHeight
        } else {
          this.videoDomWidth = this.playerWidth
          this.videoDomHeight = this.videoHeight * this.playerWidth / this.videoWidth
        }
        this.controlDomWidth = this.videoDomWidth >= 500 ? this.videoDomWidth : 500
        if (this.videoWidth * screen.height > this.videoHeight * screen.width) {
          this.canvasWidth = '100%'
          this.canvasHeight = ''
        } else {
          this.canvasWidth = ''
          this.canvasHeight = '100%'
        }
      }
    },
    saveSetting: function () {
      var setting = {
        speed: this.speed,
        volume: this.volume
      }
      saveFullPlayerSetting(setting)
    },
    shiftCurrentVideoDom: function () {
      var videoIndex = this.curMediaIndex % kVideoDomMaxCount
      var videoDom = this.getVideoDom(videoIndex)
      delete this.videoDomList[videoIndex]
      return videoDom
    },
    getCurrentVideoDom: function () {
      return this.getVideoDom(this.curMediaIndex)
    },
    setVideoDom: function (mediaIndex, videoDom) {
      var videoIndex = mediaIndex % kVideoDomMaxCount
      if (this.videoDomList[videoIndex] != null && this.videoDomList[videoIndex].dom !== videoDom.dom) {
        this.videoDomList[videoIndex].dom.mediaIndex = -1
        this.videoDomList[videoIndex].dom.src = ''
        delete this.videoDomList[videoIndex]
      }
      this.videoDomList[videoIndex] = videoDom
      videoDom.dom.mediaIndex = mediaIndex
    },
    getVideoDom: function (mediaIndex) {
      var videoIndex = mediaIndex % kVideoDomMaxCount
      if (this.videoDomList[videoIndex] == null) {
        let videoDom = this.createVideoDom()
        let gainNode = null
        if (this.audioContext != null) {
          gainNode = this.audioContext.createGain()
          const sourceNode = this.audioContext.createMediaElementSource(videoDom)
          sourceNode.connect(gainNode)
          gainNode.connect(this.audioContext.destination)
          gainNode.gain.value = 0
        }
        this.videoDomList[videoIndex] = {
          dom: videoDom,
          gainNode: gainNode,
          canPaused: false,
          willPause: false
        }
      }
      const videoDom = this.videoDomList[videoIndex]
      // if (this.mediaList[mediaIndex] != null && videoDom.dom.src !== this.mediaList[mediaIndex].url) {
      //   videoDom.dom.src = this.mediaList[mediaIndex].url
      // }
      videoDom.dom.mediaIndex = mediaIndex
      return videoDom
    },
    pauseOtherVideoDoms: function () {
      var videoIndex = this.curMediaIndex % kVideoDomMaxCount
      this.videoDomList.forEach((videoDom, index) => {
        if (index !== videoIndex) {
          this.pauseVideoDom(videoDom, index)
        }
      })
    },
    updateVideoSpeed: function (speed) {
      this.videoDomList.forEach(videoDom => {
        if (videoDom != null) {
          videoDom.dom.playbackRate = speed
        }
      })
    },
    updateVideoVolume: function (volume) {
      this.videoDomList.forEach(videoDom => {
        if (videoDom != null) {
          videoDom.dom.volume = volume
        }
      })
    },
    createVideoDom: function () {
      var videoDom = document.createElement('video')
      videoDom.crossOrigin = 'anonymous'
      videoDom.preload = 'auto'
      videoDom.ontimeupdate = this.onTimeUpdate
      videoDom.onplay = this.onPlay
      videoDom.onplaying = this.onPlaying
      videoDom.onpause = this.onPause
      videoDom.onended = this.onEnded
      videoDom.onerror = this.onError
      videoDom.onabort = this.onAbort
      videoDom.onwaiting = this.onWaiting
      videoDom.onloadeddata = this.onLoadedData
      videoDom.onloadedmetadata = this.onLoadedMetaData
      videoDom.src = ''
      return videoDom
    },
    updateThumbnail: function (thumbnailUrl) {
      var img = new Image()
      img.addEventListener('load', () => {
        this.canvasContext.drawImage(img, 0, 0)
      })
      img.src = thumbnailUrl
    },
    updateMediaList: function (mediaList) {
      if (mediaList != null) {
        if (mediaList.length > 0) {
          const currentTime = this.currentTime
          let needJump = true
          const currentVideoDom = this.shiftCurrentVideoDom()
          const currentMedia = this.mediaList[this.curMediaIndex]
          this.mediaList = mediaList
          this.mediaList.forEach((media, index) => {
            if (currentMedia != null && media.mediaStart === currentMedia.mediaStart && media.mediaEnd === currentMedia.mediaEnd && needJump) {
              this.currentTime = media.start + currentVideoDom.dom.currentTime * 1000 - media.mediaStart
              this.curMediaIndex = index
              this.setVideoDom(index, currentVideoDom)
              needJump = false
            }
          })
          if (needJump) {
            if (currentVideoDom != null) {
              currentVideoDom.mediaIndex = -1
              currentVideoDom.dom.src = ''
            }
            this.seekTo(currentTime)
          } else {
            this.cacheNextMedia()
            this.pauseOtherVideoDoms()
          }
          if (this.videoWidth === 0 && this.videoHeight === 0) {
            const mediaInfo = mediaList[0]
            if (mediaInfo.width > 0 && mediaInfo.height > 0) {
              this.videoWidth = mediaInfo.width
              this.videoHeight = mediaInfo.height
              this.calcVideoDomSize()
            }
          }
        } else {
          this.canvasContext.clearRect(0, 0, this.videoWidth, this.videoHeight)
          this.init()
        }
      }
    },
    seekTo: function (timestamp, notify) {
      var playing = this.playing && !this.wasPlaying
      let left = 0
      let right = this.mediaList.length - 1
      let middle = -1
      while (left <= right) {
        middle = parseInt((left + right) / 2)
        const middleMedia = this.mediaList[middle]
        if (timestamp >= middleMedia.start && timestamp < middleMedia.end) {
          break
        } else if (timestamp >= middleMedia.end) {
          left = middle + 1
        } else if (timestamp < middleMedia.start) {
          right = middle - 1
        }
      }
      var destIndex = middle !== -1 ? middle : 0
      this.curMediaIndex = destIndex
      const curMedia = this.mediaList[destIndex]
      const curVideoDom = this.getCurrentVideoDom()
      curVideoDom.dom.mediaIndex = destIndex
      if (!this.mediaUrlEquals(curVideoDom.dom.src, curMedia.url)) {
        curVideoDom.dom.src = curMedia.url
        // console.log('update current video' + curMedia.url)
      }
      curVideoDom.dom.currentTime = (curMedia.mediaStart + timestamp - curMedia.start) / 1000
      if (playing) {
        this.play()
      } else {
        this.cacheNextMedia()
      }
      this.currentTime = timestamp
      this.$refs.progress.value = timestamp
      this.updateRangeStyle(this.$refs.progress, this.$refs.progressBg)
      this.drawVideoImage()
      this.pauseOtherVideoDoms()
      if (notify == null || notify) {
        this.$emit('changeCurrentTime', timestamp)
      }
    },
    checkSwitchMedia: function () {
      let currentTime = 0
      const curMediaItem = this.mediaList[this.curMediaIndex]
      const videoDom = this.getCurrentVideoDom()
      const videoDomTime = videoDom.dom.currentTime * 1000
      if (videoDomTime >= curMediaItem.mediaEnd) {
        currentTime = curMediaItem.end
        // console.log('before pause:' + this.curMediaIndex + ';' + videoDom.gainNode.gain.value)
        this.pause()
        if (this.curMediaIndex >= this.mediaList.length - 1) {
          this.curMediaIndex = 0
          this.onPlayListEnded()
        } else {
          this.curMediaIndex++
          // console.log('before play:' + this.curMediaIndex + ';' + this.getCurrentVideoDom().gainNode.gain.value)
          this.play()
        }
      } else {
        if (videoDomTime >= curMediaItem.mediaEnd - 70 && videoDom.gainNode.gain.value === 1) {
          videoDom.gainNode.gain.setValueAtTime(1, this.audioContext.currentTime)
          videoDom.gainNode.gain.linearRampToValueAtTime(0, this.audioContext.currentTime + 0.03)
          // console.log('change gain value:' + this.curMediaIndex + ';')
        }
        currentTime = curMediaItem.start + videoDomTime - curMediaItem.mediaStart
      }
      this.currentTime = currentTime
      this.$refs.progress.value = currentTime
      this.updateRangeStyle(this.$refs.progress, this.$refs.progressBg)
      this.$emit('changeCurrentTime', currentTime)
    },
    onPlayListEnded: function () {
      this.getVideoDom(0).dom.mediaIndex = 0
      this.getVideoDom(0).dom.currentTime = this.mediaList[0].mediaStart / 1000
      this.cacheNextMedia()
      this.stopDrawInterval()
      this.playing = false
      this.loading = false
      this.playEnded = true
      this.clearLoadingTimer()
      this.onPlayStateChanged()
      this.drawVideoImage()
    },
    canvasResize: function(arr) {
      if (arr) {
        this.hotList = arr
      }
      if (this.showhot && document.querySelector('.progress-bar')) {
        this.$nextTick(() => {
          draw('#canvas1', this.hotList)
        })
      }
    }
  }
}
</script>
<style scoped lang="scss">
.player {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .player-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid rgba(216, 216, 216, 0.5);
    border-radius: 4px;
    overflow: hidden;
    .video-content {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      .video-area {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        & video {
          width: 100%;
          height: 100%;
        }
        & canvas {
          max-width: 100%;
          max-height: 100%;
        }
        .slide-content {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: black;
          display: flex;
          align-items: center;
          justify-content: center;
          & img {
            max-width: 100%;
            max-height: 100%;
          }
        }
        .play-control {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          .full-pause {
            width: 72px;
            height: 72px;
            cursor: pointer;
          }
          .replay-button {
            cursor: pointer;
            margin-top: 25px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            & img {
              width: 18px;
              height: 18px;
            }
            & div {
              margin-left: 9px;
              margin-right: 10px;
              font-size: 14px;
              font-weight: normal;
              color: rgba(255,255,255,1);
            }
          }
        }
        .link-control {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          background: rgba(0,0,0,0.21);
        }
        .link-button {
          min-width: 180px;
          max-width: 360px;
          height: 50px;
          cursor: pointer;
          padding: 0 16px;
          background: rgba(62,127,255,1);
          border-radius: 4px;
          font-size: 16px;
          font-weight: 500;
          color: rgba(255,255,255,1);
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .link-button-top {
          position: absolute;
          top: 20px;
          right: 20px;
        }
        .full-control-bar {
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          display: flex;
          flex-direction: column;
          background: linear-gradient(0deg, rgba(0,0,0,1) 0%,rgba(0,0,0,0) 100%);
          button {
            padding: 0;
            margin: 0;
            background: transparent;
            border: 0;
            outline: none;
          }
          .space-line {
            height: 50px;
          }
          .progress {
            margin-left: 32px;
            margin-right: 32px;
            height: 10px;
            position: relative;
            cursor: pointer;
            .bg {
              height: 4px;
              margin-top: 3px;
              width: 100%;
              background: rgba(255,255,255, 0.39);
              background-image: linear-gradient(#FFFFFF, #FFFFFF);
              background-size: 0% 100%;
              background-repeat: no-repeat;
              border-radius: 4px;
            }
            .range {
              outline: 0;
              position: absolute;
              top: 1px;
              left: 0;
              width: 100%;
              bottom: 0;
              height: 4px;
              margin-left: 0;
              margin-right: 0;
              -webkit-appearance: none;
              background: transparent;
              cursor: pointer;
            }
            .range::-webkit-slider-thumb {
              -webkit-appearance: none;
              border: none;
              height: 10px;
              width: 6px;
              background: rgba(0,112,255,1);
              border-radius: 1px;
            }
            .range::-webkit-slider-runnable-track {
              background: rgba(255,255,255,0);
            }
          }
          .time {
            margin: 4px 0px 0px 32px;
            font-size: 14px;
            font-weight: 500;
            color: rgba(255,255,255,0.64);
            .current {
              color: rgba(255,255,255,1);
            }
          }
          .button-line {
            margin: 0 32px;
            height: 72px;
            display: flex;
            flex-direction: row;
            align-items: center;
            .play-button {
              width: 24px;
              height: 24px;
              cursor: pointer;
              & img {
                width: 24px;
                height: 24px;
              }
            }
            .action-button {
              margin-left: 32px;
              width: 24px;
              height: 24px;
              cursor: pointer;
              & img {
                width: 24px;
                height: 24px;
              }
            }
            .action-button-disable {
              cursor: not-allowed;
            }
            .speed-button {
              margin-left: 32px;
              cursor: pointer;
            }
            .space {
              flex-grow: 1;
            }
          }
        }
        .spinner-container {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          flex-shrink: 1;
          & img {
            width: 56px;
            height: 56px;
            -webkit-animation: changeright 1s linear infinite;
          }
        }
        @-webkit-keyframes changeright {
          0% {
            -webkit-transform: rotate(0deg);
          }
          50% {
            -webkit-transform: rotate(180deg);
          }
          100% {
            -webkit-transform: rotate(360deg);
          }
        }
        &:fullscreen {
          .full-pause {
            width: 88px;
            height: 88px;
          }
        }
      }
      .slide-list {
        height: 106px;
        min-height: 106px;
        background: rgba(28,28,28,1);
        padding: 14px 10px 0px 10px;
        .slide-list-inner {
          display: flex;
          flex-direction: row;
          overflow-x: scroll;
          .slide-item {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-right: 12px;
            .image-wrapper {
              width: 112px;
              height: 68px;
              border: 2px solid transparent;
              border-radius: 12px;
              display: flex;
              align-items: center;
              justify-content: center;
              & img {
                width: 100px;
                height: 56px;
                border-radius: 8px;
              }
            }
            .image-wrapper-active {
              border: 2px solid rgba(36,106,250,1);
            }
            .text {
              margin-top: 6px;
              font-size: 12px;
              font-weight: 500;
              color: rgba(255,255,255,1);
            }
          }
          .slide-item-last {
            margin-right: 0px;
          }
        }
      }
    }
    .control-bar {
      position: relative;
      padding: 0 12px;
      height: 60px;
      min-height: 60px;
      background: rgba(247,247,247,1);
      display: flex;
      flex-direction: row;
      align-items: center;
      .action-button {
        margin: 0 12px;
        width: 24px;
        height: 24px;
        padding: 0;
        background: transparent;
        cursor: pointer;
        & img {
          width: 24px;
          height: 24px;
        }
        &:disabled {
          cursor: default;
        }
      }
      .speed-button {
        position: relative;
        margin: 0 16px 0 12px;
        width: 32px;
        height: 24px;
        .speed-icon {
          width: 32px;
          height: 24px;
          cursor: pointer;
        }
        .float-speed-menu {
          position: absolute;
          bottom: 30px;
          left: -12px;
          width: 56px;
          display: flex;
          flex-direction: column;
          padding: 4px 0;
          background: rgba(255,255,255,1);
          border-radius: 8px;
          box-shadow:  0 0 2px 0 rgba(0,0,0,0.3);
          .menu-item {
            height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 12px;
            font-weight: 500;
            color: rgba(153,153,153,1);
            cursor: pointer;
            &:hover {
              background: rgba(235,235,235,1);
              color: rgba(51,51,51,1);
            }
          }
          .menu-item-active {
            background: rgba(235,235,235,1);
            color: rgba(51,51,51,1);
          }
        }
      }
      .progress-bar {
        height: 40px;
        background: rgba(235,235,235,1);
        border-radius: 8px;
        flex-grow: 1;
        margin-right: 8px;
        position: relative;
        overflow: hidden;
        cursor: pointer;
        .progress-backend-text {
          position: absolute;
          padding-left: 16px;
          height: 40px;
          line-height: 40px;
          font-size: 14px;
          font-weight: 500;
          color: rgba(153,153,153,1);
          z-index: 10;
        }
        .progress-thumb {
          position: absolute;
          height: 40px;
          background: rgba(0,112,255,1);
          overflow: hidden;
          white-space: nowrap;
          text-overflow: clip;
          z-index: 11;
          .progress-front-text {
            // position: relative;
            // z-index: 10;
            padding-left: 16px;
            height: 40px;
            line-height: 40px;
            font-size: 14px;
            font-weight: 500;
            color: rgba(255,255,255,1);
          }
        }
        .progressBarCanvas{
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
        .progressBarCanvas2{
          overflow: hidden;
          z-index: 11;
          // background: rgba(0,112,255,1);
        }
      }
      .volume-button {
        margin: 0 12px;
        width: 24px;
        height: 24px;
        position: relative;
        .volume-icon {
          width: 24px;
          height: 24px;
          cursor: pointer;
        }
        .float-volume-menu {
          position: absolute;
          bottom: 30px;
          left: -10px;
          width: 44px;
          height: 88px;
          background: #EBEBEB;
          background-image: linear-gradient(#FFFFFF, #FFFFFF);
          background-size: 100% 0%;
          background-repeat: no-repeat;
          border-radius: 8px;
          box-shadow:  0 0 2px 0 rgba(0,0,0,0.3);
          cursor: pointer;
          .volume-text {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            text-align: center;
            font-size: 12px;
            font-weight: 500;
            color: rgba(153,153,153,1);
          }
        }
      }
      .control-bar-cover {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(255,255,255, 0.5);
      }
    }
  }
}
</style>
