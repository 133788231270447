<template>
  <transition name="fade">
    <div class="bg" v-if="visible" @click="clickBg">
      <div class="dialog-out">
        <div class="dialog" :style="{ height: shareSwitch ? '412px' : '360px' }" @click.stop>
          <img class="close-button" src="../../../assets/homepage/dialog-close.svg" @click="clickCloseButton" />
          <div class="header">
            <div class="title">分享</div>
          </div>
          <div class="line" />
          <div class="content-row">
            <div class="text">开启分享，链接允许访问</div>
            <div class="space"></div>
            <switcher v-model="shareSwitch" @change="onShareSwitchChanged" />
          </div>
          <div class="line" />
          <div class="section-title">设置权限</div>
          <div class="content-row">
            <div class="text" :class="shareSwitch ? '' : 'text-grey'">需微信登录才可以访问（关闭后无法获取访客信息）</div>
            <div class="space"></div>
            <switcher v-if="shareSwitch" v-model="wechatSwitch" @change="onWechatSwitchChanged" />
          </div>
          <div class="content-row">
            <div class="text" :class="shareSwitch ? '' : 'text-grey'">开启访问密码，需输入密码才可以访问</div>
            <div class="password" v-if="shareSwitch && passwordSwitch">{{ password }}</div>
            <div class="space"></div>
            <switcher v-model="passwordSwitch" v-if="shareSwitch" @change="onPasswordSwitchChanged" />
          </div>
          <div class="section-title">视频链接</div>
          <div class="link" :class="shareSwitch ? '' : 'link-grey'">{{ docUrl }}</div>
          <div v-if="shareSwitch" class="copy-button" @click="clickCopyButton">复制链接</div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import { generateDocShare, updateDocShare } from '@/api/doc'
import Switcher from '../switcher/Switcher.vue'
import { getH5DocUrl } from '@/common/const'
import { triggerShareDoc } from '@/utils/doc'

export default {
  name: 'ShareDialog',
  components: {
    Switcher
  },
  data () {
    return {
      docId: null,
      visible: false,
      shareInfo: null,
      shareSwitch: false,
      wechatSwitch: false,
      password: '',
      passwordSwitch: false,
      docUrl: ''
    }
  },
  methods: {
    show: function () {
      generateDocShare({
        docId: this.docId
      }).then(res => {
        if (res.data.code === 0) {
          this.shareInfo = res.data.data
          this.shareSwitch = res.data.data.allowAccess === 1
          this.wechatSwitch = res.data.data.needWechatLogin === 1
          this.password = res.data.data.extractionCode
          this.passwordSwitch = res.data.data.needExtractCode === 1
          this.visible = true
        } else {
          this.$message(res.data.desc, 'error')
        }
      })
      this.docUrl = getH5DocUrl(this.docId)
    },
    onShareSwitchChanged: function (value) {
      this.saveDocShare(value, this.wechatSwitch, this.passwordSwitch, () => {
        this.shareSwitch = !value
      })
    },
    onWechatSwitchChanged: function (value) {
      this.saveDocShare(this.shareSwitch, value, this.passwordSwitch, () => {
        this.wechatSwitch = !value
      })
    },
    onPasswordSwitchChanged: function (value) {
      this.saveDocShare(this.shareSwitch, this.wechatSwitch, value, () => {
        this.passwordSwitch = !value
      })
    },
    saveDocShare: function (shareSwitch, wechatSwitch, passwordSwitch, failCallback) {
      updateDocShare({
        audioDocId: this.docId,
        allowAccess: shareSwitch ? 1 : 0,
        needWechatLogin: wechatSwitch ? 1 : 0,
        needExtractCode: passwordSwitch ? 1 : 0
      }).then(res => {
        if (res.data.code !== 0) {
          this.$message(res.data.desc, 'error')
          failCallback()
        }
      }).catch(err => {
        console.log(err)
        failCallback()
      })
    },
    clickBg: function () {
      this.visible = false
    },
    clickCloseButton: function () {
      this.visible = false
    },
    clickCopyButton: function () {
      var docUrl = getH5DocUrl(this.docId)
      if (this.passwordSwitch) {
        this.$clipboard('链接：' + docUrl + ' 访问密码：' + this.password)
      } else {
        this.$clipboard(docUrl)
      }
      this.$message('链接已复制', 'success')
      triggerShareDoc(this.docId)
    }
  }
}
</script>
<style scoped lang="scss">
.bg {
  position: fixed;
  font-family: PingFangSC-Regular, PingFang SC;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000;
  background: rgba(255,255,255,0.81);
  .dialog-out {
    width: 422px;
    height: 412px;
  }
  .dialog {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 422px;
    background: rgba(255,255,255,1);
    border: 1px solid rgba(224,224,224,1);
    border-radius: 8px;
    box-shadow:  0 0 10px 0 rgba(0,0,0,0.16);
    position: relative;
    .close-button {
      position: absolute;
      top: 14px;
      right: 16px;
      width: 28px;
      height: 28px;
      cursor: pointer;
    }
    .header {
      height: 58px;
      display: flex;
      flex-direction: row;
      align-items: center;
      .title {
        margin-left: 24px;
        font-size: 16px;
        font-weight: 500;
        color: rgba(51,51,51,1);
      }
    }
    .line {
      height: 1px;
      background: rgba(240,240,240,1);
    }
    .section-title {
      margin: 16px 0px 6px 24px;
      font-size: 14px;
      font-weight: normal;
      color: rgba(153,153,153,1);
    }
    .content-row {
      height: 50px;
      padding: 0 24px;
      display: flex;
      flex-direction: row;
      align-items: center;
      .text {
        font-size: 14px;
        font-weight: normal;
        color: rgba(51,51,51,1);
      }
      .text-grey {
        color: rgba(153,153,153,1);
      }
      .space {
        flex-grow: 1;
      }
      .password {
        margin-left: 6px;
        padding: 10px 15px;
        border: 1px solid rgba(235,235,235,1);
        border-radius: 4px;
        font-size: 14px;
        font-weight: normal;
        color: rgba(51,51,51,1);
      }
    }
    .link {
      margin-bottom: 16px;
      padding: 0 24px;
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 50px;
      background: rgba(250,251,252,1);
      font-size: 12px;
      font-weight: normal;
      color: rgba(51,51,51,1);
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .link-grey {
      color: rgba(168,168,168,1);
    }
    .copy-button {
      margin: 0 auto 16px auto;
      width: 118px;
      height: 36px;
      background: rgba(35,108,251,1);
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      font-weight: 500;
      color: rgba(255,255,255,1);
      cursor: pointer;
    }
  }
}
</style>
