<template>
  <div class="history-button" v-clickoutside="hideHistoryMenu">
    <img src="../../assets/detail/icon-history.svg" @click="clickHistoryButton" />
    <div class="float-history-menu" v-show="historyMenuVisible">
      <div class="title">历史记录</div>
      <div class="line"></div>
      <div class="item-list">
        <div v-for="(item, index) in historyDocList" :key="index" class="item">
          <div class="title-line">
            <div class="text">{{ currentVersion === item.version ? '当前版本' : item.title }}</div>
            <div class="version">V{{ item.version }}</div>
            <div class="space"></div>
            <div v-if="currentVersion !== item.version" class="replace-button" @click="clickRestoreButton(item)">替换</div>
          </div>
          <div class="desc">{{ item.description }}</div>
          <div class="modify-time">{{ item.modifyTime }}</div>
          <div class="up-line" v-show="index > 0"></div>
          <div class="dot"></div>
          <div class="bottom-line" v-show="index < historyDocList.length - 1"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getHistoryList } from '../../api/history'
import { formatHistoryTime } from '@/utils/util'

export default {
  name: 'HistoryButton',
  props: {
    docId: {
      required: true
    },
    currentVersion: {
      required: true
    }
  },
  data () {
    return {
      historyMenuVisible: false,
      historyDocList: []
    }
  },
  methods: {
    hideHistoryMenu: function () {
      this.historyMenuVisible = false
    },
    clickHistoryButton: function () {
      this.historyMenuVisible = !this.historyMenuVisible
      if (this.historyMenuVisible) {
        this.loadDocHistory()
      }
    },
    clickRestoreButton: function (item) {
      this.$emit('restore', item.version)
    },
    loadDocHistory: function () {
      this.historyDocList = []
      getHistoryList({
        audioDocId: this.docId,
        page: 0,
        size: 1000
      }).then(res => {
        if (res.data.code === 0) {
          res.data.data.content.forEach((item) => {
            let date = new Date(item.createTime)
            if (isNaN(date)) {
              date = new Date(item.createTime.replace(/-/g, '/'))
            }
            this.historyDocList.push({
              id: item.id,
              version: item.version,
              title: formatHistoryTime(date),
              description: item.historyDesc,
              modifyTime: this.formatHistoryTimeFromNow(item.createTime)
            })
          })
        } else {
          this.$message(res.data.desc, 'error')
        }
      })
    },
    formatHistoryTimeFromNow: function(date) {
      let updateDate = new Date(date)
      if (isNaN(updateDate)) {
        updateDate = new Date(date.replace(/-/g, '/'))
      }
      var updateTime = updateDate.getTime()
      var currentTime = new Date().getTime()
      var timeSlot = ''
      if ((currentTime - updateTime) / 1000 / 60 / 60 / 24 >= 1) {
        timeSlot = '由用户在' + Math.floor((currentTime - updateTime) / 1000 / 60 / 60 / 24) + '天前更改'
      } else {
        if ((currentTime - updateTime) / 1000 / 60 / 60 >= 1) {
          timeSlot = '由用户在' + Math.floor((currentTime - updateTime) / 1000 / 60 / 60) + '小时前更改'
        } else {
          if ((currentTime - updateTime) / 1000 / 60 >= 1) {
            timeSlot = '由用户在' + Math.floor((currentTime - updateTime) / 1000 / 60) + '分钟前更改'
          } else {
            timeSlot = '由用户在' + Math.ceil((currentTime - updateTime) / 1000) + '秒前更改'
          }
        }
      }
      return timeSlot
    }
  }
}
</script>
<style scoped lang="scss">
.history-button {
  margin: 0 0 0 7px;
  width: 24px;
  height: 24px;
  position: relative;
  & img {
    width: 24px;
    height: 24px;
    cursor: pointer;
    &:hover {
      background: rgba(245,247,251,1);
      border-radius: 4px;
    }
  }
  .float-history-menu {
    position: absolute;
    top: 36px;
    right: 0;
    width: 384px;
    height: calc(100vh - 300px);
    min-height: 300px;
    background: rgba(255,255,255,1);
    border: 1px solid rgba(224,224,224,1);
    border-radius: 8px;
    box-shadow:  0 0 8px 0 rgba(0,0,0,0.32);
    z-index: 1000;
    display: flex;
    flex-direction: column;
    .title {
      height: 56px;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-left: 24px;
      font-size: 16px;
      font-weight: 500;
      color: rgba(51,51,51,1);
    }
    .line {
      height: 1px;
      background: rgba(224,224,224,1);
    }
    .item-list {
      flex-grow: 1;
      height: 0;
      overflow-y: scroll;
      display: flex;
      flex-direction: column;
      .item {
        display: flex;
        flex-direction: column;
        position: relative;
        &:hover {
          background: rgba(245,247,251,0.51);
        }
        .title-line {
          margin: 12px 32px 0px 46px;
          display: flex;
          flex-direction: row;
          align-items: center;
          .text {
            font-size: 14px;
            font-weight: 500;
            color: rgba(51,51,51,1);
          }
          .version {
            margin-left: 8px;
            padding: 0 7px;
            height: 18px;
            background: rgba(232,233,236,1);
            border-radius: 2px;
            font-size: 14px;
            font-weight: normal;
            color: rgba(51,51,51,1);
          }
          .space {
            flex-grow: 1;
          }
          .replace-button {
            font-size: 12px;
            font-weight: 500;
            color: rgba(0,112,255,1);
            cursor: pointer;
          }
        }
        .desc {
          margin: 10px 32px 0px 46px;
          font-size: 12px;
          font-weight: normal;
          color: rgba(51,51,51,1);
        }
        .modify-time {
          margin: 6px 32px 12px 46px;
          font-size: 12px;
          font-weight: normal;
          color: rgba(102,102,102,1);
        }
        .up-line {
          position: absolute;
          top: 0px;
          left: 34px;
          width: 2px;
          height: 12px;
          background: rgba(232,233,236,1);
          border-bottom-left-radius: 2px;
          border-bottom-right-radius: 2px;
        }
        .dot {
          position: absolute;
          top: 18px;
          left: 32px;
          width: 6px;
          height: 6px;
          border-radius: 3px;
          background: rgba(198,198,198,1);
        }
        .bottom-line {
          position: absolute;
          left: 34px;
          bottom: 0;
          top: 30px;
          width: 2px;
          background: rgba(232,233,236,1);
          border-top-left-radius: 2px;
          border-top-right-radius: 2px;
        }
      }
    }
  }
}
</style>
