import Vue from 'vue'
import Share from './Share.vue'

const ShareDialog = Vue.extend(Share)

Share.install = function (docId) {
  let data = {
    docId: docId
  }
  let instance = new ShareDialog({
    data: data
  }).$mount()

  document.body.appendChild(instance.$el)

  Vue.nextTick(() => {
    instance.show()
  })
}

export default Share
