<template>
  <div class="message-button" v-clickoutside="hideMessageMenu">
    <img src="../../../assets/homepage/message.svg" @click="showMessageMenu"/>
    <div class="badge" v-show="unreadCount > 0">{{ unreadCount }}</div>
    <div class="message-box" v-show="messageMenuVisible">
      <div class="message-header">
        <div class="title">消息中心</div>
        <div class="read-button" :class="allRead ? '' : 'read-button-active'" @click="clickAllRead">全部已读</div>
      </div>
      <div class="line"></div>
      <div class="message-list">
        <div v-infinite-scroll="loadMore">
          <div v-for="(item, index) in messageList" :key="index" class="message-item" :class="item.isRead ? 'message-item-read' : ''"
              @click="clickMessageItem(item)">
            <img v-if="item.type === 'watch'" class="icon" :src="item.content.image_url" />
            <img v-else-if="item.isRead" class="icon" src="../../../assets/homepage/type-message-read.svg" />
            <img v-else class="icon" src="../../../assets/homepage/type-message-new.svg" />
            <div class="meta">
              <div class="title">{{ item.content.title }}</div>
              <div class="description">{{ item.content.message }}</div>
            </div>
            <div class="date">{{ item.createTime }}</div>
            <div class="delete-button" @click.stop="clickDeleteItem(item)">删除</div>
          </div>
        </div>
        <div class="footer" v-if="messageList.length !== 0">没有更多了～</div>
        <div v-else-if="!loading" class="nomore">
          <img class="image" src="../../../assets/homepage/tip-message-empty.svg" alt="" srcset="" />
          <div class="label">消息列表为空</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import InfiniteScroll from 'vue-infinite-scroll'
import { deleteSystemMessage, getSystemMessageList, getSystemMessageUnreadCount } from '@/api/notification'
import { formatTime } from '@/utils/time'
import { isLogined } from '@/utils/auth'
import { getMessageUnreadCount, getNotificationBaseTime, setMessageUnreadCount, setNotificationBaseTime } from '@/utils/config'
import { isWinOS } from '@/utils/os'

export default {
  name: 'MessageButton',
  directives: {
    InfiniteScroll
  },
  data () {
    return {
      isWin: false,
      messageMenuVisible: false,
      allRead: true,
      messageList: [],
      pageSize: 100,
      pageNum: 0,
      loading: false,
      unreadCount: 0,
      loopInterval: null
    }
  },
  mounted () {
    this.unreadCount = getMessageUnreadCount()
    this.loopGetUnreadCount()
    this.isWin = isWinOS()
    Notification.requestPermission()
  },
  destroyed () {
    if (this.loopInterval != null) {
      clearInterval(this.loopInterval)
      this.loopInterval = null
    }
  },
  methods: {
    showMessageMenu: function () {
      this.messageMenuVisible = !this.messageMenuVisible
      if (this.messageMenuVisible) {
        this.pageNum = 0
        this.loadMessageList()
      }
    },
    hideMessageMenu: function () {
      if (this.messageMenuVisible) {
        this.messageMenuVisible = false
        this.updateUnreadCount(0)
        this.allRead = true
      }
    },
    loopGetUnreadCount: function () {
      this.getUnreadCount()
      this.loopInterval = setInterval(() => {
        this.getUnreadCount()
      }, 1000 * 10)
    },
    getUnreadCount() {
      if (isLogined()) {
        getSystemMessageUnreadCount().then(res => {
          if (res.data.code === 0) {
            const { data } = res.data
            const count = Number(data)
            if (count > this.unreadCount) {
              this.loadMessageListForNotification(count - this.unreadCount)
            }
            this.updateUnreadCount(count)
          } else {
            this.$message(res.data.desc, 'error')
          }
        })
      }
    },
    updateUnreadCount(value) {
      this.unreadCount = value
      setMessageUnreadCount(value)
    },
    loadMessageList: function () {
      const pageNum = this.pageNum
      const pageSize = this.pageSize
      this.loading = true
      getSystemMessageList({ pageNum, pageSize }).then(res => {
        this.loading = false
        if (res.data.code === 0) {
          var allRead = true
          const { data } = res.data
          const arr = [...data.list]
          arr.forEach(item => {
            item.content = JSON.parse(item.content)
            if (item.type === 'watch') {
              if (item.content.image_url == null || item.content.image_url === '') {
                item.content.image_url = require('../../../assets/homepage/default-avatar.svg')
              }
            }
            item.createTime = formatTime(item.createTime)
            if (!item.isRead) {
              allRead = false
            }
          })
          if (pageNum === 0) {
            this.messageList.splice(0, this.messageList.length, ...arr)
          } else {
            this.messageList.push(...arr)
          }
          this.allRead = allRead
        } else {
          this.$message(res.data.desc, 'error')
        }
      }).catch(err => {
        console.log(err)
        this.loading = false
      })
    },
    loadMessageListForNotification: function (count) {
      const _this = this
      let flag = false
      getSystemMessageList({
        pageNum: 0,
        pageSize: count,
        isRead: 0
      }).then(res => {
        if (res.data.code === 0) {
          const notificationBaseTime = getNotificationBaseTime()
          const list = res.data.data.list
          for (var i = 0; i < list.length; i++) {
            const messageTime = Date.parse(list[i].createTime)
            if (messageTime > notificationBaseTime) {
              const content = JSON.parse(list[i].content)
              const targetId = list[i].targetId
              new Notification(content.title, { body: content.message, silent: true }).onclick = function (event) {
                window.focus()
                _this.gotoDocDetail(targetId)
                event.target.close()
              }
            }
            if (list[i].type === 'watch') {
              flag = true
            }
          }
          if (flag) {
            this.$emit('getMessage')
          }
          setNotificationBaseTime(new Date().getTime())
        }
      })
    },
    gotoDocDetail: function (docId) {
      if (this.$route.path !== '/moyin/detail') {
        this.$router.push({
          path: '/moyin/detail',
          query: {
            docId: docId
          }
        })
      } else {
        this.$bus.$emit('doc-switch', docId)
      }
    },
    loadMore: function () {
      this.pageNum = this.pageNum + 1
    },
    clickAllRead: function () {
      this.messageList.forEach(item => {
        item.isRead = true
      })
      this.updateUnreadCount(0)
      this.allRead = true
    },
    clickMessageItem: function (item) {
      item.isRead = true
      var allRead = true
      this.messageList.forEach(item => {
        if (!item.isRead) {
          allRead = false
        }
      })
      this.allRead = allRead
      this.messageMenuVisible = false
      if (item.type === 'doc' || item.type === 'can_download' || item.type === 'watch') {
        if (this.$route.path !== '/moyin/detail') {
          this.$router.push({
            path: '/moyin/detail',
            query: {
              docId: item.targetId
            }
          })
        } else {
          this.$bus.$emit('doc-switch', item.targetId)
        }
      }
    },
    clickDeleteItem: function (item) {
      const { id } = item
      deleteSystemMessage({ id }).then(res => {
        this.pageNum = 0
        this.loadMessageList()
        if (res.data.code === 0) {
          this.$message('删除成功', 'success')
        } else {
          this.$message(res.data.desc, 'error')
        }
      })
    }
  }
}
</script>
<style scoped lang="scss">
.message-button {
  width: 32px;
  height: 32px;
  position: relative;
  -webkit-app-region: no-drag;
  cursor: pointer;
  & img {
    width: 32px;
    height: 32px;
  }
  &:hover {
    background: rgba(245,247,251,1);
    border-radius: 4px;
  }
  &:active {
    background: rgba(239,241,245,1);
    border-radius: 4px;
  }
  .badge {
    position: absolute;
    top: 0px;
    right: -6px;
    padding: 0px 5px;
    height: 16px;
    line-height: 16px;
    background: rgba(254,89,89,1);
    border-radius: 8px;
    font-size: 10px;
    font-weight: 600;
    color: rgba(255,255,255,1);
  }
  .message-box {
    position: absolute;
    z-index: 1000;
    top: 42px;
    right: 0px;
    width: 384px;
    height: calc(100vh - 300px);
    min-height: 300px;
    background: rgba(255,255,255,1);
    border: 1px solid rgba(224,224,224,1);
    border-radius: 8px;
    box-shadow:  0 0 8px 0 rgba(0,0,0,0.32);
    display: flex;
    flex-direction: column;
    cursor: default;
    .message-header {
      height: 58px;
      min-height: 58px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      .title {
        margin-left: 24px;
        font-size: 16px;
        font-weight: 500;
        color: rgba(51,51,51,1);
      }
      .read-button {
        margin-right: 24px;
        font-size: 12px;
        font-weight: 500;
        color: rgba(193,193,193,1);
        cursor: pointer;
      }
      .read-button-active {
        color: rgba(0,112,255,1);
        cursor: default;
      }
    }
    .line {
      height: 1px;
      background: rgba(245,245,245,1);
    }
    .message-list {
      display: flex;
      flex-direction: column;
      overflow-y: scroll;
      flex-grow: 1;
      .message-item {
        height: 76px;
        display: flex;
        flex-direction: row;
        align-items: center;
        position: relative;
        cursor: pointer;
        .icon {
          margin-left: 24px;
          width: 36px;
          height: 36px;
          border-radius: 18px;
        }
        .meta {
          flex-grow: 1;
          display: flex;
          flex-direction: column;
          margin-left: 10px;
          margin-right: 100px;
          .title {
            font-size: 14px;
            font-weight: 500;
            color: rgba(51,51,51,1);
          }
          .description {
            font-size: 12px;
            font-weight: normal;
            color: rgba(51,51,51,1);
            & span {
              color: rgba(102,102,102,1);
              margin-right: 4px;
            }
          }
        }
        .date {
          position: absolute;
          top: 20px;
          right: 24px;
          font-size: 12px;
          font-weight: normal;
          color: rgba(102,102,102,1);
        }
        .delete-button {
          position: absolute;
          right: 24px;
          bottom: 16px;
          font-size: 12px;
          font-weight: normal;
          color: rgba(51,51,51,1);
          cursor: pointer;
          visibility: hidden;
        }
        &:hover {
          background: rgba(245,247,251,0.51);
          .delete-button {
            visibility: visible;
          }
        }
      }
      .message-item-read {
        .meta {
          .title {
            color: rgba(51,51,51,0.51);
          }
          .description {
            color: rgba(51,51,51,0.51);
          }
        }
        .date {
          color: rgba(102,102,102,0.51);
        }
      }
      .footer {
        padding: 10px;
        height: 35px;
        font-size: 12px;
        font-weight: 400;
        color: #999999;
        text-align: center;
        line-height: 35px;
      }

      .nomore {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .image {
          width: 140px;
          height: 140px;
        }
        .label {
          margin-top: 6px;
          margin-bottom: 30px;
          font-size: 12px;
          font-weight: normal;
          color: rgba(102,102,102,1);
        }
      }
    }
  }
}
</style>
