<template>
  <div class="visitor-button" v-clickoutside="hideVisitorDetail" @click="clickVisitorButton" v-if="viewRecordInfo != null" :style="{ width: (viewRecordInfo.headImageList.length > 2 ? 114 : (viewRecordInfo.headImageList.length > 1 ? 88 : (viewRecordInfo.headImageList.length > 0 ? 80 : 49))) + 'px' }">
    <div class="avatar-list" :style="{ width: (viewRecordInfo.headImageList.length > 2 ? 54 : (viewRecordInfo.headImageList.length > 1 ? 38 : 24)) + 'px' }">
      <img v-if="viewRecordInfo.headImageList.length > 0" class="avatar-1" :src="viewRecordInfo.headImageList[0]" />
      <img v-if="viewRecordInfo.headImageList.length > 1" class="avatar-2" :src="viewRecordInfo.headImageList[1]" />
      <img v-if="viewRecordInfo.headImageList.length > 2" class="avatar-3" :src="viewRecordInfo.headImageList[2]" />
    </div>
    <div class="visitor-count">{{ viewRecordInfo.pv }}</div>
    <img class="visitor-tip" src="../../assets/detail/icon-visitors.svg" />
    <div class="visitor-detail-dialog" @click.stop v-if="visitorDetailVisible">
      <div class="header">
        <div class="title">访客记录</div>
        <div class="total"><span>{{ viewRecordInfo.pv }}</span>次访问，<span>{{ viewRecordInfo.uv }}</span>个独立访客</div>
      </div>
      <div class="line"></div>
      <div class="visitor-list">
        <div v-infinite-scroll="loadMoreRecordList">
          <div class="visitor-item" @click="showDetailDataView(item)"  v-for="(item, index) in viewRecordList" :key="index">
            <img class="icon" :src="item.headImageUrl" />
            <div class="name" :title="item.nickName + '观看了' + item.viewDuration">{{ item.nickName }} 观看了{{ item.viewDuration }}</div>
            <div class="time">{{ item.viewTimeStr }}</div>
          </div>
        </div>
        <div class="footer" v-if="viewRecordList.length !== 0">没有更多了～</div>
        <div v-else-if="!loading" class="nomore">
          <img class="image"  src="../../assets/homepage/tip-result-empty.svg" alt="" srcset="" />
          <div class="label">访问记录列表为空</div>
        </div>
      </div>
      <div class="line"></div>
      <div >
        <button class="visitorRecords" @click="show" > 
          <img class="nohover" style="margin-right: 12px" src="../../assets/icon/data.svg" alt="">
          <img class="hover" style="margin-right: 12px" src="../../assets/icon/data_hover.svg" alt="">
          <span>数据总览</span>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import InfiniteScroll from 'vue-infinite-scroll'
import { getDocViewRecord, listDocViewRecord } from '@/api/doc'
import { formatDocTime } from '@/utils/time'
export default {
  name: 'VisitorButton',
  directives: {
    InfiniteScroll
  },
  props: {
    docId: {
      required: true
    }
  },
  data () {
    return {
      visitorDetailVisible: false,
      pageSize: 10,
      pageNum: 0,
      viewRecordInfo: null,
      viewRecordList: [],
      loading: false
    }
  },
  watch: {
    docId (to) {
      if (to !== null) {
        this.loadViewRecordInfo()
        this.loadViewRecordList()
      }
    }
  },
  methods: {
    clickVisitorButton: function () {
      // var options = {
      //   dir: "auto", // 文字方向
      //   body: "通知：OBKoro1评论了你的朋友圈", // 通知主体
      //   requireInteraction: true, // 不自动关闭通知
      //   // 通知图标 
      //   silent: true,
      //   sound: './test.mp3'
      // };
      // var notification = new Notification('今天', options)
      this.visitorDetailVisible = !this.visitorDetailVisible
      if (this.visitorDetailVisible) {
        this.loadViewRecordInfo()
        this.loadViewRecordList()
      }
    },
    refreshInfo: function () {
      this.loadViewRecordInfo()
      this.loadViewRecordList()
    },
    hideVisitorDetail: function () {
      this.visitorDetailVisible = false
    },
    loadViewRecordInfo: function () {
      getDocViewRecord({
        audioDocId: this.docId
      }).then(res => {
        if (res.data.code === 0) {
          var recordInfo = res.data.data
          if (recordInfo.pv > 0) {
            for (let i = 0; i < recordInfo.headImageList.length; i++) {
              if (recordInfo.headImageList[i] === '') {
                recordInfo.headImageList[i] = require('../../assets/detail/visitor-avatar.png')
              }
            }
            // var max = Math.min(recordInfo.pv, 3)
            // for (let i = recordInfo.headImageList.length; i < max; i++) {
            //   recordInfo.headImageList.push(require('../../assets/detail/visitor-avatar.png'))
            // }
          }
          this.viewRecordInfo = recordInfo
        } else {
          this.$message(res.data.desc, 'error')
        }
      })
    },
    loadViewRecordList: function () {
      if (this.loading) {
        return
      }
      this.pageNum = 0
      this.loadMoreRecordList()
    },
    loadMoreRecordList: function () {
      if (this.loading) {
        return
      }
      this.loading = true
      listDocViewRecord({
        audioDocId: this.docId,
        page: this.pageNum,
        size: this.pageSize
      }).then(res => {
        this.loading = false
        if (res.data.code === 0) {
          var recordList = res.data.data.content
          for (var i = 0; i < recordList.length; i++) {
            if (recordList[i].headImageUrl === '') {
              recordList[i].headImageUrl = require('../../assets/detail/visitor-avatar.png')
            }
            if (recordList[i].nickName === '') {
              recordList[i].nickName = '未知用户'
            }
            recordList[i].viewTimeStr = formatDocTime(recordList[i].viewTime)
          }
          if (this.pageNum === 0) {
            this.viewRecordList = recordList
          } else {
            this.viewRecordList.push(...recordList)
          }
          this.pageNum++
        } else {
          this.$message(res.data.desc, 'error')
        }
      }).catch(err => {
        console.log(err)
        this.loading = false
      })
    },
    show() {
      this.$bus.$emit('doc-dataView')
    },
    showDetailDataView (val) {
      this.$bus.$emit('doc-detailView', val)
    }
  }
}
</script>
<style scoped lang="scss">
.visitor-button {
  height: 38px;
  background: rgba(255,255,255,1);
  border: 1px solid rgba(235,235,235,1);
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  -webkit-app-region: no-drag;
  position: relative;
  &:hover {
    background: rgba(245,247,251,1);
  }
  &:active {
    background: rgba(239,241,245,1);
  }
  .avatar-list {
    height: 24px;
    margin-left: 10px;
    position: relative;
    .avatar-1 {
      position: relative;
      width: 22px;
      height: 22px;
      border-radius: 12px;
      border: 1px solid white;
      z-index: 10;
    }
    .avatar-2 {
      position: absolute;
      left: 14px;
      width: 22px;
      height: 22px;
      border-radius: 12px;
      border: 1px solid white;
      z-index: 5;
    }
    .avatar-3 {
      position: absolute;
      left: 30px;
      width: 22px;
      height: 22px;
      border-radius: 12px;
      border: 1px solid white;
      z-index: 0;
    }
  }
  .visitor-count {
    flex-grow: 1;
    margin-right: 2px;
    font-size: 12px;
    font-weight: 500;
    color: rgba(51,51,51,1);
    text-align: right;
  }
  .visitor-tip {
    margin-right: 10px;
    width: 14px;
    height: 14px;
  }
  .visitor-detail-dialog {
    position: absolute;
    z-index: 1000;
    top: 42px;
    right: 0;
    width: 376px;
    background: rgba(255,255,255,1);
    border: 1px solid rgba(224,224,224,1);
    border-radius: 8px;
    box-shadow:  0 0 10px 0 rgba(0,0,0,0.16);
    display: flex;
    flex-direction: column;
    cursor: default;
    .header {
      height: 58px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      .title {
        margin-left: 24px;
        font-size: 16px;
        font-weight: 500;
        color: rgba(51,51,51,1);
      }
      .total {
        margin-right: 24px;
        font-size: 12px;
        font-weight: normal;
        color: rgba(153,153,153,1);
        & span {
          margin-right: 3px;
          color: rgba(51,51,51,1);
          font-weight: 500;
        }
      }
    }
    .line {
      height: 1px;
      background: rgba(240,240,240,1);
    }
    .visitor-list {
      display: flex;
      flex-direction: column;
      height: 390px;
      overflow-y: scroll;
      .visitor-item {
        height: 64px;
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;
        .icon {
          margin-left: 24px;
          width: 36px;
          height: 36px;
          border-radius: 18px;
        }
        .name {
          flex-grow: 1;
          margin-left: 10px;
          margin-right: 10px;
          font-size: 14px;
          font-weight: 500;
          color: rgba(51,51,51,1);
          overflow:hidden;
          text-overflow:ellipsis;
          white-space:nowrap;
          width: 180px;
        }
        .time {
          margin-right: 24px;
          font-size: 12px;
          font-weight: normal;
          color: rgba(102,102,102,1);
        }
      }
      .footer {
        padding: 10px;
        height: 35px;
        font-size: 12px;
        font-weight: 400;
        color: #999999;
        text-align: center;
        line-height: 35px;
      }

      .nomore {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .image {
          width: 140px;
          height: 140px;
        }
        .label {
          margin-top: 6px;
          margin-bottom: 60px;
          font-size: 12px;
          font-weight: normal;
          color: rgba(102,102,102,1);
        }
      }
    }
  }
}
.visitorRecords {
  display: block;
  margin: 22px auto;
  width: 328px;
  height: 44px;
  background: rgba(236,236,239,1);
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  .hover {
    display: none;
  }
  &:hover{
    background: rgba(221,236,255,1);
    color: rgba(0,112,255,1);
    .nohover {
      display: none;
    }
    .hover {
      display: inline-block;
    }
  }
  
}

</style>
