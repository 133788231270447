const ConfigVideoRecordKey = 'config_video_record'
const ConfigAudioRecordKey = 'config_audio_record'
const ConfigSubtitleOn = 'config_subtitle_on'
const ConfigDocIdStatusMap = 'config_doc_id_status_map'
const ConfigCreativeWindowKey = 'config_creative_window'
const ConfigRecordWindowKey = 'config_record_window'
const ConfigDetailDocKey = 'config_detail_doc'
const ConfigFullPlayerKey = 'config_full_player'
const ConfigRecentDocKey = 'config_recent_doc'
const ConfigGroupDocKey = 'config_group_doc'
const ConfigSideBarKey = 'config_side_bar'
const ConfigDocIdDownloadMap = 'config_doc_id_download_map'
const ConfigPendingDocTaskListKey = 'config_pending_doc_task_list'

export function setVideoRecordConfig (config) {
  if (config != null) {
    localStorage.setItem(ConfigVideoRecordKey, JSON.stringify(config))
  }
}

export function getVideoRecordConfig () {
  var configStr = localStorage.getItem(ConfigVideoRecordKey)
  return configStr != null ? JSON.parse(configStr) : {}
}

export function setAudioRecordConfig (config) {
  if (config != null) {
    localStorage.setItem(ConfigAudioRecordKey, JSON.stringify(config))
  }
}

export function getAudioRecordConfig () {
  var configStr = localStorage.getItem(ConfigAudioRecordKey)
  return configStr != null ? JSON.parse(configStr) : {}
}

export function setSubtitleOn (enable) {
  if (enable != null) {
    localStorage.setItem(ConfigSubtitleOn, enable)
  }
}

export function isSubtitleOn () {
  var subtitleOn = localStorage.getItem(ConfigSubtitleOn)
  return subtitleOn != null ? subtitleOn : true
}

export function loadDocIdStatusMap () {
  var configStr = localStorage.getItem(ConfigDocIdStatusMap)
  return configStr != null ? JSON.parse(configStr) : {}
}

export function saveDocIdStatusMap (docIdStatusMap) {
  if (docIdStatusMap != null) {
    localStorage.setItem(ConfigDocIdStatusMap, JSON.stringify(docIdStatusMap))
  }
}

export function loadDocIdDownloadMap () {
  var configStr = localStorage.getItem(ConfigDocIdDownloadMap)
  return configStr != null ? JSON.parse(configStr) : {}
}

export function saveDocIdDownloadMap (docIdDownloadMap) {
  if (docIdDownloadMap != null) {
    localStorage.setItem(ConfigDocIdDownloadMap, JSON.stringify(docIdDownloadMap))
  }
}

export function loadPendingDocTaskList () {
  var configStr = localStorage.getItem(ConfigPendingDocTaskListKey)
  return configStr != null ? JSON.parse(configStr) : []
}

export function savePendingDocTaskList (pendingDocTaskList) {
  if (pendingDocTaskList != null) {
    localStorage.setItem(ConfigPendingDocTaskListKey, JSON.stringify(pendingDocTaskList))
  }
}

export function loadCreativeWindowSetting () {
  var settingStr = localStorage.getItem(ConfigCreativeWindowKey)
  return settingStr != null ? JSON.parse(settingStr) : {}
}

export function saveCreativeWindowSetting (setting) {
  if (setting != null) {
    localStorage.setItem(ConfigCreativeWindowKey, JSON.stringify(setting))
  }
}

export function loadRecordWindowSetting () {
  var settingStr = localStorage.getItem(ConfigRecordWindowKey)
  return settingStr != null ? JSON.parse(settingStr) : {}
}

export function saveRecordWindowSetting (setting) {
  if (setting != null) {
    localStorage.setItem(ConfigRecordWindowKey, JSON.stringify(setting))
  }
}

export function loadDetailDocSetting () {
  var settingStr = localStorage.getItem(ConfigDetailDocKey)
  return settingStr != null ? JSON.parse(settingStr) : {}
}

export function saveDetailDocSetting (setting) {
  if (setting != null) {
    localStorage.setItem(ConfigDetailDocKey, JSON.stringify(setting))
  }
}

export function loadFullPlayerSetting () {
  var settingStr = localStorage.getItem(ConfigFullPlayerKey)
  return settingStr != null ? JSON.parse(settingStr) : {}
}

export function saveFullPlayerSetting (setting) {
  if (setting != null) {
    localStorage.setItem(ConfigFullPlayerKey, JSON.stringify(setting))
  }
}

export function loadRecentDocSetting () {
  var settingStr = localStorage.getItem(ConfigRecentDocKey)
  return settingStr != null ? JSON.parse(settingStr) : {}
}

export function saveRecentDocSetting (setting) {
  if (setting != null) {
    localStorage.setItem(ConfigRecentDocKey, JSON.stringify(setting))
  }
}

export function loadGroupDocSetting () {
  var settingStr = localStorage.getItem(ConfigGroupDocKey)
  return settingStr != null ? JSON.parse(settingStr) : {}
}

export function saveGroupDocSetting (setting) {
  if (setting != null) {
    localStorage.setItem(ConfigGroupDocKey, JSON.stringify(setting))
  }
}

export function loadSideBarSetting () {
  var settingStr = localStorage.getItem(ConfigSideBarKey)
  return settingStr != null ? JSON.parse(settingStr) : {}
}

export function saveSideBarSetting (setting) {
  if (setting != null) {
    localStorage.setItem(ConfigSideBarKey, JSON.stringify(setting))
  }
}

let messageUnreadCount = 0
export function setMessageUnreadCount (value) {
  messageUnreadCount = value
}

export function getMessageUnreadCount () {
  return messageUnreadCount
}

let notificationBaseTime = new Date().getTime()
export function setNotificationBaseTime (value) {
  notificationBaseTime = value
}

export function getNotificationBaseTime () {
  return notificationBaseTime
}

export function saveUrlPathCache (url, path) {
  if (url != null && url !== '' && path != null && path !== '') {
    localStorage.setItem(url, path)
  }
}

export function getUrlCachePath (url) {
  if (url != null && url !== '') {
    const urlKey = url.split('?')[0]
    if (localStorage.getItem(urlKey) != null) {
      const fs = require('fs')
      const filePath = localStorage.getItem(urlKey)
      if (fs.existsSync(filePath)) {
        return encodeURI('myfile://' + filePath)
      } else {
        localStorage.removeItem(urlKey)
      }
    }
  }
  return url
}
