function toPlayMedia (word, wordMedia) {
  return {
    url: wordMedia.videoSmartUrl !== '' ? wordMedia.videoSmartUrl : wordMedia.videoUrl,
    mediaStart: word.realAudioStart,
    mediaEnd: word.realAudioEnd,
    start: word.audioStart,
    end: word.audioEnd,
    width: wordMedia.width,
    height: wordMedia.height
  }
}

export function switchPlayMedia (curPlayMedia, wordObj, playMediaList, mediaList) {
  const wordMedia = mediaList[wordObj.mediaId - 1]
  const wordMediaUrl = wordMedia.videoSmartUrl !== '' ? wordMedia.videoSmartUrl : wordMedia.videoUrl
  if (curPlayMedia == null) {
    curPlayMedia = toPlayMedia(wordObj, wordMedia)
  } else {
    if (curPlayMedia.url !== wordMediaUrl) {
      if (curPlayMedia.mediaStart !== curPlayMedia.mediaEnd) {
        playMediaList.push(curPlayMedia)
      }
      curPlayMedia = toPlayMedia(wordObj, wordMedia)
    } else {
      if (wordObj.realAudioStart === curPlayMedia.mediaEnd) {
        curPlayMedia.mediaEnd = wordObj.realAudioEnd
        curPlayMedia.end = wordObj.audioEnd
      } else {
        if (curPlayMedia.mediaStart !== curPlayMedia.mediaEnd) {
          playMediaList.push(curPlayMedia)
        }
        curPlayMedia = toPlayMedia(wordObj, wordMedia)
      }
    }
  }
  return curPlayMedia
}
