<template>
  <transition name="fade">
    <div class="bg" v-if="visible" @click="clickBg">
      <div class="dialog" @click.stop>
        <div class="text">{{ content }}</div>
        <div class="line"></div>
        <div class="buttons">
          <div class="cancel-button" @click="clickCancelButton">{{ cancelText }}</div>
          <div class="confirm-button" @click="clickConfirmButton">{{ confirmText }}</div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
export default {
  name: 'ConfirmDialog',
  data () {
    return {
      visible: false,
      content: '',
      cancelText: '取消',
      confirmText: '确定',
      cancelCallback: null,
      confirmCallback: null
    }
  },
  methods: {
    clickBg: function () {
      this.visible = false
    },
    clickCancelButton: function () {
      if (this.cancelCallback) {
        this.cancelCallback()
      }
      this.visible = false
    },
    clickConfirmButton: function () {
      if (this.confirmCallback) {
        this.confirmCallback()
      }
      this.visible = false
    }
  }
}
</script>
<style scoped lang="scss">
.bg {
  position: fixed;
  font-family: PingFangSC-Regular, PingFang SC;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000;
  .dialog {
    display: flex;
    flex-direction: column;
    width: 420px;
    background: rgba(255,255,255,1);
    border: 1px solid rgba(224,224,224,1);
    border-radius: 8px;
    box-shadow:  0 0 6px 0 rgba(0,0,0,0.18);
    .text {
      min-height: 80px;
      padding: 20px;
      font-size: 16px;
      font-weight: 500;
      color: rgba(51,51,51,1);
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .line {
      height: 1px;
      background: rgba(216,216,216,0.5);
    }
    .buttons {
      height: 68px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      .cancel-button {
        margin: 0 8px;
        width: 86px;
        height: 36px;
        background: rgba(245,247,251,1);
        border: 1px solid rgba(216,216,216,1);
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        font-weight: 500;
        color: rgba(51,51,51,1);
        cursor: pointer;
        &:hover {
          background: rgba(237,240,243,1);
        }
        &:active {
          background: rgba(220,222,225,1);
          border: 1px solid rgba(188,189,190,1);
        }
      }
      .confirm-button {
        margin: 0 8px;
        width: 86px;
        height: 36px;
        background: rgba(35,108,251,1);
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        font-weight: 500;
        color: rgba(255,255,255,1);
        cursor: pointer;
        &:hover {
          background: rgba(28,113,223,1);
        }
        &:active {
          background: rgba(18,93,188,1);
        }
      }
    }
  }
}
</style>
