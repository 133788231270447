<template>
  <div class="main">
    <div class="title">最近</div>
    <div class="batch-menu" v-if="batchManageEnabled">
      <div class="cancel-button" @click="clickBatchCancelButton">取消</div>
      <div class="delete-button" @click="clickBatchDeleteButton">删除记录</div>
    </div>
    <div class="head">
      <div class="col-check">
        <img src="../../assets/homepage/unchecked.svg" />
      </div>
      <div class="col-name"><span>名称</span></div>
      <div class="col-from"><span>来自</span></div>
      <div class="col-time">
        <div class="sort-type" :class="sortTypeMenuVisible ? 'sort-type-active' : ''"  v-clickoutside="hideSortTypeMenu">
          <div class="text">{{ sortTypeText }}</div>
          <img class="arrow" src="../../assets/homepage/sort-type-arrow-down.svg" />
          <div class="sort-type-float-menu" v-show="sortTypeMenuVisible">
            <div class="menu-item" @click.stop="clickSortType('viewTime')">
              <div class="icon">
                <img v-show="sortType === 'viewTime'" src="../../assets/homepage/select.svg" />
              </div>
              <div class="text">最近查看时间</div>
            </div>
            <div class="menu-item" @click.stop="clickSortType('editTime')">
              <div class="icon">
                <img v-show="sortType === 'editTime'" src="../../assets/homepage/select.svg" />
              </div>
              <div class="text">最近编辑时间</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="line"></div>
    <div class="content-area">
      <div class="row-list" v-show="recordList.length > 0" ref="rowList" @scroll="onRowListScrolled">
        <div v-for="record in recordList" :key="record.id">
          <div class="row">
            <div class="col-check" :class="batchManageEnabled ? 'col-check-batch' : ''">
              <img v-if="record.checked" src="../../assets/homepage/checked.svg" @click="clickCheckButton(record)"/>
              <img v-else src="../../assets/homepage/unchecked.svg" @click="clickCheckButton(record)"/>
            </div>
            <div class="col-name">
              <img class="icon" src="../../assets/homepage/video-icon.svg" />
              <div class="name">
                <input :id="'docName-' + record.id" v-if="record.editState" class="input" :value="record.docName" @keypress.enter="onPressEnter" @blur="onBlurRecordName($event, record)" />
                <div v-else class="text" @click.stop="clickEditRecord(record)">{{ record.docName === '' ? '未命名文档' : record.docName }}</div>
              </div>
              <img class="link-button" src="../../assets/homepage/copylink.svg" @click.stop="clickLinkButton(record)"/>
              <div class="more-button">
                <img src="../../assets/homepage/getmore.svg" @click="clickMoreButton(record)" />
                <div class="float-more-menu" v-show="record.menuVisible">
                  <div class="menu-item" @click.stop="clickViewRecord(record)">查看</div>
                  <div class="menu-item" v-show="record.selfDoc" @click.stop="clickEditRecord(record)">编辑</div>
                  <div class="menu-line"></div>
                  <div class="menu-item" @click.stop="clickShareRecord(record)">分享</div>
                  <div class="menu-item" v-show="record.selfDoc" @click.stop="clickRenameRecord(record)">重命名</div>
                  <div class="menu-line"></div>
                  <div class="menu-item" @click.stop="clickDeleteRecord(record)">删除记录</div>
                  <div class="menu-item" @click.stop="clickBatchManageRecord(record)">批量管理</div>
                </div>
              </div>
            </div>
            <div class="col-from"><span>{{ record.sourceName }}</span></div>
            <div class="col-time">
              <span v-if="record.docStatus == null">{{ formatRecordTime(record) }}</span>
              <div v-else class="status">
                <div class="text" :class="(record.docStatus.status === 11 || record.docStatus.status === 12 || record.docStatus.status === 13) ? 'text-red' : ''">{{ record.docStatus.statusText + record.docStatus.progressText }}</div>
                <div v-if="record.docStatus.status === 11 || record.docStatus.status === 12 || record.docStatus.status === 13" class="retry-button" @click.stop="clickRetryRecord(record)">重试</div>
              </div>
            </div>
          </div>
          <div class="line"></div>
        </div>
      </div>
      <template v-if="!loading">
        <div v-if="loadFailed" class="empty-content">
          <template v-if="failedError === 1">
            <img src="../../assets/homepage/tip-server-error.svg" />
            <div>服务器出问题了</div>
          </template>
          <template v-else-if="failedError === 2">
            <img src="../../assets/homepage/tip-network-error.svg" />
            <div>哎呀，网络出错了</div>
          </template>
          <template v-else>
            <img src="../../assets/homepage/tip-load-failed.svg" />
            <div>加载失败，请重试</div>
          </template>
        </div>
        <div v-else-if="!loadFailed && recordList.length === 0" class="empty-content">
          <img src="../../assets/homepage/tip-result-empty.svg" />
          <div>内容为空</div>
        </div>
      </template>
      <div class="loading-area" v-show="loading && pageNum === 0">
        <img src="../../assets/homepage/loading.svg" />
      </div>
    </div>
  </div>
</template>
<script>
import throttle from 'throttle-debounce/throttle'
import { batchDeleteViewRecord, deleteViewRecord, listViewRecords } from '../../api/record'
import { formatDocTime } from '@/utils/time'
import { getH5DocUrl } from '@/common/const'
import { getUid } from '@/utils/auth'
import { modifyAudioDoc } from '@/api/doc'
import { kErrorServerError, getErrorCode } from '../../common/errorCode'
import { kDocStatusTranscriptFinish } from '../../utils/statusConst'
import { saveRecentDocSetting } from '../../utils/config'

export default {
  name: 'RecentDocList',
  data () {
    return {
      loading: false,
      loadFailed: false,
      failedError: -1,
      sortTypeMenuVisible: false,
      sortType: 'viewTime',
      batchManageEnabled: false,
      recordList: [],
      pageNum: 0,
      pageSize: 20,
      hasMore: true
    }
  },
  computed: {
    sortTypeText: function () {
      if (this.sortType === 'viewTime') {
        return '最近查看'
      } else if (this.sortType === 'editTime') {
        return '最近编辑'
      } else {
        return ''
      }
    }
  },
  watch: {
    $route (to) {
      if (to.path === '/moyin/homepage/recent') {
        this.refreshViewRecordsList()
      }
    }
  },
  mounted () {
    this.refreshViewRecordsList()
    this.$bus.$on('doc-status', this.onDocStatus)
  },
  destroyed () {
    this.$bus.$off('doc-status', this.onDocStatus)
  },
  methods: {
    onDocStatus: function (docId, docStatus) {
      this.recordList.forEach(record => {
        if (record.docId === docId) {
          if (docStatus.status === kDocStatusTranscriptFinish) {
            record.docStatus = null
          } else {
            record.docStatus = docStatus
          }
        }
      })
    },
    clickBatchCancelButton: function () {
      this.batchManageEnabled = false
      this.recordList.forEach(item => {
        item.checked = false
      })
    },
    clickBatchDeleteButton: function () {
      this.$confirm('确定删除选中浏览记录么？', {
        confirmCallback: () => {
          this.batchManageEnabled = false
          var ids = []
          var idMap = {}
          this.recordList.forEach(item => {
            if (item.checked) {
              ids.push(item.id)
              idMap[item.id] = true
            }
          })
          batchDeleteViewRecord({
            ids: ids.join(',')
          }).then(res => {
            if (res.data.code === 0) {
              var newRecord = []
              this.recordList.forEach(item => {
                if (!(item.id in idMap)) {
                  newRecord.push(item)
                }
              })
              this.recordList = newRecord
              this.$message('选中浏览记录已删除', 'success')
            } else {
              this.$message(res.data.desc, 'error')
            }
          })
        }
      })
    },
    clickSortTypeMenu: function () {
      this.sortTypeMenuVisible = !this.sortTypeMenuVisible
    },
    hideSortTypeMenu: function () {
      this.sortTypeMenuVisible = false
    },
    clickSortType: function (type) {
      if (type !== this.sortType) {
        this.sortType = type
        this.pageNum = 0
        this.hasMore = true
        this.loadViewRecordsList(true)
        saveRecentDocSetting({
          sortType: type
        })
      }
      this.sortTypeMenuVisible = false
    },
    refreshViewRecordsList: throttle(1000, function () {
      this.pageNum = 0
      this.hasMore = true
      this.loadViewRecordsList(true)
    }),
    loadViewRecordsList: function () {
      this.loading = true
      var uid = getUid()
      var pageNum = this.pageNum
      listViewRecords({
        pageNum: pageNum,
        pageSize: this.pageSize,
        orderBy: this.sortType
      }).then(res => {
        if (res.data.code === 0) {
          res.data.data.content.forEach(item => {
            item.checked = false
            item.menuVisible = false
            item.editState = false
            item.selfDoc = (uid === item.authorId)
            item.docStatus = this.$docService.getStatus(item.docId)
          })
          if (pageNum === 0) {
            this.recordList = res.data.data.content
          } else {
            this.recordList.push(...res.data.data.content)
          }
          this.loadFailed = false
          this.failedError = -1
          if (res.data.data.content.length === 0) {
            this.hasMore = false
          } else {
            this.$nextTick(() => {
              if (this.$refs.rowList.offsetHeight > 57 * this.recordList.length) {
                this.pageNum++
                this.loadViewRecordsList()
              }
            })
          }
        } else {
          this.$message(res.data.desc, 'error')
          if (this.recordList.length === 0) {
            this.loadFailed = true
            this.failedError = kErrorServerError
          }
        }
        this.loading = false
      }).catch(err => {
        console.log(err)
        this.loading = false
        if (this.recordList.length === 0) {
          this.loadFailed = true
          this.failedError = getErrorCode(err)
        }
      })
    },
    onRowListScrolled: function () {
      var rowListDom = this.$refs.rowList
      if (rowListDom.scrollTop + rowListDom.offsetHeight >= rowListDom.scrollHeight && this.hasMore) {
        this.pageNum++
        this.loadViewRecordsList()
      }
    },
    clickCheckButton: function (record) {
      record.menuVisible = false
      record.checked = !record.checked
      if (record.checked) {
        this.batchManageEnabled = true
      } else {
        var count = 0
        this.recordList.forEach(item => {
          if (item.checked) {
            count++
          }
        })
        this.batchManageEnabled = count > 0
      }
    },
    clickLinkButton: function (record) {
      record.menuVisible = false
      this.$share(record.docId)
    },
    clickMoreButton: function (record) {
      record.menuVisible = !record.menuVisible
    },
    hideMoreMenu: function (record) {
      record.menuVisible = false
    },
    clickViewRecord: function (record) {
      record.menuVisible = false
      window.open(getH5DocUrl(record.docId))
    },
    clickEditRecord: function (record) {
      record.menuVisible = false
      this.$router.push({
        path: '/moyin/detail',
        query: {
          docId: record.docId
        }
      })
    },
    clickShareRecord: function (record) {
      this.clickLinkButton(record)
    },
    clickRenameRecord: function (record) {
      record.menuVisible = false
      record.editState = true
      setTimeout(() => {
        const inputDom = document.getElementById('docName-' + record.id)
        inputDom.focus()
      }, 0)
    },
    onPressEnter: function (event) {
      event.target.blur()
    },
    onBlurRecordName: function (event, record) {
      var oldName = record.docName
      var newName = event.target.value
      record.editState = false
      if (newName !== oldName) {
        record.docName = newName
        modifyAudioDoc({
          id: record.docId,
          title: newName
        }).then(res => {
          if (res.data.code !== 0) {
            record.docName = oldName
            this.$message(res.data.desc, 'error')
          }
        })
      }
    },
    clickDeleteRecord: function (record) {
      record.menuVisible = false
      this.$confirm('确定删除该浏览记录么？', {
        confirmCallback: () => {
          deleteViewRecord({
            id: record.id
          }).then(res => {
            if (res.data.code === 0) {
              for (var i = 0; i < this.recordList.length; i++) {
                if (this.recordList[i].id === record.id) {
                  this.recordList.splice(i, 1)
                  break
                }
              }
              this.$message('浏览记录已删除', 'success')
            } else {
              this.$message(res.data.desc, 'error')
            }
          })
        }
      })
    },
    clickBatchManageRecord: function (record) {
      record.menuVisible = false
      record.checked = true
      this.batchManageEnabled = true
    },
    formatRecordTime: function (record) {
      var timeStr = ''
      if (this.sortType === 'viewTime') {
        timeStr = record.lastViewTime
      } else if (this.sortType === 'editTime') {
        timeStr = record.lastEditTime
      }
      if (timeStr !== '') {
        return formatDocTime(timeStr)
      } else {
        return ''
      }
    },
    clickRetryRecord: function (record) {
      this.$docService.retry(record.docId)
    }
  }
}
</script>
<style scoped lang="scss">
.main {
  display: flex;
  flex-direction: column;
  position: relative;
  .title {
    margin-left: 32px;
    margin-top: 18px;
    height: 30px;
    font-size: 18px;
    font-weight: 500;
    color: rgba(51,51,51,1);
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .batch-menu {
    position: absolute;
    top: 19px;
    right: 33px;
    display: flex;
    flex-direction: row;
    .cancel-button {
      width: 60px;
      height: 28px;
      background: rgba(243,245,247,1);
      border: 1px solid rgba(235,235,235,1);
      border-radius: 4px 0 0 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      font-weight: normal;
      color: rgba(51,51,51,1);
      cursor: pointer;
    }
    .delete-button {
      width: 70px;
      height: 28px;
      background: rgba(255,255,255,1);
      border: 1px solid rgba(235,235,235,1);
      border-radius: 0 4px 4px 0;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      font-weight: normal;
      color: rgba(51,51,51,1);
      cursor: pointer;
    }
  }
  .head {
    height: 42px;
    display: flex;
    flex-direction: row;
    align-items: center;
    .col-check {
      width: 36px;
      min-width: 36px;
      display: flex;
      flex-direction: row;
      align-items: center;
      & img {
        width: 16px;
        height: 16px;
        margin-left: 16px;
        cursor: pointer;
        visibility: hidden;
      }
    }
    .col-name {
      width: 58%;
      font-size: 12px;
      font-weight: 500;
      color: rgba(102,102,102,1);
      & span {
        margin-left: 8px;
      }
    }
    .col-from {
      width: 27%;
      font-size: 12px;
      font-weight: 500;
      color: rgba(102,102,102,1);
      & span {
        margin-left: 16px;
      }
    }
    .col-time {
      height: 42px;
      width: 15%;
      position: relative;
      .sort-type {
        margin-top: 7px;
        width: 78px;
        height: 28px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        .text {
          font-size: 12px;
          font-weight: 500;
          color: rgba(102,102,102,1);
        }
        .arrow {
          margin-left: 6px;
          width: 8px;
          height: 6px;
          opacity: 0;
        }
      }
      .sort-type-active {
        background: rgba(243,245,247,1);
        border-radius: 4px;
        .text {
          color: rgba(0,112,255,1);
        }
      }
      .sort-type-float-menu {
        position: absolute;
        z-index: 100;
        top: 41px;
        left: -60px;
        width: 138px;
        background: rgba(255,255,255,1);
        border: 1px solid rgba(224,224,224,1);
        border-radius: 4px;
        box-shadow:  0 0 6px 0 rgba(187,187,187,0.5);
        display: flex;
        flex-direction: column;
        padding: 10px 0px;
        cursor: default;
        .menu-item {
          height: 32px;
          display: flex;
          flex-direction: row;
          align-items: center;
          cursor: pointer;
          .icon {
            margin-left: 20px;
            margin-right: 10px;
            width: 16px;
            & img {
              width: 16px;
              height: 12px;
            }
          }
          .text {
            font-size: 12px;
            font-weight: normal;
            color: rgba(51,51,51,1);
          }
          &:hover {
            background: rgba(243,245,247,1);
          }
        }
      }
    }
  }
  .line {
    height: 1px;
    background: rgba(240,240,240,1);
    margin: 0 32px;
  }
  .content-area {
    position: relative;
    height: calc(100vh - 162px);
    .row-list {
      height: calc(100vh - 162px);
      overflow-y: scroll;
      display: flex;
      flex-direction: column;
      .row {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 56px;
        .col-check {
          width: 36px;
          min-width: 36px;
          display: flex;
          flex-direction: row;
          align-items: center;
          & img {
            width: 16px;
            height: 16px;
            margin-left: 16px;
            cursor: pointer;
            visibility: hidden;
          }
        }
        .col-check-batch {
          & img {
            visibility: visible;
          }
        }
        .col-name {
          height: 56px;
          width: 58%;
          display: flex;
          flex-direction: row;
          align-items: center;
          position: relative;
          .icon {
            width: 36px;
            height: 36px;
          }
          .name {
            margin-left: 4px;
            flex-grow: 1;
            overflow: hidden;
            width: 0;
            .text {
              font-size: 14px;
              font-weight: normal;
              color: rgba(51,51,51,1);
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              cursor: pointer;
            }
            .input {
              outline: 0;
              width: 70%;
              height: 36px;
              line-height: 36px;
              padding: 0 10px;
              background: rgba(255,255,255,1);
              border: 1px solid rgba(0,112,255,1);
              border-radius: 4px;
              box-shadow:  0 0 3px 0 rgba(0,112,255,0.7);
              font-size: 14px;
              font-weight: normal;
              color: rgba(51,51,51,1);
            }
          }
          .link-button {
            margin-left: 10px;
            width: 28px;
            height: 28px;
            visibility: hidden;
            cursor: pointer;
            &:hover {
              background: rgba(237,240,243,1);
              border-radius: 4px;
            }
          }
          .more-button {
            margin-left: 10px;
            width: 28px;
            height: 28px;
            visibility: hidden;
            cursor: pointer;
            position: relative;
            & img {
              width: 28px;
              height: 28px;
            }
            &:hover {
              background: rgba(237,240,243,1);
              border-radius: 4px;
            }
          }
          .float-more-menu {
            position: absolute;
            top: 32px;
            right: 0px;
            width: 136px;
            display: flex;
            flex-direction: column;
            padding: 10px 0;
            background: rgba(255,255,255,1);
            border: 1px solid rgba(224,224,224,1);
            border-radius: 4px;
            box-shadow:  0 0 6px 0 rgba(187,187,187,0.5);
            z-index: 100;
            .menu-item {
              height: 32px;
              padding-left: 24px;
              font-size: 12px;
              font-weight: normal;
              color: rgba(51,51,51,1);
              display: flex;
              flex-direction: row;
              align-items: center;
              cursor: pointer;
              &:hover {
                background: rgba(243,245,247,1);
              }
            }
            .menu-line {
              margin: 1px 0px;
              height: 1px;
              background: rgba(216,216,216,0.5);
            }
          }
        }
        .col-from {
          width: 27%;
          font-size: 12px;
          font-weight: normal;
          color: rgba(51,51,51,1);
          & span {
            margin-left: 16px;
          }
        }
        .col-time {
          width: 15%;
          font-size: 12px;
          font-weight: normal;
          color: rgba(51,51,51,1);
          & span {
            margin-left: 8px;
          }
          .status {
            margin-left: 8px;
            display: flex;
            flex-direction: row;
            align-items: center;
            .text {
              font-size: 12px;
              font-weight: normal;
              color: rgba(0,112,255,1);
            }
            .text-red {
              color: rgba(254,89,89,1);
            }
            .retry-button {
              margin-left: 8px;
              width: 58px;
              height: 24px;
              background: rgba(255,255,255,1);
              border: 1px solid rgba(235,235,235,1);
              border-radius: 4px;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 12px;
              font-weight: normal;
              color: rgba(0,112,255,1);
              cursor: pointer;
              &:hover {
                background: rgba(243,245,247,1);
              }
              &:active {
                background: rgba(237,240,243,1);
              }
            }
          }
        }
        &:hover {
          background: rgba(250,251,252,1);
          .col-check {
            & img {
              visibility: visible;
            }
          }
          .col-name {
            .link-button {
              visibility: visible;
            }
            .more-button {
              visibility: visible;
            }
          }
        }
      }
    }
    .empty-content {
      height: calc(100vh - 162px);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding-bottom: 100px;
      & img {
        width: 140px;
        height: 140px;
      }
      & div {
        margin-top: 6px;
        font-size: 12px;
        font-weight: normal;
        color: rgba(102,102,102,1);
        margin-bottom: 100px;
      }
    }
    .loading-area {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 100;
      background: rgba(255,255,255,0.8);
      display: flex;
      align-items: center;
      justify-content: center;
      & img {
        width: 64px;
        height: 64px;
        -webkit-animation: changeright 1s linear infinite;
      }
    }
    @-webkit-keyframes changeright {
      0% {
        -webkit-transform: rotate(0deg);
      }
      50% {
        -webkit-transform: rotate(180deg);
      }
      100% {
        -webkit-transform: rotate(360deg);
      }
    }
  }
}
</style>
