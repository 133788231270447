<template>
  <section class="app-main">
    <transition name="fade">
      <keep-alive>
        <router-view :key="key" />
      </keep-alive>
    </transition>
  </section>
</template>

<script>
export default {
  name: 'AppMain',
  computed: {
    key() {
      return this.$route.path
    }
  }
}
</script>

<style lang="scss" scoped>
.app-main {
  flex-grow: 1;
  height: calc(100vh - 71px);
  position: relative;
}
</style>
