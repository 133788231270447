<template>
  <transition name="fade">
    <div class="message" v-if="visible">
      <img v-if="type === 'success'" class="icon" src="../../../assets/homepage/success-message.svg" />
      <img v-else-if="type === 'error'" class="icon" src="../../../assets/homepage/error-message.svg" />
      <div class="text">{{ content }}</div>
    </div>
  </transition>
</template>
<script>
export default {
  name: 'MessageToast',
  data() {
    return {
      content: '',
      time: 3000,
      visible: false,
      type: 'success'
    }
  },
  mounted() {
    this.close()
  },
  methods: {
    close() {
      window.setTimeout(() => {
        this.visible = false
      }, this.time)
    }
  }
}
</script>
<style scoped lang="scss">
.message {
  position: fixed;
  z-index: 10000;
  font-family: PingFangSC-Regular, PingFang SC;
  top: 80px;
  left: 50%;
  -webkit-transform:translateX(-50%);
  transform:translateX(-50%);
  height: 50px;
  background: rgba(255,255,255,1);
  border: 1px solid rgba(224,224,224,1);
  border-radius: 8px;
  box-shadow:  0 0 6px 0 rgba(187,187,187,0.5);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 20px;
  .icon {
    width: 16px;
    height: 16px;
  }
  .text {
    margin-left: 10px;
    font-size: 14px;
    font-weight: normal;
    color: rgba(51,51,51,1);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
