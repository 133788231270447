<template>
  <div class="bg" v-if="visible" @click="hide">
    <div class="dialog" @click.stop>
      <div class="header">
        <div class="title">移动到</div>
        <img @click="hide" class="close-button" src="../../assets/homepage/dialog-close.svg" />
      </div>
      <div class="all-group">全部分组</div>
      <div class="group-list">
        <div class="group-item">
          <div class="group-row" @click="clickSelectGroup('default', '')">
            <img class="folder" src="../../assets/homepage/folder.svg" />
            <div class="title" :class="selectedId === 'default' ? 'title-selected' : ''">默认分组</div>
            <img v-if="selectedId === 'default'" class="select" src="../../assets/homepage/select.svg" />
          </div>
          <div class="line"></div>
        </div>
        <div v-for="group in groupList" :key="group.fsId" class="group-item" @click="clickSelectGroup(group.fsId, group.name)">
          <div class="group-row">
            <img class="folder" src="../../assets/homepage/folder.svg" />
            <div class="title" :class="selectedId === group.fsId ? 'title-selected' : ''">{{ group.name }}</div>
            <img v-if="selectedId === group.fsId" class="select" src="../../assets/homepage/select.svg" />
          </div>
          <div class="line"></div>
        </div>
      </div>
      <div class="line"></div>
      <div class="buttons">
        <div class="cancel-button" @click="hide">取消</div>
        <div class="confirm-button" @click="clickConfirmButton">确定</div>
      </div>
    </div>
  </div>
</template>
<script>
import { listFsDocGroup } from '@/api/fs'
export default {
  name: 'GroupChooser',
  data () {
    return {
      visible: false,
      originId: '',
      selectedId: '',
      groupList: []
    }
  },
  methods: {
    show: function (id, name) {
      this.originId = id
      this.selectedId = id
      this.selectedName = name
      this.visible = true
      this.refreshGroupList()
    },
    hide: function () {
      this.visible = false
    },
    refreshGroupList: function () {
      this.groupList = []
      listFsDocGroup({}).then(res => {
        if (res.data.code === 0) {
          this.groupList = res.data.data
        } else {
          this.$message(res.data.desc, 'error')
        }
      })
    },
    clickSelectGroup: function (id, name) {
      this.selectedId = id
      this.selectedName = name
    },
    clickConfirmButton: function () {
      if (this.originId !== this.selectedId) {
        this.$emit('choosed', this.selectedId, this.selectedName)
      }
      this.hide()
    }
  }
}
</script>
<style scoped lang="scss">
.bg {
  position: fixed;
  z-index: 100000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255,255,255,0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  .dialog {
    width: 780px;
    background: rgba(255,255,255,1);
    border: 1px solid rgba(224,224,224,1);
    border-radius: 8px;
    box-shadow:  0 0 10px 0 rgba(0,0,0,0.16);
    display: flex;
    flex-direction: column;
    .header {
      height: 58px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      .title {
        margin-left: 32px;
        font-size: 16px;
        font-weight: 500;
        color: rgba(51,51,51,1);
      }
      .close-button {
        margin-right: 14px;
        width: 28px;
        height: 28px;
      }
    }
    .all-group {
      height: 40px;
      background: rgba(250,251,252,1);
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-left: 32px;
      font-size: 12px;
      font-weight: normal;
      color: rgba(153,153,153,1);
      border-bottom: 1px solid rgba(240,240,240,1);
    }
    .group-list {
      display: flex;
      flex-direction: column;
      height: 295px;
      overflow-y: scroll;
      .group-row {
        cursor: pointer;
        height: 48px;
        min-height: 48px;
        display: flex;
        flex-direction: row;
        align-items: center;
        .folder {
          width: 20px;
          height: 20px;
          margin-left: 28px;
        }
        .title {
          margin-left: 12px;
          margin-right: 12px;
          flex-grow: 1;
          font-size: 12px;
          font-weight: normal;
          color: rgba(51,51,51,1);
        }
        .title-selected {
          color: rgba(35,108,251,1);
        }
        .select {
          margin-right: 32px;
          width: 16px;
          height: 12px;
        }
        &:hover {
          background: rgba(250,251,252,1);
        }
      }
      .line {
        height: 1px;
        background: rgba(240,240,240,1);
      }
    }
    .line {
      height: 1px;
      background: rgba(216,216,216,0.5);
    }
    .buttons {
      height: 68px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      .cancel-button {
        margin: 0 8px;
        width: 86px;
        height: 36px;
        background: rgba(245,247,251,1);
        border: 1px solid rgba(216,216,216,1);
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        font-weight: 500;
        color: rgba(51,51,51,1);
        cursor: pointer;
        &:hover {
          background: rgba(237,240,243,1);
        }
        &:active {
          background: rgba(220,222,225,1);
          border: 1px solid rgba(188,189,190,1);
        }
      }
      .confirm-button {
        margin: 0 8px;
        width: 86px;
        height: 36px;
        background: rgba(35,108,251,1);
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        font-weight: 500;
        color: rgba(255,255,255,1);
        cursor: pointer;
        &:hover {
          background: rgba(28,113,223,1);
        }
        &:active {
          background: rgba(18,93,188,1);
        }
      }
    }
  }
}
</style>
