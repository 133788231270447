import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueClipboard from 'vue2-clipboard'
import Message from './layout/components/message/index'
import Confirm from './layout/components/confirm/index'
import Input from './layout/components/input/index'
import Share from './layout/components/share/index'
import Bus from './layout/bus/index'
import ClickOutside from 'vue-click-outside'
import infiniteScroll from "vue-infinite-scroll";
Vue.config.productionTip = false
Vue.directive('clickoutside', ClickOutside)
Vue.prototype.$message = Message.install
Vue.prototype.$confirm = Confirm.install
Vue.prototype.$input = Input.install
Vue.prototype.$bus = Bus
Vue.prototype.$share = Share.install
let { DocService } = require('./layout/service/DocService')
Vue.prototype.$docService = new DocService()
Vue.use(VueClipboard)
Vue.use(infiniteScroll);
/* eslint-disable no-new */
new Vue({
  components: { App },
  router,
  template: '<App/>'
}).$mount('#app')
