import Vue from 'vue'
import Message from './Message.vue'

const MessageBox = Vue.extend(Message)

Message.install = function (text, type) {
  let options = {
    content: text,
    type: type != null ? type : 'success'
  }
  let instance = new MessageBox({
    data: options
  }).$mount()

  document.body.appendChild(instance.$el)

  Vue.nextTick(() => {
    instance.visible = true
  })
}

export default Message
