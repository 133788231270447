<template>
  <div class="bg" v-show="visible" @click="hide">
    <div class="dialog" @click.stop>
      <div class="title">编辑资料</div>
      <img class="close-button" src="../../../assets/homepage/dialog-close.svg" @click="hide" />
      <div class="avatar-row">
        <div class="left">
          <div class="avatar-line">
            <img :src="users.imageUrl || require('../../../assets/homepage/default-avatar.svg')" />
            <div>头像</div>
          </div>
          <div class="tip">支持jpg、png的图片，大小不超过3M</div>
        </div>
        <div class="modify-button" @click="clickModifyAvatarButton">修改头像</div>
      </div>
      <div class="line"></div>
      <div class="row">
        <div class="left">
          <div class="key">手机号</div>
          <div class="value">{{ userMobile }}</div>
        </div>
      </div>
      <div class="line"></div>
      <div class="row">
        <div class="left">
          <div class="key">昵称</div>
          <div class="value">{{ users.nickname || '魔音小助手' }}</div>
        </div>
        <div class="modify-button" @click="clickModifyNicknameButton">编辑昵称</div>
      </div>
      <div class="line"></div>
      <!-- <div class="row">
        <div class="left">
          <div class="key">微信</div>
          <div class="value" :class="users.isBind === 0 ? 'value-inactive' : ''">{{ users.isBind === 1 ? users.wechatNick : '未绑定' }}</div>
        </div>
        <div class="modify-button" @click="clickBindButton">{{ users.isBind === 1 ? '解绑' : '绑定' }}</div>
      </div>
      <div class="line"></div> -->
    </div>
  </div>
</template>
<script>
import { getUserInfo, setUserInfo } from '@/utils/auth'
import { modifyUserInfo, unbindWechat } from '@/api/user'
export default {
  name: 'ProfileDialog',
  data () {
    return {
      visible: false,
      userInfo: {},
      users: {},
      userMobile: '',
      userNickname: '魔音小助手'
    }
  },
  mounted () {
    this.updateUserInfo()
  },
  methods: {
    updateUserInfo () {
      this.userInfo = getUserInfo()
      if (this.userInfo != null) {
        this.users = this.userInfo.user
        this.userMobile = `${this.users.mobile.substr(0, 3)} ${this.users.mobile.substr(3, 4)} ${this.users.mobile.substr(7)}`
        this.userNickname = this.users.nickname || '魔音小助手'
      }
    },
    show () {
      this.visible = true
    },
    hide () {
      this.visible = false
    },
    clickModifyAvatarButton: function () {
      this.$emit('editAvatar')
    },
    clickModifyNicknameButton: function () {
      this.$input('编辑昵称', this.userNickname, {
        placeholder: '请输入昵称',
        confirmCallback: title => {
          if (title.length === 0 || title.length > 16) {
            this.$message('请输入1-16个字的昵称', 'error')
            return false
          }
          if (title !== this.userNickname) {
            modifyUserInfo({
              userId: this.users.id,
              nickname: title
            }).then(res => {
              if (res.data.code === 0) {
                this.users.nickname = title
                this.userInfo.user = this.users
                setUserInfo(this.userInfo)
                this.$emit('updateUserInfo')
                this.$message('修改成功', 'success')
              } else {
                this.$message(res.data.desc, 'error')
              }
            })
          }
          return true
        }
      })
    },
    clickBindButton: function () {
      if (this.users.isBind === 1) {
        this.$confirm('确定要解除微信绑定吗？', {
          confirmCallback: () => {
            unbindWechat({}).then(res => {
              if (res.data.code === 0) {
                this.users.isBind = 0
                this.users.wechatNick = ''
                this.userInfo.user = this.users
                setUserInfo(this.userInfo)
                this.$emit('updateUserInfo')
                this.$message('解绑成功', 'success')
              } else {
                this.$message(res.data.desc, 'error')
              }
            })
          }
        })
      } else {
        this.$emit('bindWx')
      }
    }
  }
}
</script>
<style scoped lang="scss">
.bg {
  position: fixed;
  font-family: PingFangSC-Regular, PingFang SC;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,0.1);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  .dialog {
    display: flex;
    flex-direction: column;
    width: 532px;
    padding-bottom: 34px;
    background: rgba(255,255,255,1);
    border: 1px solid rgba(224,224,224,1);
    border-radius: 8px;
    box-shadow:  0 0 10px 0 rgba(0,0,0,0.16);
    position: relative;
    .title {
      height: 58px;
      padding-left: 32px;
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 16px;
      font-weight: 500;
      color: rgba(51,51,51,1);
    }
    .close-button {
      position: absolute;
      top: 14px;
      right: 14px;
      width: 28px;
      height: 28px;
      cursor: pointer;
    }
    .avatar-row {
      margin: 11px 48px 21px 48px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      .left {
        display: flex;
        flex-direction: column;
        .avatar-line {
          display: flex;
          flex-direction: row;
          align-items: center;
          & img {
            width: 36px;
            height: 36px;
            border-radius: 18px;
          }
          & div {
            margin-left: 10px;
            font-size: 14px;
            font-weight: normal;
            color: rgba(153,153,153,1);
          }
        }
        .tip {
          margin-top: 12px;
          font-size: 12px;
          font-weight: normal;
          color: rgba(51,51,51,1);
        }
      }
      .modify-button {
        width: 80px;
        height: 34px;
        border: 1px solid rgba(221,223,230,1);
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        font-weight: normal;
        color: rgba(51,51,51,1);
        cursor: pointer;
      }
    }
    .line {
      margin: 0 48px;
      height: 1px;
      background: rgba(240,240,240,1);
    }
    .row {
      margin: 0 48px;
      height: 78px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      .left {
        display: flex;
        flex-direction: column;
      }
      .key {
        font-size: 14px;
        font-weight: normal;
        color: rgba(153,153,153,1);
      }
      .value {
        margin-top: 8px;
        font-size: 12px;
        font-weight: normal;
        color: rgba(51,51,51,1);
      }
      .value-inactive {
        color: rgba(153,153,153,1);
      }
      .modify-button {
        width: 80px;
        height: 34px;
        border: 1px solid rgba(221,223,230,1);
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        font-weight: normal;
        color: rgba(51,51,51,1);
        cursor: pointer;
      }
    }
  }
}
</style>
