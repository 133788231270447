<template>
  <div class="context-menu" v-show="(selectedWordList.length !== 0 || copyboardSlice != null) && visible" ref="contextMenu">
    <button class="action-button" @click.stop="clickDeleteButton(false)" :disabled="copyboardSlice != null && selectedWordList.length === 0">
      <img src="../../assets/detail/context-icon-delete.svg" />
    </button>
    <button class="action-button" @click.stop="clickCutButton" :disabled="copyboardSlice != null && selectedWordList.length === 0">
      <img src="../../assets/detail/context-icon-cut.svg" />
    </button>
    <button class="action-button" @click.stop="clickCopyButton" :disabled="copyboardSlice != null && selectedWordList.length === 0">
      <img src="../../assets/detail/context-icon-copy.svg" />
    </button>
    <button class="action-button" @click.stop="clickPasteButton" :disabled="copyboardSlice == null || selectedWordList.length > 0">
      <img src="../../assets/detail/context-icon-paste.svg" />
    </button>
    <div class="vline"></div>
    <button class="action-button" @click.stop="clickShareButton" :disabled="selectedWordList.length === 0">
      <img src="../../assets/detail/context-icon-share.svg" />
    </button>
  </div>
</template>
<script>
import { Slice } from 'prosemirror-model'
import { getMagicSpanMark, getWordNodeId } from '@/utils/node'
import { buildBlockJson, buildDeletedWords } from '@/utils/editorDom'
import { filterHighlightClass } from '@/utils/dom'

export default {
  name: 'ContextMenu',
  props: {
    highlightWordId: {
      type: String,
      default: ''
    },
    selectedWordList: {
      type: Array,
      required: true,
      default: () => {
        return []
      }
    },
    editor: {
      required: true
    },
    wordMap: {}
  },
  watch: {
    selectedWordList () {
      this.updateMenuPosition()
    }
  },
  data () {
    return {
      visible: false,
      copyboardSlice: null
    }
  },
  methods: {
    show() {
      this.visible = true
    },
    hide() {
      this.visible = false
    },
    updateMenuPosition: function () {
      if (this.selectedWordList.length === 0 && this.copyboardSlice == null) {
        return
      }

      const contextMenu = this.$refs.contextMenu
      const { from } = this.editor.state.selection
      var fromDom = this.editor.view.nodeDOM(from)
      if (fromDom == null) {
        fromDom = this.editor.view.domAtPos(from, -1).node
        if (fromDom == null) {
          return
        }
      }
      if (fromDom.nodeName === '#text') {
        fromDom = fromDom.parentNode
      }
      contextMenu.style.top = fromDom.offsetTop - 48 + 'px'
      const contentArea = document.getElementById('docContentArea')
      var menuWidth = 180
      if (fromDom.offsetLeft + menuWidth > contentArea.offsetWidth && menuWidth < contentArea.offsetWidth) {
        contextMenu.style.left = (contentArea.offsetWidth - menuWidth) + 'px'
      } else {
        contextMenu.style.left = fromDom.offsetLeft + 'px'
      }
    },
    clickDeleteButton: function (complete) {
      this.hide()
      const { from, to } = this.editor.state.selection
      if (from === to) {
        return
      }
      this.selectedWordList.forEach(word => {
        if (word.id === this.highlightWordId) {
          let lastWordDom = document.getElementById(this.highlightWordId)
          let lastWordPos = this.editor.view.posAtDOM(lastWordDom, 0)
          if (lastWordPos !== -1) {
            let resolvedPos = this.editor.state.doc.resolve(lastWordPos)
            let magicSpanMark = getMagicSpanMark(resolvedPos.nodeAfter)
            let newMark = this.editor.schema.marks.magic_span.create({
              id: magicSpanMark.attrs.id,
              style: magicSpanMark.attrs.style,
              class: filterHighlightClass(magicSpanMark.attrs.class),
              'data-id': magicSpanMark.attrs['data-id'],
              'data-time': magicSpanMark.attrs['data-time']
            })
            let tr = this.editor.state.tr.addMark(lastWordPos, lastWordPos + resolvedPos.nodeAfter.nodeSize, newMark)
            tr.setMeta('addToHistory', false)
            this.editor.view.dispatch(tr)
          }
        }
      })
      let tr = this.editor.state.tr
      if (complete) {
        tr = tr.delete(from, to)
      } else {
        var wordId = 'word-deleted-' + new Date().getTime()
        var deletedWords = buildDeletedWords(this.editor, this.wordMap)
        this.$emit('deletedWordCreated', wordId, deletedWords)
        var div = this.editor.schema.nodes.delete_words.create({ id: wordId })
        tr = tr.replaceWith(from, to, div)
      }
      this.editor.view.dispatch(tr)
      setTimeout(() => {
        window.getSelection().removeAllRanges()
      }, 0)
    },
    clickCutButton: function () {
      this.hide()
      const { from, to } = this.editor.state.selection
      if (from === to) {
        return
      }
      this.copyboardSlice = Slice.fromJSON(this.editor.state.schema, this.editor.state.selection.content().toJSON())
      this.clickDeleteButton(true)
      const docMainContent = document.getElementById('docMainContent')
      docMainContent.style.cursor = 'context-menu'
    },
    clickCopyButton: function () {
      this.hide()
      const { from, to } = this.editor.state.selection
      if (from === to) {
        return
      }
      this.copyboardSlice = Slice.fromJSON(this.editor.state.schema, this.editor.state.selection.content().toJSON())
      const docMainContent = document.getElementById('docMainContent')
      docMainContent.style.cursor = 'context-menu'
    },
    clickShareButton: function () {
      this.hide()
      var blockJson = buildBlockJson(this.editor.state.selection.content(), this.wordMap)
      this.$emit('shareBlocks', blockJson)
    },
    clickPasteButton: function () {
      this.hide()
      if (this.copyboardSlice == null) {
        return
      }
      for (let i = 0; i < this.copyboardSlice.content.childCount; i++) {
        let paraNode = this.copyboardSlice.content.child(i)
        if (paraNode.type.name === 'paragraph') {
          for (let j = 0; j < paraNode.content.childCount; j++) {
            let spanNode = paraNode.content.child(j)
            if (spanNode.type.name === 'text') {
              let id = getWordNodeId(spanNode)
              let spanMark = getMagicSpanMark(spanNode)
              if (document.getElementById(id) != null) {
                let word = this.wordMap.get(id)
                let newWord = Object.assign({}, word)
                newWord.id = `word-${this.wordMap.size}-${word.realAudioStart}-${word.realAudioEnd}`
                this.$emit('wordCreated', newWord)
                spanMark.attrs.id = newWord.id
                spanMark.attrs['data-id'] = newWord.id
              }
              spanMark.attrs.class = filterHighlightClass(spanMark.attrs.class)
            } else if (spanNode.type.name === 'delete_words') {
              let id = spanNode.attrs.id
              if (document.getElementById(id) != null) {
                let deleteWords = this.wordMap.get(id)
                let newDeletedWords = []
                deleteWords.forEach(word => {
                  let newWord = Object.assign({}, word)
                  newWord.id = `word-${this.wordMap.size}-${word.realAudioStart}-${word.realAudioEnd}`
                  this.$emit('wordCreated', newWord)
                  newDeletedWords.push(newWord)
                })
                let newId = 'word-deleted-' + new Date().getTime()
                this.$emit('deletedWordCreated', newId, newDeletedWords)
                spanNode.attrs.id = newId
              }
            }
          }
        }
      }
      const { from } = this.editor.state.selection
      const { nodeBefore, nodeAfter, parent } = this.editor.state.selection.$anchor
      var insertPos = from
      if (parent.type.name !== 'paragraph') {
        if (nodeBefore == null) {
          insertPos = insertPos - 1
        } else {
          if (nodeAfter != null) {
            insertPos = insertPos + nodeAfter.nodeSize + 1
          } else {
            insertPos = insertPos + 1
          }
        }
        let tr = this.editor.state.tr.replace(insertPos, insertPos, this.copyboardSlice)
        this.editor.view.dispatch(tr)
      } else {
        if (nodeBefore != null && nodeAfter != null) {
          var beforeId = getWordNodeId(nodeBefore)
          var afterId = getWordNodeId(nodeAfter)
          if (beforeId != null && beforeId === afterId) {
            insertPos = insertPos + nodeAfter.nodeSize
          }
        }
        let sliceChildCount = this.copyboardSlice.content.childCount
        if (sliceChildCount === 1) {
          let tr = this.editor.state.tr.replace(insertPos, insertPos, this.copyboardSlice)
          this.editor.view.dispatch(tr)
        } else {
          if (this.copyboardSlice.content.child(0).type.name === 'paragraph' && this.copyboardSlice.content.child(sliceChildCount - 1).type.name === 'paragraph' &&
              this.copyboardSlice.content.child(0).textContent !== '' && this.copyboardSlice.content.child(sliceChildCount - 1).textContent !== '') {
            let tr = this.editor.state.tr.replace(insertPos, insertPos, this.copyboardSlice)
            this.editor.view.dispatch(tr)
          } else {
            if (nodeBefore == null) {
              insertPos = insertPos - 1
            }
            let tr = this.editor.state.tr
            for (var i = 0; i < sliceChildCount; i++) {
              var child = this.copyboardSlice.content.child(i)
              if (child.type.name === 'paragraph' && child.textContent === '') {
                continue
              }
              tr.insert(insertPos, child)
              insertPos += child.nodeSize
            }
            this.editor.view.dispatch(tr)
          }
        }
      }
      this.copyboardSlice = null
      const textEditor = document.getElementById('docMainContent')
      textEditor.style.cursor = 'default'
    }
  }
}
</script>
<style scoped lang="scss">
.context-menu {
  position: absolute;
  height: 44px;
  background: rgba(255,255,255,1);
  border: 1px solid rgba(216,216,216,1);
  border-radius: 8px;
  box-shadow:  0 0 2px 0 rgba(0,0,0,0.3);
  padding: 0px 9px;
  display: flex;
  flex-direction: row;
  align-items: center;
  .action-button {
    padding: 0;
    width: 24px;
    height: 24px;
    margin: 0px 3px;
    cursor: pointer;
    background: white;
    &:hover {
      background: rgba(245,247,251,1);
      border-radius: 4px;
    }
    &:disabled {
      background: white;
    }
  }
  .vline {
    width: 1px;
    height: 16px;
    background: rgba(220,220,220,1);
    margin: 0 3px;
  }
}
</style>
