<template>
  <div></div>
</template>
<script>
import { getProjectId, getUserInfo } from '../utils/auth'
import { getProjectDetail } from '../api/project'

export default {
  name: 'OnboardIndex',
  mounted () {
    var userInfo = getUserInfo()
    var projectId = getProjectId()
    if (userInfo != null && projectId != null) {
      getProjectDetail({
        projectId: projectId
      }).then(response => {
        if (response.data.code === 0) {
          this.$router.replace('/moyin/homepage')
        } else if (response.data.code === 1001 || response.data.code === 1002) {
          this.$router.replace('/login')
        } else {
          console.log('Error Desc:' + response.data.desc)
          this.$router.replace('/moyin/homepage')
        }
      }).catch(err => {
        console.log(err)
        this.$router.replace('/moyin/homepage')
      })
    } else {
      this.$router.replace('/login')
    }
  }
}
</script>