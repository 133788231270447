import request from './../utils/request'

export function getProjectList (data) {
  return request({
    url: 'audio/project/list',
    method: 'get',
    params: data
  })
}

export function getProjectDetail (data) {
  return request({
    url: 'audio/project/detail',
    method: 'get',
    params: data
  })
}
