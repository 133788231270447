export const kErrorLoadFailed = 0
export const kErrorServerError = 1
export const kErrorNetworkError = 2

export function getErrorCode(error) {
  if (error.response) {
    return kErrorServerError
  } else if (error.request) {
    return kErrorNetworkError
  } else {
    return kErrorLoadFailed
  }
}
