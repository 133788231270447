<template>
  <div class="main">
    <div class="title">结果<span v-if="resultList.length > 0" class="count">(<font color="#0C77FE">{{ resultList.length }}</font>)</span></div>
    <div class="head">
      <div class="col-check"></div>
      <div class="col-name"><span>名称</span></div>
      <div class="col-from"><span>来自</span></div>
      <div class="col-time"><span>更新时间</span></div>
    </div>
    <div class="line"></div>
    <div class="content-area">
      <div class="row-list" v-show="resultList.length > 0">
        <div v-for="(item, index) in resultList" :key="index">
          <div v-if="item.isGroup === 1" class="folder">
            <div class="row">
              <div class="col-check"></div>
              <div class="col-name">
                <img class="icon" src="../../assets/homepage/folder-big.svg" />
                <div class="name">
                  <input :id="'groupName-' + item.id" v-if="item.editState" class="input" :value="item.title" @keypress.enter="onPressEnter" @blur="onBlurItemName($event, item)" />
                  <div v-else class="text" v-html="item.highlightTitle"></div>
                </div>
                <img class="link-button" @click="clickLinkButton(item)" src="../../assets/homepage/copylink.svg" />
                <div class="more-button">
                  <img src="../../assets/homepage/getmore.svg" @click="clickMoreButton(item)" />
                  <div class="float-more-menu" v-show="item.menuVisible">
                    <div class="menu-item" @click.stop="clickViewItem(item)">查看</div>
                    <div class="menu-line"></div>
                    <div class="menu-item" @click.stop="clickShareItem(item)">分享</div>
                    <div class="menu-item" @click.stop="clickRenameItem(item)">重命名</div>
                    <div class="line"></div>
                    <div class="menu-item" @click.stop="clickCancelItem(item)">取消分组</div>
                    <div class="menu-item" @click.stop="clickDeleteItem(item)">删除分组</div>
                  </div>
                </div>
              </div>
              <div class="col-from"></div>
              <div class="col-time"></div>
            </div>
            <div class="line"></div>
          </div>
          <div v-else-if="item.isGroup === 0" class="doc">
            <div class="row">
              <div class="col-check">
                <template v-if="item.sentenceList.length > 0">
                  <img v-if="item.folderOn" src="../../assets/homepage/arrow-up.svg" @click="clickItemArrow(item)" />
                  <img v-else src="../../assets/homepage/arrow-down.svg" @click="clickItemArrow(item)" />
                </template>
              </div>
              <div class="col-name">
                <img class="icon" src="../../assets/homepage/video-icon.svg" />
                <div class="name">
                  <input :id="'docName-' + item.id" v-if="item.editState" class="input" :value="item.title" @keypress.enter="onPressEnter" @blur="onBlurItemName($event, item)" />
                  <div v-else class="text" v-html="item.highlightTitle" @click.stop="clickEditItem(item)"></div>
                </div>
                <img class="link-button" @click="clickLinkButton(item)" src="../../assets/homepage/copylink.svg" />
                <div class="more-button">
                  <img src="../../assets/homepage/getmore.svg" @click="clickMoreButton(item)" />
                  <div class="float-more-menu" v-show="item.menuVisible">
                    <div class="menu-item" @click.stop="clickViewItem(item)">查看</div>
                    <div class="menu-item" @click.stop="clickEditItem(item)">编辑</div>
                    <div class="menu-line"></div>
                    <div class="menu-item" @click.stop="clickShareItem(item)">分享</div>
                    <div class="menu-item" @click.stop="clickRenameItem(item)">重命名</div>
                    <div class="menu-line"></div>
                    <div class="menu-item" @click.stop="clickDeleteItem(item)">删除</div>
                  </div>
                </div>
              </div>
              <div class="col-from"><span>我</span></div>
              <div class="col-time"><span>{{ formatItemDisplayTime(item) }}</span></div>
            </div>
            <div class="line"></div>
            <div v-show="item.folderOn" v-for="(sentence, index2) in item.sentenceList" :key="index2">
              <div class="row">
                <div class="col-content" @click.stop="clickSentenceItem(item, sentence)" v-html="sentence.highlightTitle"></div>
              </div>
              <div class="line"></div>
            </div>
          </div>
        </div>
      </div>
      <template v-if="!loading">
        <div v-if="loadFailed" class="empty-content">
          <template v-if="failedError === 1">
            <img src="../../assets/homepage/tip-server-error.svg" />
            <div>服务器出问题了</div>
          </template>
          <template v-else-if="failedError === 2">
            <img src="../../assets/homepage/tip-network-error.svg" />
            <div>哎呀，网络出错了</div>
          </template>
          <template v-else>
            <img src="../../assets/homepage/tip-load-failed.svg" />
            <div>加载失败，请重试</div>
          </template>
        </div>
        <div v-else-if="!loadFailed && resultList.length === 0" class="empty-content">
          <img src="../../assets/homepage/tip-search-result-empty.svg" />
          <div>没有结果，试试搜索别的词吧~</div>
        </div>
      </template>
      <div class="loading-area" v-show="loading">
        <img src="../../assets/homepage/loading.svg" />
      </div>
    </div>
  </div>
</template>
<script>
import { deleteAudioDoc, modifyAudioDoc, searchDocSimple } from '@/api/doc'
import { getUid } from '@/utils/auth'
import { formatDocTime } from '@/utils/time'
import { formatHighlightTitle } from '@/utils/util'
import { getH5DocUrl, getH5GroupUrl } from '@/common/const'
import { cancelFsDir, deleteDir, modifyDirName } from '@/api/fs'
import { kErrorServerError, getErrorCode } from '../../common/errorCode'

export default {
  name: 'SearchDocResult',
  data () {
    return {
      loading: false,
      loadFailed: false,
      failedError: -1,
      query: '',
      resultList: []
    }
  },
  watch: {
    $route (to) {
      if (to.path === '/moyin/homepage/search') {
        this.query = to.query.query
        this.loadSearchResult()
      }
    }
  },
  mounted () {
    this.query = this.$route.query.query
    this.loadSearchResult()
  },
  methods: {
    ensureEmptyTitle: function (title) {
      return title === '' ? '未命名文档' : title
    },
    loadSearchResult: function () {
      this.loading = true
      searchDocSimple({
        keyword: this.query,
        uid: getUid(),
        page: 0,
        size: 50
      }).then(res => {
        if (res.data.code === 0) {
          res.data.data.content.forEach(item => {
            item.menuVisible = false
            item.folderOn = true
            item.editState = false
            item.highlightTitle = this.ensureEmptyTitle(formatHighlightTitle(item.title, item.splitKeywords))
            if (item.sentenceList != null) {
              item.sentenceList.forEach(sentence => {
                sentence.highlightTitle = formatHighlightTitle(sentence.content, item.splitKeywords)
              })
            }
          })
          this.resultList = res.data.data.content
          this.loadFailed = false
          this.failedError = -1
        } else {
          this.$message(res.data.desc, 'error')
          this.loadFailed = true
          this.failedError = kErrorServerError
        }
        this.loading = false
      }).catch(err => {
        console.log(err)
        this.loading = false
        this.loadFailed = true
        this.failedError = getErrorCode(err)
      })
    },
    clickItemArrow: function (item) {
      item.folderOn = !item.folderOn
    },
    clickLinkButton: function (item) {
      item.menuVisible = false
      if (item.isGroup === 1) {
        let url = getH5GroupUrl(item.id, getUid())
        this.$clipboard(url)
        this.$message('链接已复制', 'success')
      } else if (item.isGroup === 0) {
        this.$share(item.id)
      }
    },
    clickMoreButton: function (item) {
      item.menuVisible = !item.menuVisible
    },
    clickViewItem: function (item) {
      item.menuVisible = false
      if (item.isGroup === 1) {
        this.$router.replace({
          path: '/moyin/homepage/group',
          query: {
            id: item.id,
            name: item.title
          }
        })
      } else if (item.isGroup === 0) {
        window.open(getH5DocUrl(item.id))
      }
    },
    clickEditItem: function (item) {
      item.menuVisible = false
      this.$router.push({
        path: '/moyin/detail',
        query: {
          docId: item.id
        }
      })
    },
    clickShareItem: function (item) {
      this.clickLinkButton(item)
    },
    clickRenameItem: function (item) {
      item.menuVisible = false
      item.editState = true
      setTimeout(() => {
        if (item.isGroup === 1) {
          const inputDom = document.getElementById('groupName-' + item.id)
          inputDom.focus()
        } else if (item.isGroup === 0) {
          const inputDom = document.getElementById('docName-' + item.id)
          inputDom.focus()
        }
      }, 10)
    },
    onPressEnter: function (event) {
      event.target.blur()
    },
    onBlurItemName: function (event, item) {
      var oldName = item.title
      var newName = event.target.value
      item.editState = false
      if (newName !== oldName) {
        if (item.isGroup === 1) {
          if (newName === '') {
            this.$message('分组名称不能为空', 'error')
          } else {
            item.title = newName
            item.highlightTitle = formatHighlightTitle(item.title, item.splitKeywords)
            modifyDirName({
              fsId: item.id,
              newName: newName
            }).then(res => {
              if (res.data.code === 0) {
                this.$bus.$emit('group-name-changed', item.id, newName)
              } else {
                item.title = oldName
                item.highlightTitle = formatHighlightTitle(item.title, item.splitKeywords)
                this.$message(res.data.desc, 'error')
              }
            }).catch(err => {
              console.log(err)
              item.title = oldName
              item.highlightTitle = formatHighlightTitle(item.title, item.splitKeywords)
            })
          }
        } else if (item.isGroup === 0) {
          item.title = newName
          item.highlightTitle = this.ensureEmptyTitle(formatHighlightTitle(item.title, item.splitKeywords))
          modifyAudioDoc({
            id: item.id,
            title: newName
          }).then(res => {
            if (res.data.code !== 0) {
              this.$message(res.data.desc, 'error')
              item.title = oldName
              item.highlightTitle = this.ensureEmptyTitle(formatHighlightTitle(item.title, item.splitKeywords))
            }
          }).catch(err => {
            console.log(err)
            item.title = oldName
            item.highlightTitle = this.ensureEmptyTitle(formatHighlightTitle(item.title, item.splitKeywords))
          })
        }
      }
    },
    clickCancelItem: function (item) {
      item.menuVisible = false
      this.$confirm('确定解散分组吗？', {
        confirmCallback: () => {
          cancelFsDir({
            fsId: this.id
          }).then(res => {
            if (res.data.code === 0) {
              this.$bus.$emit('group-deleted', item.id, item.title)
              for (var i = 0; i < this.resultList.length; i++) {
                if (this.resultList[i].id === item.id) {
                  this.resultList.splice(i, 1)
                  break
                }
              }
            } else {
              this.$message(res.data.desc, 'error')
            }
          })
        }
      })
    },
    clickDeleteItem: function (item) {
      item.menuVisible = false
      if (item.isGroup === 1) {
        this.$confirm('确定删除分组及其包含的文件吗？', {
          confirmCallback: () => {
            deleteDir({
              fsId: item.id
            }).then(res => {
              if (res.data.code === 0) {
                this.$bus.$emit('group-deleted', item.id, item.title)
                for (var i = 0; i < this.resultList.length; i++) {
                  if (this.resultList[i].id === item.id) {
                    this.resultList.splice(i, 1)
                    break
                  }
                }
              } else {
                this.$message(res.data.desc, 'error')
              }
            })
          }
        })
      } else if (item.isGroup === 0) {
        this.$confirm('确定删除文件么？', {
          confirmCallback: () => {
            deleteAudioDoc({
              audioDocId: item.id
            }).then(res => {
              if (res.data.code === 0) {
                for (var i = 0; i < this.resultList.length; i++) {
                  if (this.resultList[i].id === item.id) {
                    this.resultList.splice(i, 1)
                    break
                  }
                }
              } else {
                this.$message(res.data.desc, 'error')
              }
            })
          }
        })
      }
    },
    formatItemDisplayTime: function (doc) {
      return formatDocTime(doc.updateTime)
    },
    clickSentenceItem: function (item, sentence) {
      this.$router.push({
        path: '/moyin/detail',
        query: {
          docId: item.id,
          beginTime: sentence.beginTime,
          splitKeywords: item.splitKeywords
        }
      })
    }
  }
}
</script>
<style scoped lang="scss">
.main {
  display: flex;
  flex-direction: column;
  position: relative;
  .title {
    margin-left: 32px;
    margin-top: 18px;
    height: 30px;
    font-size: 18px;
    font-weight: 500;
    color: rgba(51,51,51,1);
    display: flex;
    flex-direction: row;
    align-items: center;
    .count {
      margin-left: 8px;
      font-size: 14px;
      font-weight: normal;
      color: rgba(51,51,51,1);
      margin-top: 4px;
    }
  }
  .head {
    height: 42px;
    display: flex;
    flex-direction: row;
    align-items: center;
    .col-check {
      width: 36px;
      min-width: 36px;
    }
    .col-name {
      width: 58%;
      font-size: 12px;
      font-weight: 500;
      color: rgba(102,102,102,1);
      & span {
        margin-left: 8px;
      }
    }
    .col-from {
      width: 27%;
      font-size: 12px;
      font-weight: 500;
      color: rgba(102,102,102,1);
      & span {
        margin-left: 16px;
      }
    }
    .col-time {
      width: 15%;
      font-size: 12px;
      font-weight: 500;
      color: rgba(102,102,102,1);
      & span {
        margin-left: 8px;
      }
    }
  }
  .line {
    height: 1px;
    background: rgba(240,240,240,1);
    margin: 0 32px;
  }
  .content-area {
    position: relative;
    height: calc(100vh - 162px);
    .row-list {
      height: calc(100vh - 162px);
      overflow-y: scroll;
      display: flex;
      flex-direction: column;
      .row {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 56px;
        position: relative;
        .col-check {
          width: 36px;
          min-width: 36px;
          display: flex;
          flex-direction: row;
          align-items: center;
          & img {
            width: 13px;
            height: 6px;
            margin-left: 15px;
            cursor: pointer;
          }
        }
        .col-name {
          height: 56px;
          width: 58%;
          display: flex;
          flex-direction: row;
          align-items: center;
          position: relative;
          .icon {
            width: 36px;
            height: 36px;
          }
          .name {
            margin-left: 4px;
            flex-grow: 1;
            overflow: hidden;
            width: 0;
            .text {
              font-size: 14px;
              font-weight: normal;
              color: rgba(51,51,51,1);
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              cursor: pointer;
            }
            .input {
              outline: 0;
              width: 70%;
              height: 36px;
              line-height: 36px;
              padding: 0 10px;
              background: rgba(255,255,255,1);
              border: 1px solid rgba(0,112,255,1);
              border-radius: 4px;
              box-shadow:  0 0 3px 0 rgba(0,112,255,0.7);
              font-size: 14px;
              font-weight: normal;
              color: rgba(51,51,51,1);
            }
          }
          .link-button {
            margin-left: 10px;
            width: 28px;
            height: 28px;
            visibility: hidden;
            cursor: pointer;
            &:hover {
              background: rgba(237,240,243,1);
              border-radius: 4px;
            }
          }
          .more-button {
            margin-left: 10px;
            width: 28px;
            height: 28px;
            visibility: hidden;
            cursor: pointer;
            position: relative;
            & img {
              width: 28px;
              height: 28px;
            }
            &:hover {
              background: rgba(237,240,243,1);
              border-radius: 4px;
            }
          }
          .float-more-menu {
            position: absolute;
            top: 32px;
            right: 0px;
            width: 136px;
            display: flex;
            flex-direction: column;
            padding: 10px 0;
            background: rgba(255,255,255,1);
            border: 1px solid rgba(224,224,224,1);
            border-radius: 4px;
            box-shadow:  0 0 6px 0 rgba(187,187,187,0.5);
            z-index: 100;
            .menu-item {
              height: 32px;
              padding-left: 24px;
              font-size: 12px;
              font-weight: normal;
              color: rgba(51,51,51,1);
              display: flex;
              flex-direction: row;
              align-items: center;
              cursor: pointer;
              &:hover {
                background: rgba(243,245,247,1);
              }
            }
            .menu-line {
              margin: 1px 0;
              height: 1px;
              background: rgba(216,216,216,0.5);
            }
          }
        }
        .col-from {
          width: 27%;
          font-size: 12px;
          font-weight: normal;
          color: rgba(51,51,51,1);
          & span {
            margin-left: 16px;
          }
        }
        .col-time {
          width: 15%;
          font-size: 12px;
          font-weight: normal;
          color: rgba(51,51,51,1);
          & span {
            margin-left: 8px;
          }
        }
        &:hover {
          background: rgba(250,251,252,1);
          .col-name {
            .link-button {
              visibility: visible;
            }
            .more-button {
              visibility: visible;
            }
          }
        }
        .col-content {
          position: absolute;
          left: 76px;
          right: 94px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 14px;
          font-weight: normal;
          color: #333333;
          cursor: pointer;
        }
      }
    }
    .empty-content {
      height: calc(100vh - 162px);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding-bottom: 100px;
      & img {
        width: 140px;
        height: 140px;
      }
      & div {
        margin-top: 6px;
        font-size: 12px;
        font-weight: normal;
        color: rgba(102,102,102,1);
        margin-bottom: 100px;
      }
    }
    .loading-area {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 100;
      background: rgba(255,255,255,0.8);
      display: flex;
      align-items: center;
      justify-content: center;
      & img {
        width: 64px;
        height: 64px;
        -webkit-animation: changeright 1s linear infinite;
      }
    }
    @-webkit-keyframes changeright {
      0% {
        -webkit-transform: rotate(0deg);
      }
      50% {
        -webkit-transform: rotate(180deg);
      }
      100% {
        -webkit-transform: rotate(360deg);
      }
    }
  }
}
</style>
