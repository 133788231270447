<template>
  <div class="main">
    <div class="title">{{ name }}</div>
    <div v-show="id !=='default' " class="group-more-button" v-clickoutside="hideGroupMoreMenu">
      <img class="icon" @click="clickGroupMoreButton" src="../../assets/homepage/folder-more.svg" />
      <div class="float-more-menu" v-show="groupMenuVisible">
        <div class="menu-item" @click="clickShareGroup">分享</div>
        <div class="menu-item" @click="clickRenameGroup">重命名</div>
        <div class="line"></div>
        <div class="menu-item" @click="clickCancelGroup">取消分组</div>
        <div class="menu-item" @click="clickDeleteGroup">删除分组</div>
      </div>
    </div>
    <div class="batch-menu" v-if="batchManageEnabled">
      <div class="cancel-button" @click="clickBatchCancelButton">取消</div>
      <div class="move-button" @click="clickBatchMoveButton">移动</div>
      <div class="delete-button" @click="clickBatchDeleteButton">删除</div>
    </div>
    <div class="head">
      <div class="col-check">
        <img src="../../assets/homepage/unchecked.svg" />
      </div>
      <div class="col-name"><span>名称</span></div>
      <div class="col-from"><span>来自</span></div>
      <div class="col-time">
        <div class="sort-type" :class="sortTypeMenuVisible ? 'sort-type-active' : ''" @click="clickSortTypeMenu"  v-clickoutside="hideSortTypeMenu">
          <div class="text">{{ sortTypeText }}</div>
          <img class="arrow" src="../../assets/homepage/sort-type-arrow-down.svg" />
        </div>
        <div class="sort-type-float-menu" v-show="sortTypeMenuVisible">
          <div class="menu-item" v-for="item in sortTypeList" :key="item.type" @click.stop="clickSortType(item.type, false)">
            <div class="icon">
              <img v-if="sortType === item.type" src="../../assets/homepage/select.svg" />
            </div>
            <div class="text">按{{ item.text }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="line"></div>
    <div class="content-area">
      <div class="row-list" v-show="docList.length > 0">
        <div v-for="doc in docList" :key="doc.docId">
          <div class="row">
            <div class="col-check" :class="batchManageEnabled ? 'col-check-batch' : ''">
              <img v-if="doc.checked" src="../../assets/homepage/checked.svg" @click="clickCheckButton(doc)"/>
              <img v-else src="../../assets/homepage/unchecked.svg" @click="clickCheckButton(doc)"/>
            </div>
            <div class="col-name">
              <img class="icon" src="../../assets/homepage/video-icon.svg" />
              <div class="name">
                <input :id="'docName-' + doc.docId" v-if="doc.editState" class="input" :value="doc.name" @keypress.enter="onPressEnter" @blur="onBlurDocName($event, doc)" />
                <div v-else class="text" @click.stop="clickEditDoc(doc)">{{ doc.name === '' ? '未命名文档' : doc.name }}</div>
              </div>
              <img class="link-button" src="../../assets/homepage/copylink.svg" @click.stop="clickLinkButton(doc)" />
              <div class="more-button">
                <img src="../../assets/homepage/getmore.svg" @click="clickMoreButton(doc)" />
                <div class="float-more-menu" v-show="doc.menuVisible">
                  <div class="menu-item" @click.stop="clickViewDoc(doc)">查看</div>
                  <div class="menu-item" @click.stop="clickEditDoc(doc)">编辑</div>
                  <div class="menu-line"></div>
                  <div class="menu-item" @click.stop="clickShareDoc(doc)">分享</div>
                  <div class="menu-item" @click.stop="clickRenameDoc(doc)">重命名</div>
                  <div class="menu-line"></div>
                  <div class="menu-item" @click.stop="clickDeleteDoc(doc)">删除</div>
                  <div class="menu-item" @click.stop="clickMoveDoc(doc)">移动</div>
                  <div class="menu-item" @click.stop="clickBatchManageDoc(doc)">批量管理</div>
                </div>
              </div>
            </div>
            <div class="col-from"><span>我</span></div>
            <div class="col-time">
              <span v-if="doc.docStatus == null">{{ formatDocDisplayTime(doc) }}</span>
              <div v-else class="status">
                <div class="text" :class="(doc.docStatus.status === 11 || doc.docStatus.status === 12 || doc.docStatus.status === 13) ? 'text-red' : ''">{{ doc.docStatus.statusText + doc.docStatus.progressText }}</div>
                <div v-if="doc.docStatus.status === 11 || doc.docStatus.status === 12 || doc.docStatus.status === 13" class="retry-button" @click.stop="clickRetryDoc(doc)">重试</div>
              </div>
            </div>
          </div>
          <div class="line"></div>
        </div>
      </div>
      <template v-if="!loading">
        <div v-if="loadFailed" class="empty-content">
          <template v-if="failedError === 1">
            <img src="../../assets/homepage/tip-server-error.svg" />
            <div>服务器出问题了</div>
          </template>
          <template v-else-if="failedError === 2">
            <img src="../../assets/homepage/tip-network-error.svg" />
            <div>哎呀，网络出错了</div>
          </template>
          <template v-else>
            <img src="../../assets/homepage/tip-load-failed.svg" />
            <div>加载失败，请重试</div>
          </template>
        </div>
        <div v-else-if="!loadFailed && docList.length === 0" class="empty-content">
          <img src="../../assets/homepage/tip-result-empty.svg" />
          <div>内容为空</div>
        </div>
      </template>
      <div class="loading-area" v-show="loading">
        <img src="../../assets/homepage/loading.svg" />
      </div>
    </div>
    <group-chooser ref="groupChooser" @choosed="onGroupChoosed" />
  </div>
</template>
<script>
import { formatDocTime } from '@/utils/time'
import { getH5DocUrl, getH5GroupUrl } from '@/common/const'
import { batchDeleteAudioDoc, deleteAudioDoc, modifyAudioDoc } from '@/api/doc'
import { cancelFsDir, deleteDir, getDirList, modifyDirName, moveFile, moveMulti } from '@/api/fs'
import { getUid } from '@/utils/auth'
import GroupChooser from '../dialog/GroupChooser.vue'
import { kErrorServerError, getErrorCode } from '../../common/errorCode'
import { kDocStatusTranscriptFinish } from '../../utils/statusConst'
import { loadGroupDocSetting, saveGroupDocSetting } from '../../utils/config'

export default {
  name: 'GroupDocList',
  components: {
    GroupChooser
  },
  data () {
    return {
      id: '',
      name: '',
      loading: false,
      loadFailed: false,
      failedError: -1,
      groupMenuVisible: false,
      sortTypeMenuVisible: false,
      sortType: 'createTime',
      sortTypeList: [{
        type: 'viewTime',
        text: '查看时间'
      }, {
        type: 'editTime',
        text: '编辑时间'
      }, {
        type: 'createTime',
        text: '创建时间'
      }, {
        type: 'nameAtoZ',
        text: '名称 (A-Z)'
      }, {
        type: 'nameZtoA',
        text: '名称 (Z-A)'
      }],
      batchManageEnabled: false,
      docList: [],
      moveMode: '',
      moveFsId: null
    }
  },
  computed: {
    sortTypeText: function () {
      for (var i = 0; i < this.sortTypeList.length; i++) {
        if (this.sortTypeList[i].type === this.sortType) {
          return this.sortTypeList[i].text
        }
      }
      return ''
    }
  },
  watch: {
    $route (to) {
      if (to.path === '/moyin/homepage/group') {
        this.id = to.query.id
        this.name = to.query.name
        this.loadDocList()
      }
    }
  },
  mounted () {
    this.id = this.$route.query.id
    this.name = this.$route.query.name
    this.$bus.$on('doc-added', this.onDocAdded)
    this.$bus.$on('doc-status', this.onDocStatus)
    var setting = loadGroupDocSetting()
    if (setting.sortType != null) {
      this.sortType = setting.sortType
    }
    this.loadDocList()
  },
  destroyed () {
    this.$bus.$off('doc-added', this.onDocAdded)
    this.$bus.$off('doc-status', this.onDocStatus)
  },
  methods: {
    onDocAdded: function (docInfo) {
      if (this.id === 'default') {
        this.addNewDoc(docInfo)
      }
    },
    onDocStatus: function (docId, docStatus) {
      this.docList.forEach(doc => {
        if (doc.docId === docId) {
          if (docStatus.status === kDocStatusTranscriptFinish) {
            doc.docStatus = null
          } else {
            doc.docStatus = docStatus
          }
        }
      })
    },
    clickGroupMoreButton: function () {
      this.groupMenuVisible = !this.groupMenuVisible
    },
    hideGroupMoreMenu: function () {
      this.groupMenuVisible = false
    },
    clickShareGroup: function () {
      this.groupMenuVisible = false
      var url = getH5GroupUrl(this.id, getUid())
      this.$clipboard(url)
      this.$message('链接已复制', 'success')
    },
    clickRenameGroup: function () {
      this.groupMenuVisible = false
      this.$input('修改分组', this.name, {
        confirmCallback: title => {
          if (title !== '') {
            modifyDirName({
              fsId: this.id,
              newName: title
            }).then(res => {
              if (res.data.code === 0) {
                this.name = title
                this.$bus.$emit('group-name-changed', this.id, title)
              } else {
                this.$message(res.data.desc, 'error')
              }
            })
            return true
          } else {
            this.$message('分组名称不能为空', 'error')
            return false
          }
        }
      })
    },
    clickCancelGroup: function () {
      this.groupMenuVisible = false
      this.$confirm('确定解散分组吗？', {
        confirmCallback: () => {
          cancelFsDir({
            fsId: this.id
          }).then(res => {
            if (res.data.code === 0) {
              this.$bus.$emit('group-deleted', this.id, this.name)
              this.$router.replace({
                path: '/moyin/homepage/group',
                query: {
                  id: 'default',
                  name: '默认分组'
                }
              })
            } else {
              this.$message(res.data.desc, 'error')
            }
          })
        }
      })
    },
    clickDeleteGroup: function () {
      this.groupMenuVisible = false
      this.$confirm('确定删除分组及其包含的文件吗？', {
        confirmCallback: () => {
          deleteDir({
            fsId: this.id
          }).then(res => {
            if (res.data.code === 0) {
              this.$bus.$emit('group-deleted', this.id, this.name)
              this.$router.replace({
                path: '/moyin/homepage/group',
                query: {
                  id: 'default',
                  name: '默认分组'
                }
              })
            } else {
              this.$message(res.data.desc, 'error')
            }
          })
        }
      })
    },
    clickBatchCancelButton: function () {
      this.batchManageEnabled = false
      this.docList.forEach(item => {
        item.checked = false
      })
    },
    ensureBatchManageMenu: function () {
      var count = 0
      this.docList.forEach(item => {
        if (item.checked) {
          count++
        }
      })
      this.batchManageEnabled = count > 0
    },
    clickBatchMoveButton: function () {
      this.moveMode = 'batch'
      this.$refs.groupChooser.show(this.id, this.name)
    },
    onGroupChoosed: function (groupId, groupName) {
      if (this.moveMode === 'batch') {
        var ids = []
        var idMap = {}
        this.docList.forEach(item => {
          if (item.checked) {
            ids.push(item.id)
            idMap[item.id] = true
          }
        })
        moveMulti({
          fsIds: ids.join(','),
          upperName: groupId === 'default' ? '/' : '/' + groupName + '/'
        }).then(res => {
          if (res.data.code === 0) {
            var newDocList = []
            this.docList.forEach(item => {
              if (!(item.id in idMap)) {
                newDocList.push(item)
              }
            })
            this.docList = newDocList
          } else {
            this.$message(res.data.desc, 'error')
          }
        })
      } else if (this.moveMode === 'single') {
        moveFile({
          fsId: this.moveFsId,
          upperName: groupId === 'default' ? '/' : '/' + groupName + '/'
        }).then(res => {
          if (res.data.code === 0) {
            for (var i = 0; i < this.docList.length; i++) {
              if (this.docList[i].id === this.moveFsId) {
                this.docList.splice(i, 1)
                break
              }
            }
            this.ensureBatchManageMenu()
          } else {
            this.$message(res.data.desc, 'error')
          }
        })
      }
    },
    clickBatchDeleteButton: function () {
      this.$confirm('确定删除选中文件么？', {
        confirmCallback: () => {
          this.batchManageEnabled = false
          var ids = []
          var idMap = {}
          this.docList.forEach(item => {
            if (item.checked) {
              ids.push(item.docId)
              idMap[item.docId] = true
            }
          })
          batchDeleteAudioDoc({
            ids: ids.join(',')
          }).then(res => {
            if (res.data.code === 0) {
              var newDocList = []
              this.docList.forEach(item => {
                if (!(item.docId in idMap)) {
                  newDocList.push(item)
                }
              })
              this.docList = newDocList
              this.ensureBatchManageMenu()
              ids.forEach(id => {
                this.$docService.delete(id)
              })
              this.$message('选中文件已删除', 'success')
            } else {
              this.$message(res.data.desc, 'error')
            }
          })
        }
      })
    },
    clickSortTypeMenu: function () {
      this.sortTypeMenuVisible = !this.sortTypeMenuVisible
    },
    hideSortTypeMenu: function () {
      this.sortTypeMenuVisible = false
    },
    clickSortType: function (type, force) {
      if (type !== this.sortType || force) {
        this.sortType = type
        this.docList.sort((a, b) => {
          if (this.sortType === 'editTime') {
            return b.updateTime.localeCompare(a.updateTime)
          } else if (this.sortType === 'createTime') {
            return b.createTime.localeCompare(a.createTime)
          } else if (this.sortType === 'nameAtoZ') {
            return a.name.localeCompare(b.name)
          } else if (this.sortType === 'nameZtoA') {
            return b.name.localeCompare(a.name)
          } else {
            let bViewTime = b.viewTime ? b.viewTime : b.createTime
            let aViewTime = a.viewTime ? a.viewTime : a.createTime
            return bViewTime.localeCompare(aViewTime)
          }
        })
        saveGroupDocSetting({
          sortType: type
        })
      }
      this.sortTypeMenuVisible = false
    },
    loadDocList: function () {
      this.loading = true
      this.batchManageEnabled = false
      this.docList = []
      getDirList({
        upperName: this.id === 'default' ? '/' : '/' + this.name + '/'
      }).then(res => {
        if (res.data.code === 0) {
          var fileList = res.data.data.filter(item => item.type === 'file')
          fileList.forEach(item => {
            item.checked = false
            item.menuVisible = false
            item.editState = false
            item.docStatus = this.$docService.getStatus(item.docId)
          })
          this.docList = fileList
          this.loadFailed = false
          this.failedError = -1
          this.clickSortType(this.sortType, true)
        } else {
          this.$message(res.data.desc, 'error')
          this.loadFailed = true
          this.failedError = kErrorServerError
        }
        this.loading = false
      }).catch(err => {
        console.log(err)
        this.loading = false
        this.loadFailed = true
        this.failedError = getErrorCode(err)
      })
    },
    addNewDoc: function (docInfo) {
      var found = false
      this.docList.forEach(doc => {
        if (doc.docId === docInfo.docId) {
          found = true
        }
      })
      if (!found) {
        docInfo.checked = false
        docInfo.menuVisible = false
        docInfo.editState = false
        docInfo.docStatus = this.$docService.getStatus(docInfo.docId)
        this.docList.splice(0, 0, docInfo)
      }
    },
    clickCheckButton: function (doc) {
      doc.menuVisible = false
      doc.checked = !doc.checked
      if (doc.checked) {
        this.batchManageEnabled = true
      } else {
        this.ensureBatchManageMenu()
      }
    },
    clickLinkButton: function (doc) {
      doc.menuVisible = false
      this.$share(doc.docId)
    },
    clickMoreButton: function (doc) {
      doc.menuVisible = !doc.menuVisible
    },
    hideMoreMenu: function (doc) {
      doc.menuVisible = false
    },
    clickViewDoc: function (doc) {
      doc.menuVisible = false
      window.open(getH5DocUrl(doc.docId))
    },
    clickEditDoc: function (doc) {
      doc.menuVisible = false
      this.$router.push({
        path: '/moyin/detail',
        query: {
          docId: doc.docId
        }
      })
    },
    clickShareDoc: function (doc) {
      this.clickLinkButton(doc)
    },
    clickRenameDoc: function (doc) {
      doc.menuVisible = false
      doc.editState = true
      setTimeout(() => {
        const inputDom = document.getElementById('docName-' + doc.docId)
        inputDom.focus()
      }, 0)
    },
    onPressEnter: function (event) {
      event.target.blur()
    },
    onBlurDocName: function (event, doc) {
      var oldName = doc.name
      var newName = event.target.value
      doc.editState = false
      if (newName !== oldName) {
        doc.name = newName
        modifyAudioDoc({
          id: doc.docId,
          title: newName
        }).then(res => {
          if (res.data.code !== 0) {
            doc.name = oldName
            this.$message(res.data.desc, 'error')
          }
        })
      }
    },
    clickDeleteDoc: function (doc) {
      doc.menuVisible = false
      this.$confirm('确定删除文件么？', {
        confirmCallback: () => {
          deleteAudioDoc({
            audioDocId: doc.docId
          }).then(res => {
            if (res.data.code === 0) {
              for (var i = 0; i < this.docList.length; i++) {
                if (this.docList[i].docId === doc.docId) {
                  this.docList.splice(i, 1)
                  break
                }
              }
              this.ensureBatchManageMenu()
              this.$docService.delete(doc.docId)
              this.$message('文件已删除', 'success')
            } else {
              this.$message(res.data.desc, 'error')
            }
          })
        }
      })
    },
    clickMoveDoc: function (doc) {
      doc.menuVisible = false
      this.moveMode = 'single'
      this.moveFsId = doc.id
      this.$refs.groupChooser.show(this.id, this.name)
    },
    clickBatchManageDoc: function (doc) {
      doc.menuVisible = false
      doc.checked = true
      this.batchManageEnabled = true
    },
    formatDocDisplayTime: function (doc) {
      var timeStr = ''
      if (this.sortType === 'createTime') {
        timeStr = doc.createTime
      } else if (this.sortType === 'editTime') {
        timeStr = doc.updateTime
      } else if (this.sortType === 'viewTime') {
        timeStr = doc.viewTime ? doc.viewTime : doc.createTime
      } else {
        timeStr = doc.updateTime
      }
      if (timeStr !== '') {
        return formatDocTime(timeStr)
      } else {
        return ''
      }
    },
    clickRetryDoc: function (doc) {
      this.$docService.retry(doc.docId)
    }
  }
}
</script>
<style scoped lang="scss">
.main {
  display: flex;
  flex-direction: column;
  position: relative;
  .title {
    margin-left: 32px;
    margin-top: 18px;
    height: 30px;
    font-size: 18px;
    font-weight: 500;
    color: rgba(51,51,51,1);
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .group-more-button {
    width: 28px;
    height: 28px;
    position: absolute;
    top: 18px;
    right: 32px;
    &:hover {
      background: rgba(243,245,247,1);
      border-radius: 4px;
    }
    .icon {
      width: 28px;
      height: 28px;
      cursor: pointer;
    }
    .float-more-menu {
      position: absolute;
      top: 32px;
      right: 0px;
      width: 136px;
      background: rgba(255,255,255,1);
      border: 1px solid rgba(224,224,224,1);
      border-radius: 4px;
      box-shadow:  0 0 6px 0 rgba(187,187,187,0.5);
      padding: 10px 0;
      display: flex;
      flex-direction: column;
      z-index: 100;
      .menu-item {
        height: 32px;
        padding-left: 24px;
        font-size: 12px;
        font-weight: normal;
        color: rgba(51,51,51,1);
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;
        &:hover {
          background: rgba(243,245,247,1);
        }
      }
      .line {
        margin: 1px 0;
        height: 1px;
        background: rgba(216,216,216,0.5);
      }
    }
  }
  .batch-menu {
    position: absolute;
    top: 19px;
    right: 33px;
    display: flex;
    flex-direction: row;
    .cancel-button {
      width: 60px;
      height: 28px;
      background: rgba(243,245,247,1);
      border: 1px solid rgba(235,235,235,1);
      border-radius: 4px 0 0 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      font-weight: normal;
      color: rgba(51,51,51,1);
      cursor: pointer;
    }
    .move-button {
      width: 60px;
      height: 28px;
      background: rgba(255,255,255,1);
      border: 1px solid rgba(235,235,235,1);
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      font-weight: normal;
      color: rgba(51,51,51,1);
      cursor: pointer;
    }
    .delete-button {
      width: 70px;
      height: 28px;
      background: rgba(255,255,255,1);
      border: 1px solid rgba(235,235,235,1);
      border-radius: 0 4px 4px 0;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      font-weight: normal;
      color: rgba(51,51,51,1);
      cursor: pointer;
    }
  }
  .head {
    height: 42px;
    display: flex;
    flex-direction: row;
    align-items: center;
    .col-check {
      width: 36px;
      min-width: 36px;
      display: flex;
      flex-direction: row;
      align-items: center;
      & img {
        width: 16px;
        height: 16px;
        margin-left: 16px;
        cursor: pointer;
        visibility: hidden;
      }
    }
    .col-name {
      width: 58%;
      font-size: 12px;
      font-weight: 500;
      color: rgba(102,102,102,1);
      & span {
        margin-left: 8px;
      }
    }
    .col-from {
      width: 27%;
      font-size: 12px;
      font-weight: 500;
      color: rgba(102,102,102,1);
      & span {
        margin-left: 16px;
      }
    }
    .col-time {
      height: 42px;
      width: 15%;
      position: relative;
      display: flex;
      flex-direction: row;
      .sort-type {
        margin-top: 7px;
        height: 28px;
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;
        .text {
          margin-left: 8px;
          font-size: 12px;
          font-weight: 500;
          color: rgba(102,102,102,1);
        }
        .arrow {
          margin-left: 6px;
          margin-right: 8px;
          width: 8px;
          height: 6px;
        }
      }
      .sort-type-active {
        background: rgba(243,245,247,1);
        border-radius: 4px;
        .text {
          color: rgba(0,112,255,1);
        }
      }
      .sort-type-float-menu {
        position: absolute;
        z-index: 100;
        top: 41px;
        left: -60px;
        width: 138px;
        background: rgba(255,255,255,1);
        border: 1px solid rgba(224,224,224,1);
        border-radius: 4px;
        box-shadow:  0 0 6px 0 rgba(187,187,187,0.5);
        display: flex;
        flex-direction: column;
        padding: 10px 0px;
        cursor: default;
        .menu-item {
          height: 32px;
          display: flex;
          flex-direction: row;
          align-items: center;
          cursor: pointer;
          .icon {
            margin-left: 20px;
            margin-right: 10px;
            width: 16px;
            & img {
              width: 16px;
              height: 12px;
            }
          }
          .text {
            font-size: 12px;
            font-weight: normal;
            color: rgba(51,51,51,1);
          }
          &:hover {
            background: rgba(243,245,247,1);
          }
        }
      }
    }
  }
  .line {
    height: 1px;
    background: rgba(240,240,240,1);
    margin: 0 32px;
  }
  .content-area {
    position: relative;
    height: calc(100vh - 162px);
    .row-list {
      height: calc(100vh - 162px);
      overflow-y: scroll;
      display: flex;
      flex-direction: column;
      .row {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 56px;
        .col-check {
          width: 36px;
          min-width: 36px;
          display: flex;
          flex-direction: row;
          align-items: center;
          & img {
            width: 16px;
            height: 16px;
            margin-left: 16px;
            cursor: pointer;
            visibility: hidden;
          }
        }
        .col-check-batch {
          & img {
            visibility: visible;
          }
        }
        .col-name {
          height: 56px;
          width: 58%;
          display: flex;
          flex-direction: row;
          align-items: center;
          position: relative;
          .icon {
            width: 36px;
            height: 36px;
          }
          .name {
            margin-left: 4px;
            flex-grow: 1;
            overflow: hidden;
            width: 0;
            .text {
              font-size: 14px;
              font-weight: normal;
              color: rgba(51,51,51,1);
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              cursor: pointer;
            }
            .input {
              outline: 0;
              width: 70%;
              height: 36px;
              line-height: 36px;
              padding: 0 10px;
              background: rgba(255,255,255,1);
              border: 1px solid rgba(0,112,255,1);
              border-radius: 4px;
              box-shadow:  0 0 3px 0 rgba(0,112,255,0.7);
              font-size: 14px;
              font-weight: normal;
              color: rgba(51,51,51,1);
            }
          }
          .link-button {
            margin-left: 10px;
            width: 28px;
            height: 28px;
            visibility: hidden;
            cursor: pointer;
            &:hover {
              background: rgba(237,240,243,1);
              border-radius: 4px;
            }
          }
          .more-button {
            margin-left: 10px;
            width: 28px;
            height: 28px;
            visibility: hidden;
            cursor: pointer;
            position: relative;
            & img {
              width: 28px;
              height: 28px;
            }
            &:hover {
              background: rgba(237,240,243,1);
              border-radius: 4px;
            }
          }
          .float-more-menu {
            position: absolute;
            top: 32px;
            right: 0px;
            width: 136px;
            display: flex;
            flex-direction: column;
            padding: 10px 0;
            background: rgba(255,255,255,1);
            border: 1px solid rgba(224,224,224,1);
            border-radius: 4px;
            box-shadow:  0 0 6px 0 rgba(187,187,187,0.5);
            z-index: 100;
            .menu-item {
              height: 32px;
              padding-left: 24px;
              font-size: 12px;
              font-weight: normal;
              color: rgba(51,51,51,1);
              display: flex;
              flex-direction: row;
              align-items: center;
              cursor: pointer;
              &:hover {
                background: rgba(243,245,247,1);
              }
            }
            .menu-line {
              margin: 1px 0;
              height: 1px;
              background: rgba(216,216,216,0.5);
            }
          }
        }
        .col-from {
          width: 27%;
          font-size: 12px;
          font-weight: normal;
          color: rgba(51,51,51,1);
          & span {
            margin-left: 16px;
          }
        }
        .col-time {
          width: 15%;
          font-size: 12px;
          font-weight: normal;
          color: rgba(51,51,51,1);
          & span {
            margin-left: 8px;
          }
          .status {
            margin-left: 8px;
            display: flex;
            flex-direction: row;
            align-items: center;
            .text {
              font-size: 12px;
              font-weight: normal;
              color: rgba(0,112,255,1);
            }
            .text-red {
              color: rgba(254,89,89,1);
            }
            .retry-button {
              margin-left: 8px;
              width: 58px;
              height: 24px;
              background: rgba(255,255,255,1);
              border: 1px solid rgba(235,235,235,1);
              border-radius: 4px;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 12px;
              font-weight: normal;
              color: rgba(0,112,255,1);
              cursor: pointer;
              &:hover {
                background: rgba(243,245,247,1);
              }
              &:active {
                background: rgba(237,240,243,1);
              }
            }
          }
        }
        &:hover {
          background: rgba(250,251,252,1);
          .col-check {
            & img {
              visibility: visible;
            }
          }
          .col-name {
            .link-button {
              visibility: visible;
            }
            .more-button {
              visibility: visible;
            }
          }
        }
      }
    }
    .empty-content {
      height: calc(100vh - 162px);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding-bottom: 100px;
      & img {
        width: 140px;
        height: 140px;
      }
      & div {
        margin-top: 6px;
        font-size: 12px;
        font-weight: normal;
        color: rgba(102,102,102,1);
        margin-bottom: 100px;
      }
    }
    .loading-area {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 100;
      background: rgba(255,255,255,0.8);
      display: flex;
      align-items: center;
      justify-content: center;
      & img {
        width: 64px;
        height: 64px;
        -webkit-animation: changeright 1s linear infinite;
      }
    }
    @-webkit-keyframes changeright {
      0% {
        -webkit-transform: rotate(0deg);
      }
      50% {
        -webkit-transform: rotate(180deg);
      }
      100% {
        -webkit-transform: rotate(360deg);
      }
    }
  }
}
</style>
