function draw (node, heatmap) {
  const canvas = document.querySelector(node)
  const canvas2 = document.querySelector('#canvas2')
  const parent = document.querySelector('.progress-bar')
  let height = parent.offsetHeight || 40
  canvas.height = height * 2
  canvas2.height = height * 2
  let width = parent.offsetWidth + 8
  canvas.width = parent.offsetWidth * 2
  canvas2.width = parent.offsetWidth * 2
  let ctx = canvas.getContext('2d')
  let ctx2 = canvas2.getContext('2d')
  ctx.scale(2, 2)
  // ctx2.scale(2, 2)
  let step = width / heatmap.length
  const Max = Math.max(...heatmap)
  ctx.clearRect(0, 0, width, canvas.height)
  ctx2.clearRect(0, 0, width, canvas.height)
  ctx.fillStyle = 'rgba(222,222,222,1)'
  ctx2.fillStyle = 'rgba(222,222,222,0.32)'
  render()

  function render () {
    ctx.beginPath()
    ctx2.beginPath()
    for (let i = 0; i < heatmap.length; i++) {
      const e = heatmap[i]
      ctx.fillRect(step * i, height * (1 - e / Max), step, height)
      ctx2.fillRect(step * i, height * (1 - e / Max), step, height)
    }
    
    // ctx.stroke()
  }
}

export default draw
