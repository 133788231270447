export function findWordIndex (words, startTime, endTime) {
  var left = 0
  var right = words.length - 1
  var middle = 0
  var selectedIndex = -1
  while (left <= right) {
    middle = parseInt((left + right) / 2)
    var middleWord = words[middle]
    if (startTime === middleWord.audioStart && endTime === middleWord.audioEnd) {
      selectedIndex = middle
      break
    } else if (startTime >= middleWord.audioEnd) {
      left = middle + 1
    } else if (endTime <= middleWord.audioStart) {
      right = middle - 1
    }
  }
  return selectedIndex
}
