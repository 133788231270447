export function getWordNodeId(node) {
  for (var i = 0; i < node.marks.length; i++) {
    var mark = node.marks[i]
    if (mark.type.name === 'magic_span') {
      if (mark.attrs.id != null && mark.attrs.id !== '') {
        return mark.attrs.id
      } else {
        return mark.attrs['data-id']
      }
    }
  }
  return null
}
export function getMagicSpanMark(node) {
  for (var i = 0; i < node.marks.length; i++) {
    var mark = node.marks[i]
    if (mark.type.name === 'magic_span') {
      return mark
    }
  }
  return null
}
export function setWordNodeId(node, wordId) {
  for (var i = 0; i < node.marks.length; i++) {
    var mark = node.marks[i]
    if (mark.type.name === 'magic_span') {
      mark.attrs.id = wordId
      mark.attrs['data-id'] = wordId
    }
  }
}
export function findPrevWordId(editor, pos, excludeId) {
  var tmpPos = pos - 1
  while (tmpPos >= 0) {
    var dom = editor.view.nodeDOM(tmpPos)
    if (dom != null && dom.nodeName.toLowerCase() === '#text') {
      let flag = true
      while (flag) {
        dom = dom.parentNode
        if (dom == null) {
          break
        }
        if (dom.nodeName.toLowerCase() === 'span') {
          break
        }
      }
      if (dom != null && dom.id !== excludeId) {
        return dom.id
      }
    }
    tmpPos--
  }
  return null
}

export function findPrevSpanNodeId(editor, pos) {
  var dom = editor.view.nodeDOM(pos)
  if (dom == null) {
    return null
  }
  console.log(dom)
  if (dom.nodeName === '#text') {
    let flag = true
    while (flag) {
      dom = dom.parentNode
      if (dom == null) {
        break
      }
      if (dom.nodeName.toLowerCase() === 'span') {
        break
      }
    }
  }
  var prevDom = dom.previousSibling
  if (prevDom != null) {
    return prevDom.id
  } else {
    var paraDom = dom.parentNode
    while (paraDom.previousSibling != null) {
      paraDom = paraDom.previousSibling
      if (paraDom.nodeName.toLowerCase() === 'p') {
        if (paraDom.hasChildNodes()) {
          return paraDom.lastChild.id
        }
      }
    }
    return null
  }
}

export function findInsertAndCursorPos(state) {
  let { pos, nodeBefore, nodeAfter } = state.selection.$anchor
  var insertPos = pos
  var cursorPos = pos + 2
  if (nodeBefore == null && nodeAfter != null) {
    insertPos = pos - 1
    cursorPos = insertPos + 1
  } else if (nodeBefore != null && nodeAfter != null) {
    var beforeId = getWordNodeId(nodeBefore)
    var afterId = getWordNodeId(nodeAfter)
    if (beforeId != null && beforeId === afterId) {
      insertPos = pos + nodeAfter.nodeSize
      cursorPos = pos + 3
    }
  }
  return { insertPos: insertPos, cursorPos: cursorPos }
}

export function findNextSpanNodeId(editor, pos) {
  var dom = editor.view.nodeDOM(pos)
  if (dom == null) {
    return null
  }
  if (dom.nodeName === '#text') {
    let flag = true
    while (flag) {
      dom = dom.parentNode
      if (dom == null) {
        break
      }
      if (dom.nodeName.toLowerCase() === 'span') {
        break
      }
    }
  }
  var prevDom = dom.nextSibling
  if (prevDom != null) {
    return prevDom.id
  } else {
    var paraDom = dom.parentNode
    while (paraDom.nextSibling != null) {
      paraDom = paraDom.nextSibling
      if (paraDom.nodeName.toLowerCase() === 'p') {
        if (paraDom.hasChildNodes()) {
          return paraDom.firstChild.id
        }
      }
    }
    return null
  }
}
