import { Mark } from 'tiptap'
import { colorHex, isHexColor } from './color'
import applyMark from './apply_mark'

export default class TipTapSpan extends Mark {
  get name() {
    return 'magic_span'
  }

  get schema() {
    return {
      attrs: {
        class: {
          default: ''
        },
        id: {
          default: ''
        },
        style: {
          default: ''
        },
        'data-id': {
          default: ''
        },
        'data-time': {
          default: ''
        }
      },
      parseDOM: [
        {
          tag: 'span',
          getAttrs: dom => {
            return {
              id: dom.getAttribute('id'),
              style: dom.getAttribute('style'),
              class: dom.getAttribute('class'),
              'data-id': dom.getAttribute('data-id'),
              'data-time': dom.getAttribute('data-time')
            }
          }
        }
      ],
      toDOM: node => {
        let { style } = node.attrs
        if (style) {
          const arr = style.split(';')
          for (let i = 0; i < arr.length; i++) {
            const property = arr[i]
            const attrArr = property.split(':')
            if (attrArr[1] && !isHexColor(attrArr[1].trim())) {
              attrArr[1] = colorHex(attrArr[1].trim())
              arr[i] = `${attrArr[0]}:${attrArr[1]}`
            }
          }
          style = arr.join(';')
        }
        return [
          'span',
          {
            ...node.attrs,
            style
          },
          0
        ]
      }
    }
  }

  // 开始和结束位置
  // 拿到每个span的mark type，遍历设置属性
  commands() {
    return ({ type, color }) => (state, dispatch, view) => {
      if (color !== undefined) {
        const { schema } = state
        let { tr } = state
        const markType = schema.marks.magic_span
        const realColor = color && isHexColor(color) ? color : null
        tr = applyMark(state, view, state.tr.setSelection(state.selection), markType, type, realColor)
        if (tr.docChanged || tr.storedMarksSet) {
          dispatch && dispatch(tr)
          return true
        }
      }
      return false
    }
  }
}
