import request from './../utils/request'

export function listViewRecords (data) {
  return request({
    url: 'audio/viewRecord/list',
    method: 'post',
    params: data
  })
}

export function deleteViewRecord (data) {
  return request({
    url: 'audio/viewRecord/delete',
    method: 'post',
    params: data
  })
}

export function batchDeleteViewRecord (data) {
  return request({
    url: 'audio/viewRecord/delete/batch',
    method: 'post',
    params: data
  })
}
