import { Fragment, Slice } from 'prosemirror-model'
import { getWordNodeId } from './node'

export function buildDocDomParasV2(mediaResult, start, end) {
  const fragment = document.createDocumentFragment()
  for (var i = start; i < end; i++) {
    var paraObj = mediaResult[i]

    if (paraObj.type === 'wordList') {
      var paraDom = document.createElement('p')
      paraDom.className = 'paragraph-content'
      if (paraObj.speaker != null) {
        paraDom.setAttribute('data-speaker-id', paraObj.speaker.id)
        paraDom.setAttribute('data-speaker-name', paraObj.speaker.nickname)
        paraDom.setAttribute('data-speaker-desc', paraObj.speaker.roleDesc)
      }

      for (var j = 0; j < paraObj.words.length; j++) {
        var wordObj = paraObj.words[j]
        if (wordObj.type === 'delete_words') {
          let wordDom = document.createElement('label')
          wordDom.innerText = ' '
          wordDom.id = wordObj.id
          wordDom.className = 'word-deleted-words'
          wordDom.setAttribute('data-type', 'delete_words')
          paraDom.appendChild(wordDom)
        } else {
          let wordDomContainer = null
          let lastDomContainer = null
          const wordDom = document.createElement('span')
          if (wordObj.type === 'silence') {
            wordDom.className = 'word-normal word-silence'
          } else {
            wordDom.className = 'word-normal'
          }
          if (wordObj.style.trim() !== '') {
            try {
              const { tags, styles } = JSON.parse(wordObj.style)
              if (tags && tags.length !== 0) {
                lastDomContainer = tags
                  .filter(item => item.trim() !== '')
                  .reduce((tempTag, tag) => {
                    if (tempTag === null) {
                      tempTag = document.createElement(tag)
                      wordDomContainer = tempTag
                      return tempTag
                    } else {
                      return tempTag.appendChild(document.createElement(tag))
                    }
                  }, null)
              }
              if (styles) {
                const arr = styles.split(';')
                for (let i = 0; i < arr.length; i++) {
                  const property = arr[i]
                  const attrArr = property.split(':')
                  if (attrArr[0] === 'background-color') {
                    wordDom.style.backgroundColor = attrArr[1]
                  }
                  if (attrArr[0] === 'color') {
                    wordDom.style.color = attrArr[1]
                  }
                }
              }
            } catch (error) {
              console.log('%c⧭', 'color: #ffcc00', error)
            }
          }
          wordDom.id = wordObj.id
          wordDom.innerText = wordObj.word
          wordDom.setAttribute('data-id', wordObj.id)
          wordDom.setAttribute('data-time', '')

          if (wordDomContainer) {
            lastDomContainer.appendChild(wordDom)
            paraDom.appendChild(wordDomContainer)
          } else {
            paraDom.appendChild(wordDom)
          }
        }
      }
      fragment.appendChild(paraDom)
    } else if (paraObj.type === 'h1') {
      let h1Dom = document.createElement('h1')
      h1Dom.innerText = paraObj.title
      fragment.appendChild(h1Dom)
    } else if (paraObj.type === 'h2') {
      let h2Dom = document.createElement('h2')
      h2Dom.innerText = paraObj.title
      fragment.appendChild(h2Dom)
    } else if (paraObj.type === 'h3') {
      let h3Dom = document.createElement('h3')
      h3Dom.innerText = paraObj.title
      fragment.appendChild(h3Dom)
    } else if (paraObj.type === 'image') {
      let divDom = document.createElement('div')
      divDom.setAttribute('data-type', 'image')
      let imgDom = document.createElement('img')
      imgDom.src = paraObj.imageUrl
      divDom.appendChild(imgDom)
      fragment.appendChild(divDom)
    }
  }
  return fragment
}

export function tryExpandSelectionV2 (editor) {
  const { from, to } = editor.state.selection
  if (from === to) {
    return
  }
  var newFrom = from
  var anchorNodeAfter = editor.state.selection.$from.nodeAfter
  var anchorNodeBefore = editor.state.selection.$from.nodeBefore
  if (anchorNodeAfter && anchorNodeBefore) {
    var anchorParent = editor.state.selection.$from.parent
    if (anchorParent.type.name === 'heading') {
      newFrom = from - anchorNodeBefore.nodeSize - 1
    } else {
      let afterId = getWordNodeId(anchorNodeAfter)
      let beforeId = getWordNodeId(anchorNodeBefore)
      if (afterId && beforeId && afterId === beforeId) {
        newFrom = from - anchorNodeBefore.nodeSize
      }
    }
  }
  var newTo = to
  var headNodeAfter = editor.state.selection.$to.nodeAfter
  var headNodeBefore = editor.state.selection.$to.nodeBefore
  if (headNodeAfter && headNodeBefore) {
    var headParent = editor.state.selection.$to.parent
    if (headParent.type.name === 'heading') {
      newTo = to + headNodeAfter.nodeSize
    } else {
      let afterId = getWordNodeId(headNodeAfter)
      let beforeId = getWordNodeId(headNodeBefore)
      if (afterId && beforeId && afterId === beforeId) {
        newTo = to + headNodeAfter.nodeSize
      }
    }
  }
  if (newFrom === from && newTo === to) {
    return
  }
  var startNode = editor.view.nodeDOM(newFrom)
  var endNode = editor.view.domAtPos(newTo, -1).node
  var selection = window.getSelection()
  selection.removeAllRanges()
  var newRange = document.createRange()
  newRange.setStart(startNode, 0)
  newRange.setEnd(endNode, endNode.length)
  selection.addRange(newRange)
}

export function setSelectedWordListsV2(editor, wordMap, recordSelectedWordLists) {
  const { from, to } = editor.state.selection
  if (from === to) {
    recordSelectedWordLists.splice(0, recordSelectedWordLists.length)
    return
  }
  var wordList = []
  editor.state.doc.nodesBetween(from, to, (node) => {
    var wordId = getWordNodeId(node)
    if (wordId != null) {
      var word = wordMap.get(wordId)
      if (word != null) {
        wordList.push(word)
      }
    }
  })
  return recordSelectedWordLists.splice(0, recordSelectedWordLists.length, ...wordList)
}

export function buildBlockJson(slice, wordMap) {
  var blocks = []
  var currentTs = 0
  slice.content.content.forEach(paraNode => {
    var block = {}
    if (paraNode.type.name === 'paragraph') {
      block.type = 'wordList'
      block.wordList = []
      for (var j = 0; j < paraNode.content.childCount; j++) {
        let wordNode = paraNode.content.child(j)
        if (wordNode.type.name === 'text') {
          let wordId = getWordNodeId(wordNode)
          if (wordId) {
            let word = wordMap.get(wordId)
            if (word != null) {
              word.word = wordNode.text
              word.style = getWordNodeStyle(wordNode)
              word.audioStart = currentTs
              word.audioEnd = currentTs + word.realAudioEnd - word.realAudioStart
              block.wordList.push(word)
              currentTs = word.audioEnd
            }
          }
        }
      }
      blocks.push(block)
    } else if (paraNode.type.name === 'heading') {
      block.type = 'h1'
      block.title = paraNode.textContent
      blocks.push(block)
    }
  })
  return JSON.stringify(blocks)
}

export function buildDeletedWords(editor, wordMap) {
  var words = []
  const { from, to } = editor.state.selection
  editor.state.doc.nodesBetween(from, to, (node) => {
    if (node.type.name === 'paragraph') {
      if (words.length > 0 && (words[words.length - 1].type === 'normal' || words[words.length - 1].type === 'silence')) {
        words.push({
          type: 'break',
          del: 1
        })
      }
    } else if (node.type.name === 'text') {
      let wordId = getWordNodeId(node)
      if (wordId != null) {
        let word = wordMap.get(wordId)
        if (word != null) {
          word.style = getWordNodeStyle(node)
          words.push(word)
        }
      }
    } else if (node.type.name === 'heading') {
      words.push({
        type: 'title',
        word: node.textContent,
        del: 1
      })
    } else if (node.type.name === 'delete_words') {
      let wordId = node.attrs.id
      let deleteWords = wordMap.get(wordId)
      if (deleteWords != null) {
        words.push(...deleteWords)
      }
    }
  })
  return words
}

export function buildWordsSlice(editor, words) {
  var nodes = []
  var wordNodes = []
  words.forEach(word => {
    if (word.type === 'break') {
      if (wordNodes.length > 0) {
        nodes.push(editor.schema.nodes.paragraph.createAndFill({ class: 'paragraph-content' }, wordNodes))
        wordNodes = []
      }
    } else if (word.type === 'title') {
      if (wordNodes.length > 0) {
        nodes.push(editor.schema.nodes.paragraph.createAndFill({ class: 'paragraph-content' }, wordNodes))
        wordNodes = []
      }
      let textNode = editor.schema.text(word.word)
      let heading = editor.schema.nodes.heading.createAndFill({ level: 1 }, textNode)
      nodes.push(heading)
    } else if (word.type === 'normal') {
      let textNode = editor.schema.text(word.word, buildWordNodeMarks(editor, word))
      wordNodes.push(textNode)
    } else if (word.type === 'silence') {
      let textNode = editor.schema.text('▪', buildWordNodeMarks(editor, word))
      wordNodes.push(textNode)
    }
  })
  if (wordNodes.length > 0) {
    nodes.push(editor.schema.nodes.paragraph.createAndFill({ class: 'paragraph-content' }, wordNodes))
  }
  return new Slice(Fragment.fromArray(nodes), 1, 1)
}

export function getWordNodeStyle (wordNode) {
  var style = {
    tags: [],
    styles: ''
  }
  for (var i = 0; i < wordNode.marks.length; i++) {
    var mark = wordNode.marks[i]
    if (mark.type.name === 'bold') {
      style.tags.push('strong')
    } else if (mark.type.name === 'italic') {
      style.tags.push('em')
    } else if (mark.type.name === 'underline') {
      style.tags.push('u')
    } else if (mark.type.name === 'magic_span') {
      style.styles = mark.attrs.style
    }
  }
  return JSON.stringify(style)
}

export function buildWordNodeMarks (editor, word) {
  var style = JSON.parse(word.style)
  var marks = []
  var magicSpanMark = editor.schema.marks.magic_span.create({
    class: word.type === 'silence' ? 'word-normal word-silence' : 'word-normal',
    'data-id': word.id,
    'data-time': '',
    id: word.id,
    style: style.styles
  })
  marks.push(magicSpanMark)
  style.tags.forEach(tag => {
    if (tag === 'strong') {
      marks.push(editor.schema.marks.bold.create())
    } else if (tag === 'em') {
      marks.push(editor.schema.marks.italic.create())
    } else if (tag === 'u') {
      marks.push(editor.schema.marks.underline.create())
    }
  })
  return marks
}

export function setWindowSelection(from, to, editor, isEnd) {
  const node = editor.state.doc.nodeAt(from)
  if (!node || !node.isText) {
    return
  }
  const mark = node.marks[0]
  const wordId = mark.attrs.id
  const dom = document.getElementById(wordId)
  const range = document.createRange()
  range.selectNodeContents(dom)
  if (isEnd) {
    range.collapse()
  } else {
    range.collapse(true)
  }
  const sel = window.getSelection()
  sel.removeAllRanges()
  sel.addRange(range)
}

export function setWordSelection(from, editor, textOffset) {
  const node = editor.state.doc.nodeAt(from)
  if (!node || !node.isText) {
    return
  }
  const mark = node.marks[0]
  const wordId = mark.attrs.id
  const dom = document.getElementById(wordId)
  const textNode = dom.firstChild.firstChild
  if (textNode) {
    const sel = window.getSelection()
    sel.collapse(textNode, textOffset)
  }
}
