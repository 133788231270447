import Vue from 'vue'
import Input from './Input.vue'

const InputBox = Vue.extend(Input)

Input.install = function (title, input, options) {
  let data = {
    title: title != null ? title : '',
    input: input != null ? input : '',
    cancelText: options.cancelText ? options.cancelText : '取消',
    confirmText: options.confirmText ? options.confirmText : '确定',
    cancelCallback: options.cancelCallback,
    confirmCallback: options.confirmCallback,
    placeholder: options.placeholder ? options.placeholder : ''
  }
  let instance = new InputBox({
    data: data
  }).$mount()

  document.body.appendChild(instance.$el)

  Vue.nextTick(() => {
    instance.show()
  })
}

export default Input
