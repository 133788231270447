<template>
  <div class="doc-content-area" id="docContentArea">
    <editor-content
      ref="docMain"
      :editor="editor"
      class="doc-main-content"
      @keydown.native="onKeyDown"
      @mousedown.native="onMouseDown"
    />
    <context-menu ref="contextMenu"
      :wordMap="wordMap"
      :editor="editor"
      :selectedWordList="selectedWordList"
      :highlightWordId="lastWordId"
      @wordCreated="onContextMenuWordCreated"
      @deletedWordCreated="onContextMenuDeletedWordCreated"
      @shareBlocks="onContextMenuShareBlocks"
      />
  </div>
</template>
<script>
import { joinBackward, joinForward } from 'prosemirror-commands'
import { TextSelection } from 'prosemirror-state'
import { Editor, EditorContent, Extension } from 'tiptap'
import { Bold, Italic, Underline } from 'tiptap-extensions'
import TipTapSpan from '@/components/editor/marks/span'
import MarklessHeading from '@/components/editor/nodes/MarklessHeading'
import TipTapP from '@/components/editor/nodes/paragraph'
import DeleteWordsNode from '@/components/editor/nodes/DeletedWordsNode'
import HighlightTime from '@/components/editor/extensions/HighlightTime'
import { findInsertAndCursorPos, getMagicSpanMark, getWordNodeId, findPrevWordId } from '@/utils/node'
import { buildWordsSlice, setWindowSelection, setWordSelection } from '@/utils/editorDom'
import History from '@/components/editor/extensions/History'
import { isArrowKey } from '@/utils/keyboard'
import { isMacOS, isWinOS } from '@/utils/os'
import ContextMenu from './ContextMenu.vue'
import { addHighlightClass, filterHighlightClass } from '@/utils/dom'
// import { removeClass, addClass } from '@/utils/dom'

export default {
  name: 'TextEditor',
  components: {
    EditorContent,
    ContextMenu
  },
  props: {
    wordMap: {},
    selectedWordList: {}
  },
  watch: {
    selectedWordList (val) {
      if (val != null && val.length > 0) {
        this.$emit('pauseMedia')
      }
    }
  },
  data () {
    const vm = this
    return {
      lastWordId: '',
      // activeWordId: null,
      editor: new Editor({
        onTransaction({ editor, transaction }) {
          // console.log(transaction)
          if (transaction.steps.length > 0) {
            if (transaction.meta.addToHistory != null && !transaction.meta.addToHistory) {
              return
            }
            vm.onStepTransaction()
            var step = transaction.steps[0]
            if (step.constructor.prototype.jsonID === 'replace') {
              var deletedWordIdList = []
              transaction.before.nodesBetween(step.from, step.to, (node, pos, parent, index) => {
                if (node.type.name === 'text') {
                  let wordId = getWordNodeId(node)
                  if (wordId != null) {
                    let word = vm.wordMap.get(wordId)
                    if (word != null && document.getElementById(wordId) == null) {
                      deletedWordIdList.push(wordId)
                    }
                  }
                }
              })
              if (deletedWordIdList.length > 0) {
                vm.$emit('mediaDeleted', deletedWordIdList)
              }
              var nearWordIdMap = {}
              transaction.before.nodesBetween(Math.max(step.from - 10, 0), Math.min(step.to + 10, transaction.before.nodeSize - 2), (node, pos, parent, index) => {
                let wordId = getWordNodeId(node)
                if (wordId != null) {
                  nearWordIdMap[wordId] = true
                }
              })
              var wordIdList = []
              step.slice.content.descendants((node, pos, parent) => {
                if (node.type.name === 'paragraph') {
                  return true
                } else if (node.type.name === 'text') {
                  var wordId = getWordNodeId(node)
                  if (wordId != null && !(wordId in nearWordIdMap)) {
                    wordIdList.push(wordId)
                  }
                }
                return false
              })
              if (wordIdList.length > 0) {
                var beforeWordId = findPrevWordId(vm.editor, step.from, wordIdList[0])
                var insertedStartTime = 0
                var insertedEndTime = 0
                if (beforeWordId != null) {
                  var beforeWord = vm.wordMap.get(beforeWordId)
                  insertedStartTime = beforeWord.audioStart
                  insertedEndTime = beforeWord.audioEnd
                }
                vm.$emit('mediaInserted', insertedStartTime, insertedEndTime, wordIdList)
              }
            }
          }
          // if (transaction.curSelection.from === transaction.curSelection.to) {
          //   var nodeAfter = transaction.curSelection.$from.nodeAfter
          //   if (nodeAfter != null && nodeAfter.type.name === 'text') {
          //     var wordId = getWordNodeId(nodeAfter)
          //     console.log(document.getElementById(vm.activeWordId))
          //     console.log(document.getElementById(wordId))
          //     if (vm.activeWordId != null && vm.activeWordId !== wordId) {
          //       removeClass(document.getElementById(vm.activeWordId), 'word-focused')
          //     }
          //     if (wordId != null) {
          //       vm.$nextTick(() => {
          //         addClass(document.getElementById(wordId), 'word-focused')
          //       })
          //       // var text = nodeAfter.text
          //       // if (!text.endsWith(' ')) {
          //       //   var newTextNode = nodeAfter.withText(text + ' ')
          //       //   var tr = vm.editor.state.tr.replaceWith(transaction.curSelection.from, transaction.curSelection.from + nodeAfter.nodeSize, newTextNode)
          //       //   tr.setMeta('preventUpdate', true)
          //       //   tr.setMeta('addToHistory', false)
          //       //   vm.editor.view.dispatch(tr)
          //       // }
          //     }
          //     vm.activeWordId = wordId
          //   } else {
          //     vm.activeWordId = null
          //   }
          // } else {
          //   vm.activeWordId = null
          // }
          // console.log('activeWordId:' + vm.activeWordId)
        },
        editorProps: {
          handleClickOn: (view, pos, node, nodePos, event, direct) => {
            if (event.button === 0 && direct) {
              vm.onClickDocEditor(view, pos, event)
              return false
            }
            return false
          },
          handleDoubleClickOn: () => {
            return true
          },
          handleTripleClickOn: () => {
            return true
          },
          handleTextInput: (view, from, to, text) => {
            if (this.selectedWordList.length > 0) {
              return true
            }
            var resolvedPos = vm.editor.state.doc.resolve(from)
            if (resolvedPos.parent.type.name === 'heading') {
              return false
            }
            return true
          },
          handlePaste: (view, event, slice) => {
            var isHeading = false
            const { from, to } = vm.editor.state.selection
            if (from !== to) {
              const content = vm.editor.state.selection.content().content
              if (content.childCount === 1 && content.child(0).type.name === 'heading') {
                isHeading = true
              }
            } else {
              const { parent } = vm.editor.state.selection.$anchor
              if (parent.type.name === 'heading') {
                isHeading = true
              }
            }
            if (isHeading) {
              var tr = vm.editor.state.tr.insertText(slice.content.textBetween(0, slice.content.size), from, to)
              vm.editor.view.dispatch(tr)
            }
            return true
          },
          handleDOMEvents: {
            dragstart: (view, event) => {
              event.preventDefault()
            },
            dragenter: (view, event) => {
              event.preventDefault()
            },
            dragover: (view, event) => {
              event.preventDefault()
            },
            drop: (view, event) => {
              event.preventDefault()
            }
          }
        },
        extensions: [
          new TipTapSpan(),
          new Bold(),
          new Italic(),
          new Underline(),
          new MarklessHeading({ levels: [1] }),
          new History(),
          new TipTapP(),
          new HighlightTime(),
          new DeleteWordsNode(),
          new (class extends Extension {
            keys() {
              return {
                Backspace: state => {
                  const { from, to } = state.selection
                  if (from !== to) {
                    const content = state.selection.content().content
                    if (content.childCount === 1 && content.child(0).type.name !== 'paragraph') {
                      return false
                    }
                    return true
                  }
                  const { nodeBefore, nodeAfter, parent } = state.selection.$anchor
                  if (parent.type.name === 'heading') {
                    if (!nodeBefore && nodeAfter) {
                      return true
                    }
                    if (!nodeBefore && !nodeAfter) {
                      let prevParaNode = state.doc.childBefore(from - 1)
                      if (prevParaNode.node == null) {
                        let tr = state.tr.delete(from - 1, from + 1)
                        vm.editor.view.dispatch(tr)
                        return true
                      }
                    }
                  }
                  if (parent.type.name === 'paragraph') {
                    if (!nodeBefore && !nodeAfter) {
                      let prevParaNode = state.doc.childBefore(from - 1)
                      if (prevParaNode.node == null) {
                        let tr = state.tr.delete(from - 1, from + 1)
                        vm.editor.view.dispatch(tr)
                        return true
                      }
                    }
                    if (!nodeBefore && nodeAfter) {
                      let prevParaNode = state.doc.childBefore(from - 1)
                      if (prevParaNode.node != null && prevParaNode.node.type.name !== 'paragraph') {
                        return true
                      }
                    }
                    if (nodeBefore && nodeBefore.type.name !== 'text') {
                      return true
                    }
                    if (nodeBefore && nodeBefore.type.name === 'text') {
                      return true
                    }
                    if (nodeBefore && nodeBefore.type.name === 'text' && nodeBefore.nodeSize === 1) {
                      var nodeId = getWordNodeId(nodeBefore)
                      if (nodeId != null) {
                        // var tr = state.tr()
                        var replaceNode = nodeBefore.withText('▪')
                        console.log(replaceNode)
                        if (!nodeAfter) {
                          // let tr = state.tr.replaceWith(from, to, replaceNode)
                          // vm.editor.view.dispatch(tr)
                          // let prevNodeId = findPrevSpanNodeId(vm.editor, from - 1)
                          // if (prevNodeId != null) {
                          //   vm.$emit('deleteWordSpan', nodeId, prevNodeId, null)
                          // } else {
                          //   let nextNodeId = findNextSpanNodeId(vm.editor, from - 1)
                          //   if (nextNodeId != null) {
                          //     vm.$emit('deleteWordSpan', nodeId, null, nextNodeId)
                          //   }
                          // }
                        } else {
                          var afterNodeId = getWordNodeId(nodeAfter)
                          if (afterNodeId !== nodeId) {
                            // let tr = state.tr.replaceWith(from, to, replaceNode)
                            // vm.editor.view.dispatch(tr)
                            // let prevNodeId = findPrevSpanNodeId(vm.editor, from - 1)
                            // if (prevNodeId != null) {
                            //   vm.$emit('deleteWordSpan', nodeId, prevNodeId, null)
                            // } else {
                            //   let nextNodeId = findNextSpanNodeId(vm.editor, from - 1)
                            //   if (nextNodeId != null) {
                            //     vm.$emit('deleteWordSpan', nodeId, null, nextNodeId)
                            //   }
                            // }
                          }
                        }
                      }
                    }
                  }
                  return false
                }
              }
            }

            commands() {
              return {
                joinBackward: () => joinBackward,
                joinForward: () => joinForward
              }
            }
          })(),
          new (class extends Extension {
            keys() {
              return {
                Delete: state => {
                  return true
                  // const { from, to } = state.selection
                  // if (from !== to) {
                  //   return true
                  // }
                  // const { nodeBefore, nodeAfter, parent } = state.selection.$anchor
                  // if (parent.type.name === 'heading' || parent.type.name === 'image_paragraph') {
                  //   if (nodeBefore && !nodeAfter) {
                  //     return true
                  //   }
                  // }
                  // if (parent.type.name === 'paragraph') {
                  //   if (nodeBefore && !nodeAfter) {
                  //     var prevParaNode = state.doc.childAfter(from + 1)
                  //     if (prevParaNode.node != null && prevParaNode.node.type.name !== 'paragraph') {
                  //       return true
                  //     }
                  //   }
                  // }
                  // return false
                }
              }
            }
          })(),
          new (class extends Extension {
            keys() {
              return {
                Enter(state) {
                  const { from, to } = state.selection
                  if (from !== to) {
                    return true
                  }
                  const { nodeAfter, nodeBefore, parent } = state.selection.$anchor
                  if (!nodeAfter || !nodeBefore) {
                    return true
                  }
                  if (parent != null && (parent.type.name === 'heading' || parent.type.name === 'image_paragraph')) {
                    return true
                  }
                  // if (vm.isPlaying()) {
                  //   vm.$emit('pauseMedia')
                  //   return true
                  // }
                  if (parent.type.name === 'paragraph') {
                    var beforeWordId = getWordNodeId(nodeBefore)
                    var afterWordId = getWordNodeId(nodeAfter)
                    if (beforeWordId === afterWordId) {
                      var mark = getMagicSpanMark(nodeBefore)
                      var word = vm.wordMap.get(beforeWordId)
                      if (mark != null && word != null) {
                        var beforeWord = Object.assign({}, word)
                        var afterWord = Object.assign({}, word)
                        var duration = word.realAudioEnd - word.realAudioStart
                        var wordMapSize = vm.wordMap.size
                        if (duration === 0) {
                          beforeWord.id = `word-${wordMapSize}-${word.realAudioStart}-${word.realAudioEnd}`
                          afterWord.id = `word-${wordMapSize + 1}-${word.realAudioStart}-${word.realAudioEnd}`
                        } else {
                          var middle = parseInt(nodeBefore.nodeSize * duration / (nodeBefore.nodeSize + nodeAfter.nodeSize))
                          beforeWord.realAudioEnd = beforeWord.realAudioStart + middle
                          beforeWord.audioEnd = beforeWord.audioStart + middle
                          afterWord.realAudioStart = beforeWord.realAudioEnd
                          afterWord.audioStart = beforeWord.audioEnd
                          beforeWord.id = `word-${wordMapSize}-${beforeWord.realAudioStart}-${beforeWord.realAudioEnd}`
                          afterWord.id = `word-${wordMapSize + 1}-${afterWord.realAudioStart}-${afterWord.realAudioEnd}`
                        }
                        vm.$emit('wordCreated', beforeWord)
                        vm.$emit('wordCreated', afterWord)
                        var tr = state.tr.addMark(from - nodeBefore.nodeSize, from,
                          state.schema.marks.magic_span.create({
                            id: beforeWord.id,
                            class: mark.attrs.class,
                            style: mark.attrs.style,
                            'data-id': beforeWord.id,
                            'data-time': mark.attrs['data-time']
                          }))
                        tr.addMark(from, from + nodeAfter.nodeSize,
                          state.schema.marks.magic_span.create({
                            id: afterWord.id,
                            class: mark.attrs.class,
                            style: mark.attrs.style,
                            'data-id': beforeWord.id,
                            'data-time': mark.attrs['data-time']
                          }))
                        vm.editor.view.dispatch(tr)
                        return false
                      } else {
                        return true
                      }
                    }
                  }
                  return false
                }
              }
            }
          })()
        ],
        content: '',
        onInit: ({ view }) => {
          view.dom.setAttribute('id', 'docMainContent')
        }
      })
    }
  },
  methods: {
    getEditor() {
      return this.editor
    },
    highlightWord: function (wordId, isPlaying, timeStr) {
      if (this.lastWordId !== '') {
        let lastWordDom = document.getElementById(this.lastWordId)
        let lastWordPos = this.editor.view.posAtDOM(lastWordDom, 0)
        if (lastWordPos !== -1) {
          let resolvedPos = this.editor.state.doc.resolve(lastWordPos)
          let magicSpanMark = getMagicSpanMark(resolvedPos.nodeAfter)
          let newMark = this.editor.schema.marks.magic_span.create({
            id: magicSpanMark.attrs.id,
            style: magicSpanMark.attrs.style,
            class: filterHighlightClass(magicSpanMark.attrs.class),
            'data-id': magicSpanMark.attrs['data-id'],
            'data-time': magicSpanMark.attrs['data-time']
          })
          let tr = this.editor.state.tr.addMark(lastWordPos, lastWordPos + resolvedPos.nodeAfter.nodeSize, newMark)
          tr.setMeta('addToHistory', false)
          this.editor.view.dispatch(tr)
        }
      }
      let wordDom = document.getElementById(wordId)
      let wordPos = this.editor.view.posAtDOM(wordDom, 0)
      if (wordPos !== -1) {
        let resolvedPos = this.editor.state.doc.resolve(wordPos)
        let magicSpanMark = getMagicSpanMark(resolvedPos.nodeAfter)
        let newMark = this.editor.schema.marks.magic_span.create({
          id: magicSpanMark.attrs.id,
          style: magicSpanMark.attrs.style,
          class: addHighlightClass(magicSpanMark.attrs.class, isPlaying),
          'data-id': magicSpanMark.attrs['data-id'],
          'data-time': timeStr
        })
        let tr = this.editor.state.tr.addMark(wordPos, wordPos + resolvedPos.nodeAfter.nodeSize, newMark)
        tr.setMeta('addToHistory', false)
        this.editor.view.dispatch(tr)
      }
      this.lastWordId = wordId
    },
    switchHighlightWordStatus: function (playing) {
      if (this.lastWordId !== '') {
        let lastWordDom = document.getElementById(this.lastWordId)
        let lastWordPos = this.editor.view.posAtDOM(lastWordDom, 0)
        if (lastWordPos !== -1) {
          let resolvedPos = this.editor.state.doc.resolve(lastWordPos)
          let magicSpanMark = getMagicSpanMark(resolvedPos.nodeAfter)
          let newMark = this.editor.schema.marks.magic_span.create({
            id: magicSpanMark.attrs.id,
            style: magicSpanMark.attrs.style,
            class: addHighlightClass(filterHighlightClass(magicSpanMark.attrs.class), playing),
            'data-id': magicSpanMark.attrs['data-id'],
            'data-time': magicSpanMark.attrs['data-time']
          })
          let tr = this.editor.state.tr.addMark(lastWordPos, lastWordPos + resolvedPos.nodeAfter.nodeSize, newMark)
          tr.setMeta('addToHistory', false)
          this.editor.view.dispatch(tr)
        }
      }
    },
    onStepTransaction: function () {
      this.$emit('stepTransaction')
    },
    onClickDocEditor(view, pos, event) {
      var target = event.target
      var tagName = target.tagName.toLowerCase()
      if (tagName === 'span') {
        this.$emit('clickWord', target)
      } else if (tagName === 'u' || tagName === 'em' || tagName === 'strong') {
        let flag = true
        while (flag) {
          target = target.parentNode
          tagName = target.tagName.toLowerCase()
          if (tagName === 'p' || tagName === 'div') {
            break
          }
          if (tagName === 'span') {
            this.$emit('clickWord', target)
            break
          }
        }
      } else if (tagName === 'label') {
        var id = target.id
        var deletedWords = this.wordMap.get(id)
        if (deletedWords != null) {
          var slice = buildWordsSlice(this.editor, this.wordMap.get(id))
          let tr = this.editor.state.tr.replace(pos - 1, pos, slice)
          this.editor.view.dispatch(tr)
        }
      }
    },
    setWordSelectionAgain() {
      setTimeout(() => {
        const { from, $anchor } = this.editor.state.selection
        const { textOffset } = $anchor
        setWindowSelection(from, from, this.editor)
        setTimeout(() => {
          setWordSelection(from, this.editor, textOffset)
        }, 60)
      }, 0)
    },
    clickH1Title: function () {
      this.showMoreMenu = false
      this.addHeadingTitle(1)
    },
    addHeadingTitle: function (level) {
      let { from, to } = this.editor.state.selection
      if (from !== to) {
        return
      }
      let { insertPos, cursorPos } = findInsertAndCursorPos(this.editor.state)
      var heading = this.editor.schema.nodes.heading.createAndFill({ level: level })
      var tr = this.editor.state.tr.insert(insertPos, heading)
      this.editor.view.dispatch(tr)
      var selection = TextSelection.create(this.editor.state.doc, cursorPos) // new TextSelection(this.editor.state.doc.resolve(cursorPos), this.editor.state.doc.resolve(cursorPos))
      var tr2 = this.editor.state.tr.setSelection(selection)
      this.editor.view.dispatch(tr2)
      this.editor.view.focus()
      this.setHeadingSelectionAgain()
    },
    setHeadingSelectionAgain() {
      setTimeout(() => {
        const { from } = this.editor.state.selection
        const dom = this.editor.view.domAtPos(from, 1).node
        if (!dom) {
          return
        }
        const range = document.createRange()
        range.selectNodeContents(dom)
        range.collapse()
        const sel = window.getSelection()
        sel.removeAllRanges()
        sel.addRange(range)
        setTimeout(() => {
          const textNode = dom.firstChild
          if (textNode) {
            const sel = window.getSelection()
            sel.collapse(textNode, 0)
          }
        }, 60)
      }, 0)
    },
    onMouseDown: function (event) {
      this.$emit('nativeMouseDown', event)
    },
    onKeyDown: function (event) {
      const { keyCode } = event
      if (isArrowKey(keyCode)) {
        this.$emit('arrowKey')
      } else if ((isMacOS() && event.metaKey && keyCode === 90) || (isWinOS() && event.ctrlKey && keyCode === 90)) {
        // CMD + Z
      } else if ((isMacOS() && event.metaKey && keyCode === 65) || (isWinOS() && event.ctrlKey && keyCode === 65)) {
        // CMD + A
        this.$emit('commandA')
      } else if (
        (isMacOS() && event.shiftKey && event.metaKey && keyCode === 68) ||
        (isWinOS() && event.shiftKey && event.ctrlKey && keyCode === 68)
      ) {
        // SHIFT + CMD + D
        // window.editDelete()
      } else if (
        (isMacOS() && event.shiftKey && event.metaKey && keyCode === 67) ||
        (isWinOS() && event.shiftKey && event.ctrlKey && keyCode === 67)
      ) {
        // SHIFT + CMD + C
        // window.editCopy()
      } else if (
        (isMacOS() && event.shiftKey && event.metaKey && keyCode === 88) ||
        (isWinOS() && event.shiftKey && event.ctrlKey && keyCode === 88)
      ) {
        // SHIFT + CMD + X
        // window.editCut()
      } else if (
        (isMacOS() && event.shiftKey && event.metaKey && keyCode === 86) ||
        (isWinOS() && event.shiftKey && event.ctrlKey && keyCode === 86)
      ) {
        // SHIFT + CMD + V
        // window.editPaste()
      } else if ((isMacOS() && event.metaKey && keyCode === 88) || (isWinOS() && event.ctrlKey && keyCode === 88)) {
        // CMD + X
        event.preventDefault()
      } else if ((isMacOS() && event.metaKey && keyCode === 8) || (isWinOS() && event.ctrlKey && keyCode === 8)) {
        // CMD + DELETE
        event.preventDefault()
      } else if (event.shiftKey && (keyCode === 37 || keyCode === 38 || keyCode === 39 || keyCode === 40)) {
        event.preventDefault()
      } else if (keyCode === 8) {
        event.preventDefault()
        this.$refs.contextMenu.onSyncDelete()
      } else if (keyCode === 9) {
        event.preventDefault()
        this.$emit('tabKey')
      }
    },
    showContextMenu: function () {
      this.$refs.contextMenu.show()
    },
    onContextMenuWordCreated: function (word) {
      this.$emit('wordCreated', word)
    },
    onContextMenuDeletedWordCreated: function (wordId, deleteWords) {
      this.$emit('deletedWordCreated', wordId, deleteWords)
    },
    onContextMenuShareBlocks: function (blockJson) {
      this.$emit('shareBlocks', blockJson)
    }
  }
}
</script>
<style scoped lang="scss">
.doc-content-area {
  margin-left: 18px;
  padding-right: 20px;
  position: relative;
  .doc-main-content {
    padding-bottom: 10px;
    position: relative;
  }
}
.doc-main-content ::selection {
  background-color: rgba(0, 212, 255, 0.35);
}
</style>
<style lang="scss">
.doc-main-content h1 {
  margin: 0px 0px 10px 0px;
  font-size: 20px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: rgba(74,74,74,1);
}
.paragraph-content {
  margin-top: 0px;
  padding: 0;
  font-size: 15px;
  font-family: PingFangSC-Regular, PingFang SC;
  color: rgba(74, 74, 74, 1);
  line-height: 29px;
  margin-bottom: 10px;
}
.paragraph-content span.word-normal:hover {
  background: rgba(237, 237, 237, 0.8);
}
.paragraph-content span.word-selected {
  background: rgba(0, 98, 255, 1);
  color: #ffffff;
}
.paragraph-content span.word-selected:hover {
  background: rgba(0, 98, 255, 1);
  color: #ffffff;
}
.paragraph-content span.word-silence {
  letter-spacing: 5px;
  color: #B2B2B2;
  display: inline-block;
}
.paragraph-content span.word-normal-selected {
  background-color: rgba(0, 212, 255, 0.35);
}

.paragraph-content span.word-focused {
  background-color: #cdedba;
  padding: 0 10px;
}

.paragraph-content span.word-delete {
  background-image: url('../../assets/icon/delete_flag.svg');
  background-size: 17px 47px;
  background-repeat: no-repeat;
  background-position: -5px 0px;
}
.paragraph-content span.word-playhead {
  position: relative;
  // background: rgba(237, 237, 237, 0.8);
}
.paragraph-content span.word-playhead:after {
  content: attr(data-time);
  letter-spacing: 0;
  font-size: 10px;
  color: rgba(179, 179, 179, 1);
  background: (255, 255, 255, 0);
  text-align: center;
  font-variant-numeric: tabular-nums;
  position: absolute;
  font-style: normal;
  top: -19px;
  left: -20px;
  width: 40px;
  cursor: default;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.paragraph-content span.word-silence:after {
  top: -15px;
}
.paragraph-content span.word-empty {
  border-bottom: 1px solid rgb(252, 132, 135);
  margin: 0 1px;
}

.paragraph-content span.note {
  color: #47a0d9;
}
.paragraph-content span.word-wrong {
  background: rgba(62, 217, 27, 0.3);
}
[contenteditable]:focus {
  outline: none;
}
.word-deleted-words {
  display: inline-block;
  width: 18px;
  height: 18px;
  background: url(../../assets/detail/word-deleted-flag.svg);
  background-size: 18px 18px;
  background-repeat: no-repeat;
  position: relative;
  top: 3px;
  cursor: pointer;
}
.word-deleted-words:hover {
  background-image: url(../../assets/detail/word-deleted-restore.svg);
}
</style>
