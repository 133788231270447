<template>
  <div class="content" @mouseup="onMainMouseUp" @mouseleave="onMainMouseLeave">
    <div class="navbar">
      <!-- <div class="logo">
        <img class="logo-image" src="../../assets/creative/logo-image.svg" />
        <img class="logo-font" src="../../assets/creative/logo-font.svg" />
      </div> -->
      <img @click="clickBackButton" class="back-button" src="../../assets/detail/icon-back.svg" />
      <div class="doc-switcher">
        <div class="title">{{ currentDoc.docTitle === '' ? '未命名文档' : currentDoc.docTitle }}</div>
        <div class="switch-button" @click="clickSwitchButton">
          <div>切换</div>
          <img src="../../assets/detail/switch-arrow.svg" />
        </div>
      </div>
      <div class="space"></div>
      <visitor-button :docId="currentDoc.docId" ref="visitorbutton" />
      <div class="share-button" @click="clickShareButton">
        <img class="icon" src="../../assets/detail/icon-share.svg" />
        <div class="text">分享</div>
      </div>
      <download-button ref="downloadButton" @click.native="clickDownloadButton" :docId="currentDoc.docId" :currentVersion="currentDocDetail.currentVersion" :docTitle="currentDoc.docTitle" />
      <message-button  @getMessage='getMessage'/>
      <div class="vline"></div>
      <avatar-button @quit="clickQuit" />
    </div>
    <div class="line"></div>
    <div ref="mainPanel" class="main-panel">
      <full-player
        ref="fullPlayer"
        :style="{ width: playerWidth + 'px' }"
        :duration="docDuration"
        :playerWidth="playerWidth"
        :playerHeight="playerHeight"
        :slideList="slideList"
        :linkEditing="linkEditing"
        @playing="onPlayerPlaying"
        @pause="onPlayerPause"
        @changeCurrentTime="onPlayerChangeCurrentTime"
        />
      <div class="main-vline"></div>
      <div class="doc-editor-panel" :style="{ width: editorWidth + 'px' }">
        <div class="toolbar">
          <button class="action-button" @click="clickUndoButton"
            :disabled="editor == null || undoDepth === 0">
            <img src="../../assets/detail/icon-undo-active.svg" />
          </button>
          <button class="action-button" @click="clickRedoButton"
            :disabled="editor == null || redoDepth === 0">
            <img src="../../assets/detail/icon-redo-active.svg" />
          </button>
          <div class="vline"></div>
          <button class="action-button" @click="clickLinkButton">
            <img src="../../assets/detail/icon-link.svg" />
          </button>
          <div class="space"></div>
          <button class="action-button save-button" @click="clickSaveButton">
            <img src="../../assets/detail/icon-save-main.svg" />
            <img v-if="saving" class="status status-saving" src="../../assets/detail/icon-save-saving.svg" />
            <img v-else-if="modifyStep === 0" class="status" src="../../assets/detail/icon-save-success.svg" />
            <img v-else class="status" src="../../assets/detail/icon-save-ready.svg" />
          </button>
          <div class="vline"></div>
          <history-button :docId="currentDoc.docId" :currentVersion="currentDocDetail.currentVersion" @restore="clickRestoreHistoryVersion" />
        </div>
        <div ref="docEditor" class="doc-editor">
          <div class="doc-title-input">
            <input
              type="text"
              placeholder="未命名文档"
              v-model="currentDoc.docTitle"
              @focus="onFocusDocName"
              @blur="onBlurDocName"
              @input="onInputDocName"
              @keypress.enter="onEnterDocName($event)"
              maxlength="50"
            />
          </div>
          <div class="doc-status" v-if="currentDocStatus != null">
            <img class="image" src="../../assets/detail/tip-doc-status.svg" />
            <div class="status-line">
              <div class="text" :class="(currentDocStatus.status === 11 || currentDocStatus.status === 12 || currentDocStatus.status === 13) ? 'text-red' : ''">{{ currentDocStatus.statusText + currentDocStatus.progressText }}</div>
              <div v-if="currentDocStatus.status === 11 || currentDocStatus.status === 12 || currentDocStatus.status === 13" class="retry-button" @click.stop="clickRetryDocButton">重试</div>
            </div>
          </div>
          <text-editor
            v-show="currentDocStatus == null"
            ref="textEditor"
            :wordMap="wordMap"
            :selectedWordList="selectedWordList"
            @pauseMedia="onEditorPauseMedia"
            @wordCreated="onEditorWordCreated"
            @deletedWordCreated="onEditorDeletedWordCreated"
            @stepTransaction="onEditorStepTransaction"
            @clickWord="onEditorClickWord"
            @nativeMouseDown="onEditorMouseDown"
            @arrowKey="onEditorArrowKey"
            @commandA="onEditorCommandA"
            @tabKey="onEditorTabKey"
            @shareBlocks="onEditorShareBlocks"
            @mediaDeleted="onEditorMediaDeleted"
            @mediaInserted="onEditorMediaInserted"
            />
        </div>
        <div class="editor-loading-area" v-show="editorLoading">
          <img src="../../assets/homepage/loading.svg" />
        </div>
        <link-panel ref="linkPanel" :docId="currentDoc.docId" @show="onLinkPanelShow" @hide="onLinkPanelHide" />
        <data-view ref="dataView"  :docId="currentDoc.docId" :duration="docDuration" />
      </div>
    </div>
    <div class="loading-area" v-show="loading">
      <img src="../../assets/homepage/loading.svg" />
    </div>
    <doc-chooser ref="docChooser" @choosed="onDocChoosed" />
  </div>
</template>
<script>
import MessageButton from '@/layout/components/button/MessageButton.vue'
import AvatarButton from '@/layout/components/button/AvatarButton.vue'
import DownloadButton from '@/layout/components/button/DownloadButton.vue'
import FullPlayer from '../player/FullPlayer.vue'
import TextEditor from '../editor/TextEditor.vue'
import VisitorButton from './VisitorButton.vue'
import { getAudioDocDetail, getDocDetailPlayList, modifyAudioDoc, requestShareDocFragment, updateAudioDocVersion, updateClick } from '@/api/doc'
import { buildDocDomParasV2, getWordNodeStyle, setSelectedWordListsV2, tryExpandSelectionV2 } from '@/utils/editorDom'
import DocChooser from '../dialog/DocChooser.vue'
import HistoryButton from './HistoryButton.vue'
import LinkPanel from './LinkPanel.vue'
import { getH5DocUrl } from '@/common/const'
import { getWordNodeId } from '@/utils/node'
import { formatDuration } from '@/utils/util'
import { addClass, removeClass } from '@/utils/dom'
import { kDocStatusTranscriptFinish, kDocStatusTranscriptTaskCreated } from '../../utils/statusConst'
import { switchPlayMedia } from '@/utils/playMedia'
import { findWordIndex } from '@/utils/playWords'
import { isMacOS, isWinOS } from '@/utils/os'
import { triggerShareDoc } from '@/utils/doc'
import { removeProjectId, removeUserInfo } from '../../utils/auth'
import { getUid } from '@/utils/auth'
import DataView from '../dataview/index.vue'
export default {
  name: 'DetailDoc',
  components: {
    MessageButton,
    AvatarButton,
    DownloadButton,
    FullPlayer,
    TextEditor,
    DocChooser,
    HistoryButton,
    VisitorButton,
    LinkPanel,
    DataView
  },
  data () {
    return {
      isWin: false,
      loading: false,
      editorLoading: false,
      saving: false,
      currentDoc: {
        docId: null,
        docTitle: ''
      },
      currentDocInputTitle: '',
      currentDocDetail: {},
      docDuration: 0,
      numberViews: -1,
      currentDocStatus: null,
      jumpBeginTime: null,
      jumpSplitKeyWords: null,
      linkEditing: false,

      editor: null,
      undoDepth: 0,
      redoDepth: 0,
      modifyStep: 0,
      editorWidth: 0,
      playerWidth: 0,
      playerHeight: 0,
      slideList: [],
      showHistoryPopover: false,
      mediaList: [],
      wordMap: new Map(),
      selectedWordList: [],
      // lastWord: null,
      // lastWordId: '',
      needScrollToPlayWord: false,

      currentTs: 0,
      playTimedWords: [],
      playMediaList: [],
      contentButtonDown: false,
      isDestroyed: false,
      hasReferer: true
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      if (from.fullPath === '/') {
        vm.hasReferer = false
      }
    })
  },
  mounted () {
    this.isWin = isWinOS()
    this.editorWidth = 427
    this.playerWidth = this.$refs.mainPanel.clientWidth - 1 - this.editorWidth
    this.currentDoc.docId = this.$route.query.docId
    if (this.$route.query.docTitle != null) {
      this.currentDoc.docTitle = this.$route.query.docTitle
      this.currentDoc.originTitle = this.$route.query.docTitle
      this.currentDocInputTitle = this.$route.query.docTitle
    }
    if (this.$route.query.beginTime != null && this.$route.query.splitKeywords != null) {
      this.jumpBeginTime = this.$route.query.beginTime
      this.jumpSplitKeyWords = this.$route.query.splitKeywords
    }
    this.editor = this.$refs.textEditor.getEditor()
    this.loadCurrentDoc(null, true)
    this.currentDocStatus = this.$docService.getStatus(this.currentDoc.docId)
    this.initLocalPlayList()
    window.addEventListener('resize', this.onWindowResized, true)
    document.addEventListener('keydown', this.onKeyDown, true)
    this.$bus.$on('doc-status', this.onDocStatus)
    this.$bus.$on('doc-switch', this.onDocSwitch)
    this.$bus.$on('doc-dataView', this.onDocDataView)
    this.$bus.$on('doc-detailView', this.showDetailDataView)
    this.$bus.$on('doc-dataviewClose', this.dataviewClose)
    this.$bus.$on('doc-setHeatMap', this.setHeatMap)
    window['editor'] = this.editor
  },
  destroyed () {
    window.removeEventListener('resize', this.onWindowResized)
    document.removeEventListener('keydown', this.onKeyDown)
    this.$bus.$off('doc-status', this.onDocStatus)
    this.$bus.$off('doc-switch', this.onDocSwitch)
    this.$bus.$off('doc-dataView', this.onDocDataView)
    this.$bus.$off('doc-detailView', this.showDetailDataView)
    this.$bus.$off('doc-dataviewClose', this.dataviewClose)
    this.$bus.$off('doc-setHeatMap', this.setHeatMap)
    if (this.playInterval != null) {
      clearInterval(this.playInterval)
      this.playInterval = null
    }
    this.isDestroyed = true
  },
  methods: {
    onKeyDown: function (event) {
      const { keyCode } = event
      if ((isMacOS() && event.metaKey && keyCode === 90) || (isWinOS() && event.ctrlKey && keyCode === 90)) {
        this.clickUndoButton()
      } else if ((isMacOS() && event.metaKey && keyCode === 89) || (isWinOS() && event.ctrlKey && keyCode === 89)) {
        this.clickRedoButton()
      }
    },
    onDocStatus: function (docId, docStatus) {
      if (docId === this.currentDoc.docId) {
        if (docStatus.status === kDocStatusTranscriptFinish) {
          this.currentDocStatus = null
          this.jumpBeginTime = this.getMediaCurrentTime()
          this.loadCurrentDoc(null, false)
        } else {
          if (docStatus.status === kDocStatusTranscriptTaskCreated) {
            this.jumpBeginTime = this.getMediaCurrentTime()
            this.loadCurrentDoc(null, false)
          }
          this.currentDocStatus = docStatus
        }
      }
    },
    onDocSwitch: function (docId) {
      this.onDocChoosed(docId, '')
    },
    clickQuit: function () {
      var callbackFunc = function () {
        removeUserInfo()
        removeProjectId()
        this.$router.replace('/login')
      }
      if (this.modifyStep > 0) {
        this.saveCurrentDoc(true, callbackFunc)
      } else if (this.currentDocInputTitle !== this.currentDoc.originTitle) {
        this.saveCurrentDocTitle(callbackFunc)
      } else {
        callbackFunc()
      }
    },
    cachePlayMediaList: function (playMediaList) {
      var newMediaList = []
      playMediaList.forEach(mediaItem => {
        var newMediaItem = Object.assign({}, mediaItem)
        newMediaItem.url = mediaItem.url
        newMediaList.push(newMediaItem)
      })
      return newMediaList
    },
    loadCurrentDoc: function (version, showLoading) {
      if (showLoading) {
        this.loading = true
      }
      this.undoDepth = 0
      this.redoDepth = 0
      this.modifyStep = 0
      var param = {
        audioDocId: this.currentDoc.docId
      }
      if (version != null) {
        param.version = version
      }
      getDocDetailPlayList(param).then(res => {
        if (res.data.code === 0) {
          const { mediaList, playList, title } = res.data.data
          this.currentDoc.docTitle = title
          this.currentDoc.originTitle = title
          this.currentDocInputTitle = title
          if (mediaList.length > 0) {
            this.$refs.fullPlayer.updateThumbnail(mediaList[0].coverUrl)
          }
          var played = false
          if (playList.length > 0) {
            this.playMediaList = playList
            this.$refs.fullPlayer.updateMediaList(this.cachePlayMediaList(this.playMediaList))
            this.docDuration = playList[playList.length - 1].end
            this.tryJumpToDestPosition()
            played = true
            this.loading = false
            this.editorLoading = true
          }
          var detailParam = {
            audioDocId: this.currentDoc.docId
          }
          if (version != null) {
            param.version = version
          }
          getAudioDocDetail(detailParam).then(res => {
            if (res.data.code === 0) {
              this.handleDocResult(res.data.data, played)
            } else {
              this.$message(res.data.desc, 'error')
            }
            this.loading = false
            this.editorLoading = false
          }).catch(err => {
            console.log(err)
            this.loading = false
            this.editorLoading = false
          })
        } else {
          this.$message(res.data.desc, 'error')
          this.loading = false
        }
      }).catch(err => {
        console.log(err)
        this.loading = false
      })
    },
    handleDocResult: function (data, played) {
      this.updateCurrentDocDetail(data)
      const { mediaList, blockList } = data
      if (mediaList.length > 0) {
        try {
          mediaList.forEach(item => {
            if (item.interactvImageJson !== '') {
              item.imageList = JSON.parse(item.interactvImageJson)
            } else {
              item.imageList = []
            }
          })
          this.mediaList = mediaList
          const { dealResult } = this.dealSplitResult(blockList)

          this.handleDocDom(dealResult)
          this.initPlayMediaList(dealResult)
          if (!played) {
            this.tryJumpToDestPosition()
          }
        } catch (error) {
          console.log('%c⧭', 'color: #408059', error)
        }
      } else {
        this.mediaList = []
        this.wordMap = new Map()
        if (this.currentDocStatus != null && this.currentDocStatus.filePath != null) {
          this.playMedia()
        } else {
          this.playMediaList = []
        }
      }
    },
    updateCurrentDocDetail: function(docDetail) {
      this.currentDoc.docTitle = docDetail.title
      this.currentDoc.originTitle = docDetail.title
      this.currentDocInputTitle = docDetail.title
      this.currentDocDetail = docDetail
      this.numberViews = docDetail.numberOfViews != null ? parseInt(docDetail.numberOfViews) : 0
      if (docDetail.imageList != null) {
        this.slideList = docDetail.imageList
      }
      if (docDetail.docStatus === 3) {
        this.$docService.delete(docDetail.docId)
        this.currentDocStatus = null
      }
    },
    dealSplitResult: function(splitResult) {
      const allWords = []
      const dealResult = splitResult.reduce((total, sentence) => {
        const { wordList, type, title, style, imageUrl } = sentence
        var paraResult = {
          type: type
        }
        if (type === 'wordList') {
          const paragraph = { title, style }

          paraResult.words = this.dealWordList(wordList)
          paraResult.sentence = paragraph

          if (paraResult.words.length === 0) {
            return total
          }
          allWords.push(...paraResult.words)
        } else if (type === 'h1' || type === 'h2' || type === 'h3') {
          paraResult.title = title
        } else if (type === 'image') {
          paraResult.imageUrl = imageUrl
        } else {
          return total
        }
        return [...total, paraResult]
      }, [])

      this.wordMap = new Map()
      allWords.forEach((word, index) => {
        if (word.type === 'delete_words') {
          word.words.forEach((delWord, delIndex) => {
            if (delWord.type === 'normal' || delWord.type === 'silence') {
              delWord.id = `word-${index}-${delIndex}-${delWord.realAudioStart}-${delWord.realAudioEnd}`
              this.wordMap.set(delWord.id, delWord)
            }
          })
          word.id = `word-deleted-${index}`
          this.wordMap.set(word.id, word.words)
        } else {
          // 1. set id && paragraphId
          word.id = `word-${index}-${word.realAudioStart}-${word.realAudioEnd}`
          // 3. set wordMap
          this.wordMap.set(word.id, word)
        }
      })
      return { dealResult }
    },
    dealWordList: function (wordList) {
      var resultWords = []
      var delWords = []
      for (var i = 0; i < wordList.length; i++) {
        var word = wordList[i]
        if (word.del === 1) {
          delWords.push(word)
        } else {
          if (delWords.length > 0) {
            resultWords.push({
              type: 'delete_words',
              words: delWords
            })
            delWords = []
          }
          if (word.type === 'silence') {
            var start = word.audioStart
            var realStart = word.realAudioStart
            let flag = true
            while (flag) {
              var finished = false
              var end = start + 500
              var realEnd = realStart + 500
              if (end >= word.audioEnd) {
                end = word.audioEnd
                finished = true
              }
              if (realEnd >= word.realAudioEnd) {
                realEnd = word.realAudioEnd
              }
              var newWord = Object.assign({}, word)
              newWord.audioStart = start
              newWord.audioEnd = end
              newWord.realAudioStart = realStart
              newWord.realAudioEnd = realEnd
              newWord.word = '▪'
              resultWords.push(newWord)
              if (finished) {
                break
              }
              start = end
              realStart = realEnd
            }
          } else {
            resultWords.push(word)
          }
        }
      }
      if (delWords.length > 0) {
        resultWords.push({
          type: 'delete_words',
          words: delWords
        })
      }
      return resultWords
    },
    handleDocDom(dealResult) {
      this.$nextTick(() => {
        this.buildDocDom(dealResult)
      })
    },
    buildDocDom: function(mediaResult) {
      if (mediaResult.length > 0) {
        this.buildDocDomParas(mediaResult, 0, mediaResult.length)
      } else {
        this.editor.options.content = ''
        this.editor.view.updateState(this.editor.createState())
      }
    },
    buildDocDomParas: function(mediaResult, start, end) {
      const paraDom = buildDocDomParasV2(mediaResult, start, end)
      let div = document.createElement('div')
      div.appendChild(paraDom)
      const editor = this.editor
      editor.options.content = div.innerHTML
      editor.view.updateState(editor.createState())
      div = null
    },
    getWord(wordId) {
      if (!wordId) {
        return
      }
      if (this.wordMap.size === 0) {
        return
      }
      try {
        return this.wordMap.get(wordId)
      } catch (error) {
        console.log(error)
      }
    },
    clickBackButton: function () {
      var _this = this
      var callbackFunc = function () {
        // if (_this.hasReferer) {
        //   _this.$router.go(-1)
        // } else {
        _this.$router.replace('/')
        // }
      }
      if (this.modifyStep > 0) {
        this.saveCurrentDoc(true, callbackFunc)
      } else if (this.currentDocInputTitle !== this.currentDoc.originTitle) {
        this.saveCurrentDocTitle(callbackFunc)
      } else {
        callbackFunc()
      }
    },
    clickSwitchButton: function () {
      this.$refs.docChooser.show(this.currentDoc.docId, this.currentDoc.docTitle)
    },
    clickShareButton: function () {
      this.$share(this.currentDoc.docId)
    },
    clickDownloadButton: function () {
      var _this = this
      var callbackFunc = function () {
        _this.$nextTick(() => {
          _this.$refs.downloadButton.doDownloadAction()
        })
      }
      if (this.modifyStep > 0) {
        this.saveCurrentDoc(false, callbackFunc)
      } else {
        callbackFunc()
      }
    },
    clickUndoButton: function () {
      this.editor.commands.undo()
    },
    clickRedoButton: function () {
      this.editor.commands.redo()
    },
    clickLinkButton: function () {
      this.$refs.linkPanel.show()
    },
    clickSaveButton: function () {
      if (this.modifyStep > 0) {
        this.saveCurrentDoc(false)
      } else {
        this.$message('当前已是最新版本', 'success')
      }
    },
    clickRestoreHistoryVersion: function (version) {
      updateAudioDocVersion({
        audioDocId: this.currentDoc.docId,
        currentVersion: version
      }).then(res => {
        if (res.data.code === 0) {
          this.loadCurrentDoc(version, false)
        } else {
          this.$message(res.data.desc, 'error')
        }
      })
    },
    onFocusDocName: function () {
      this.currentDoc.originTitle = this.currentDoc.docTitle
    },
    onBlurDocName: function () {
      this.saveCurrentDocTitle(null)
    },
    saveCurrentDocTitle: function (callback) {
      if (this.currentDoc.originTitle === this.currentDoc.docTitle) {
        callback && callback()
        return
      }
      modifyAudioDoc({
        id: this.currentDoc.docId,
        title: this.currentDoc.docTitle
      }).then(res => {
        if (res.data.code !== 0) {
          this.$message(res.data.desc, 'error')
          this.currentDoc.docTitle = this.currentDoc.originTitle
          this.currentDocInputTitle = this.currentDoc.originTitle
        } else {
          this.currentDoc.originTitle = this.currentDoc.docTitle
          this.currentDocInputTitle = this.currentDoc.docTitle
        }
        callback && callback()
      }).catch(err => {
        console.log(err)
        this.currentDoc.docTitle = this.currentDoc.originTitle
        this.currentDocInputTitle = this.currentDoc.originTitle
        callback && callback()
      })
    },
    onInputDocName: function (event) {
      this.currentDocInputTitle = event.target.value
    },
    onEnterDocName: function (event) {
      event.target.blur()
    },
    clickRetryDocButton: function () {
      this.$docService.retry(this.currentDoc.docId)
    },
    onEditorPauseMedia: function () {
      this.pauseMedia()
    },
    onEditorWordCreated: function (word) {
      this.wordMap.set(word.id, word)
    },
    onEditorDeletedWordCreated: function (wordId, deleteWords) {
      this.wordMap.set(wordId, deleteWords)
    },
    onEditorStepTransaction: function () {
      this.undoDepth = this.editor.commands.undoDepth()
      this.redoDepth = this.editor.commands.redoDepth()
      this.modifyStep++
      if (this.modifyStep > 10) {
        this.saveCurrentDoc(true)
      }
    },
    onEditorClickWord: function (element) {
      if (!element) {
        return
      }
      var wordId = element.id
      if (!wordId) {
        wordId = element.getAttribute('data-id')
        if (wordId) {
          element.id = wordId
        }
      }
      const word = this.getWord(wordId)
      this.handleHighlightTime(word)
      this.$refs.fullPlayer.seekTo(word.audioStart, false)
    },
    onEditorMouseDown: function (event) {
      if (event.button === 0) {
        this.contentButtonDown = true
      }
    },
    onMainMouseUp: function(event) {
      if (event.button === 0 && this.contentButtonDown) {
        this.handleSelectionResult()
      }
      this.contentButtonDown = false
    },
    onMainMouseLeave: function(event) {
      if (event.button === 0 && this.contentButtonDown) {
        var _this = this
        setTimeout(function() {
          if (_this.isDestroyed) {
            return
          }
          tryExpandSelectionV2(_this.editor)
          setTimeout(() => {
            if (_this.isDestroyed) {
              return
            }
            setSelectedWordListsV2(_this.editor, _this.wordMap, _this.selectedWordList)
          }, 50)
        }, 0)
      }
      this.contentButtonDown = false
    },
    onEditorArrowKey: function () {
      this.selectedWordList.splice(0, this.selectedWordList.length)
    },
    onEditorCommandA: function () {
      setSelectedWordListsV2(this.editor, this.wordMap, this.selectedWordList)
    },
    onEditorTabKey: function () {
      this.$refs.fullPlayer.clickPlayOrPauseButton()
    },
    onEditorShareBlocks: function (blockJson) {
      requestShareDocFragment({
        docId: this.currentDoc.docId,
        blockJson: blockJson
      }).then(res => {
        if (res.data.code === 0) {
          var url = getH5DocUrl(res.data.data.docId)
          this.$clipboard(url)
          this.$message('链接已复制', 'success')
          triggerShareDoc(res.data.data.docId)
        } else {
          this.$message(res.data.desc, 'error')
        }
      })
    },
    onEditorMediaDeleted: function (wordIdList) {
      this.pauseMedia()
      var wordIdMap = {}
      wordIdList.forEach(wordId => {
        wordIdMap[wordId] = true
      })
      var timedWords = []
      var currentTs = 0
      this.playTimedWords.forEach(word => {
        if (!(word.id in wordIdMap)) {
          word.audioStart = currentTs
          word.audioEnd = currentTs + word.realAudioEnd - word.realAudioStart
          timedWords.push(word)
          currentTs = word.audioEnd
        }
      })
      this.playTimedWords = timedWords
      this.computePlayMediaList()
      if (this.playMediaList.length > 0) {
        this.docDuration = this.playMediaList[this.playMediaList.length - 1].end
      }
      this.$refs.fullPlayer.updateMediaList(this.cachePlayMediaList(this.playMediaList))
      // this.updateHighlightWordTime()
    },
    onEditorMediaInserted: function (insertedStartTime, insertedEndTime, wordIdList) {
      this.pauseMedia()
      var insertedIndex = findWordIndex(this.playTimedWords, insertedStartTime, insertedEndTime)
      var insertedWords = []
      wordIdList.forEach(wordId => {
        let word = this.wordMap.get(wordId)
        if (word != null) {
          insertedWords.push(word)
        }
      })
      this.playTimedWords.splice(insertedIndex + 1, 0, ...insertedWords)
      var currentTs = 0
      this.playTimedWords.forEach(word => {
        word.audioStart = currentTs
        word.audioEnd = currentTs + word.realAudioEnd - word.realAudioStart
        currentTs = word.audioEnd
      })
      this.computePlayMediaList()
      if (this.playMediaList.length > 0) {
        this.docDuration = this.playMediaList[this.playMediaList.length - 1].end
      }
      this.$refs.fullPlayer.updateMediaList(this.cachePlayMediaList(this.playMediaList))
      // this.updateHighlightWordTime()
    },
    handleSelectionResult() {
      var _this = this
      setTimeout(() => {
        if (_this.isDestroyed) {
          return
        }
        tryExpandSelectionV2(_this.editor)
        setTimeout(() => {
          if (_this.isDestroyed) {
            return
          }
          setSelectedWordListsV2(_this.editor, _this.wordMap, _this.selectedWordList)
        }, 50)
        this.$refs.textEditor.showContextMenu()
      }, 60)
    },
    onWindowResized: function () {
      if (this.$refs.mainPanel != null) {
        this.editorWidth = 427
        this.playerWidth = this.$refs.mainPanel.clientWidth - 1 - this.editorWidth
        this.playerHeight = this.$refs.mainPanel.clientHeight
        this.$refs.fullPlayer.canvasResize()
      }
    },
    onPlayerPlaying: function () {
      this.needScrollToPlayWord = true
      // if (this.lastWord != null) {
      //   this.setHighlightCss(this.lastWord, 'word-selected')
      // }
      this.$refs.textEditor.switchHighlightWordStatus(true)
    },
    onPlayerPause: function () {
      // if (this.lastWord != null) {
      //   const word = this.wordMap.get(this.lastWordId)
      //   this.setHighlightCss(this.lastWord, 'word-playhead', formatDuration(word.audioStart))
      // }
      this.$refs.textEditor.switchHighlightWordStatus(false)
    },
    onPlayerChangeCurrentTime: function (time) {
      var destWord = this.findWordByTime(time, true)
      if (destWord) {
        var element = document.getElementById(destWord.id)
        if (!element) {
          return
        }
        var wordId = element.id
        if (!wordId) {
          wordId = element.getAttribute('data-id')
          if (wordId) {
            element.id = wordId
          }
        }
        this.handleHighlightTime(destWord)
      }
    },
    onLinkPanelShow: function () {
      this.linkEditing = true
      this.pauseMedia()
    },
    onLinkPanelHide: function () {
      this.linkEditing = false
    },
    onDocChoosed: function (id, name) {
      if (id !== this.currentDoc.docId) {
        var _this = this
        var callbackFunc = function () {
          _this.currentDoc.docId = id
          _this.currentDoc.docTitle = name
          _this.currentDoc.originTitle = name
          _this.currentDocInputTitle = name
          _this.wordMap.clear()
          // _this.lastWord = null
          // _this.lastWordId = ''
          _this.pauseMedia()
          _this.jumpBeginTime = null
          _this.loadCurrentDoc(null, false)
          _this.currentDocStatus = _this.$docService.getStatus(_this.currentDoc.docId)
          _this.initLocalPlayList()
        }
        if (this.modifyStep > 0) {
          this.saveCurrentDoc(true, callbackFunc)
        } else {
          callbackFunc()
        }
      }
    },
    highlightTime: function (timestamp) {
      if (this.playTimedWords.length > 0) {
        var left = 0
        var right = this.playTimedWords.length - 1
        var middle = 0
        var destWord = null
        while (left <= right) {
          middle = parseInt((left + right) / 2)
          var middleWord = this.playTimedWords[middle]
          if (middleWord.audioStart === middleWord.audioEnd && timestamp === middleWord.audioStart) {
            destWord = middleWord
            break
          } else if (timestamp >= middleWord.audioStart && timestamp < middleWord.audioEnd) {
            destWord = middleWord
            break
          } else if (timestamp >= middleWord.audioEnd) {
            left = middle + 1
          } else if (timestamp < middleWord.audioStart) {
            right = middle - 1
          }
        }
        if (destWord != null) {
          this.handleHighlightTime(destWord)
        }
      }
    },
    findWordByTime(timestamp, allowDot) {
      if (this.playTimedWords.length === 0) {
        return null
      }
      let left = 0
      let right = this.playTimedWords.length - 1
      let middle = 0
      while (left <= right) {
        middle = parseInt((left + right) / 2)
        const middleWord = this.playTimedWords[middle]
        if (middleWord.audioStart === middleWord.audioEnd && middleWord.audioStart === timestamp && allowDot) {
          return middleWord
        } else if (timestamp >= middleWord.audioStart && timestamp < middleWord.audioEnd) {
          return middleWord
        } else if (timestamp >= middleWord.audioEnd) {
          left = middle + 1
        } else if (timestamp < middleWord.audioStart) {
          right = middle - 1
        }
      }
      return null
    },
    tryJumpToDestPosition: function () {
      if (this.jumpBeginTime != null && this.jumpSplitKeyWords != null) {
        this.$refs.fullPlayer.seekTo(this.jumpBeginTime, false)
        this.playMedia()
      } else if (this.jumpBeginTime != null) {
        // for case jump
      } else {
        this.playMedia()
      }
      this.jumpBeginTime = null
      this.jumpSplitKeyWords = null
    },
    computePlayMediaList: function () {
      var playMediaList = []
      var curPlayMedia = null
      this.playTimedWords.forEach(word => {
        curPlayMedia = switchPlayMedia(curPlayMedia, word, playMediaList, this.mediaList)
      })
      if (curPlayMedia != null) {
        playMediaList.push(curPlayMedia)
      }
      this.playMediaList = playMediaList
    },
    initPlayMediaList: function (dealResult) {
      var timedWords = []
      var currentTs = 0
      for (var i = 0; i < dealResult.length; i++) {
        var paraObj = dealResult[i]
        if (paraObj.type === 'wordList') {
          for (var j = 0; j < paraObj.words.length; j++) {
            var wordObj = paraObj.words[j]
            if (wordObj.type !== 'delete_words') {
              wordObj.audioStart = currentTs
              wordObj.audioEnd = currentTs + wordObj.realAudioEnd - wordObj.realAudioStart
              timedWords.push(wordObj)
              currentTs = wordObj.audioEnd
            }
          }
        }
      }
      this.playTimedWords = timedWords
      this.computePlayMediaList()
      if (this.playMediaList.length === 0 && this.mediaList.length > 0) {
        const mediaInfo = this.mediaList[0]
        this.playMediaList.push({
          url: mediaInfo.videoSmartUrl !== '' ? mediaInfo.videoSmartUrl : mediaInfo.videoUrl,
          mediaStart: 0,
          mediaEnd: mediaInfo.duration,
          start: 0,
          end: mediaInfo.duration,
          width: mediaInfo.width,
          height: mediaInfo.height
        })
      }
      if (this.playMediaList.length > 0) {
        this.docDuration = this.playMediaList[this.playMediaList.length - 1].end
      }
      this.$refs.fullPlayer.updateMediaList(this.cachePlayMediaList(this.playMediaList))
    },
    initLocalPlayList: function () {
      if (this.currentDocStatus != null) {
        var url = null
        if (this.currentDocStatus.file != null && this.currentDocStatus.file.size != null && this.currentDocStatus.file.name != null) {
          url = URL.createObjectURL(this.currentDocStatus.file)
        }
        if (url != null) {
          this.playMediaList = [{
            url: url,
            start: 0,
            end: this.currentDocStatus.duration,
            mediaStart: 0,
            mediaEnd: this.currentDocStatus.duration,
            width: this.currentDocStatus.width,
            height: this.currentDocStatus.height
          }]
          this.docDuration = this.currentDocStatus.duration
        } else {
          this.playMediaList = []
          this.docDuration = 0
        }
      } else {
        this.playMediaList = []
        this.docDuration = 0
      }
      this.$refs.fullPlayer.updateMediaList(this.playMediaList)
    },
    handleHighlightTime(destWord) {
      this.$refs.textEditor.highlightWord(destWord.id, this.$refs.fullPlayer.isPlaying(), formatDuration(destWord.audioStart))
      if (this.needScrollToPlayWord) {
        const destWordDom = document.getElementById(destWord.id)
        if (destWordDom != null) {
          var docEditor = this.$refs.docEditor
          if (destWordDom.offsetTop - docEditor.scrollTop >= 0 && (destWordDom.offsetTop + destWordDom.offsetHeight - docEditor.scrollTop) <= docEditor.offsetHeight) {
            // for logic reserved
          } else {
            this.$refs.docEditor.scrollTo({
              top: destWordDom.offsetTop + 51,
              behavior: 'smooth'
            })
          }
          this.needScrollToPlayWord = false
        }
      }
      // if (this.lastWord != null) {
      //   removeClass(this.lastWord, 'word-selected')
      //   removeClass(this.lastWord, 'word-playhead')
      //   this.lastWord = null
      //   this.lastWordId = ''
      // }
      // this.lastWordId = destWordId
      // this.lastWord = destWord
      // if (destWord) {
      //   const word = this.wordMap.get(destWordId)
      //   if (word == null) {
      //     return
      //   }
      //   if (this.$refs.fullPlayer.isPlaying()) {
      //     this.setHighlightCss(destWord, 'word-selected', formatDuration(word.audioStart))
      //     if (this.needScrollToPlayWord) {
      //       var docEditor = this.$refs.docEditor
      //       if (destWord.offsetTop - docEditor.scrollTop >= 0 && (destWord.offsetTop + destWord.offsetHeight - docEditor.scrollTop) <= docEditor.offsetHeight) {
      //       } else {
      //         this.$refs.docEditor.scrollTo({
      //           top: destWord.offsetTop + 51,
      //           behavior: 'smooth'
      //         })
      //       }
      //       this.needScrollToPlayWord = false
      //     }
      //   } else {
      //     this.setHighlightCss(destWord, 'word-playhead', formatDuration(word.audioStart))
      //   }
      // }
    },
    getMediaCurrentTime: function () {
      return this.$refs.fullPlayer.getCurrentTime()
    },
    isDotWord: function(word) {
      return word.audioStart === word.audioEnd
    },
    pauseMedia: function () {
      this.$refs.fullPlayer.pause()
    },
    playMedia: function () {
      if (this.hasReferer) {
        this.$refs.fullPlayer.play()
      }
    },
    setHighlightCss(dom, className, dataTime) {
      try {
        // document.getElementsByClassName('word-selected').forEach(ele => {
        //   if (ele !== dom) {
        //     removeClass(ele, 'word-selected')
        //     addClass(ele, 'word-normal')
        //     if (ele.style.backgroundColor === 'rgba(237, 237, 237, 0.8)') {
        //       ele.style.backgroundColor = ''
        //     }
        //   }
        // })
        // document.getElementsByClassName('word-playhead').forEach(ele => {
        //   if (ele !== dom) {
        //     removeClass(ele, 'word-playhead')
        //     addClass(ele, 'word-normal')
        //     if (ele.style.backgroundColor === 'rgba(237, 237, 237, 0.8)') {
        //       ele.style.backgroundColor = ''
        //     }
        //   }
        // })
        // var _this = this
        // setTimeout(() => {
        // if (_this.isDestroyed) {
        //   return
        // }
        removeClass(dom, 'word-selected')
        removeClass(dom, 'word-playhead')
        // if (className === 'word-selected' && dom.style.backgroundColor === 'rgba(237, 237, 237, 0.8)') {
        //   dom.style.backgroundColor = ''
        // }
        // if (className === 'word-playhead' && dom.style.backgroundColor === '') {
        //   dom.style.backgroundColor = 'rgba(237, 237, 237, 0.8)'
        // }
        addClass(dom, className)
        dom.setAttribute('data-time', dataTime)
        // }, 0)
      } catch (error) {
        console.log('%c⧭', 'color: #e57373', error)
      }
    },
    updateHighlightWordTime: function () {
      if (this.lastWordId != null) {
        var word = this.wordMap.get(this.lastWordId)
        var wordDom = document.getElementById(this.lastWordId)
        if (word != null && wordDom != null) {
          wordDom.setAttribute('data-time', formatDuration(word.audioStart))
        }
      }
    },
    buildSaveResult: function () {
      let editResult = []
      var docContent = this.editor.state.doc.content
      for (var i = 0; i < docContent.childCount; i++) {
        let paraNode = docContent.child(i)
        let block = {}
        if (paraNode.type.name === 'paragraph') {
          block.type = 'wordList'
          block.wordList = []
          for (var j = 0; j < paraNode.content.childCount; j++) {
            let wordNode = paraNode.content.child(j)
            if (wordNode.type.name === 'text') {
              let wordId = getWordNodeId(wordNode)
              if (wordId) {
                let word = this.wordMap.get(wordId)
                if (word != null) {
                  word.del = 0
                  word.word = wordNode.text
                  if (word.type === 'silence') {
                    word.word = ''
                  }
                  word.style = getWordNodeStyle(wordNode)
                  block.wordList.push(word)
                }
              }
            } else if (wordNode.type.name === 'delete_words') {
              let wordId = wordNode.attrs.id
              if (wordId) {
                let deleteWords = this.wordMap.get(wordId)
                if (deleteWords != null) {
                  deleteWords.forEach(word => { word.del = 1 })
                  block.wordList.push(...deleteWords)
                }
              }
            }
          }
        } else if (paraNode.type.name === 'heading') {
          block.type = 'h' + paraNode.attrs.level
          block.title = paraNode.textContent
        } else {
          continue
        }
        editResult.push(block)
      }
      return {
        content: editResult
      }
    },
    saveCurrentDoc: function (isAutoSave, successCallback) {
      var step = this.modifyStep
      this.modifyStep = 0
      var editResult = this.buildSaveResult()
      var param = {
        id: this.currentDoc.docId,
        title: this.currentDoc.docTitle,
        blockJson: JSON.stringify(editResult.content),
        mediaJson: JSON.stringify(this.mediaList),
        playListJson: JSON.stringify(this.playMediaList),
        historyDesc: isAutoSave ? '自动保存' : '手动保存'
      }
      this.saving = true
      modifyAudioDoc(param).then(res => {
        this.saving = false
        if (res.data.code === 0) {
          if (!isAutoSave) {
            this.$message('保存成功', 'success')
          }
          this.currentDocDetail.currentVersion = res.data.data.version
          if (successCallback) {
            successCallback()
          }
        } else {
          if (!isAutoSave) {
            this.$message(res.data.desc, 'error')
          }
          this.modifyStep += step
        }
      }).catch(err => {
        console.log(err)
        this.saving = false
        this.modifyStep += step
      })
    },
    getMessage: function () {
      this.$refs.visitorbutton.refreshInfo()
    },
    onDocDataView: function() {
      this.$refs.dataView.init(true)
      this.$refs.visitorbutton.hideVisitorDetail()
    },
    showDetailDataView: function(val) {
      this.$refs.dataView.init(false)
      this.$refs.visitorbutton.hideVisitorDetail()
      this.$refs.dataView.toDetail(val.userId, val)
    },
    dataviewClose: function() {
      this.$refs.fullPlayer.showhot = false
    },
    setHeatMap: function (arr) {
      this.$refs.fullPlayer.showhot = true
      this.$refs.fullPlayer.canvasResize(arr)
    },
    // linkClick: function (time) {
    //   updateClick({docId: this.currentDoc.docId, userId: getUid(), clickTime: time})
    // }
  }
}
</script>
<style scoped lang="scss">
.content {
  display: flex;
  flex-direction: column;
  font-family: PingFangSC-Regular, PingFang SC;
  position: relative;
  .navbar {
    height: 70px;
    min-height: 70px;
    display: flex;
    flex-direction: row;
    align-items: center;
    -webkit-app-region: drag;
    .logo {
      margin-left: 20px;
      width: 117px;
      display: flex;
      flex-direction: row;
      align-items: center;
      .logo-image {
        width: 34px;
        height: 34px;
      }
      .logo-font {
        margin-left: 8px;
        width: 75px;
        height: 22px;
      }
    }
    .back-button {
      margin-left: 80px;
      width: 32px;
      height: 32px;
      cursor: pointer;
      -webkit-app-region: no-drag;
      &:hover {
        background: rgba(245,247,251,1);
        border-radius: 4px;
      }
      &:active {
        background: rgba(239,241,245,1);
        border-radius: 4px;
      }
    }
    .doc-switcher {
      margin-left: 20px;
      width: 300px;
      height: 38px;
      background: rgba(255,255,255,1);
      border: 1px solid rgba(232,232,232,1);
      border-radius: 8px;
      display: flex;
      flex-direction: row;
      align-items: center;
      -webkit-app-region: no-drag;
      .title {
        margin: 0 10px;
        flex-grow: 1;
        font-size: 14px;
        font-weight: 500;
        color: rgba(51,51,51,1);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 0;
      }
      .switch-button {
        margin-right: 10px;
        width: 52px;
        height: 24px;
        background: rgba(245,247,251,1);
        border-radius: 4px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        & div {
          font-size: 12px;
          font-weight: normal;
          color: rgba(51,51,51,1);
        }
        & img {
          margin-left: 4px;
          width: 8px;
          height: 6px;
        }
        &:hover {
          background: rgba(243,245,247,1);
        }
        &:active {
          background: rgba(237,240,243,1);
        }
      }
    }
    .space {
      flex-grow: 1;
    }
    .share-button {
      margin-left: 14px;
      width: 80px;
      height: 38px;
      background: rgba(255,255,255,1);
      border: 1px solid rgba(235,235,235,1);
      border-radius: 8px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      -webkit-app-region: no-drag;
      .icon {
        width: 18px;
        height: 18px;
      }
      .text {
        margin-left: 8px;
        font-size: 14px;
        font-weight: 500;
        color: rgba(51,51,51,1);
      }
      &:hover {
        background: rgba(245,247,251,1);
      }
      &:active {
        background: rgba(239,241,245,1);
      }
    }
    .vline {
      width: 1px;
      height: 12px;
      background: rgba(216,216,216,1);
      margin-left: 16px;
      margin-right: 16px;
      position: relative;
    }
  }
  .line {
    height: 1px;
    background: rgba(216,216,216,0.5);
  }
  .main-panel {
    margin: 0 0px 0px 20px;
    height: calc(100vh - 71px);
    display: flex;
    flex-direction: row;
    .main-vline {
      margin-left: 18px;
      width: 1px;
      min-width: 1px;
      background: rgba(216,216,216,0.5);
    }
    .doc-editor-panel {
      display: flex;
      flex-direction: column;
      position: relative;
      .toolbar {
        margin: 14px 14px 14px 14px;
        display: flex;
        flex-direction: row;
        align-items: center;
        .action-button {
          margin: 0 6px;
          padding: 0;
          width: 24px;
          height: 24px;
          cursor: pointer;
          background: transparent;
          & img {
            width: 24px;
            height: 24px;
          }
          &:hover {
            background: rgba(245,247,251,1);
            border-radius: 4px;
          }
          &:disabled {
            cursor: default;
            background: transparent;
          }
        }
        .first-button {
          margin-left: 0;
        }
        .action-button-active {
          background: rgba(245,247,251,1);
          border-radius: 4px;
        }
        .vline {
          width: 1px;
          height: 16px;
          background: rgba(220,220,220,1);
          margin: 0 4px;
        }
        .space {
          flex-grow: 1;
        }
        .save-button {
          position: relative;
          .status {
            position: absolute;
            width: 11px;
            height: 11px;
            right: 1px;
            bottom: 1px;
          }
          .status-saving {
            animation: rotate 1s linear infinite;
            -webkit-animation: rotate 1s linear infinite;
          }
        }
      }
      .doc-editor {
        height: calc(100vh - 148px);
        overflow-y: scroll;
        overflow-x: hidden;
        display: flex;
        flex-direction: column;
        position: relative;
        // &::-webkit-scrollbar {
        //   // display: block;
        //   width: 6px;
        // }
        &::-webkit-scrollbar-thumb {
          height: 48px;
          background: #E1E4ED;
          border-radius: 3px;
        }
        // &::-webkit-scrollbar-track {
        //   background: white;
        //   border-radius: 3px;
        // }
        .doc-title-input {
          margin-left: 18px;
          margin-top: 10px;
          margin-bottom: 12px;
          & input {
            font-size: 24px;
            font-weight: 500;
            color: rgba(49, 49, 49, 1);
            border: 0;
            width: calc(100% - 73px);
          }
          & input::placeholder {
            color: rgba(74,74,74,0.2);
          }
          & input:focus {
            outline: none;
          }
        }
        .doc-status {
          display: flex;
          flex-direction: column;
          align-items: center;
          .image {
            margin-top: 200px;
            width: 100px;
            height: 100px;
          }
          .status-line {
            margin-top: 8px;
            display: flex;
            flex-direction: row;
            align-items: center;
            .text {
              font-size: 12px;
              font-weight: normal;
              color: rgba(102,102,102,1);
            }
            .text-red {
              color: rgba(254,89,89,1);
            }
            .retry-button {
              margin-left: 8px;
              width: 58px;
              height: 24px;
              background: rgba(255,255,255,1);
              border: 1px solid rgba(235,235,235,1);
              border-radius: 4px;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 12px;
              font-weight: normal;
              color: rgba(0,112,255,1);
              cursor: pointer;
              &:hover {
                background: rgba(243,245,247,1);
              }
              &:active {
                background: rgba(237,240,243,1);
              }
            }
          }
        }
      }
      .editor-loading-area {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: rgba(255,255,255,1);
        z-index: 100;
        display: flex;
        align-items: center;
        justify-content: center;
        & img {
          width: 64px;
          height: 64px;
          -webkit-animation: changeright 1s linear infinite;
        }
      }
    }
  }
  .loading-area {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 71px;
    background: rgba(255,255,255,1);
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    & img {
      width: 64px;
      height: 64px;
      -webkit-animation: changeright 1s linear infinite;
    }
  }
  @-webkit-keyframes changeright {
    0% {
      -webkit-transform: rotate(0deg);
    }
    50% {
      -webkit-transform: rotate(180deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
}
</style>
