// https://github.com/regexhq/hex-color-regex/blob/master/index.js
function hexColorRegex() {
  return /#([a-f0-9]{3}|[a-f0-9]{4}(?:[a-f0-9]{2}){0,2})\b/gi
}

export const COLOR_SET = [
  '#fce9a9',
  '#febbd9',
  '#fed1ba',
  '#b7f3c3',
  '#b6e5fa',
  '#d4c5fd'
]

export function isHexColor(color) {
  return hexColorRegex().test(color)
}

export function colorHex(color) {
  // RGB颜色值的正则
  var reg = /^(rgb|RGB)/
  if (reg.test(color)) {
    var strHex = '#'
    // 把RGB的3个数值变成数组
    var colorArr = color.replace(/(?:\(|\)|rgb|RGB)*/g, '').split(',')
    // 转成16进制
    for (var i = 0; i < colorArr.length; i++) {
      var hex = Number(colorArr[i]).toString(16)
      if (hex === '0') {
        hex += hex
      }
      strHex += hex
    }
    return strHex
  } else {
    return String(color)
  }
}
