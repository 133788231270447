export function isMacOS () {
  return detectOS() === 'mac'
}

export function isWinOS () {
  return detectOS() === 'windows'
}

export function detectOS() {
  var isWin = (navigator.platform === 'Win32') || (navigator.platform === 'Windows')
  var isMac = (navigator.platform === 'Mac68K') || (navigator.platform === 'MacPPC') || (navigator.platform === 'Macintosh') || (navigator.platform === 'MacIntel')
  if (isMac) return 'mac'
  if (isWin) return 'windows'
  return 'other'
}
