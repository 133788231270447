export default function openUriWithInputTimeoutHack(uri, failCb, successCb) {
  let target = document.createElement('input')
  target.style.position = 'fixed'
  target.style.opacity = '0'
  document.body.appendChild(target)
  target.focus()
  var handler = _registerEvent(target, "blur", onBlur)

  function onBlur() {
    successCb && successCb()
    handler.remove()
    clearTimeout(timeout)
    document.body.removeChild(target)
  }

  //will trigger onblur
  if (Array.isArray(uri)) {
    uri.forEach(function (u) {
      location.href = u
    })
  } else {
    location.href = uri
  }
  

  // Note: timeout could vary as per the browser version, have a higher value
  var timeout = setTimeout(function () {
    console.log('setTimeout')
    failCb && failCb()
    handler.remove()
    document.body.removeChild(target)
  }, 1000)
}

function _registerEvent(target, eventType, cb) {
  if (target.addEventListener) {
    target.addEventListener(eventType, cb)
    return {
      remove: function () {
        target.removeEventListener(eventType, cb)
      }
    }
  } else {
    target.attachEvent(eventType, cb)
    return {
      remove: function () {
        target.detachEvent(eventType, cb)
      }
    }
  }
}

// 测试
// let protocalUrl = `moyin:?a=1&b=2`
// openUriWithInputTimeoutHack(protocalUrl, () => {
//   console.log('检测到，未安装客户端')
// }, () => {
//     // 浏览器弹窗提示
//   console.log('检测到：已安装了客户端')
// })