import fs from 'fs'
import { isWinOS } from './os'

export function parseFileName (path) {
  var pos = isWinOS() ? path.lastIndexOf('\\') : path.lastIndexOf('/')
  var tempPath = path
  if (pos !== -1) {
    tempPath = path.substr(pos + 1)
  }
  var dotPos = tempPath.lastIndexOf('.')
  if (dotPos !== -1) {
    return tempPath.substr(0, dotPos)
  } else {
    return tempPath
  }
}

export function parseFileNameWithExt (path) {
  var pos = isWinOS() ? path.lastIndexOf('\\') : path.lastIndexOf('/')
  if (pos !== -1) {
    return path.substr(pos + 1)
  } else {
    return path
  }
}

export function parseFileNameForDoc (path) {
  var fileName = parseFileName(path)
  return fileName.replace(/_/g, ':')
}

var SEPERATOR = isWinOS() ? '\\' : '/'

var SIGN_REGEXP = /([yMdhsmS])(\1*)/g
var DEFAULT_PATTERN = 'yyyy-MM-dd'

function padding (s, len) {
  var len2 = len - (s + '').length
  for (var i = 0; i < len2; i++) {
    s = '0' + s
  }
  return s
}

export function formatDate (date, pattern) {
  pattern = pattern || DEFAULT_PATTERN
  return pattern.replace(SIGN_REGEXP, function ($0) {
    switch ($0.charAt(0)) {
      case 'y': {
        return padding(date.getFullYear(), $0.length)
      }
      case 'M': {
        return padding(date.getMonth() + 1, $0.length)
      }
      case 'd': {
        return padding(date.getDate(), $0.length)
      }
      case 'w': {
        return date.getDay() + 1
      }
      case 'h': {
        return padding(date.getHours(), $0.length)
      }
      case 'm': {
        return padding(date.getMinutes(), $0.length)
      }
      case 's': {
        return padding(date.getSeconds(), $0.length)
      }
    }
  })
}

export function formatDuration (time) {
  var str = ''
  var hour = time >= 3600000 ? parseInt(time / 3600000) : 0
  var minute = time >= 60000 ? parseInt((time % 3600000) / 60000) : 0
  var second = time >= 1000 ? parseInt((time % 60000) / 1000) : 0

  if (hour > 0) {
    str += padding(hour, 2) + ':'
  }
  str += padding(minute, 2) + ':' + padding(second, 2)
  return str
}

export function formatHistoryTime (date) {
  var month = date.getMonth() + 1
  var day = date.getDate()
  var hour = date.getHours()
  var minutes
  if (date.getMinutes() >= 10) {
    minutes = date.getMinutes()
  } else {
    minutes = '0' + date.getMinutes()
  }
  return month + '月' + day + '日 ' + hour + ':' + minutes
}

export function generateVideoPath (videoDir, name, ext) {
  var replaceName = name.replace(/:/g, '_')
  var dstName = replaceName
  var posfix = ext ? '.' + ext : '.webm'
  if (!replaceName.endsWith(posfix)) {
    dstName = replaceName + posfix
  }
  var myDir = ''
  if (videoDir.endsWith(SEPERATOR)) {
    myDir = videoDir + '魔音助手' + SEPERATOR
  } else {
    myDir = videoDir + SEPERATOR + '魔音助手' + SEPERATOR
  }
  if (!fs.existsSync(myDir)) {
    fs.mkdirSync(myDir)
  }
  return myDir + dstName
}

export function formatSize (bytes) {
  if (bytes >= 1024 * 1024 * 1024) {
    var gb = bytes / (1024 * 1024 * 1024)
    return gb.toFixed(1) + 'GB'
  } else if (bytes >= 1024 * 1024) {
    var mb = bytes / (1024 * 1024)
    return mb.toFixed(1) + 'MB'
  } else if (bytes >= 1024) {
    var kb = bytes / 1024
    return kb.toFixed(1) + 'KB'
  } else {
    return bytes + 'B'
  }
}

export function formatHighlightTitle (title, splitKeywords) {
  if (splitKeywords != null && splitKeywords.length > 0) {
    var notEmptyWords = []
    splitKeywords.forEach(word => {
      if (word != null && word.length > 0) {
        notEmptyWords.push(word)
      }
    })
    if (notEmptyWords.length > 0) {
      return title.replace(new RegExp('(' + notEmptyWords.join('|') + ')', 'g'), '<span style="color: rgba(0,112,255,1);">$1</span>')
    }
  }
  return title
}

export function testUrl(url) {
  var reg = new RegExp('((http|https)://)(www.)?[a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)')
  return reg.test(url)
}
