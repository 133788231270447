export function filterHighlightClass(className) {
  return (' ' + className + ' ').replace(' word-selected ', ' ').replace(' word-playhead ', ' ').trim()
}

export function addHighlightClass(className, isPlaying) {
  return className + ' ' + (isPlaying ? 'word-selected' : 'word-playhead')
}

export function getClass(elem) {
  return (elem.getAttribute && elem.getAttribute('class')) || ''
}

export function hasClass(elem, clzName) {
  var curClass = ' ' + getClass(elem) + ' '
  return curClass.indexOf(' ' + clzName + ' ') > -1
}
export function addClass(elem, clzName) {
  if (!hasClass(elem, clzName)) {
    elem.className = elem.className + ' ' + clzName
  }
}
export function removeClass(elem, clzName) {
  var curClass = ' ' + getClass(elem) + ' '
  elem.className = curClass.replace(' ' + clzName + ' ', ' ').trim()
}
export function adjustClass(elem, removeClzNames, addClzNames) {
  var curClass = ' ' + getClass(elem) + ' '
  if (removeClzNames != null && removeClzNames.length > 0) {
    removeClzNames.forEach(name => {
      curClass = curClass.replace(' ' + name + ' ', ' ')
    })
  }
  if (addClzNames != null && addClzNames.length > 0) {
    addClzNames.forEach(name => {
      if (curClass.indexOf(' ' + name + ' ') === -1) {
        curClass = curClass + ' ' + name
      }
    })
  }
  elem.className = curClass.trim()
}

export function scrollToViewArea(ele, target, offset) {
  if (!ele) {
    return
  }
  var pix = 0
  if (offset) pix = offset
  const rect = ele.getBoundingClientRect()
  const targetRect = target.getBoundingClientRect()
  if (rect.bottom + pix > targetRect.bottom) {
    target.scrollTo({
      top: target.scrollTop + rect.bottom - targetRect.bottom + 40 + pix,
      behavior: 'smooth'
    })
  } else if (rect.top < targetRect.top) {
    target.scrollTo({
      top: target.scrollTop + rect.top - targetRect.top - 40,
      behavior: 'smooth'
    })
  }
}
