<template>
  <div class="bg" v-show="visible" @click="hide">
    <div class="dialog" @click.stop>
      <div class="title">编辑头像</div>
      <img class="close-button" src="../../../assets/homepage/dialog-close.svg" @click="hide" />
      <div class="box-area">
        <div class="preview">
          <div class="avatar">
            <img v-if="previews !== ''" :src="previews" />
            <img v-else src="../../../assets/homepage/default-avatar.svg" />
          </div>
          <div v-show="!showCropper" class="tip">预览</div>
          <label v-show="showCropper" class="reselect-button" for="uploads">重新上传</label>
        </div>
        <div class="cropper-area">
          <div v-show="!showCropper" class="select-area">
            <label class="select-button" for="uploads">选择图片</label>
            <div class="tip">支持jpg、png的图片，大小不超过3M</div>
            <input type="file" :value="userSelectFile" id="uploads" style="position:absolute; clip:rect(0 0 0 0);" accept="image/png, image/jpeg, image/gif, image/jpg" @change="selectImg($event)">
          </div>
          <div v-show="showCropper" class="cropper-wrap">
            <vue-cropper
              ref="cropper"
              :img="option.img"
              :outputSize="option.outputSize"
              :outputType="option.outputType"
              :info="option.info"
              :canScale="option.canScale"
              :autoCrop="option.autoCrop"
              :autoCropWidth="option.autoCropWidth"
              :autoCropHeight="option.autoCropHeight"
              :fixed="option.fixed"
              :fixedNumber="option.fixedNumber"
              :full="option.full"
              :fixedBox="option.fixedBox"
              :canMove="option.canMove"
              :canMoveBox="option.canMoveBox"
              :original="option.original"
              :centerBox="option.centerBox"
              :height="option.height"
              :infoTrue="option.infoTrue"
              :maxImgSize="option.maxImgSize"
              :enlarge="option.enlarge"
              :mode="option.mode"
              @realTime="realTime"
              @imgLoad="imgLoad"
              />
            <span class="cropper-tip">鼠标拖拽调整位置、滚轮控制大小</span>
          </div>
        </div>
      </div>
      <div class="line"></div>
      <div class="buttons">
        <div class="cancel-button" @click="hide">取消</div>
        <div class="confirm-button" @click="uploadImgToQiniu">确定</div>
      </div>
    </div>
  </div>
</template>
<script>
import { VueCropper } from 'vue-cropper'
import { getUserInfo, setUserInfo } from '@/utils/auth'
import { modifyUserInfo, uploadImgToServer } from '@/api/user'
export default {
  name: 'AvatarDialog',
  components: {
    VueCropper
  },
  data () {
    return {
      visible: false,
      showCropper: false,
      userInfo: {},
      users: {},
      userSelectFile: '',
      previews: '',
      option: {
        img: '', // 裁剪图片的地址
        outputSize: 1, // 裁剪生成图片的质量(可选0.1 - 1)
        outputType: 'jpeg', // 裁剪生成图片的格式（jpeg || png || webp）
        info: false, // 图片大小信息
        canScale: true, // 图片是否允许滚轮缩放
        autoCrop: true, // 是否默认生成截图框
        autoCropWidth: 206, // 默认生成截图框宽度
        autoCropHeight: 206, // 默认生成截图框高度
        fixed: true, // 是否开启截图框宽高固定比例
        fixedNumber: [1, 1], // 截图框的宽高比例
        full: false, // false按原比例裁切图片，不失真
        fixedBox: true, // 固定截图框大小，不允许改变
        canMove: true, // 上传图片是否可以移动
        canMoveBox: false, // 截图框能否拖动
        original: false, // 上传图片按照原始比例渲染
        centerBox: false, // 截图框是否被限制在图片里面
        height: true, // 是否按照设备的dpr 输出等比例图片
        infoTrue: false, // true为展示真实输出图片宽高，false展示看到的截图框宽高
        maxImgSize: 3000, // 限制图片最大宽度和高度
        enlarge: 1, // 图片根据截图框输出比例倍数
        mode: 'cover' // 图片默认渲染方式
      }
    }
  },
  mounted () {
    this.updateUserInfo()
  },
  methods: {
    updateUserInfo () {
      this.userInfo = getUserInfo()
      if (this.userInfo != null) {
        this.users = this.userInfo.user
      }
    },
    show () {
      this.visible = true
    },
    hide () {
      this.visible = false
      this.showCropper = false
      this.userSelectFile = ''
      this.previews = ''
      this.option.img = ''
    },
    // 初始化函数
    imgLoad (msg) {
      console.log('VueCropper初始化函数:' + msg)
    },
    // 图片缩放
    changeScale (num) {
      num = num || 1
      this.$refs.cropper.changeScale(num)
    },
    // 实时预览函数
    realTime () {
      this.$refs.cropper.getCropBlob(async (data) => {
        this.previews = URL.createObjectURL(data)
      })
    },
    // 选择图片
    selectImg (e) {
      const file = e.target.files[0]
      const fileSize = file && file.size
      if (!file || !e.target.value) {
        return false
      }
      if (!/\.(jpg|jpeg|png|JPG|JPEG|PNG)$/.test(e.target.value)) {
        this.$message('图片类型要求：jpg、jpeg、png', 'error')
        return false
      }
      if (fileSize >= (1024 * 1024 * 5)) {
        this.$message('图片大小要求：在3M以内', 'error')
        return false
      }
      // 转化为blob
      let reader = new FileReader()
      reader.onload = (e) => {
        let data = ''
        if (typeof e.target.result === 'object') {
          data = window.URL.createObjectURL(new Blob([e.target.result]))
        } else {
          data = e.target.result
        }
        this.option.img = data
      }
      // 转化为base64
      reader.readAsDataURL(file)
      this.showCropper = true
    },
    blobToDataURL(blob) {
      return new Promise((resolve) => {
        let reader = new FileReader()
        reader.readAsDataURL(blob)
        reader.onload = () => {
          resolve(reader.result)
        }
      })
    },
    // 上传图片到七牛
    uploadImgToQiniu() {
      // 获取截图的blob数据
      this.$refs.cropper.getCropBlob((data) => {
        this.blobToDataURL(data).then((readableStream) => {
          uploadImgToServer({imgBase64: readableStream}).then((res) => {
            if (res.data.code === 0) {
              this.uploadUserAvatar(res.data.data)
            } else {
              this.$message(res.data.desc, 'error')
            }
          }).catch((err) => {
            console.log(179, err)
            this.$message('未知错误', 'error')
          })
        })
      })
    },
    uploadUserAvatar(imageUrl) {
      modifyUserInfo({
        userId: this.users.id,
        imageUrl: imageUrl
      }).then(response => {
        if (response.data.code === 0) {
          this.hide()
          this.users.imageUrl = imageUrl
          this.userInfo.user = this.users
          setUserInfo(this.userInfo)
          this.$emit('updateUserInfo')
          this.$message('修改成功', 'success')
        } else {
          this.$message(response.data.desc, 'error')
        }
      })
    }
  }
}
</script>
<style scoped lang="scss">
/deep/ .vue-cropper {
  background: none;
}
/deep/ .cropper-crop-box {
  border: 2px dotted #fff !important;
  .cropper-view-box {
    outline: none;
  }
}
.bg {
  position: fixed;
  font-family: PingFangSC-Regular, PingFang SC;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,0.1);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  .dialog {
    display: flex;
    flex-direction: column;
    width: 532px;
    background: rgba(255,255,255,1);
    border: 1px solid rgba(224,224,224,1);
    border-radius: 8px;
    box-shadow:  0 0 10px 0 rgba(0,0,0,0.16);
    position: relative;
    .title {
      height: 58px;
      padding-left: 32px;
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 16px;
      font-weight: 500;
      color: rgba(51,51,51,1);
    }
    .close-button {
      position: absolute;
      top: 14px;
      right: 14px;
      width: 28px;
      height: 28px;
      cursor: pointer;
    }
    .box-area {
      margin: 9px auto 16px auto;
      display: flex;
      flex-direction: row;
      .preview {
        margin-right: 16px;
        width: 184px;
        height: 268px;
        background: rgba(245,247,251,1);
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .avatar {
          margin-top: 98px;
          width: 72px;
          height: 72px;
          border-radius: 36px;
          overflow: hidden;
          & img {
            width: 72px;
            height: 72px;
          }
        }
        .tip {
          margin-top: 34px;
          font-size: 12px;
          font-weight: normal;
          color: rgba(102,102,102,1);
        }
        .reselect-button {
          margin-top: 24px;
          width: 80px;
          height: 32px;
          background: rgba(0,112,255,1);
          border-radius: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 12px;
          font-weight: 500;
          color: rgba(255,255,255,1);
          cursor: pointer;
        }
      }
      .cropper-area {
        width: 268px;
        height: 268px;
        border-radius: 8px;
        overflow: hidden;
        .select-area {
          width: 268px;
          height: 268px;
          background: rgba(245,247,251,1);
          border-radius: 8px;
          display: flex;
          flex-direction: column;
          align-items: center;
          .select-button {
            margin-top: 116px;
            width: 86px;
            height: 36px;
            background: rgba(0,112,255,1);
            border-radius: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            font-weight: 500;
            color: rgba(255,255,255,1);
            cursor: pointer;
          }
          .tip {
            margin-top: 24px;
            font-size: 12px;
            font-weight: normal;
            color: rgba(102,102,102,1);
          }
        }
        .cropper-wrap {
          position: relative;
          width: 268px;
          height: 268px;
          background: rgba(245,247,251,1);
          .cropper-tip {
            position: absolute;
            bottom: 6px;
            left: 0;
            right: 0;
            text-align: center;
            font-size: 12px;
            font-weight: normal;
            color: rgba(255,255,255,1);
          }
        }
      }
    }
    .line {
      margin: 0 16px;
      height: 1px;
      background: rgba(216,216,216,0.5);
    }
    .buttons {
      height: 68px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      .cancel-button {
        margin: 0 8px;
        width: 86px;
        height: 36px;
        background: rgba(245,247,251,1);
        border: 1px solid rgba(216,216,216,1);
        border-radius: 8px;
        font-size: 14px;
        font-weight: 500;
        color: rgba(51,51,51,1);
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        &:hover {
          background: rgba(237,240,243,1);
        }
        &:active {
          background: rgba(220,222,225,1);
          border: 1px solid rgba(188,189,190,1);
        }
      }
      .confirm-button {
        margin: 0 8px;
        width: 86px;
        height: 36px;
        background: rgba(0,112,255,1);
        border-radius: 8px;
        font-size: 14px;
        font-weight: 500;
        color: rgba(255,255,255,1);
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        &:hover {
          background: rgba(28,113,223,1);
        }
        &:active {
          background: rgba(18,93,188,1);
        }
      }
    }
  }
}
</style>
