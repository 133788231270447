<template>
  <div id="app">
    <transition name="fade">
      <router-view></router-view>
    </transition>
    <img class="top-test-icon" v-if="testEnv" src="./assets/icon/icon_test.svg" />
  </div>
</template>

<script>
import { isTestEnv } from './common/const'
import ahamoment from "ahamoment.js"
export default {
  name: 'moyinhelper-www',
  data () {
    return {
      testEnv: false
    }
  },
  watch: {
    $route () {
      this.testEnv = this.shouldShowTestSign()
    }
  },
  mounted () {
    this.testEnv = this.shouldShowTestSign()
    if (isTestEnv()) {
      ahamoment.init('6329a090a708bcdaf270e2eb')
    } else {
      ahamoment.init('63c4e3205a4bb7049e241fcf')
    }
  },
  methods: {
    shouldShowTestSign: function () {
      return isTestEnv() && (this.$route.path.startsWith('/moyin/homepage') || this.$route.path.startsWith('/moyin/detail') || this.$route.path.startsWith('/login'))
    }
  }
}
</script>
<style>
body {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
html,
body {
  margin: 0px;
  width: 100%;
  height: 100%;
  background: transparent;
  overflow-y: hidden;
  overflow-x: hidden;
}
[contenteditable]:focus {
  outline: none;
}
img {
  -webkit-user-drag: none;
}
button {
  outline: none;
  border: none;
}
button:disabled {
  opacity: .5;
  cursor: not-allowed !important;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .3s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
<style scoped>
#app {
  font-family: PingFangSC-Regular, PingFang SC;
  position: relative;
}
.top-test-icon {
  position: fixed;
  top: unset;
  bottom: 0;
  left: 0;
  width: 32px;
  height: 32px;
  z-index: 10;
}
</style>
