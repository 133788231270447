<template>
<div class="dataview-main"  v-if="showMain">
  <div class="dataview-content item" v-if="!showDetail">
    <p class="dataview-title">数据总览 <img class="close-button" @click="close" src="../../assets/homepage/dialog-close.svg" /></p>
    <div class="dataview-list">
      <div class="data-item" >
        <p class="text">观看次数</p>
        <p class="num">{{dataOverview.pv || 0}}</p>
      </div>
      <div class="data-item">
        <p class="text">播放完成率</p>
        <p class="num"><span>{{parseFloat(dataOverview.watchCompleteRate || '0')}}</span> <span class="fs28">%</span></p>
      </div>
      <div class="data-item" v-if="dataOverview.linkClickRate">
        <p class="text">链接点击率</p>
        <p class="num"><span>{{parseFloat(dataOverview.linkClickRate || '0')}}</span> <span class="fs28">%</span></p>
      </div>
      <div class="user-list">
        <p class="title"><span>独立访客</span> <span>{{viewerNumber}}</span></p>
        <div class="user-item" v-for="item in viewerList" :key="item.userId">
          <div class="items" @click="toDetail(item.userId, item)">
            <div style="display:flex; align-items: center">
              <img class="avatar" :src="item.imageUrl ? item.imageUrl : require('../../assets/detail/visitor-avatar.png')" alt="">
              <span>{{item.nickname}}</span>
            </div>
            <div class="watchDuration">累计观看{{item.watchDuration}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="dataview-detail item" v-else>
    <p class="dataview-title">用户数据 <img class="close-button" @click="close" src="../../assets/homepage/dialog-close.svg" /></p>
    <div class="dataview-list">
      <p class="user-info"><img class="left-arrow" @click="returnMain" src="../../assets/icon/icon_back.svg" alt=""><img class="avatar" 
      style="margin-left: 18px"
      :src="userInfo.imageUrl ? userInfo.imageUrl : require('../../assets/detail/visitor-avatar.png')" alt=""><span>{{userInfo.nickname}}</span></p>
      <div class="detail-item" style="margin-top:17px">
        <p><span>观看次数</span><span class="num">{{userOverview.watchNumber}}</span></p>
      </div>
      <div class="detail-item">
        <p><span>播放完成率</span><span class="num"><span>{{parseFloat(userOverview.watchCompleteRate)}}</span><span class="fs28" style="margin-left:4px">%</span></span></p>
      </div>
      <div class="detail-item">
        <p><span>链接点击次数</span><span class="num">{{userOverview.clickLinkNumber}}</span></p>
      </div>
      <div class="collapse">
        <div class="collapse-item">
            <div class="tab" @click="toLoadmore(0)">
              <p><span>观看记录</span><span><img  class="arrow" src="../../assets/homepage/arrow-down.svg" /></span></p>
            </div>
            <template v-if="viewRecordList.length">
                <div class="viewList"  v-infinite-scroll="loadMoreUserViewRecord">
                      <div class="view-item"  v-for="(item, index) in viewRecordList.slice(0,2)" :key="index+'view'">
                        <p><span class="p1">观看了{{item.viewDuration}}</span><span class="p2">{{formatDocTime(item.viewTime)}}</span></p>
                      </div>
                      <collapse-transition>
                        <div v-show="loadmore[0]">
                          <div class="view-item"  v-for="(item, index) in viewRecordList.slice(2)" :key="index+'view'">
                            <p><span class="p1">观看了{{item.viewDuration}}</span><span class="p2">{{formatDocTime(item.viewTime)}}</span></p>
                          </div>
                        </div>
                      </collapse-transition>
                </div>
                <button class="loadmore" v-show="!loadmore[0]" v-if="viewRecordList.length > 2" @click="toLoadmore(0)">加载更多</button>
            </template>
            <div v-else  style="text-align: center">
                  暂无数据
            </div>
        </div>
        <div class="collapse-item">
             <div class="tab" @click="toLoadmore(1)">
              <p><span>点击记录</span><span><img  class="arrow" src="../../assets/homepage/arrow-down.svg" /></span></p>
            </div>
            <template v-if="clickRecordList.length">
                <div class="viewList" v-infinite-scroll="loadMoreClickRecord">
                      <div class="view-item"  v-for="(item, index) in clickRecordList.slice(0,2)" :key="index+'click'">
                        <p><span>{{formatTime(item.clickTime)}}处点击</span><span>{{formatDocTime(item.createTime)}}</span></p>
                      </div>
                      <collapse-transition>
                        <div v-show="loadmore[1]">
                          <div class="view-item"  v-for="(item, index) in clickRecordList.slice(2)" :key="index+'view'">
                            <p><span>{{formatTime(item.clickTime)}}处点击</span><span>{{formatDocTime(item.createTime)}}</span></p>
                          </div>
                        </div>
                      </collapse-transition>
                </div>
                <button class="loadmore" v-show="!loadmore[1]" v-if="viewRecordList.length > 2" @click="toLoadmore(1)">加载更多</button>
            </template>
            <div v-else  style="text-align: center">
                  暂无数据
            </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import CollapseTransition from './collapse-transition'
import { formatDocTime } from '@/utils/time'
import { docViewData, getWatcherList, getUserData, getUserViewRecord, getUserClickRecord, getVideoHeatmap, getUserHeatmap } from '@/api/doc'
export default {
  name: 'DataView',
  components: {
    CollapseTransition
  },
  props: {
    docId: {
      required: true
    },
    duration: {
      type: Number,
      required: true,
      default: 0
    }
  },
  data: function () {
    return {
      userId: '',
      showDetail: false,
      boxshow: [false, false],
      showMain: false,
      dataOverview: {
        pv: 0, // 观看次数
        watchCompleteRate: '0%', // 观看完成率
        linkClickRate: '0%' // 链接点击率
      },
      userOverview: {
        watchNumber: 0,
        watchCompleteRate: '0%',
        clickLinkNumber: 0
      },
      userInfo: {
        nickname: '',
        imageUrl: ''
      },
      currentPage: {
        page: 0,
        size: 10
      },
      viewerList: [],
      viewerNumber: 0,
      viewCurrentPage: {
        page: 0,
        size: 10
      },
      cilckCurrentPage: {
        page: 0,
        size: 10
      },
      viewRecordList: [],
      clickRecordList: [],
      loadmore: [false, false],
      hotList: [],
      userHotList: []
    }
  },
  methods: {
    close: function () {
      this.showMain = false
      this.showDetail = false
      this.boxshow = [false, false],
      this.$bus.$emit('doc-dataviewClose')
    },
    toshow: function(index) {
      const val = this.boxshow[index]
      this.boxshow = [false, false]
      if (!val) {
        this.boxshow[index] = true
      }
      this.$set(this, 'boxshow', this.boxshow)
    },
    toLoadmore: function (index) {
      const val = this.loadmore[index]
      this.loadmore = [false, false]
      this.loadmore[index] = !val
      this.$set(this, 'loadmore', this.loadmore)
    },
    getData: function () {
      docViewData({docId: this.docId}).then(res => {
        if (res.data.code === 0) {
          this.dataOverview = res.data.data
          console.log(this.dataOverview)
        }
      })
    },
    getWatcherList: function () {
      getWatcherList({docId: this.docId, ...this.currentPage}).then(res => {
        if (res.data.code === 0) {
          this.viewerList = res.data.data.content
          this.viewerNumber = res.data.data.totalElements
        }
      })
    },
    getUserData: function (id) {
      getUserData({docId: this.docId, userId: id}).then(res => {
        if (res.data.code === 0) {
          this.userOverview = res.data.data
          this.showDetail = true
        }
      })
    },
    getUserViewRecord: function (id) {
      getUserViewRecord({docId: this.docId, userId: id, ...this.viewCurrentPage}).then(res => {
        if (res.data.code === 0) {
          const recordList = res.data.data.content
          if (this.viewCurrentPage.page === 0) {
            this.viewRecordList = recordList
          } else {
            this.viewRecordList.push(...recordList)
          }
        }
      })
    },
    formatDocTime: function (val) {
      return formatDocTime(val)
    },
    getUserClickRecord: function(id) {
      getUserClickRecord({docId: this.docId, userId: id, ...this.cilckCurrentPage}).then(res => {
        if (res.data.code === 0) {
          const recordList = res.data.data.content
          if (this.cilckCurrentPage.page === 0) {
            this.clickRecordList = recordList
          } else {
            this.clickRecordList.push(...recordList)
          }
        }
      })
    },
    returnMain: function () {
      this.showDetail = false
      if (this.hotList.length) {
        this.$bus.$emit('doc-setHeatMap', this.hotList)
      } else {
        this.getVideoHeatmap()
      }
    },
    toDetail: function(id, info) {
      this.getUserData(id)
      this.getUserViewRecord(id)
      this.getUserClickRecord(id)
      this.setInfo(info)
      this.getUserHeatmap(id)
      this.userId = id
    },
    init: function (flag) {
      this.getData()
      this.getWatcherList()
      this.showMain = true
      this.loadmore = [false, false]
      if (flag) {
        this.getVideoHeatmap()
      }
    },
    loadMoreUserViewRecord () {
      this.viewCurrentPage.page++
      this.getUserViewRecord(this.userId)
    },
    loadMoreClickRecord () {
      this.cilckCurrentPage.page++
      this.getUserClickRecord(this.userId)
    },
    setInfo: function (info) {
      if (info.nickName) {
        info.nickname = info.nickName
      }
      if (info.headImageUrl) {
        info.imageUrl = info.headImageUrl
      }
      this.$set(this, 'userInfo', info)
    },
    getVideoHeatmap: function () {
      getVideoHeatmap({docId: this.docId}).then(res => {
        if (res.data.code === 0) {
          this.hotList = res.data.data
          this.$bus.$emit('doc-setHeatMap', this.hotList)
        }
      })
    },
    getUserHeatmap: function (id) {
      getUserHeatmap({docId: this.docId, userId: id}).then(res => {
        if (res.data.code === 0) {
          this.userHotList = res.data.data
          this.$bus.$emit('doc-setHeatMap', this.userHotList)
        }
      })
    },
    formatTime: function (val) {
      const arr = val.split(':')
      if (this.duration > 60 * 60 * 10000) {
        return val
      } else {
        arr.shift()
        return arr.join(':')
      }
    }
  }
}
</script>

<style scoped lang="scss">
$color1: rgba(209,241,210,1);
$color2: rgba(255,240,214,1);
.dataview-main {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: all 0.3s;
  background: rgba(255,255,255,1);
  // border: 1px solid rgba(224,224,224,1);
  // border-radius: 6px;
  padding: 14px;
  .item{
    display: flex;
    flex-direction: column;
    height: 100%;
    border: 1px solid rgba(224,224,224,1);
    position: relative;
    box-sizing: border-box;
    border-radius: 6px;
  }
  p {
    margin: 0;
  }
}
.dataview-title {
  height: 16px;
  line-height: 16px;
  padding: 21px;
  border-bottom: 1px solid rgba(245,245,245,1);
}
.close-button {
  position: absolute;
  right:13px;
  top: 13px;
  cursor: pointer;
}
.user-item {
  padding: 25px 0px;
  .items {
    line-height: 16px;
    height: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }
}
.user-list {
  .title{
    height: 16px;
    font-size: 16px;
    font-family: PingFangSC;
    font-weight: 500;
    line-height: 16px;
    color: rgba(51,51,51,1);
    margin-bottom: 14px;
    margin-top: 28px;
  }
}
.data-item {
  height: 118px;
  background: rgba(214,226,255,1);
  border-radius: 4px;
  padding: 20px;
  box-sizing: border-box;
  margin-bottom: 14px;
  .text {
    font-size: 16px;
    font-family: PingFangSC;
    font-weight: normal;
    color: rgba(51,51,51,1);
    line-height: 16px;
    height: 16px;
    margin-bottom: 19px;
  }
  .num {
    display: flex;
    align-items: baseline;
    line-height: 38px;
  }
  &:nth-child(2) {
      background: $color1;
  }
  &:nth-child(3) {
      background: $color2;
  }
}
.num{
  font-size: 38px;
  font-family: DIN;
  font-weight: 500;
  color: rgba(51,51,51,1);
  height: 38px;
  .fs28 {
    font-size: 28px;
  }
}
.detail-item {
  height: 64px;
  background: rgba(214,226,255,1);
  border-radius: 4px;
  box-sizing: border-box;
  margin-bottom: 14px;
  padding: 24px 20px;
  p {
    font-size: 16px;
    line-height: 16px;
    display: flex;
    justify-content: space-between;
  }
  &:nth-child(3) {
      background: $color1;
  }
  &:nth-child(4) {
      background: $color2;
  }
}
.collapse-item {
  .tab {
    cursor: pointer;
    padding: 14px 20px;
    p {
      height: 16px;
      font-size: 16px;
      font-family: PingFangSC;
      font-weight: 500;
      color: rgba(51,51,51,1);
      line-height: 16px;
      display: flex;
      justify-content: space-between;
    }
  }
}
.viewList{
  overflow: hidden;
  transition: height  0.5s;
  background: linear-gradient(180deg, rgba(255,255,255,1) 0%,rgba(247,247,247,1) 100%);
  .view-item{
    p {
      display: flex;
      justify-content: space-between;
      height: 64px;
      line-height: 64px;
      padding: 0 20px;
    }
    span{
      font-size: 14px;
      font-family: PingFangSC;
      font-weight: normal;
      color: rgba(51,51,51,1);
    }
    .p2{
      color: rgba(102,102,102,1);
    }
    // &:last-child {
    //   background: linear-gradient(180deg, rgba(255,255,255,1) 0%,rgba(247,247,247,1) 100%);
    // }
  }
    
}

.draw-enter, .draw-leave-to {
    height: 0;
}
.user-info {
  display: flex;
  align-items: center;
  
}
.dataview-list {
  padding: 16px;
  overflow: auto;
  flex: 1;
  &::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }
}
.left-arrow {
  cursor: pointer;
}
.avatar {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  margin-right: 10px;
}
.watchDuration {
  height: 14px;
  font-size: 14px;
  font-family: PingFangSC;
  font-weight: normal;
  color: rgba(102,102,102,1);
}
.loadmore {
  display: block;
  margin: 22px auto;
  width: 328px;
  height: 44px;
  background: rgba(236,236,239,1);
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}
</style>