<template>
  <div ref='component' class="component" @click="showPicker" v-clickoutside="hidePicker">
    <div class="text">{{ value }}</div>
    <div class="color">
      <div class="preview" :style="{ 'backgroundColor': value }"></div>
      <img class="icon" src="../../assets/detail/icon-color.png" />
    </div>
    <div class="selector" v-show="pickerVisible" :style="{ top: showFromBottom ? '44px' : '', bottom: showFromBottom ? '' : '46px' }" >
      <color-picker v-model="pickerColor" />
    </div>
  </div>
</template>
<script>
import { Sketch } from 'vue-color'

export default {
  name: 'ColorSelector',
  components: {
    'color-picker': Sketch
  },
  props: {
    value: {
      required: true
    }
  },
  data () {
    return {
      pickerVisible: false,
      pickerColor: '#FFFFFFFF',
      showFromBottom: true
    }
  },
  watch: {
    pickerColor (val) {
      this.$emit('input', val.hex8)
      this.$emit('change')
    }
  },
  methods: {
    showPicker: function () {
      this.pickerVisible = true
      let component = this.$refs.component
      let parent = component.offsetParent
      let rect = component.getBoundingClientRect()
      let parentRect = parent.getBoundingClientRect()
      if (rect.y - parentRect.y > (parentRect.y + parentRect.height - rect.y - rect.height)) {
        this.showFromBottom = false
      } else {
        this.showFromBottom = true
      }
    },
    hidePicker: function () {
      this.pickerVisible = false
    }
  }
}
</script>
<style scoped lang="scss">
.component {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  height: 44px;
  .text {
    font-size: 14px;
    font-weight: normal;
    color: rgba(102,102,102,1);
  }
  .color {
    margin-left: 15px;
    position: relative;
    width: 30px;
    height: 30px;
    .preview {
      position: relative;
      top: 1px;
      left: 1px;
      width: 28px;
      height: 28px;
      border: 1px solid rgba(224,224,224,1);
      border-radius: 4px;
    }
    .icon {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 12px;
      height: 12px;
    }
  }
  .selector {
    position: absolute;
    right: 0;
    z-index: 1000;
  }
}
</style>
