function markApplies(doc, ranges, type) {
  for (let i = 0; i < ranges.length; i++) {
    const { $from, $to } = ranges[i]
    let can = $from.depth === 0 ? doc.type.allowsMarkType(type) : false
    doc.nodesBetween($from.pos, $to.pos, node => {
      if (can) return false

      can = node.inlineContent && node.type.allowsMarkType(type)
      return true
    })
    if (can) return true
  }
  return false
}

// https://github.com/ProseMirror/prosemirror-commands/blob/master/src/commands.js
export default function applyMark(state, view, tr, markType, attrType, color) {
  if (!tr.selection || !tr.doc || !markType) return tr

  const { empty, $cursor, ranges } = tr.selection

  if ((empty && !$cursor) || !markApplies(tr.doc, ranges, markType)) return tr

  if ($cursor) {
    // tr = tr.removeStoredMark(markType)
    // return attrs ? tr.addStoredMark(markType.create(attrs)) : tr
  }

  let has = false
  for (let i = 0; !has && i < ranges.length; i++) {
    const { $from, $to } = ranges[i]
    has = tr.doc.rangeHasMark($from.pos, $to.pos, markType)
  }

  // $from $to 切割以 span 为单位的 from 和 to
  for (let i = 0; i < ranges.length; i++) {
    const { $from, $to } = ranges[i]

    let nodes = []
    state.doc.nodesBetween($from.pos, $to.pos, node => {
      nodes = [...nodes, node]
    })

    nodes.forEach(node => {
      const { type, marks, nodeSize } = node
      const { name } = type
      if (name === 'text') {
        const { attrs: oldAttrs } = marks[0]
        let { style } = oldAttrs
        if (style) {
          const arr = style.split(';')
          let flag = false
          for (let i = 0; i < arr.length; i++) {
            const property = arr[i]
            const attrArr = property.split(':')
            if (attrArr[0] === attrType) {
              attrArr[1] = color
              arr[i] = `${attrArr[0]}:${attrArr[1]}`
              flag = true
            }
          }
          if (!flag) {
            style = `${style}${attrType}:${color};`
          } else {
            style = arr.join(';')
          }
        } else {
          style = `${attrType}:${color};`
        }
        const newAttr = { ...oldAttrs, style }
        const { id } = oldAttrs
        const dom = document.getElementById(id)
        const from = view.posAtDOM(dom)
        const to = from + nodeSize
        if (has) {
          tr = tr.removeMark(from, to, markType)
        }
        if (newAttr) {
          tr = tr.addMark(from, to, markType.create(newAttr))
        }
      }
    })
  }

  return tr
}
