const kTestEnv = true
const kVersion = '1.5.0'
const kChannel = 'default'

export function isTestEnv () {
  return kTestEnv
}

export function getAppVersion () {
  return kVersion
}

export function getAppChannel () {
  return kChannel
}

export function getApiBaseUrl () {
  return kTestEnv ? 'https://dev.mapi.moyincloud.com' : 'https://mapi.moyincloud.com'
}

export function getH5BaseUrl () {
  return kTestEnv ? 'https://dev.m.moyincloud.com' : 'https://m.moyincloud.com'
}

export function getH5DocUrl (docId) {
  return getH5BaseUrl() + '/#/detail?docId=' + docId
}

export function getH5GroupUrl (fsId, userId) {
  return getH5BaseUrl() + '/#/group?fsId=' + fsId + '&userId=' + userId
}
