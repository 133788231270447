<template>
  <div class="content">
    <navbar />
    <div class="line"></div>
    <div class="main-content">
      <sidebar />
      <div class="vline"></div>
      <app-main />
    </div>
  </div>
</template>
<script>
import AppMain from './components/AppMain.vue'
import Navbar from './components/Navbar.vue'
import Sidebar from './components/Sidebar'

export default {
  name: 'LayoutIndex',
  components: {
    Navbar,
    AppMain,
    Sidebar
  }
}
</script>
<style scoped lang="scss">
.content {
  display: flex;
  flex-direction: column;
  font-family: PingFangSC-Regular, PingFang SC;
  .line {
    height: 1px;
    background: rgba(216,216,216,0.5);
  }
  .main-content {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    .vline {
      width: 1px;
      height: 100%;
      background: rgba(216,216,216,0.5);
    }
  }
}
</style>
