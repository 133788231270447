import axios from 'axios'
import md5 from 'js-md5'
import Vue from 'vue'
import { getUid, getToken, getSalt, removeUserInfo, removeProjectId } from './auth'
import { getApiBaseUrl, getAppVersion, getAppChannel } from '../common/const'
import router from '../router'

// create an axios instance
const service = axios.create({
  baseURL: getApiBaseUrl(), // api的baseUrl
  timeout: 60 * 30 * 1000, // request timeout
  headers: {
  }
})

service.interceptors.request.use(
  config => {
    if (config.url === 'audio/merge' || config.url === 'audio/doc/modify' ||
      config.url === 'audio/split/word/callbackWithDoa' ||
      config.url === 'audio/doc/fragment/share/generate' ||
      config.url === 'audio/link/save') {
      config.headers['Content-Type'] = 'application/json'
    }
    if (!config.noparam) {
      fillParam(config)
    }
    return config
  },
  error => {
    console.log(error)
    return Promise.reject(error)
  }
)
service.interceptors.response.use(
  response => {
    const res = response.data
    if (res.code === 1001 || res.code === 1003) {
      removeUserInfo()
      removeProjectId()
      router.replace('/login')
      return Promise.reject(res.desc || 'error')
    } else {
      return response
    }
  },
  error => {
    // 避免每个请求都需要写错误回调
    console.log(JSON.parse(JSON.stringify(error)))
    if (error.config.url && !error.config.url.endsWith('audio/split/word/progress/v1') &&
        !error.config.url.endsWith('audio/user/systemMessageUnreadCount') &&
        !error.config.url.endsWith('audio/doc/simple/share')) {
      if (error.response) {
        Vue.prototype.$message('服务器错误，请稍后重试', 'error')
      } else if (error.request) {
        Vue.prototype.$message('网络错误，请稍后重试', 'error')
      } else {
        Vue.prototype.$message('未知错误，请稍后重试', 'error')
      }
    }
    return Promise.reject(error)
  }
)

function fillParam (config) {
  if (config.params == null) config.params = {}
  var uid = getUid()
  var token = getToken()
  var salt = getSalt()
  config.params.v = getAppVersion()
  config.params.ch = getAppChannel()
  config.params.t = new Date().getTime()
  if (uid != null) {
    config.params.uid = uid
  }
  if (token != null) {
    config.params.token = token
  }
  var os = getOS()
  config.params.f = 'web'
  config.params.osv = os
  config.params.s = caculateSignature(config.params, salt)
}

function caculateSignature (params, salt) {
  var array = []
  for (var key in params) {
    array.push(key)
  }
  array.sort()
  var string = ''
  for (var i = 0; i < array.length; i++) {
    key = array[i]
    string += key
    string += params[key]
  }
  string += salt
  return md5(string).toLocaleUpperCase()
}

function getOS () {
  var isWin = (navigator.platform === 'Win32') || (navigator.platform === 'Windows')
  var isMac = (navigator.platform === 'Mac68K') || (navigator.platform === 'MacPPC') || (navigator.platform === 'Macintosh') || (navigator.platform === 'MacIntel')
  if (isMac) return 'Mac'
  var isUnix = (navigator.platform === 'X11') && !isWin && !isMac
  if (isUnix) return 'Unix'
  var isLinux = (String(navigator.platform).indexOf('Linux') > -1)
  if (isLinux) return 'Linux'
  if (isWin) return 'Win'
  return 'other'
}

export default service
