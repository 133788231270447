import request from './../utils/request'

export function addDir(data) {
  if (data == null) data = {}
  data.t = new Date().getTime()
  return request({
    url: 'audio/doc/fs/addDir',
    method: 'post',
    params: data
  })
}

export function deleteDir(data) {
  if (data == null) data = {}
  data.t = new Date().getTime()
  return request({
    url: 'audio/doc/fs/delete',
    method: 'post',
    params: data
  })
}

export function getDirList(data) {
  if (data == null) data = {}
  data.t = new Date().getTime()
  return request({
    url: 'audio/doc/fs/list',
    method: 'get',
    params: data
  })
}

export function modifyDirName(data) {
  if (data == null) data = {}
  data.t = new Date().getTime()
  return request({
    url: 'audio/doc/fs/modifyName',
    method: 'post',
    params: data
  })
}

export function moveFile(data) {
  if (data == null) data = {}
  data.t = new Date().getTime()
  return request({
    url: 'audio/doc/fs/move',
    method: 'post',
    params: data
  })
}

export function moveMulti(data) {
  if (data == null) data = {}
  data.t = new Date().getTime()
  return request({
    url: 'audio/doc/fs/moveMulti',
    method: 'post',
    params: data
  })
}

export function cancelFsDir (data) {
  if (data == null) data = {}
  data.t = new Date().getTime()
  return request({
    url: 'audio/doc/fs/cancel',
    method: 'post',
    params: data
  })
}

export function listFsDocGroup (data) {
  if (data == null) data = {}
  data.t = new Date().getTime()
  return request({
    url: 'audio/doc/fs/list/group',
    method: 'post',
    params: data
  })
}
