<template>
  <div class="navbar">
    <div class="logo">
      <img class="logo-image" src="../../assets/homepage/logo-image.svg" :style="{ top: isWin ? '0px' : '' }" />
      <img class="logo-font" src="../../assets/homepage/logo-font.svg" :style="{ top: isWin ? '0px' : '' }" />
    </div>
    <div class="search-input">
      <img src="../../assets/homepage/search.svg" />
      <input v-model="searchText" type="text" placeholder="搜索标题、内容" @keypress.enter="clickSearchEnter" />
    </div>
    <div class="space"></div>
    <message-button />
    <div class="vline"></div>
    <avatar-button ref="avatar" @quit="clickQuit" />
  </div>
</template>
<script>
import MessageButton from './button/MessageButton.vue'
import AvatarButton from './button/AvatarButton.vue'
import { isWinOS } from '@/utils/os'
import { removeProjectId, removeUserInfo } from '../../utils/auth'

export default {
  name: 'NavBar',
  components: {
    MessageButton,
    AvatarButton
  },
  data () {
    return {
      searchText: '',
      isWin: false
    }
  },
  mounted () {
    if (this.$route.path === '/moyin/homepage/search') {
      this.searchText = this.$route.query.query
    }
    this.isWin = isWinOS()
    this.$refs.avatar.buildAvatarText()
  },
  methods: {
    clickSearchEnter: function () {
      if (this.searchText !== '') {
        this.$router.replace({
          path: '/moyin/homepage/search',
          query: {
            query: this.searchText,
            ts: new Date().getTime()
          }
        })
      } else {
        this.$message('搜索词不能为空', 'error')
      }
    },
    clickQuit: function () {
      removeUserInfo()
      removeProjectId()
      this.$router.replace('/login')
    }
  }
}
</script>
<style scoped lang="scss">
.navbar {
  height: 70px;
  display: flex;
  flex-direction: row;
  align-items: center;
  -webkit-app-region: drag;
  .logo {
    margin-left: 20px;
    width: 196px;
    display: flex;
    flex-direction: row;
    align-items: center;
    .logo-image {
      position: relative;
      width: 32px;
      height: 26px;
    }
    .logo-font {
      position: relative;
      margin-left: 10px;
      width: 75px;
      height: 22px;
    }
  }
  .search-input {
    width: 360px;
    height: 38px;
    position: relative;
    -webkit-app-region: no-drag;
    & img {
      position: absolute;
      width: 14px;
      height: 14px;
      left: 10px;
      top: 12px;
    }
    & input {
      outline: none;
      background: rgba(243,245,247,1);
      border: 1px solid transparent;
      border-radius: 4px;
      padding-left: 32px;
      width: 326px;
      height: 36px;
      &::placeholder {
        font-size: 12px;
        font-weight: normal;
        color: rgba(153,153,153,1);
      }
      &:focus {
        background: rgba(255,255,255,1);
        border: 1px solid rgba(0,112,255,1);
        border-radius: 4px;
        box-shadow:  0 0 3px 0 rgba(0,112,255,0.7);
        color: rgba(51,51,51,1);
      }
    }
  }
  .space {
    flex-grow: 1;
  }
  .vline {
    width: 1px;
    height: 12px;
    background: rgba(216,216,216,1);
    margin-left: 16px;
    margin-right: 16px;
    position: relative;
  }
}
</style>