import { setBlockType } from 'tiptap-commands'
import { Node } from 'tiptap'

export default class DeleteWordsNode extends Node {
  get name() {
    return 'delete_words'
  }

  get schema() {
    return {
      inline: true,
      attrs: {
        class: {
          default: 'word-deleted-words'
        },
        id: {
          default: ''
        },
        'data-type': {
          default: 'delete_words'
        }
      },
      group: 'inline',
      draggable: false,
      parseDOM: [
        {
          tag: 'label',
          getAttrs: dom => ({
            id: dom.getAttribute('id'),
            'data-type': dom.getAttribute('data-type'),
            class: dom.getAttribute('class')
          })
        }
      ],
      toDOM: node => {
        return [
          'label',
          {
            ...node.attrs
          },
          0
        ]
      }
    }
  }

  commands({ type }) {
    return () => setBlockType(type)
  }
}
