import request from './../utils/request'

export function getSplitWordCallbackWithDoaV1 (data) {
  if (data == null) data = {}
  data.t = new Date().getTime()
  return request({
    url: 'audio/split/word/callbackWithDoa/v1',
    method: 'post',
    data: data
  })
}
export function getSplitWordProgressV1 (data) {
  if (data == null) data = {}
  data.t = new Date().getTime()
  return request({
    url: 'audio/split/word/progress/v1',
    method: 'get',
    params: data
  })
}
export function addFeedback (data) {
  return request({
    url: 'audio/feedback/add',
    method: 'post',
    params: data
  })
}

export function updateAudioDocFileMeta (data) {
  if (data == null) data = {}
  data.t = new Date().getTime()
  return request({
    url: 'audio/doc/update/fileMeta',
    method: 'post',
    params: data
  })
}
