const ProjectIdKey = 'project_id'
const UserInfoKey = 'user_info'
let flag = 0
export function setUserInfo (userInfo) {
  if (userInfo != null) {
    localStorage.setItem(UserInfoKey, JSON.stringify(userInfo))
  }
}

export function getUserInfo () {
  var userInfoStr = localStorage.getItem(UserInfoKey)
  if (userInfoStr) {
    setUniqueId(JSON.parse(userInfoStr).user)
  }
  return userInfoStr != null ? JSON.parse(userInfoStr) : null
}

export function isLogined () {
  return getUserInfo() != null
}

export function removeUserInfo () {
  localStorage.removeItem(UserInfoKey)
}

export function setProjectId (projectId) {
  if (projectId != null) {
    localStorage.setItem(ProjectIdKey, projectId)
  }
}

export function getProjectId () {
  return localStorage.getItem(ProjectIdKey)
}

export function removeProjectId () {
  localStorage.removeItem(ProjectIdKey)
}

export function getUid () {
  var userInfo = getUserInfo()
  return userInfo != null ? userInfo.user.id : null
}

export function getToken () {
  var userInfo = getUserInfo()
  return userInfo != null ? userInfo.token : null
}

export function getSalt () {
  var userInfo = getUserInfo()
  return userInfo != null ? userInfo.salt : null
}


export function setUniqueId (user) {
  if (flag === 0) {
    flag++
    window.collectEvent('config', { 
      user_unique_id: `${user.companyName}-${user.realName}-${user.email}`  //设置唯一用户ID 
    })
    window.collectEvent('getToken', (token) => {
      console.log(token)
    })
  }
  
}